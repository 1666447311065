import React from "react";

export default function DaysList({
  groupSession,
  deletegroupsession,
  activetoggle,
}) {


  const convertoarray= (string)=>{
   
    let array= string.split(',')
    array = [...new Set(array)];
    return array.join(' , ')
  }


  const sessionlist = groupSession.map((session, index) => {


    return (
      <tr className="eventsearch text-center" key={index}>
        <td style={{width:10}}>{index + 1}</td>
        <td style={{width:20}} >{session.name}</td>
        <td style={{maxWidth:80,overflow:"scroll"}} >{convertoarray(session.sessions)}</td>
        <td style={{ display: "flex", justifyContent: "center", }}>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={session.active ? true : false}
              onChange={() => activetoggle(session, !session.active,session.session_obj_ids)}
            />
            <span className="custom-toggle-slider rounded-circle " />
          </label>
        </td>
        <td style={{width:10}}>
          <button
            onClick={() => deletegroupsession(session)}
            className="btn btn-sm btn-icon btn-2 btn-danger"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </button>
        </td>
      </tr>
    );
  });

  return groupSession.length > 0 ? (
    <div style={{ padding: 20 }}>
      <center>
        <h4 className="text-muted text-center">ALL Session Groups</h4>
        <table style={{width: '100%'}} className="table table-bordered">
          <thead className="bg-default text-white text-center">
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th  scope="col">Session Group</th>
              <th style={{width:20}} scope="col">Sync to mobile</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>

          <tbody>{sessionlist}</tbody>
        </table>
      </center>
    </div>
  ) : (
    <div>No Session Groups Found</div>
  );
}
