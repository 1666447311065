import React, { Component } from "react";
import axios from "axios";
// import moment from "moment";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./monitoringstyle.css";
// import Turnstile_IMG from "../../../../images/turnstile.png";
import Mobile_IMG from "../../../../images/mobile.svg";
import Laptop_IMG from "../../../../images/laptop.png";

class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventmonitors: [],
      event_obj_id: "",
      devicename: [],
      timenow: "",
      time_zone: "",
      config_ver: 0,
    };
    // this.getNameById = this.getNameById.bind(this);
  }

  geteventmonitors = async (event_obj_id) => {
    await axios
      .get(`/cms/events/eventmonitor/${event_obj_id}`)
      .then((response) => {
        if (response.data.data.length > 0) {
          this.setState({
            eventmonitors: response.data.data,
            timenow: response.data.time_now,
            time_zone: response.data.time_zone,
          });
        }
      });
  };

  fetch_device = () => {
    let event_obj_id = sessionStorage.getItem("eventid");
    this.geteventmonitors(event_obj_id);
     this.fetch_event(event_obj_id);
  };

  fetch_event = async (event_obj_id) => {
    await axios.get(`/cms/events/${event_obj_id}`).then((response) => {
      this.setState({ config_ver: response.data.events.config_ver });
    });
  };

  componentDidMount() {
    this.fetch_device();
  }

  delete = (device) => {
    axios.get(`/cms/events/device_delete/${device._id}`).then((response) => {
      if (response.data.success) {
        alert("Delete Request Success !");
        this.fetch_device();
      } else {
        alert("something went wrong");
      }
    });
  };

  render() {
    let access_delete = false;

    const { timenow, config_ver } = this.state;

    if (this.props.auth.user.email === "darshan.asolkar@bookmyshow.com") {
      access_delete = true;
    }

    let eventmonitors = this.state.eventmonitors;

    let eventmonitorsdata = eventmonitors
      .sort((a, b) => a.lastupdated - b.lastupdated)
      .map((device, index) => {

        let start = moment(device.lastupdated, "DD-MM-YYYY HH:mm:ss").tz(
          this.state.time_zone
        );
        let end = moment(timenow, "DD-MM-YYYY HH:mm:ss").tz(
          this.state.time_zone
        );
        var diff = moment.duration(end.diff(start));
        var mins = Math.floor(diff.asMinutes());
        let status;
        let configstatus;
        let emergency;
        let offline_sync;
        this.devicename = "";
        let current = false;

        if (mins >= 2) {
          current = true;
          status = (
            <span className="blink badge p-1 mr-4 bg-danger text-white">
              <i className="bg-warning"></i>
            </span>
          );
        } else {
          status = (
            <span className="blink badge p-1 mr-4 bg-success text-white">
              <i className="bg-warning"></i>
            </span>
          );
        }

        if (device.config_ver === config_ver) {
          configstatus = (
            <span className="blink badge p-1 mr-4 bg-success text-white">
              <i className="bg-success">Upto date</i>
            </span>
          );
        } else {
          configstatus = (
            <span className="blink badge p-1 mr-4 bg-danger text-white">
              <i className="bg-warning">Outdated</i>
            </span>
          );
        }

        if (device.emergency) {
          emergency = (
            <span className="blink badge badge-dot p-1 mr-4 bg-orange text-white">
              Enable
            </span>
          );
        } else {
          emergency = (
            <span className="blink badge badge-dot p-1 mr-4 bg-success text-white">
              Disable
            </span>
          );
        }

        if (device.offline_sync) {
          offline_sync = (
            <span className="blink badge badge-dot p-1 mr-4 bg-orange text-white">
              Enable
            </span>
          );
        } else {
          offline_sync = (
            <span className="blink badge badge-dot p-1 mr-4 bg-success text-white">
              Disable
            </span>
          );
        }

        let image;

        let server = device.is_server;

        if (server === true) {
          image = Laptop_IMG;
        } else {
          image = Mobile_IMG;
        }

        return (
          <tr key={index + 1} className="text-center">
            <td>{index + 1}</td>
            <td scope="row">
              <div>
                <img height="20" alt="mobile" src={image} />
                <br />
                {device.version}
              </div>
            </td>
            <td>{device.device_id}</td>

            <td>{device.lastsynced}</td>
            <td>{device.lastupdated}</td>
            <td>
              <div className="d-flex align-items-center">
                <div>
                  <span>{Math.floor(device.battery)}%</span>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow="60"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${device.battery}%` }}
                    />
                  </div>
                </div>
              </div>
            </td>
            <td>
              {device.pending} / {device.scans}
            </td>

            <td>{device.gate_details}</td>
            <td>{device.day_details}</td>
            <td>{device.server}</td>
            <td>{device.inout ? "IN" : "OUT"}</td>
            {/* <td>{offline_sync}</td>
          <td>{emergency}</td> */}
            <td>{configstatus}</td>
            <td>{status}</td>
            <td>
              <button
                disabled={access_delete ? false : true}
                onClick={() => this.delete(device)}
                className="btn btn-sm btn-icon btn-2 btn-danger"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
              </button>
            </td>
          </tr>
        );
      });

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="mb-0">Device Monitoring</h3>
              </div>
              <div className="col text-right">
                {/* <a href="#!" className="btn btn-sm btn-primary">
                  Download
                </a> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr</th>
                  <th scope="col">Device</th>
                  <th scope="col">ID</th>
                  <th scope="col">LAST CONFIG FETCH	</th>
                  <th scope="col">LAST DATA PUSH</th>
                  <th scope="col">Battery</th>
                  <th scope="col">Barcodes</th>
                  <th scope="col">Gate</th>
                  <th scope="col">Session</th>
                  <th scope="col">Server</th>
                  <th scope="col">Mode</th>
                  {/* <th scope="col">Offline Sync</th>
                  <th scope="col">Emergency</th> */}
                  <th scope="col">Config status</th>
                  <th scope="col">Status</th>

                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>{eventmonitorsdata}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

Monitor.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Monitor);
