import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import uuid from "react-uuid";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import './report9.css'

function Report9({ eventtype, session,groupevents }) {
  const [data, setdata] = useState([]);


  useEffect(() => {

    if (session) {
      report_9();
      const interval = setInterval(() => {
        report_9();
      }, 45000);
      return () => clearInterval(interval);
    } else {
      setdata([])
    }

  }, [session]);

  const report_9 = () => {
    // console.log("report_9");
    const geteventid = sessionStorage.getItem("eventid");

    let report = {
      event_obj_id: groupevents?.length > 0
      ? [geteventid,...groupevents.map((event)=> event.event_obj_id)]
      : [geteventid],
      dashboard: true,
      session

    };


    axios
      // .post(`/pdf/report/analyticreport9`, report)
      .post(`/cms/v3/analytics/ticketpass_stats`,report)
      .then((response) => {
        if (response.data.success) {
          // setdata(response.data.report9);
          setdata(response.data.ticketpass_stats);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // let i = 0;
  return (
    <Fragment>
      {data.length > 0 ? (
        <center className="report9">
          <h4 className="text-muted">Ticket/Pass Usage/ Outstanding</h4>
          <table className="table table-bordered report9-result">
            <thead className="bg-primary text-white text-center thead-dark">
              <tr>
                <th style={{ color: "white" }} scope="col">
                  Sr.
                </th>
                {/* {eventtype === 'acs-sgp' ? <th style={{ color: "white" }} scope="col">
                  Zone
                </th> : null} */}
                <th style={{ color: "white" }} scope="col">
                  Zone
                </th>
                <th style={{ color: "white" }} scope="col">
                  Category
                </th>
                <th style={{ color: "white" }} scope="col">
                  Attendee Type
                </th>
                <th style={{ color: "white" }} scope="col">
                  Tickets sold/Passes issued
                </th>
                <th style={{ color: "white" }} scope="col">
                  Consumed 
                </th>
                <th style={{ color: "white" }} scope="col">
                  Outstanding Balance
                </th>
              </tr>
            </thead>




            <tbody >
              {data.map((datass, index) => {
                return (
                  <tr className="eventsearch text-center" key={uuid()}>
                    <td>{index + 1}</td>
                    {/* {eventtype === 'acs-sgp' ? <td>{datass.zone}</td> : null} */}
                    <td>{datass.zone}</td>
                    <td>{datass.category_name}</td>
                    <td>{datass.barcode_type}</td>
                    <td>{datass.total}</td>
                    <td>{datass.scan}</td>
                    <td>{datass.pending}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </center>
      ) : null}
    </Fragment>
  );
}



Report9.propTypes = {
  screen: PropTypes.string,
  session_selected: PropTypes.object,
};



const mapStateToProps = (state) => ({
  session: state.event.session
});
export default connect(mapStateToProps, null)(
  withRouter(Report9)
);