import React from "react";
import { Input } from "reactstrap";
import axios from "axios";
import { useState } from "react";
import List from "./List";

function Enquiry({ groupevents, eventid }) {
  const [barcodes, setBarcodes] = useState([]);
  const [search_barcode, setSearch_barcode] = useState("");

  const search = (e) => {
    e.preventDefault();
    synclogs();
  };

  const synclogs = async () => {
    let getevent = {
      event_obj_id:
        groupevents.length > 0
          ? [eventid, ...groupevents.map((event) => event.event_obj_id)]
          : [eventid],
      search_barcode: search_barcode.trim(),
      enquiry: true,
    };

    const { data } = await axios.post("/cms/dashboard/getenquiry/", getevent);

    if (data.data.length) {
      setBarcodes(data.data);
    }
  };

  return (
    <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
      <div className="card shadow">
        <div className="card-header border-0">
          <div className="row align-items-center">
            <div className="col text-left"></div>
          </div>
        </div>

        <center>
          <form onSubmit={search}>
            <Input
              placeholder="Enter Barcode / Booking / Transaction ID"
              onChange={(e) => {
                if (e.target.value === "") {
                  setSearch_barcode("");
                  synclogs();
                } else {
                  setSearch_barcode(e.target.value);
                }
              }}
              type="text"
              style={{ position: "relative", width: "50%", bottom: 20 }}
              className="mb-2"
            />
          </form>
        </center>

        <div
          style={{
            height: "800px",
            display: "inline-block",
            width: "100%",
            overflow: "auto",
          }}
          className="table-responsive"
        >
          <table className="table align-items-center" id="table-to-xls">
            <thead className="thead-light">
              <tr className="text-center">
                <th scope="col">Sr.</th>
                <th scope="col">Booking ID</th>
                <th scope="col">Barcode</th>
                <th scope="col">Category</th>
                <th scope="col">Session</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody id="myTable">
              {barcodes.map((barcode, index) => {
                let typecheck = false;
                return (
                  <List
                    enquiry={true}
                    sync={synclogs}
                    key={index}
                    barcodeData={barcode}
                    typecheck={typecheck}
                    index={index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Enquiry;
