import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import "./login.css";

import Avatar from "./avatar";
import wave_image from "./img/wave.svg";
import Logo from "./logo_bms_black.svg";

import { loginUser, cloud_login } from "../../../../helper/actions/authActions";
class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      msg: "",
    };

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  login(e) {
    e.preventDefault();

    const login = {
      email: this.state.email,
      password: this.state.password,
      error: "",
    };
    this.props.loginUser(login, this.props.history);
  }

  alert_modal = (status, msg) => {
    if (status === true) {
      return Swal.fire("Success!", msg, "success");
    } else {
      return Swal.fire("Error!", msg, "error");
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const inputs = document.querySelectorAll(".input");

    function addcl() {
      let parent = this.parentNode.parentNode;
      parent.classList.add("focus");
    }

    function remcl() {
      let parent = this.parentNode.parentNode;
      if (this.value === "") {
        parent.classList.remove("focus");
      }
    }

    inputs.forEach((input) => {
      input.addEventListener("focus", addcl);
      input.addEventListener("blur", remcl);
    });
  }

  render() {
    if (localStorage.jwtToken) {
      return <Redirect to={"/home"} />;
    }
    return (
      <div>
        <img alt="logo" className="loginnew-wave" src={wave_image} />
        <div className="loginnew-container">
          <div className="loginnew-img">
            <Avatar />
          </div>
          <div className="loginnew-login-content">
            <form className="loginnew-form" onSubmit={this.login}>
              <img alt="logo" className="loginnew-bms-logo" src={Logo} />

              {/* <h2 className="title">Welcome</h2> */}
              <div className="loginnew-input-div loginnew-one">
                <div className="loginnew-i">
                  <i className="fas fa-user"></i>
                </div>
                <div className="div">
                  <h5>Enter Email ID</h5>
                  <input
                    type="text"
                    className="input"
                    autoComplete="new-password"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="loginnew-input-div pass">
                <div className="loginnew-i">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="div">
                  <h5>Enter Password</h5>
                  <input
                    type="password"
                    className="input"
                    autoComplete="off"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <center>
                <h5 className="text-red">{this.state.msg}</h5>
              </center>
              {/* <a href="#">Forgot Password?</a> */}
              <input type="submit" className="loginnew-btn" value="Login" />
              <span style={{ fontSize: 10, color: "#474156" }}>
                © Bigtree Entertainment Pvt. Ltd. All Rights Reserved. Ver 2.0.6
              </span>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Body.protoTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  Avatar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { loginUser, cloud_login })(
  withRouter(Body)
);
