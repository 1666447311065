import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
// import Select from "react-select";

import { gateedit_update } from "../../../../../helper/actions/eventActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Gatelist from "./GatesList";
import Swal from "sweetalert2";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

class Gates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      catrefrence: "",
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      categories: [],
      options: [],
      gatename: "",
      prevcategories: [],
      gates: [],
      zone: [],
      zone_value: "",
      update_gate: false,
      prev_option: [],
      selected_gate: {},
      gate_edit: false,
      selected_option: [],
      eventtype: "",
      inputValue: "",
      selectedFilm:[],
      optioncategories:[]
    };

    

    this.getcategory = this.getcategory.bind(this);
    this.creategate = this.creategate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.savegate = this.savegate.bind(this);
    this.delete = this.delete.bind(this);
    this.synctomobile = this.synctomobile.bind(this)
    this.fetchCategories = this.fetchCategories.bind(this)
  }

  seleceted_cat = (selected_gate) => {
    // console.log(selected_gate)
    const { options } = this.state;
    let pre_op = [];
    let sel_op = [];

    options.map((option) => {

      let is_selected = selected_gate.categories.findIndex(
        (k) => k === option.value
      );

      if (is_selected === Math.sign(-3)) {
        return pre_op.push(option);
      } else {
        return sel_op.push(option);
      }
    });

    this.setState({
      options: pre_op,
      gatename: selected_gate.gatename,
      zone_value: selected_gate.zone_ref,
      update_gate: true,
      prev_option: options,
      selected_gate,
      selected_option: sel_op,
    });
  };

  unselect_cat = () => {
    this.props.gateedit_update(false);
    this.setState({
      options: this.state.prev_option,
      gatename: "",
      zone_value: "",
      update_gate: false,
      selected_gate: {},
      gate_edit: false,
    });
  };

  delete = (id) => {
    axios
      .delete(`/cms/gates/standard/${id}`)
      .then(() => {
        Swal.fire("Success!", "Gates Deleted Succesfully!", "success");
        this.gategatedata(this.state.event_obj_id);
      })
      .catch((err) => {
        Swal.fire("", "Something went wrong", "error");
        console.log(err);
      });
  };

  savegate() {
    const newgate = {
      event_id_bms: this.state.event_id_bms,
      gatename: this.state.gatename,
      event_obj_id: this.state.event_obj_id,
      categories: this.state.categories,
      // catrefrence: this.state.catrefrence,
      zone_ref: this.state.zone_value,
    };

    axios
      .post("/cms/gates/standard", newgate)

      .then((newgates) => {
        Swal.fire("Success!", "Gates Created Succesfully!", "success");

        this.setState({ gatename: "" });
        this.gategatedata(this.state.event_obj_id);
      })
      .catch((err) => {
        //alert(err.response.data.msg);
        Swal.fire("Error", err.response.data.msg, "error");
      });
  }

  async gategatedata(event_obj_id) {
    await axios
      .get(`/cms/gates/standard/${event_obj_id}`)
      .then((gates) => {
        this.setState({ gates: gates.data.gates });
      })
      .catch((err) => console.log(err));
  }

  async synctomobile(gate, index) {
    let obj = {
      _id: gate._id,
      synctomobile: !gate.synctomobile,
    };


    axios.post(`/cms/gates/standard/is_disable_toggle`, obj).then((respos) => {
      if (respos.data.success) {

        let newgates= []

        for (const gateData of this.state.gates) {
            if(gateData._id===gate._id){
              gateData.synctomobile=!gate.synctomobile
            }
            newgates.push(gateData) 
        } 

        this.setState({
          gates:newgates
        });

      } else {
        alert("something went wrong");
      }
    });
  }



  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange = (selectedOption) => {

    console.log(selectedOption)

    //  console.log(selectedOption);
    this.setState({
      selectedOption: selectedOption,
    });
  };

  async autocreategate() {
    const { gatename, options } = this.state;
    if (!gatename) {
      return alert("Gate Name Required!");
    }

    let categories = options.map((cat) => cat.value);
    // let catrefrence = options.map((cat) => cat.label).join(",");

    this.setState({ categories }, () => this.savegate());
  }

  async creategate(e) {
    e.preventDefault();

    let categories = [];
    let catrefrence = [];

    const { selectedOption } = this.state;

    if (selectedOption.length === 0) {
      return Swal.fire("Error!", "Categories Empty", "error");
    }

    selectedOption.map((selected, index) => {
      return categories.push(selected.value);
      //  catrefrence.push(selected.label);
    });

    // catrefrence = catrefrence.join();

    let category2 = [];

    let check = categories[0][0].length;

    if (check > 1) {
      category2 = categories[0];
    } else {
      category2 = categories;
    }

    await this.setState({ categories: category2 });

    this.savegate();
  }

  getcategory() {
    const categories = this.state.prevcategories;
    // let copycat = categories;
    // let selectall = [];

    // copycat.map(({ _id }) => {

    let options = [];
    let zone = [];


    categories.map((cat, index) => {
      let preoptions;

      if (this.state.eventtype === "acs-sg") {
        preoptions = {
          value: cat._id,
          label: `${cat.category_name} | ${cat.dayref} | ${cat.session} | ${cat.param2} | ${cat.param3} | ${cat.param4} | ${cat.event_id_bms} `,
        };
      } else {
        preoptions = {
          value: cat._id,
          label: `${cat.category_name} | ${cat.dayref} | ${cat.session} | ${cat.event_id_bms}  `,
        };
      }

      if (cat.zone.length === 1) {
        zone.push("All");
      } else {
        zone.push(cat.zone);
      }
      // alert(cat?.zone.length)
      return options.push(preoptions);
    });

    zone = [...new Set(zone)];

    this.setState({ options, zone });
  }

  delete_cat = (cat) => {
    // const { prevcategories, options } = this.state;
    // prevcategories.map((category) => {});
    // console.log(this.state.options);
    // console.log(cat);
  };

  update_gate = (e) => {
    let array = [];
    let label = [];

    e.preventDefault();

    const { selected_gate, selectedOption, selected_option } = this.state;

    selected_option.map((option) => {
      array.push(option.value);
      // label.push(option.label);
    });

    selectedOption.map((category) => {
      array.push(category.value);
      // label.push(category.label);
    });

    selected_gate.gatename = this.state.gatename;
    selected_gate.zone_ref = this.state.zone_value;
    selected_gate.categories = array;
    // selected_gate.catrefrence = label.toString();

    // console.log(selected_gate);

    this.set_gate(selected_gate);

    // alert("update");
  };

  set_gate = (gate_details2) => {
    axios
      .patch(`/cms/gates/standard/${gate_details2._id}`, gate_details2)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          Swal.fire(`Success`, `Gate Updated`, "success");
          this.unselect_cat();
        } else {
          Swal.fire(`Error`, `Something Went Wrong`, "errror");
        }
      });
  };


  async fetchCategories() {


    const { groupevents, event_obj_id } = this.state

    // if (value.length < 2) {
    //   return this.setState({ optioncategories: [] });
    // }



    let request_data = {
      page: 1,
      id: groupevents.length > 0
        ? [event_obj_id, ...groupevents.map((event) => event.event_obj_id)]
        : [event_obj_id],
      limit: 5000,
      search: '',
      gates: true
    };


    await axios
      .post(`/cms/v3/categories/getcategories`, request_data)
      .then((categories) => {

        if(categories.data.docs.length===0){
          return this.setState({ prevcategories: [] });
        }
        

      //   let pre=[{
      //     title: "All",
      //     _id:'AllCAT'
      //    }]

      //    let zone=[];

      //    let newcategories = categories.data.docs.map((cat)=>{

      // if (cat.zone.length === 1) {
      //   zone.push("All");
      // } else {
      //   zone.push(cat.zone);
      // }
      //     if (this.state.eventtype === "acs-sg") {
      //      return {
      //       _id: cat._id,
      //         title: `${cat.category_name} | ${cat.dayref} | ${cat.session} | ${cat.param2} | ${cat.param3} | ${cat.param4} | ${cat.event_id_bms} `,
      //       };
      //     } else {
      //      return {
      //       _id: cat._id,
      //         title: `${cat.category_name} | ${cat.dayref} | ${cat.session} | ${cat.event_id_bms}  `,
      //       };
      //     }
      //    })
    
    
        this.setState({ prevcategories:categories.data.docs});
        this.getcategory();
        // this.gategatedata(geteventid);
      }).catch(() => {
        this.setState({ prevcategories: [] });
      })
  }

  async componentDidMount() {
    const geteventid = sessionStorage.getItem("eventid");



    let event = await axios.get(`/cms/events/${geteventid}`)

    if (event) {
      this.setState({
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: event.data.events._id,
        // prevcategories: categories.data.docs,
        eventtype: event.data.events.eventtype,
        groupevents: event.data.events.groupevents,
      });
      this.gategatedata(geteventid);
      this.fetchCategories()
    }


  }
  render() {
    const {
      selectedOption,
      eventname,
      event_id_bms,
      zone,
      gatename,
      update_gate,
      groupevents
    } = this.state;


    let selected_length = selectedOption;
    let count_selected = 0;

    if (selected_length === null) {
      count_selected = 0;
    } else {
      count_selected = 1;
    }
    return (
      <Fragment>
        <center>
          <div className="container  create-event">
            <h2 className="display-2 text-muted">Create Gates</h2>
            <br />

            <div className="row ">
              <div className="col-md-2" />
              <div className="col-md-8">
                <form
                  // onSubmit={this.creategate}
                  onSubmit={
                    this.state.update_gate ? this.update_gate : this.creategate
                  }
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3" />
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Gate Name :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="gatename"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Gate Name"
                            required
                            value={gatename}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3" />
                  </div>


                  {/* <div className="col-md-3" /> */}
                  <div className="col-md-12">

                    <Autocomplete
                      // autoSelect={true}
                      onChange={(event, value) => this.handleChange(value)}
                      freeSolo
                      multiple
                      id="checkboxes-tags-demo"
                      inputValue={this.state.inputValue}
                      options={this.state.options}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      searchText={"s"}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </React.Fragment>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) =>{
                             this.setState({ inputValue: e.target.value })
                            // this.fetchCategories(e.target.value)
                          }}
                          autoComplete="false"
                          variant="outlined"
                          label="Categories List"
                          placeholder="Choose Multiple Category"
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  {this.state.eventtype === "open" ? null : (
                    <div className="col-md-6 mt-3">
                      <div className="form-group ">
                        <div className="form-group ">
                          <select
                            onChange={this.onChange}
                            name="zone_value"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                            value={this.state.zone_value}
                          >
                            <option value="">Choose Zone</option>
                            {zone.map((zone, index) => {
                              return (
                                <option key={index} value={zone}>
                                  {zone}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div className="col-md-3" /> */}

                  <hr />

                  {update_gate ? (
                    <button
                      className="btn btn-icon btn-3 btn-warning"
                      type="submit"
                      disabled={count_selected === 0 ? true : false}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-bullet-list-67" />
                      </span>

                      <span className="btn-inner--text">Update Gate</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-icon btn-3 bg-default"
                      type="submit"
                      disabled={count_selected === 0 ? true : false}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-bullet-list-67" />
                      </span>

                      <span className="btn-inner--text">Create Gate</span>
                    </button>
                  )}
                </form>
                <button
                  className="btn btn-icon btn-3 mt-2 bg-default"
                  onClick={() => this.autocreategate()}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-bullet-list-67" />
                  </span>

                  <span className="btn-inner--text">Create Auto Gate</span>
                </button>
              </div>
              <div className="col-md-2" />
            </div>
          </div>
          {this.state.gates.length > 0 ? (
            <Gatelist
              synctomobile={this.synctomobile}
              groupevents={this.state.groupevents}
              delete={this.delete}
              seleceted_cat={this.seleceted_cat}
              gatedata={this.state.gates}
              unselect_cat={this.unselect_cat}
              gate_edit={this.state.gate_edit}
              delete_cat={this.delete_cat}
              eventtype={this.state.eventtype}
            />
          ) : null}
        </center>
      </Fragment>
    );
  }
}

Gates.propTypes = {
  gateedit_update: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  gateedit: state.event.gateedit,
});

export default connect(mapStateToProps, { gateedit_update })(Gates);
