import Dashboard from "views/pages/application/view/dashboards/Dashboard";
import GatesAnalytics from "views/pages/application/view/dashboards/Gatedashboard/GatesAnalytics";

import Groupevents from "views/pages/application/view/groupevents";
import Days from "views/pages/application/view/days";
import GroupDays from "views/pages/application/view/groupdays";
import Blacklist from "views/pages/application/view/blacklist";
import Whitelist from "views/pages/application/view/whitelist";
import Gates from "views/pages/application/view/gates";
import Categories from "views/pages/application/view/categories";
import Report from "views/pages/application/view/reports";
import Report2 from "views/pages/application/view/reports2";
import Logs from "views/pages/application/view/Logs";
import Monitoring from "views/pages/application/view/monitoring";
import Configuration from "views/pages/application/view/configuration";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-info",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "ni ni-chart-pie-35 text-info",
    component: GatesAnalytics,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Configurations",
    icon: "ni ni-settings text-red",
    state: "examplesCollapse",
    views: [
      {
        path: "/groupevents",
        name: "Event Group",
        miniName: "E",
        component: Groupevents,
        layout: "/admin",
      },
      {
        path: "/days",
        name: "Sessions",
        miniName: "D",
        component: Days,
        layout: "/admin",
      },
      {
        path: "/groupdays",
        name: "Session Groups",
        miniName: "D",
        component: GroupDays,
        layout: "/admin",
      },
      {
        path: "/categories",
        miniName: "C",
        name: "Categories",
        component: Categories,
        layout: "/admin",
      },
      {
        path: "/gates",
        name: "Gates",
        miniName: "G",
        component: Gates,
        layout: "/admin",
      },
      {
        path: "/whitelist",
        miniName: "W",
        name: "Whitelist",
        component: Whitelist,
        layout: "/admin",
      },
      {
        path: "/blacklist",
        miniName: "B",
        name: "Blacklist",
        component: Blacklist,
        layout: "/admin",
      },
      {
        path: "/configuration",
        miniName: "S",
        name: "Settings",
        component: Configuration,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/reports",
    name: "Report",
    icon: "ni ni-chart-bar-32 text-blue",
    component: Report,
    layout: "/admin",
  },
  {
    path: "/reports2",
    name: "Report V2",
    icon: "ni ni-chart-bar-32 text-blue",
    component: Report2,
    layout: "/admin",
  },
  {
    path: "/monitor",
    name: "Monitor",
    icon: "ni ni-controller text-yellow",
    component: Monitoring,
    layout: "/admin",
  },
  {
    path: "/logs",
    name: "Audit",
    icon: "ni ni-bullet-list-67 text-red",
    component: Logs,
    layout: "/admin",
  },
];

export default routes;
