import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Categories from "./Categories";
import Swal from "sweetalert2";

class CategoryStandard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventname: "",
      venuecode: "",
      event_id_bms: "",
      event_obj_id: "",
      days: [],
      categories: [],
      categories_sync: [],
      startdate: new Date(),
      enddate: new Date(),
      categorys: [],
      catref: "",
      dayref: "Day1",
      category_name: "",
    };

    this.onChange = this.onChange.bind(this);
    this.create_pickup_cat = this.create_pickup_cat.bind(this);
    this.delete = this.delete.bind(this);
  }

  async get_pickup_categories() {
    const { event_obj_id } = this.state;

    await axios
      .get(`/cms/transaction/get_category_trans/${event_obj_id}`)
      .then((response) => {
        let categorys = response.data.categorys;
        return this.setState({ categorys: categorys });
      })
      .catch((err) => console.log(err));
  }

  delete = (catid) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`/cms/category/standard/${catid}`)
            .then((result) => {
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Category has been deleted.",
                "success"
              );
              this.getcategories();
            })
            .catch((err) => {
              console.log(err.response.data);
              Swal.fire("Something went wrong!", "", "error");
            });
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Category is safe :)",
            "error"
          );
        }
      });
  };

  categorystandard() {
    this.props.categorystandard();
  }

  async create_pickup_category(e) {
    e.preventDefault();

    return this.create_pickup_cat();
  }

  create_pickup_cat = async (e) => {
    e.preventDefault();

    await axios
      .post("/cms/category/standard", this.state)
      .then((response) => {
        if (response.data.success) {
          let cat_id = response.data.id;
          this.update_category_id(cat_id);
        } else {
          return Swal.fire("Something Went wrong!", "", "error");
        }
      })
      .catch((err) => {
        return Swal.fire(err.response.data.msg, "", "error");
      });
  };

  update_category_id = async (cat_id) => {
    let update = {
      cat_id,
      event_obj_id: this.state.event_obj_id,
      catref: this.state.catref,
    };

    await axios
      .post("/cms/transaction/update_category_id", update)
      .then((response) => {
        if (response.data.success === true) {
          Swal.fire("Success!", "Category Created Succesfully!", "success");
          return this.getcategories();
        } else {
          return Swal.fire("Error!", "Something Went Wrong!", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange(e) {
    if (e.target.name === "catref") {
      this.setState({ category_name: e.target.value });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  getcategories = async () => {
    await axios
      .get(`/cms/category/standard/getall/${this.state.event_obj_id}`)
      .then((response) => {
        if (response) {
          this.setState({ categories: response.data });
        }
      })
      .catch((err) => console.log(err));
  };

  componentWillMount() {
    const geteventid = sessionStorage.getItem("eventid");

    axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: event.data.events._id,
        days: event.data.events.sessiondetails,
        venuecode: event.data.events.venuecode,
      });
      this.getcategories();
      this.get_pickup_categories();
    });
  }

  render() {
    //console.log(this.state.categories);
    const { eventname, event_id_bms, categorys } = this.state;

    let categorydata = "";

    if (categorys) {
      categorydata = categorys.map((category, i) => {
        return (
          <option className="text-center" key={i} value={category._id}>
            {category._id}
          </option>
        );
      });
    }

    return (
      <Fragment>
        <center>
          {/* <Completed event={true} category={true} /> */}
          <div className="container  create-event">
            <h2 className="display-2 text-muted">
              {/* Create Category - {eventtype.toUpperCase()} */}
            </h2>
            <br />

            <div className="row ">
              <div className="col-md-1" />
              <div className="col-md-10">
                <form onSubmit={this.create_pickup_cat}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label
                          className="text-muted"
                          htmlFor="exampleFormControlSelect2"
                        >
                          Category Name
                        </label>
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                {" "}
                                Category Name :
                              </span>
                            </span>
                          </div>
                          <input
                            value={this.state.category_name}
                            onChange={this.onChange}
                            name="category_name"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Category Name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Length :</span>
                            </span>
                          </div>
                          <input
                            name="length"
                            style={{ padding: "10px" }}
                            onChange={this.onChange}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Keep Blank For Default "
                            type="number"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Select Categories
                          </label>
                          <select
                            onChange={this.onChange}
                            name="catref"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                          >
                            <option className="text-center" value="">
                              Choose Category...
                            </option>
                            {categorydata}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Select Characters
                          </label>
                          <select
                            onChange={this.onChange}
                            name="alpha_numeric"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                          >
                            <option className="text-center" value="an">
                              Default
                            </option>
                            <option className="text-center" value="a">
                              Alphabets
                            </option>
                            <option className="text-center" value="n">
                              Numeric
                            </option>
                          </select>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Start With :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="starts"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Keep Blank For Default "
                            type="number"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> End with:</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="ends"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Keep Blank For Default "
                            type="number"
                          />
                        </div>
                      </div>
                    </div> */}

                    <hr />
                  </div>

                  <button
                    className="btn btn-icon btn-3 btn-primary"
                    type="submit"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">Create Category</span>
                  </button>
                </form>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
          <Categories
            delete={this.delete}
            categorystandard={this.categorystandard}
            categories={this.state.categories}
            eventtype={this.state.eventtype}
          />
        </center>
      </Fragment>
    );
  }
}

export default CategoryStandard;
