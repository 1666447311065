import React from "react";
// reactstrap components
import { Button, Card, CardBody, Modal, Row, Col, CardTitle } from "reactstrap";

class Modals extends React.Component {
  state = {
    exampleModal: true,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
    this.props.modal_props(false);
  };

  render() {
    const {
      total_barcodes_on_device,
      total_barcodes_pending_on_device,
      total_whitelist,
      total_blacklist,
      connected_device,
      total_whitelist_scan,
      total_blacklist_scan,
      activegatestring,
      activesessionstring,
    } = this.props.detail.server_details;


    // console.log(activesessionstring)

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Server : {this.props.detail.device_id}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Card className="card-stats mb-1">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      Active Gates
                    </CardTitle>
                    {activegatestring.length>0&&activegatestring.map((s, index) => {
                      return <><span key={index} className="h4 font-weight-bold mb-0 br">
                      {s}
                    </span>
                     <br />
                     </>
                    })}

                   
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="card-stats mb-1">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      Active Sessions
                    </CardTitle>
                    {activesessionstring&&activesessionstring.map((s, index) => {
                      return <><span key={index} className="h4 font-weight-bold mb-0 br">
                      {s}
                    </span>
                     <br />
                     </>
                    })}

                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="card-stats mb-1">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      Total Barcodes on Device
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0">
                      {total_barcodes_pending_on_device} / {total_barcodes_on_device}

                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="card-stats mb-1">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                    Total Whitelist With Active Session.
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0">
                    {total_whitelist}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="card-stats mb-1">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      Total Blacklist With Active Session.
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0">
                    {total_blacklist}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Modals;
