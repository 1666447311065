import React, { Component } from "react";
import List from "./Users";
import Swal from "sweetalert2";

import axios from "axios";

export default class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      users_backup: [],
    };
  }

  add = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Add User",
      html: `  
  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="devicetype">Access</label>
    <select class="custom-select custom-select-md" id="access_type">
     <option value="2" selected>User</option>
     <option value="1">Admin</option>
     <option value="0">SuperAdmin</option>
    </select>
  </div>
  <div class="form-group col-md-6">
      <label for="devicetype">Role</label>
      <select class="custom-select custom-select-md" id="role">
       <option value="Admin" selected>Admin</option>
       <option value="Superadmin">Superadmin</option>
       <option value="Console">Console</option>
       <option value="Validator">Validator</option>
       <option value="Api">Api</option>
      </select>
    </div>
  </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="deviceid">Name</label>
      <input type="text" class="form-control" id="name"
      placeholder="Please Enter Name">
    </div>
    <div class="form-group col-md-6">
    <label for="macid">Email</label>
    <input type="email" value="" class="form-control" id="email" 
     placeholder="Please Enter Email ID">
  </div>
    <div class="form-group col-md-6">
      <label for="deviceid">Password</label>
      <input type="password" value="admin1234" class="form-control" id="password"
      placeholder="Please Enter Password">
    </div>
    <div class="form-group col-md-6">
      <label for="deviceid">Venue</label>
      <input type="text"  class="form-control" id="venue_access"
      placeholder="Keep Blank For All Access">
    </div>
  </div>
  
<div class="form-row">
 
 
  
  <div class="form-group col-md-12">
      <label for="deviceserial">Event Sync Server</label>
      <select class="custom-select custom-select-md" id="access_url">
      <option value="https://acs.bms.bz" selected>India</option>
      <option value="https://acs-ae.bms.bz">Dubai</option>
      <option value="https://acs-sg.bms.bz">Singapore</option>
      <option value="https://eventmanager-acs-sit.bms.bz">SIT</option>
    </div>
    
</div>

    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          access_type: document.getElementById("access_type").value,
          role: document.getElementById("role").value,
          name: document.getElementById("name").value,
          // number: document.getElementById("number").value,
          password: document.getElementById("password").value,
          venue_access: document.getElementById("venue_access").value,
          email: document.getElementById("email").value,
          access_url: document.getElementById("access_url").value,
        };
      },
    });

    if (formValues) {
      this.adduser(formValues);
    }
  };

  sync = () => {
    this.syncusers();
  };

  adduser = (adduser) => {
    console.log(adduser);

    let venue_access = adduser.venue_access;
    let access_type = adduser.access_type;
    let access_url = adduser.access_url;
    let email = adduser.email;
    let name = adduser.name;
    // let number = adduser.number;
    let password = adduser.password;
    let admin = false;
    let superadmin = false;
    let role = adduser.role

    if (access_type === "0") {
      admin = true;
      superadmin = true;
    } else if (access_type === "1") {
      admin = true;
      superadmin = false;
    }

    if (venue_access !== "") {
      venue_access = venue_access.split(",");
    } else {
      venue_access = [];
    }

    if (
      access_type !== "" &&
      access_url !== "" &&
      email !== "" &&
      name !== "" &&
      // number !== "" &&
      password !== "" &&
      role !== ''
    ) {
      adduser = {
        venue_access,
        access_type,
        email,
        name,
        // number,
        password,
        admin,
        superadmin,
        access_url,
        role
      };

      axios
        .post("/cms/users", adduser)
        .then((response) => {
          let success = response.data.success;
          if (success) {
            Swal.fire("User Added Succesfully", "Success", "success");
            return this.syncusers();
          } else {
            console.log(response.data.err);
            Swal.fire("Something Went Wrong", "Error", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("User Already Exist or Validaion Error", "Error", "error");
        });

      //Swal.fire("good", "success", "success");
    } else {
      Swal.fire("All Fields Required", "Error", "error");
    }
  };

  fetchdevice = () => {
    axios
      .get("/cms/devices/syncdevice")
      .then((data) => {
        if (data.data.success) {
          Swal.fire(data.data.msg, "Success", "success");
          return this.syncdevice();
        } else {
          return Swal.fire(`${data.msg}`, "Error", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.syncusers();
  }

  syncusers = () => {
    axios.get("/cms/users/allusers").then((response) => {
      //console.log(response);
      let users = response.data.users;

      this.setState({ users, users_backup: users });
    });
  };


  filteruser = (input) => {
    const { users, users_backup } = this.state

    if (input.length > 0) {
      users.map((user) => {
        if (user.name === input || user.email === input) {
          this.setState({ users: [user] })
        }
      });


    } else {
      this.setState({ users: users_backup })
    }
  }


  render() {
    let userlist = this.state.users;
    let userdata;

    userdata = userlist.map((user, index) => {
      return <List sync={this.sync} key={index} user={user} index={index} />;
    });

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="text-left mb-0">
                  Users - {this.state.users.length}
                </h3>
              </div>
              <div className="form-group has-success">
                <input
                  onChange={(e) => this.filteruser(e.target.value)}
                  style={{ width: 400 }}
                  type="text"
                  id="users"
                  placeholder="Search User From Email Address or Name..."
                  className="form-control is-valid"
                />
              </div>
              <div className="col text-right">
                <button onClick={this.add} className="btn btn-md btn-success">
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "600px",
              display: "inline-block",
              width: "100%",
              overflow: "auto",
            }}
            className="table-responsive"
          >
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Disabled</th>
                  <th scope="col">Attempt</th>
                  <th scope="col">Unsuccess</th>
                  <th scope="col">Server</th>
                  <th scope="col">Access</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody id="myTable">{userdata}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
