import React, { Component } from "react";

import "./animate.css";

export default class avatar extends Component {
  render() {
    return (
      <div>
        <svg
          width="500"
          // height="869"
          viewBox="0 0 1130 869"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 1">
            <g id="undraw_dashboard_nklg 1">
              <g clipPath="url(#clip0)">
                <path
                  id="Vector"
                  opacity="0.1"
                  d="M1108.14 197.66C1096.59 175.222 1081.45 154.825 1063.31 137.28L441.45 132.1L953.45 71.31C919.949 60.1007 884.895 54.2224 849.57 53.89C800.28 20.24 736.93 0.0500031 667.83 0.0500031C605.83 0.0500031 548.5 16.34 501.76 43.98C462.04 26.68 416.76 16.87 368.65 16.87C230.48 16.87 115.35 97.77 89.52 205.06L1108.14 197.66Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_2"
                  opacity="0.1"
                  d="M1130 287.64C1129.96 258.242 1123.34 229.226 1110.62 202.72L32.86 298.36C21.9206 315.037 13.4181 333.19 7.61 352.27L341.36 394.33L0 424.99C10.27 546.45 133.43 642.35 283.88 642.35C359.61 642.35 428.42 618.05 479.41 578.42C530.56 618.8 600.03 643.63 676.58 643.63C790.15 643.63 888.18 588.96 933.83 509.92C1025.41 485.34 1096.55 423.64 1120.9 346.45L698.83 291.11H1130C1130 289.95 1130 288.8 1130 287.64Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_3"
                  opacity="0.1"
                  d="M499.98 868.09C660.132 868.09 789.96 862.297 789.96 855.15C789.96 848.003 660.132 842.21 499.98 842.21C339.828 842.21 210 848.003 210 855.15C210 862.297 339.828 868.09 499.98 868.09Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_4"
                  d="M672.09 832.39V840.21H440.84V833.86C454.943 828.635 467.179 819.343 475.998 807.161C484.817 794.978 489.824 780.453 490.384 765.424C490.944 750.394 487.032 735.537 479.144 722.732C471.255 709.927 459.745 699.75 446.07 693.49H670.52C657.152 699.611 645.846 709.478 637.973 721.896C630.1 734.313 625.998 748.748 626.164 763.45C626.33 778.152 630.757 792.491 638.909 804.727C647.061 816.964 658.587 826.573 672.09 832.39Z"
                  fill="#C8CAD7"
                />
                <path
                  id="Vector_5"
                  opacity="0.1"
                  d="M670.52 693.49C660.205 698.226 651.08 705.209 643.813 713.928C636.545 722.647 631.32 732.881 628.52 743.88H488.08C485.278 732.881 480.053 722.648 472.786 713.929C465.519 705.211 456.394 698.227 446.08 693.49H670.52Z"
                  fill="black"
                />
                <path
                  id="Vector_6"
                  d="M176 653.65V697.55C176 721.79 193.74 741.44 215.63 741.44H896.05C917.94 741.44 935.68 721.79 935.68 697.55V653.65H176Z"
                  fill="white"
                />
                <path
                  id="Vector_7"
                  opacity="0.1"
                  d="M672.09 832.39V840.21H440.84V833.86C447.18 831.501 453.176 828.306 458.67 824.36H657.91C662.337 827.535 667.091 830.227 672.09 832.39Z"
                  fill="black"
                />
                <path
                  id="Vector_8"
                  d="M739.01 826.8H373.92C368.673 826.8 364.42 831.053 364.42 836.3V840.47C364.42 845.717 368.673 849.97 373.92 849.97H739.01C744.257 849.97 748.51 845.717 748.51 840.47V836.3C748.51 831.053 744.257 826.8 739.01 826.8Z"
                  fill="#C8CAD7"
                />
                <path
                  id="Vector_9"
                  d="M935.68 172.62C935.667 162.119 931.485 152.053 924.054 144.633C916.623 137.214 906.551 133.047 896.05 133.05H215.66C205.154 133.039 195.074 137.202 187.636 144.623C180.199 152.043 176.013 162.114 176 172.62V662.19H935.68V172.62Z"
                  fill="#474157"
                />
                <path
                  id="Vector_10"
                  d="M915 190.3V610.97C915.001 613.532 914.498 616.07 913.518 618.438C912.538 620.805 911.101 622.957 909.289 624.769C907.477 626.581 905.325 628.018 902.958 628.998C900.59 629.978 898.052 630.481 895.49 630.48H216.27C213.708 630.481 211.17 629.978 208.802 628.998C206.435 628.018 204.283 626.581 202.471 624.769C200.66 622.957 199.222 620.805 198.242 618.438C197.262 616.07 196.759 613.532 196.76 610.97V190.3C196.76 185.126 198.816 180.163 202.474 176.504C206.133 172.846 211.096 170.79 216.27 170.79H895.44C898.006 170.783 900.549 171.283 902.922 172.261C905.294 173.238 907.451 174.674 909.268 176.487C911.085 178.299 912.527 180.452 913.51 182.822C914.494 185.193 915 187.734 915 190.3Z"
                  fill="#4C4C78"
                />
                <path
                  id="Vector_11"
                  d="M555.85 161.04C560.903 161.04 565 156.943 565 151.89C565 146.837 560.903 142.74 555.85 142.74C550.797 142.74 546.7 146.837 546.7 151.89C546.7 156.943 550.797 161.04 555.85 161.04Z"
                  fill="white"
                />
                <path
                  id="Vector_12"
                  d="M348.53 180.55V630.48H216.27C213.708 630.481 211.17 629.978 208.802 628.998C206.435 628.018 204.283 626.58 202.471 624.769C200.66 622.957 199.222 620.805 198.242 618.438C197.262 616.07 196.759 613.532 196.76 610.97V190.3L197.47 187.65L199.37 180.55H348.53Z"
                  fill="white"
                />
                <path
                  id="Vector_13"
                  d="M555.85 721.94C568.31 721.94 578.41 711.84 578.41 699.38C578.41 686.92 568.31 676.82 555.85 676.82C543.39 676.82 533.29 686.92 533.29 699.38C533.29 711.84 543.39 721.94 555.85 721.94Z"
                  fill="#4C4C78"
                />
                <path
                  id="Vector_14"
                  d="M365.5 187.65V228.15H196.76V190.3L197.47 187.65H365.5Z"
                  fill="#4C4C78"
                />
                <path
                  id="Vector_15"
                  d="M915 190.3H196.76C196.76 187.738 197.265 185.202 198.245 182.835C199.226 180.469 200.663 178.319 202.475 176.508C204.287 174.697 206.438 173.261 208.805 172.281C211.172 171.302 213.708 170.799 216.27 170.8H895.44C898.006 170.792 900.548 171.291 902.921 172.267C905.294 173.244 907.45 174.679 909.267 176.49C911.084 178.302 912.526 180.454 913.51 182.824C914.494 185.194 915 187.734 915 190.3Z"
                  fill="#C8CAD7"
                />
                <path
                  id="Vector_16"
                  d="M216.27 185.43C218.965 185.43 221.15 183.245 221.15 180.55C221.15 177.855 218.965 175.67 216.27 175.67C213.575 175.67 211.39 177.855 211.39 180.55C211.39 183.245 213.575 185.43 216.27 185.43Z"
                  fill="#EDEDF4"
                />
                <path
                  id="Vector_17"
                  d="M230.9 185.43C233.595 185.43 235.78 183.245 235.78 180.55C235.78 177.855 233.595 175.67 230.9 175.67C228.205 175.67 226.02 177.855 226.02 180.55C226.02 183.245 228.205 185.43 230.9 185.43Z"
                  fill="#EDEDF4"
                />
                <path
                  id="Vector_18"
                  d="M245.53 185.43C248.225 185.43 250.41 183.245 250.41 180.55C250.41 177.855 248.225 175.67 245.53 175.67C242.835 175.67 240.65 177.855 240.65 180.55C240.65 183.245 242.835 185.43 245.53 185.43Z"
                  fill="#EDEDF4"
                />
                <path
                  id="Vector_19"
                  d="M323.31 205.48H221.98V214.81H323.31V205.48Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_20"
                  opacity="0.5"
                  d="M323.31 283.15H221.98V292.48H323.31V283.15Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_21"
                  opacity="0.5"
                  d="M323.31 315.32H221.98V324.65H323.31V315.32Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_22"
                  opacity="0.5"
                  d="M323.31 379.65H221.98V388.98H323.31V379.65Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_23"
                  opacity="0.5"
                  d="M323.31 411.82H221.98V421.15H323.31V411.82Z"
                  fill="#0779E4"
                />
                <g id="dashboard-line">
                  <path
                    id="Vector_24"
                    opacity="0.5"
                    d="M323.31 250.98H221.98V260.31H323.31V250.98Z"
                    fill="#0779E4"
                  />
                  <path
                    id="Vector_25"
                    opacity="0.5"
                    d="M323.31 347.48H221.98V356.81H323.31V347.48Z"
                    fill="#0779E4"
                  />
                  <path
                    id="Vector_26"
                    opacity="0.5"
                    d="M323.31 443.98H221.98V453.31H323.31V443.98Z"
                    fill="#0779E4"
                  />
                </g>
                <path
                  id="Vector_27"
                  opacity="0.5"
                  d="M880.5 239.79H431.49V241.29H880.5V239.79Z"
                  fill="#DCE0ED"
                />
                <path
                  id="Vector_28"
                  opacity="0.5"
                  d="M880.5 274.62H431.49V276.12H880.5V274.62Z"
                  fill="#DCE0ED"
                />
                <path
                  id="Vector_29"
                  opacity="0.5"
                  d="M880.5 309.46H431.49V310.96H880.5V309.46Z"
                  fill="#DCE0ED"
                />
                <path
                  id="Vector_30"
                  opacity="0.5"
                  d="M880.5 344.29H431.49V345.79H880.5V344.29Z"
                  fill="#DCE0ED"
                />
                <path
                  id="Vector_31"
                  opacity="0.5"
                  d="M883.57 394.59H434V326.09L475.23 346.41L517.9 352.19L555.34 340.9L600.51 305.87L623.23 292.75L639.84 289.55L655.73 290.4L681.87 303.31L717.39 325.94L745.27 329.53L767.38 321.91L814.23 283.53L838.15 275L863.18 278.87L883.57 290.64V394.59Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_32"
                  opacity="0.5"
                  d="M880.5 379.12H431.49V380.62H880.5V379.12Z"
                  fill="#DCE0ED"
                />
                <g id="Group" opacity="0.5">
                  <path
                    id="Vector_33"
                    opacity="0.5"
                    d="M537.17 458.65H471.17V472.65H537.17V458.65Z"
                    fill="#DCE0ED"
                  />
                  <path
                    id="Vector_34"
                    opacity="0.5"
                    d="M688.72 458.65H622.72V472.65H688.72V458.65Z"
                    fill="#DCE0ED"
                  />
                  <path
                    id="Vector_35"
                    opacity="0.5"
                    d="M840.27 458.65H774.27V472.65H840.27V458.65Z"
                    fill="#DCE0ED"
                  />
                </g>
                <path
                  id="Vector_36"
                  opacity="0.1"
                  d="M952.73 805.84C967.603 805.84 979.66 803.803 979.66 801.29C979.66 798.777 967.603 796.74 952.73 796.74C937.857 796.74 925.8 798.777 925.8 801.29C925.8 803.803 937.857 805.84 952.73 805.84Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_37"
                  opacity="0.1"
                  d="M115.22 863.29C130.093 863.29 142.15 861.253 142.15 858.74C142.15 856.227 130.093 854.19 115.22 854.19C100.347 854.19 88.29 856.227 88.29 858.74C88.29 861.253 100.347 863.29 115.22 863.29Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_38"
                  d="M893.79 856.85C915.997 856.85 934 853.806 934 850.05C934 846.294 915.997 843.25 893.79 843.25C871.583 843.25 853.58 846.294 853.58 850.05C853.58 853.806 871.583 856.85 893.79 856.85Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_39"
                  d="M910.41 838.94C912.246 837.444 913.582 835.425 914.24 833.15C914.74 830.85 913.76 828.1 911.57 827.26C909.11 826.32 906.48 828.02 904.48 829.75C902.48 831.48 900.21 833.43 897.6 833.07C898.94 831.853 899.942 830.31 900.51 828.591C901.078 826.872 901.191 825.036 900.84 823.26C900.728 822.524 900.417 821.832 899.94 821.26C898.57 819.8 896.1 820.42 894.46 821.57C889.26 825.23 887.81 832.29 887.78 838.65C887.26 836.36 887.7 833.97 887.69 831.65C887.68 829.33 887.03 826.65 885.05 825.42C883.821 824.764 882.442 824.44 881.05 824.48C878.71 824.39 876.1 824.62 874.51 826.33C872.51 828.46 873.04 832.02 874.76 834.33C876.48 836.64 879.12 838.13 881.53 839.75C883.467 840.907 885.121 842.482 886.37 844.36C886.512 844.625 886.63 844.903 886.72 845.19H901.38C904.656 843.52 907.693 841.418 910.41 838.94Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_40"
                  d="M116.45 791.24C116.45 791.24 121.95 798.43 113.92 809.24C105.89 820.05 99.27 829.24 101.92 836.01C101.92 836.01 114.04 815.86 123.92 815.58C133.8 815.3 127.3 803.36 116.45 791.24Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_41"
                  opacity="0.1"
                  d="M116.45 791.24C116.931 791.936 117.312 792.697 117.58 793.5C127.2 804.8 132.32 815.35 123.07 815.61C114.46 815.86 104.13 831.26 101.65 835.15C101.732 835.451 101.828 835.748 101.94 836.04C101.94 836.04 114.06 815.89 123.94 815.61C133.82 815.33 127.3 803.36 116.45 791.24Z"
                  fill="black"
                />
                <path
                  id="Vector_42"
                  d="M126.66 800.4C126.66 802.93 126.38 804.98 126.03 804.98C125.68 804.98 125.4 802.98 125.4 800.4C125.4 797.82 125.75 799.06 126.1 799.06C126.45 799.06 126.66 797.87 126.66 800.4Z"
                  fill="#FFD037"
                />
                <path
                  id="Vector_43"
                  d="M130.17 803.42C127.95 804.63 126.01 805.36 125.85 805.05C125.69 804.74 127.34 803.51 129.56 802.3C131.78 801.09 130.91 801.97 131.07 802.3C131.23 802.63 132.39 802.21 130.17 803.42Z"
                  fill="#FFD037"
                />
                <path
                  id="Vector_44"
                  d="M87.44 791.24C87.44 791.24 81.94 798.43 89.97 809.24C98 820.05 104.62 829.24 101.97 836.01C101.97 836.01 89.86 815.86 79.97 815.58C70.08 815.3 76.59 803.36 87.44 791.24Z"
                  fill="#0779E4"
                />
                <path
                  id="Vector_45"
                  opacity="0.1"
                  d="M87.44 791.24C86.9589 791.936 86.5785 792.697 86.31 793.5C76.69 804.8 71.57 815.35 80.82 815.61C89.43 815.86 99.76 831.26 102.24 835.15C102.16 835.453 102.059 835.75 101.94 836.04C101.94 836.04 89.83 815.89 79.94 815.61C70.05 815.33 76.59 803.36 87.44 791.24Z"
                  fill="black"
                />
                <path
                  id="Vector_46"
                  d="M77.22 800.4C77.22 802.93 77.51 804.98 77.86 804.98C78.21 804.98 78.49 802.98 78.49 800.4C78.49 797.82 78.14 799.06 77.79 799.06C77.44 799.06 77.22 797.87 77.22 800.4Z"
                  fill="#FFD037"
                />
                <path
                  id="Vector_47"
                  d="M73.72 803.42C75.94 804.63 77.87 805.36 78.04 805.05C78.21 804.74 76.55 803.51 74.33 802.3C72.11 801.09 72.98 801.97 72.81 802.3C72.64 802.63 71.5 802.21 73.72 803.42Z"
                  fill="#FFD037"
                />
                <path
                  id="Vector_48"
                  d="M79 835.1C79 835.1 94.36 834.62 99 831.33C103.64 828.04 122.63 824.09 123.78 829.38C124.93 834.67 146.86 855.67 129.52 855.81C112.18 855.95 89.23 853.11 84.61 850.3C79.99 847.49 79 835.1 79 835.1Z"
                  fill="#A8A8A8"
                />
                <path
                  id="Vector_49"
                  opacity="0.2"
                  d="M129.79 853.97C112.45 854.11 89.49 851.27 84.87 848.46C81.36 846.31 79.95 838.62 79.49 835.08H78.97C78.97 835.08 79.97 847.46 84.57 850.28C89.17 853.1 112.14 855.93 129.48 855.79C134.48 855.79 136.21 853.97 136.12 851.33C135.42 852.95 133.51 853.94 129.79 853.97Z"
                  fill="black"
                />
                <g id="first-circle">
                  <path
                    id="Vector_50"
                    d="M466.5 556.42C466.5 535.98 483.13 520.28 503.58 520.28H503.87V510.98H503.58C478 510.98 457.2 530.85 457.2 556.42C457.199 563.035 458.617 569.573 461.358 575.594C464.1 581.614 468.1 586.977 473.09 591.32L479.68 584.73C475.552 581.257 472.234 576.924 469.957 572.034C467.68 567.143 466.5 561.814 466.5 556.42Z"
                    fill="#0779E4"
                  />
                  <path
                    id="Vector_51"
                    opacity="0.5"
                    d="M504.8 510.99V520.29C510.755 520.33 516.613 521.804 521.878 524.587C527.143 527.37 531.66 531.381 535.046 536.28C538.432 541.178 540.589 546.821 541.332 552.73C542.076 558.638 541.385 564.639 539.319 570.224C537.252 575.809 533.87 580.814 529.459 584.816C525.048 588.817 519.738 591.696 513.979 593.211C508.219 594.725 502.18 594.829 496.371 593.515C490.563 592.201 485.156 589.506 480.61 585.66L474 592.25C479.632 597.175 486.373 600.663 493.646 602.417C500.919 604.171 508.508 604.139 515.766 602.323C523.024 600.508 529.734 596.963 535.325 591.991C540.915 587.019 545.219 580.767 547.869 573.771C550.52 566.775 551.437 559.241 550.544 551.813C549.65 544.385 546.972 537.283 542.739 531.115C538.505 524.947 532.841 519.894 526.231 516.39C519.621 512.885 512.261 511.033 504.78 510.99H504.8Z"
                    fill="#0779E4"
                  />
                </g>
                <g id="third-circle">
                  <path
                    id="Vector_52"
                    opacity="0.5"
                    d="M808.8 519.81C829.24 519.81 844.94 536.44 844.94 556.89C844.94 556.98 844.94 557.08 844.94 557.18H854.24C854.24 557.08 854.24 556.98 854.24 556.89C854.24 531.32 834.37 510.51 808.8 510.51C802.185 510.508 795.646 511.925 789.625 514.667C783.604 517.408 778.242 521.409 773.9 526.4L780.49 532.99C783.963 528.862 788.296 525.544 793.186 523.267C798.077 520.99 803.406 519.81 808.8 519.81Z"
                    fill="#0779E4"
                  />
                  <path
                    id="Vector_53"
                    d="M854.23 558.11H844.93C844.89 564.065 843.416 569.923 840.633 575.188C837.85 580.453 833.839 584.969 828.94 588.356C824.042 591.742 818.399 593.899 812.49 594.642C806.582 595.386 800.581 594.695 794.996 592.629C789.411 590.562 784.406 587.18 780.404 582.769C776.403 578.358 773.524 573.048 772.009 567.289C770.495 561.529 770.391 555.49 771.705 549.681C773.019 543.873 775.714 538.466 779.56 533.92L773 527.33C768.075 532.962 764.587 539.703 762.833 546.976C761.079 554.249 761.111 561.838 762.927 569.096C764.742 576.354 768.287 583.064 773.259 588.655C778.231 594.245 784.483 598.549 791.479 601.199C798.475 603.85 806.009 604.767 813.437 603.874C820.865 602.98 827.967 600.302 834.135 596.069C840.303 591.835 845.356 586.171 848.86 579.561C852.365 572.951 854.217 565.591 854.26 558.11H854.23Z"
                    fill="#0779E4"
                  />
                </g>
                <g id="second-circle">
                  <path
                    id="Vector_54"
                    opacity="0.5"
                    d="M674 513.71L672.15 522.97C685.76 528.46 694.46 541.8 694.46 557.35C694.462 564.032 692.658 570.591 689.238 576.332C685.818 582.073 680.91 586.783 675.033 589.963C669.156 593.144 662.529 594.676 655.852 594.399C649.176 594.122 642.698 592.045 637.105 588.389C631.512 584.732 627.011 579.632 624.079 573.627C621.147 567.622 619.893 560.937 620.448 554.278C621.004 547.618 623.35 541.233 627.237 535.798C631.124 530.362 636.408 526.079 642.53 523.4L638.41 515.05C630.81 518.471 624.273 523.878 619.486 530.701C614.7 537.525 611.842 545.512 611.212 553.823C610.583 562.133 612.206 570.46 615.909 577.927C619.613 585.393 625.262 591.723 632.26 596.25C639.258 600.777 647.347 603.333 655.675 603.651C664.004 603.968 672.264 602.034 679.586 598.053C686.908 594.071 693.021 588.19 697.283 581.027C701.544 573.864 703.795 565.685 703.8 557.35C703.8 537.28 691.92 520.16 674 513.71Z"
                    fill="#0779E4"
                  />
                  <path
                    id="Vector_55"
                    d="M658.59 519.74C663.032 519.738 667.426 520.651 671.5 522.42L673.35 513.17C668.641 511.357 663.636 510.432 658.59 510.44C652.038 510.44 645.561 511.834 639.59 514.53L643.75 522.86C648.425 520.798 653.48 519.735 658.59 519.74Z"
                    fill="#0779E4"
                  />
                </g>
                <g id="dashboard-wave">
                  <path
                    id="Vector_56"
                    opacity="0.5"
                    d="M880.57 395.59H431V327.09L472.23 347.41L514.9 353.19L552.34 341.9L597.51 306.87L620.23 293.75L636.84 290.55L652.73 291.4L678.87 304.31L714.39 326.94L742.27 330.53L764.38 322.91L811.23 284.53L835.15 276L860.18 279.87L880.57 291.64V395.59Z"
                    fill="#0779E4"
                  />
                </g>
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="1130" height="868.1" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
}
