import React, { useState, useEffect } from "react";
import GateEdit from "./GateEdit";
import axios from 'axios'

import { gateedit_update } from "../../../../../helper/actions/eventActions";

import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { connect } from "react-redux";

function GatesList(props) {
  const [gates, setGates] = useState([]);
  const [gate_details, setGate_details] = useState({});
  // const [options, setOptions] = useState(props.options);
  // const [gate_edit, setGate_edit] = useState(false);

  useEffect(() => {
    setGates(props.gatedata);
  }, [props.gatedata]);

  const delete_gate = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        props.delete(id);
      }
    });
  };

  

  const edit = (gate) => {
    props.gateedit_update(true);
    setGate_details(gate);
    props.seleceted_cat(gate);
  };

  const disable_edit = () => {
    props.gateedit_update(false);
    setGate_details({});
    props.unselect_cat();
  };

  return (
    <div className="search-data">
      {props.gateedit ? (
        <GateEdit
          groupevents={props.groupevents}
          gate_details={gate_details}
          disable_edit={disable_edit}
          delete_cat={props.delete_cat}
          eventtype={props.eventtype}
        />
      ) : null}

      {props.gateedit === false && gates.length > 0 ? (
        <center>
          <h4 className="text-muted">ALL CATEGORIES</h4>
          <table className="table table-bordered">
            <thead className="theme-dark  text-white text-center">
              <tr>
                <th scope="col">Name</th>
                {props.eventtype === "open" ? null : <th scope="col">Zone</th>}

                 <th style={{width:10}} scope="col">Sync to Mobile</th>
                <th scope="col">Edit</th>

                <th scope="col">Delete</th>
              </tr>
            </thead>

            <tbody>
              {gates.length > 0
                ? gates.map((gate, index) => {
                  return (
                    <tr className="eventsearch text-center" key={index}>
                      <td style={{ width: 80 }}>{gate.gatename}</td>
                      {props.eventtype === "open" ? null : (
                        <td style={{ width: 80 }}> {gate.zone_ref}</td>
                      )}

                      <td style={{ display: "flex", justifyContent: "center" }}>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={gate.synctomobile? gate.synctomobile ? true : true:false}
                            onChange={() => {
                              props.synctomobile(gate,index);
                            }}
                          />
                          <span className="custom-toggle-slider rounded-circle " />
                        </label>
                      </td>
                      <td style={{ width: 10 }}>
                        <button
                          onClick={() => edit(gate)}
                          className="btn btn-sm btn-icon btn-2 btn-info"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-settings" />
                          </span>
                        </button>
                      </td>

                      <td style={{ width: 10 }}>
                        <button
                          onClick={() => delete_gate(gate._id)}
                          className="btn btn-sm btn-icon btn-2 btn-danger"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-fat-remove" />
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
        </center>
      ) : null}
    </div>
  );
}

GatesList.propTypes = {
  gateedit_update: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  gateedit: state.event.gateedit,
});

export default connect(mapStateToProps, { gateedit_update })(GatesList);
