import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'

import Navbar from "../navbar";

import User from "./User";
import { Footer } from "../footer";

class index extends Component {
  render() {

    const { user } = this.props.auth

    if (!user.superadmin) {
      return <Redirect to="/home" />
    } else {
      return (
        <div>
          <Navbar />
          <div className="main-content">
            <User />
          </div>
          <Footer />
        </div>
      );
    }

  }
}

index.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(index);