import React, { Component } from "react";
import "./styles.css";

import Search from "./Search";

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      searchtype: false,
      focus: false,
    };

    this.search = this.search.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  search(e) {
    e.preventDefault();

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  shouldBlur = (e) => {
    if (e.keyCode === 40) {

      alert("hello");

    }
  };
  render() {
    // let searchdata;

    return (
      <div className="create-event container-flux" style={{ display: 'flex', flexDirection: 'column', }}>
        <div className="">
          {/* <center>
            <h2 className="display4">All Events</h2>
          </center> */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <form
                onSubmit={this.search}
                className={
                  this.state.focus
                    ? "card card-sm search-bar input-bar"
                    : "card card-sm search-bar"
                }
              >
                <div className="card-body row no-gutters align-items-center ">
                  <div className="col-auto  ">
                    <i
                      className={
                        this.state.focus
                          ? "fas fa-search text-body text-white search-icon"
                          : "fas fa-search text-body"
                      }
                    />
                  </div>

                  <div className="col input-box ">
                    <input
                      style={{height:20,}}
                       onFocus={() => this.setState({ focus: false })}
                      // onBlur={() => this.setState({ focus: false })}
                      className="form-control form-control-lg form-control-borderless"
                      type="search"
                      name="search"
                      onChange={this.onChange}
                      placeholder="Search Events By Event ID or Event Name"
                    />
                  </div>

                  {/* <div className="col-auto">
                    <button className="btn btn-sm btn-primary" type="submit">
                      Search
                    </button>
                  </div> */}
                </div>
              </form>
              {/* end of search */}
            </div>

          </div>
          <Search search={this.state.search} />
        </div>


      </div>
    );
  }
}

export default Event;
