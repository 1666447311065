import React, { Component } from "react";

import GateConfig from "./GateConfig";
import PickupConfig from "./PickupConfig";
// import Dubaiconfig from './Dubaiconfig';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtype: "",
    };
  }

  componentWillMount() {
    //let eventid = this.props.match.params.id;
    //this.props.setevent(eventid);

    let eventtype = sessionStorage.getItem("eventtype");
    this.setState({ eventtype });
  }

  render() {
    const { eventtype } = this.state;

    let Mainpage;
    if (eventtype === "pickup") {
      Mainpage = <PickupConfig />;
    } else {
       Mainpage = <GateConfig />;
    }
    return Mainpage;
  }
}

export default index;
