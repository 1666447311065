import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.css";
import Swal from "sweetalert2";
import axios from "axios";
import "./style.css";
// import { Button, UncontrolledTooltip } from "reactstrap";

import Bms_logo from "./logo_bms.svg";
import CountUp from "react-countup";

import Background from "../../images/background_active.jpeg";

import { Footer } from "../footer";
import { logoutUser } from "../../../../helper/actions/authActions";
import Create from './Create'
import { IconButton } from "@material-ui/core";

class Content extends Component {
  state = {
    events: 0,
    openModal: false,

  };


  closeModal = () => {
    this.setState({ openModal: false })
  }

  componentDidMount() {
    axios
      .get(`/cms/events/total/count`)
      .then((response) => {
        // console.log(response);
        if (response) {
          this.setState({ events: response.data.count });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  logout() {
    Swal.fire({
      title: "Do You Want To Logout?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout",
    }).then((result) => {
      if (result.value) {
        //Swal.fire("Logout!", "User Logout Succesfully.", "success");
        sessionStorage.clear();
        this.props.logoutUser();
      }
    });
  }

  coming_soon = () => {
    Swal.fire("Coming Soon!", "", "success");
  };

  unauthorized = () => {
    Swal.fire("UnAuthorized Access!", "Access Denied", "error");
  };

  sync_event = () => {
    Swal.fire({
      title: "Enter Event ID",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Sync",
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        axios
          .get(`/cms/events/localsync/${value}`)

          .then((response) => {
            let success = response.data.success;

            // console.log(response);
            if (success) {
              return this.autocloser(true);
            } else {
              let err = response.data.msg;
              return this.autocloser(false, err);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage(`Request failed: ${err}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  autocloser = (status, err) => {
    let timerInterval;
    Swal.fire({
      title: "Event Sync In Progress!",
      html: "Fetching Details From Server <strong></strong>",
      timer: 2000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector(
            "strong"
          ).textContent = Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
        if (status === true) {
          return Swal.fire("Event Sync Succesfully!", "", "success");
        } else {
          return Swal.fire(err, "", "error");
        }
      }
    });
  };

  render() {
    const { user } = this.props.auth;

    let access_type = user.access_type;

    if (!user.admin) {
      return <Redirect to="/events" />;
    }

    return (
      <div
        className="background"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Create openModal={this.state.openModal} closeModal={this.closeModal} />
        <div className="wrapper">
          <div className="home-navbar">
            <Link to="/home">
              <img src={Bms_logo} alt="" height="35" />
            </Link>

            <ul>
              <li>
                <Link onClick={() => this.setState({ openModal: true })} to="#">
                  <i className="fas fa-plus-square "></i>
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <i className="fas fa-user-circle "></i>
                </Link>
              </li>
              <li>
                <Link onClick={() => this.logout()} to="#">
                  <i className="fas fa-sign-out-alt "></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="home-container ">
          <div className="dash-stats">
            <h2>
              <CountUp start={0} end={this.state.events} />
            </h2>
            <p>Total Events</p>
          </div>

          <div
            className={user.superadmin ? 'grid-container-3' : 'grid-container-1'}
          >
            <Link to="/events">
              <div className="grid-item">
                <i className="fas fa-chart-pie"></i>
                <span>View Events</span>
              </div>
            </Link>
            {/* {access_type === "0" && (
            <Link to="/create">
              <div className="grid-item">
                <i className="fas fa-pen-square"></i>
                <span>Create</span>
              </div>
            </Link>
          )} */}
            {access_type === "0" && (
              <Link
                to="/users"
              >
                <div className="grid-item">
                  <i className="fas fa-users-cog"></i>
                  <span>Users</span>
                </div>
              </Link>
            )}

            {/* {access_type === "0" && (
              <Link
                to={
                  user.email === "darshan.asolkar@bookmyshow.com"
                    ? "/logs"
                    : "#"
                }
                // onClick={
                //   user.email === "darshan.asolkar@bookmyshow.com"
                //     ? ""
                //     : () => this.unauthorized()
                // }
              >
                <div className="grid-item">
                  <i className="fas fa-list-ol"></i>
                  <span>Logs</span>
                </div>
              </Link>
            )} */}

            {access_type === "0" ? (
              <Link to="/devices">
                <div className="grid-item">
                  <i className="fas fa-mobile"></i>
                  <span>Devices</span>
                </div>
              </Link>
            ) : (
                ""
              )}
          </div>
        </div>
        <Footer color="clear" />
      </div>
    );
  }
}

Content.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Content);
