import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  logoutUser,
  changeColor,
} from "../../../../helper/actions/authActions";
import {
  deleteCurrentevent,
  setevent,
} from "../../../../helper/actions/eventActions";
import Swal from "sweetalert2";

import axios from "axios";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cache: false,
      dashboard_screen: false,
      checkedA: true,
    };

    this.clear = this.clear.bind(this);
    this.logout = this.logout.bind(this);
    this.sync = this.sync.bind(this);
  }

  handleChange = (event) => {
    this.props.changeColor();
    this.setState({ [event.target.name]: event.target.checked });
  };

  sync = () => {
    Swal.fire({
      title: "Enter Event ID",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Sync",
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        axios
          .get(`/cms/events/localsync/${value}`)

          .then((response) => {
            let success = response.data.success;

            if (success) {
              return this.autocloser(true);
            } else {
              let err = response.data.msg;
              return this.autocloser(false, err);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage(`Request failed: ${err}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  autocloser = (status, err) => {
    let timerInterval;
    Swal.fire({
      title: "Event Sync In Progress!",
      html: "Fetching Details From Server <strong></strong>",
      timer: 2000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector("strong").textContent =
            Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
        if (status === true) {
          return Swal.fire("Event Sync Succesfully!", "", "success");
        } else {
          return Swal.fire(err, "", "error");
        }
      }
    });
  };

  logout(e) {
    e.preventDefault();

    Swal.fire({
      title: "Do You Want To Logout?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout",
    }).then((result) => {
      if (result.value) {
        // Swal.fire("Logout!", "User Logout Succesfully.", "success");
        sessionStorage.clear();
        this.props.logoutUser();
      }
    });
  }

  componentDidMount() {
    // let ss = window.location.pathname;

    let jwt_expire = this.props.auth.user.exp;

    let current_time = Date.now() / 1000;
    if (jwt_expire < current_time) {
      return this.auto_logout();
    }

    let event_obj_id = sessionStorage.eventid;
    let eventtype = sessionStorage.eventtype;

    if (event_obj_id === "") {
      this.props.history.push("/home");
    } else {
      //this.setState({ event_obj_id, eventtype });
      this.props.setevent(event_obj_id);
    }
  }

  auto_logout = () => {
    Swal.fire("Logout!", "Session Timeout", "success");
    sessionStorage.clear();
    this.props.logoutUser();
  };

  clear(e) {
    e.preventDefault();
    Swal.fire({
      position: "top-middle",
      type: "success",
      title: "Session Cleared Succesfully",
      showConfirmButton: false,
      timer: 1500,
    });

    //alert("Reload Succesfully");
    sessionStorage.clear();
    this.setState({ cache: true });
  }

  render() {
    const { user, theme } = this.props.auth;

    return (
      <>
        <Navbar
          style={{ position: "sticky", top: "0", zIndex: "1000" }}
          className={
            theme === "light"
              ? "navbar-top navbar-expand border-bottom theme-light"
              : "navbar-top navbar-expand border-bottom theme-dark"
          }
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <div style={{ display: "flex" }}>
                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img alt="avatar" src={user.avatar} />
                        </span>
                      </Media>
                    </DropdownToggle>
                  </div>

                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0"> {user.name}</h6>
                      <h6 className="text-overflow m-0 text-lowercase">
                        {user.email}
                      </h6>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to="/profile" className="dropdown-item">
                        <i className="ni ni-single-02" />
                        <span>My profile</span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to="/home" className="dropdown-item">
                        <i className="ni ni-bullet-list-67" />
                        <span>Home</span>
                      </Link>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem onClick={this.logout.bind(this)}>
                      <Link to="#" className="dropdown-item">
                        <i className="ni ni-user-run" />
                        <span>Logout</span>
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
  logoutUser: PropTypes.func.isRequired,
  changeColor: PropTypes.func.isRequired,
  setevent: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  deleteCurrentevent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
  deleteCurrentevent,
  changeColor,
  setevent,
})(AdminNavbar);
