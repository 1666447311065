import React, { useEffect, useState, Fragment } from "react";
import "./style.css";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function Report13({groupevents,session}) {
  const [data, setdata] = useState([]);
  // const [event_obj_id, setevent_obj_id] = useState("");

  const zone_data = [
    "Zone 1 Total",
    "Zone 2 Total",
    "Zone 4 Total",
    "SportsHub Total",
    "Total",
  ];

  // const [zone_data, setzone_data] = useState([
  //   "Zone 1 Total",
  //   "Zone 2 Total",
  //   "Zone 4 Total",
  //   "SportsHub Total",
  //   "Total",
  // ]);

  useEffect(() => {
    report_13();
    const interval = setInterval(() => {
      report_13();
    }, 45000);
    return () => clearInterval(interval);
  }, []);

  const report_13 = () => {
    const geteventid = sessionStorage.getItem("eventid");
    let report = {
      event_obj_id:  groupevents?.length > 0
      ? [geteventid,...groupevents.map((event)=> event.event_obj_id)]
      : [geteventid],
      dashboard: true,
      zone_ref: "Zone 1",
      session,
      geteventid
    };
    axios
      .post(`/cms/pdf/report/analyticsreport13`, report)
      .then((response) => {
        setdata(response.data);
      })
      .catch((err) => {
        //Setsubmit_button(false);
        console.log(err);
      });
  };

  let x = 0;

  return (
    <Fragment>
      {data.length > 0 ? (
        <center>
          <h4 className="text-muted">Hourly Flow At Gates</h4>
          <table className="table table-bordered">
            <thead className="bg-primary text-white text-center thead-dark">
              <tr>
                <th style={{ color: "white" }} scope="col"></th>
                <th style={{ color: "white" }} scope="col">
                  0:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  13:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  14:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  15:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  16:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  17:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  18:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  19:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  20:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  21:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  22:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  23:00
                </th>
                <th style={{ color: "white" }} scope="col">
                  Row Total
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((table,index) => {
               return <tr  style={{
                backgroundColor: table.maintotal ? "#1C345D" : table.zonetotal?"lightgrey":'white',
                color: table.maintotal ? "white" : '#000000',
                fontWeight:table.maintotal ? '20px' : '10px'
              }} className="eventsearch text-center" key={index}>
                      <td>{table.name}</td>
                      <td>{table.countData["00"]}</td>
                      <td>{table.countData["13"]}</td>
                      <td>{table.countData["14"]}</td>
                      <td>{table.countData["15"]}</td>
                      <td>{table.countData["16"]}</td>
                      <td>{table.countData["17"]}</td>
                      <td>{table.countData["18"]}</td>
                      <td>{table.countData["19"]}</td>
                      <td>{table.countData["20"]}</td>
                      <td>{table.countData["21"]}</td>
                      <td>{table.countData["22"]}</td>
                      <td>{table.countData["23"]}</td>
                      <td>{table.countData.total}</td>
               </tr>
              })}
            </tbody>
          </table>
        </center>
      ) : null}
    </Fragment>
  );
}

Report13.propTypes = {
  session_selected: PropTypes.object,
};



const mapStateToProps = (state) => ({
  session: state.event.session
});
export default connect(mapStateToProps, null)(
  withRouter(Report13)
);

// export default Report13;
