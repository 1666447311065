import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Table from "./Table";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      originalcategories: [],
      getcategories: false,
    };

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  update(formValues) {
    this.props.updatecategory(formValues);
  }

  is_disable_toggle = (category) => {
    const { categories } = this.state;

    // return alert(category._id)

    let new_cat = [];

    let obj = {
      _id: category._id,
      is_disable: !category.is_disable,
    };

    // return console.log(obj)

    axios
      .post(`/cms/category/standard/is_disable_toggle`, obj)
      .then((respos) => {
        if (respos.data.success) {
          categories.map((cat) => {
            if (cat._id === category._id) {
              cat.is_disable = !category.is_disable;
            }

            return new_cat.push(cat);
          });

          this.setState({ categories: new_cat });
        } else {
          alert("something went wrong");
        }
      });
  };

  apb_toggle = (category) => {
    const { categories } = this.state;

    let new_cat = [];

    let obj = {
      _id: category._id,
      apb: !category.apb,
    };

    axios.post(`/cms/category/standard/apb_toggle`, obj).then((respos) => {
      if (respos.data.success) {
        categories.map((cat) => {
          if (cat._id === category._id) {
            cat.apb = !category.apb;
          }

          return new_cat.push(cat);
        });

        return this.setState({ categories: new_cat });
      } else {
        alert("something went wrong");
      }
    });
  };

  componentWillReceiveProps(nextprops) {
    this.setState({
      categories: nextprops.categories,
      originalcategories: nextprops.categories,
    });
  }

  async edit(category) {
    const { value: formValues } = await Swal.fire({
      title: "Edit Category",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-6">
        <label for="category_name">Category</label>
        <input type="text" class="form-control" id="category_name"
        value="${
          category.category_name
        }" placeholder="Please Enter Category Name">
      </div>
      <div class="form-group col-md-6">
        <label for="param2">Category Code</label>
        <input type="text" class="form-control" id="param2" 
        value=${category.param2} placeholder="Please Enter Category Code">
      </div>
    </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="param3">Area Code</label>
      <input type="text" class="form-control" id="param3"
      value=${category.param3} placeholder="Please Enter Area ID">
    </div>
    <div class="form-group col-md-6">
      <label for="param4">Ticket Type</label>
      <input type="text" class="form-control" id="param4" 
      value=${category.param4} placeholder="Please Enter Ticket Type">
    </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-6">
    <label for="startdate">Start Date</label>
    <input type="text" class="form-control" id="startdate" 
    value="${category.startdate}" placeholder="Please Enter Start Date">
  </div>
  <div class="form-group col-md-6">
    <label for="enddate">End Date</label>
    <input type="text" class="form-control" id="enddate"
    value="${category.enddate}" placeholder="Please Enter End Date">
  </div>
</div>
<div class="form-row">
<div class="form-group col-md-6">
<label for="startdate">Ticket Type Name</label>
<input type="text" class="form-control" id="ticket_type_name" 
value="${
        category.ticket_type_name
      }" placeholder="Please Enter Ticket Type Name">
</div>
  <div class="form-group col-md-6 ">
    <label for="startdate">Attempt</label>
    <input type="number" class="form-control" id="attempt" 
    value=${category.attempt} placeholder="Please Enter Attempt">
  </div>
  <div class="form-group col-md-6">
    <label for="zone">Zone</label>
    <input type="text" class="form-control" id="zone" 
    value="${category.zone}" placeholder="Please Enter Zone">
  </div>
  <div class="form-group col-md-6">
  <label for="barcode_type">Type</label>
  <div class="form-row">

  <select
  style={{backgroundColor:"blue"}}
  class="form-control"
  id="barcode_type"
  name="barcode_type"
  id="inlineFormCustomSelectPref"
  required>
  <option ${
    category.barcode_type === "ticket" ? "selected" : ""
  } value="ticket">Ticket</option>
  <option ${
    category.barcode_type === "accred" ? "selected" : ""
  } value="accred">Accred</option>
   <option ${
     category.barcode_type === "special" ? "selected" : ""
   } value="special">Special</option>
  </select>
  </div>
  
</div>
 
</div>
<div class="form-row">
<div class="form-group col-md-6">
  <label for="app_response_color">App Response</label>
  <div class="form-row">

  <select
  style={{backgroundColor:"blue"}}
  class="form-control"
  id="app_response_color"
  name="app_response_color"
  id="inlineFormCustomSelectPref"
  required>
  <option ${
    category?.app_response_color === "" ? "selected" : ""
  } value="">Default</option>
  <option ${
    category?.app_response_color === "#4169E1" ? "selected" : ""
  } value="#4169E1">Blue</option>
   <option ${
     category?.app_response_color === "#C99201" ? "selected" : ""
   } value="#C99201">Amber</option>
  </select>
  </div>
  
</div>
</div>

    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          param2: document.getElementById("param2").value,
          param3: document.getElementById("param3").value,
          param4: document.getElementById("param4").value,
          category_name: document.getElementById("category_name").value,
          startdate: document.getElementById("startdate").value,
          enddate: document.getElementById("enddate").value,
          ticket_type_name: document.getElementById("ticket_type_name").value,
          zone: document.getElementById("zone").value,
          attempt: document.getElementById("attempt").value,
          barcode_type: document.getElementById("barcode_type").value,
          category_id: category._id,
          param1: `${category.event_id_bms}${category.session}${
            document.getElementById("param2").value
          }${document.getElementById("param3").value}${
            document.getElementById("param4").value
          }`,
          app_response_color: document.getElementById("app_response_color").value,
          event_id_ref: category.event_id_bms,
        };
      },
    });

    if (formValues) {
      this.update(formValues);
    }
  }
  delete(catid) {
    this.props.delete(catid);
  }

  delete_all_categories = () => {
    this.props.deleteall();
  };

  search = (text) => {
    // alert('search ' + text)
    const { categories, originalcategories } = this.state;
    let searchcategories = [];

    if (text === "") {
      this.setState({ categories: originalcategories });
    } else {
      originalcategories.map((cat) => {
        if (
          cat.session === text ||
          cat.category_name === text ||
          cat.dayref === text
        ) {
          return searchcategories.push(cat);
        } else {
          return false;
        }
      });
      if (searchcategories.length === 0) {
        this.setState({ categories: originalcategories });
      } else {
        this.setState({ categories: searchcategories });
      }
    }
  };

  render() {
    const { categories } = this.state;

    return (
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Table
          getcategories={this.props.getcategories}
          categories={categories}
          deletecat={this.delete}
          edit={this.edit}
          generate={this.generate}
          apb_toggle={this.apb_toggle}
          search={this.search}
          is_disable_toggle={this.is_disable_toggle}
          pagination={this.props.pagination}
          setsearch={this.props.setsearch}
        />
      </div>
    );
  }
}

export default Categories;
