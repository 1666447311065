import React, { Component } from "react";
import List from "./List";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

// import Swal from "sweetalert2";

import axios from "axios";

export default class Barcodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodes: [],
      eventid: ""
    };
  }

  sync = () => {
    this.syncdevice();
  };

  componentDidMount() {
    let eventid = sessionStorage.getItem("eventid");
    this.setState({ eventid });
    let getevent = {
      event_obj_id: eventid
    };
    this.syncdevice(getevent);
  }

  syncdevice = getevent => {
    axios.post("/cms/transaction/gettransactions/", getevent).then(response => {
      console.log(response);
      let barcodes = response.data.data;
      this.setState({ barcodes: barcodes });
    });
  };

  render() {
    let list;

    let barcodeslist = this.state.barcodes;

    list = barcodeslist.map((barcode, index) => {
      let typecheck = false;

      return (
        <List
          sync={this.sync}
          key={index}
          device={barcode}
          typecheck={typecheck}
          index={index}
        />
      );
    });

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="mb-0" style={{ float: "left" }}>
                  Transactions - {this.state.barcodes.length}{" "}
                </h3>
                <h3 className="mb-0" style={{ float: "right" }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-md btn-success"
                    table="table-to-xls"
                    filename="Transactions"
                    sheet="Transactions"
                    buttonText="Export to Excel"
                  />
                </h3>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "700px",
              display: "inline-block",
              width: "100%",
              overflow: "auto"
            }}
            className="table-responsive"
          >
            <table className="table align-items-center" id="table-to-xls">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr.</th>
                  <th scope="col">BookingID</th>
                  <th scope="col">Category</th>
                  <th scope="col">QTY</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Pickup</th>
                  <th scope="col">PickupBy</th>
                  <th scope="col">PickupTime</th>
                  {/* <th scope="col">UpdateTime</th>
                  <th scope="col">Sync</th> */}
                  <th scope="col">Pending</th>
                </tr>
              </thead>
              <tbody id="myTable">{list}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
