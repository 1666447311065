/* eslint-disable no-use-before-define */

import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function GroupSession({ session_details, selected_session }) {
  const [inputValue, setInputValue] = useState("");


  return (
    <Autocomplete
      onChange={(event, value) =>{
        selected_session(value)
      }}
      freeSolo
      multiple
      id="checkboxes-tags-demo"
      inputValue={inputValue}
      options={session_details}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      searchText={"s"}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name} , {option.session} , {option.venue_Code}
        </React.Fragment>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setInputValue(e.target.value)}
          autoComplete="false"
          variant="outlined"
          label="Session List"
          placeholder="Choose Multiple Session"
          fullWidth
        />
      )}
    />
  );
}
