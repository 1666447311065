import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Categories from "./Categories";
import Swal from "sweetalert2";
//import DateTimePicker from "react-datetime-picker";
const moment = require("moment");
class CategoryStandard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      days: [],
      sessiondetails: [],
      session_details: [],
      category_name: "",
      catref: "",
      create: "",
      encryption: "",
      barcode_type: "ticket",
      categories: [],
      categories_sync: [],
      alpha_numeric: "an",
      startdate: moment().format("DD-MM-YYYY HH:mm"),
      enddate: moment().format("DD-MM-YYYY HH:mm"),
      venuecode: "",
      sessionid: "",
      country: "",
      eventtype: "",
      starts: "",
      ends: "",
      length: "",
      attempt: "1",
      category_creation: "",
      dtcm2: false,
      zone: "Zone 1",
    };

    this.onChange = this.onChange.bind(this);
    this.delete = this.delete.bind(this);
    this.create_open_category = this.create_open_category.bind(this);
  }
  async get_open_categories() {
    const { session_details } = this.state;

    let x = 0;
    let category_array = [];

    session_details.map((session) => {
      x = x + 1;

      let synccat = {
        event_id_bms: session.event_id_bms,
        venuecode: session.venue_Code,
        session: session.session,
      };

      return axios
        .post("/cms/transaction/get_open_category", synccat)
        .then((response) => {
          var category = response.data;

          category_array.push(category);

          console.log(category_array);

          if (session_details.length === x) {
            // console.log(category_array)

            let category_new_array = [];

            category_array.map((cat) => {
              return cat.map((cs) => {
                return category_new_array.push(cs);
              });
            });
            this.setState({ categories_sync: category_new_array });
          }
        })
        .catch((err) => console.log(err));
    });
  }

  autocreate = async () => {
    const { categories_sync, session_details, categories } = this.state;

    // return console.log(session_details);

    if (categories_sync.length === 0) {
      return alert("No Categories Found");
    }

    let newcategories = [];
    let newfilter = [];

    var uniqueItems = Array.from(new Set(categories_sync));

    uniqueItems.map((category) => {
      return session_details.map((day) => {
        let newcategory = {
          zone: this.state.zone,
          attempt: 1,
          startdate: this.state.startdate,
          enddate: this.state.enddate,
          barcode_type: "ticket",
          session: day.session,
          day_id: day._id,
          event_id_bms: this.state.event_id_bms,
          event_obj_id: this.state.event_obj_id,
          dayref: day.name,
          category_name: category,
          catref: category,
        };
        return newcategories.push(newcategory);
      });
    });

    newcategories.map((category) => {
      let filter = categories.find(
        (category_data) =>
          category_data.session === category.session &&
          category_data.catref === category.catref
      );

      if (filter === undefined) {
        return newfilter.push(category);
      } else {
        return false;
      }
    });

    if (newfilter.length > 0) {
      let data = {
        new_category_array: newfilter,
      };

      await axios
        .post(`/cms/category/standard/category_bulk`, data)
        .then((response) => {
          if (response.data.success) {
            Swal.fire("Categories Created Succesfully!", "", "success");
            this.getcategories();
          } else {
            return Swal.fire("Something Went wrong!", "", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          return Swal.fire("Database Issue !", "", "error");
        });
    } else {
      return Swal.fire("Duplicate Categories Found!", "", "error");
    }
  };

  delete = (catid) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`/cms/category/standard/${catid}`)
            .then((result) => {
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Category has been deleted.",
                "success"
              );
              this.getcategories();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Something went wrong!", "", "error");
            });
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Category is safe :)",
            "error"
          );
        }
      });
  };

  categorystandard() {
    this.props.categorystandard();
  }

  async create_open_category(e) {
    e.preventDefault();

    return this.create_open_cat(this.state);
  }
  async create_open_cat() {
    let days = this.state.session_details;

    let categorydata = [];

    days.forEach((day, index) => {
      let newcategory = {
        eventname: this.state.eventname,
        event_id_bms: this.state.event_id_bms,
        event_obj_id: this.state.event_obj_id,
        dayref: day.name,
        category_name: this.state.category_name,
        category_type: this.state.category_type,
        category_tick_accred: this.state.category_tick_accred,
        startdate: this.state.startdate,
        enddate: this.state.enddate,
        barcode_type: this.state.barcode_type,
        length: this.state.length,
        alpha_numeric: this.state.alpha_numeric,
        starts: this.state.starts,
        ends: this.state.ends,
        catref: this.state.catref || this.state.category_name,
        attempt: this.state.attempt,
        session: day.session,
        zone: this.state.zone,
      };

      //this.create_open_categorys(newcategory);
      categorydata.push(newcategory);
    });
    this.create_open_categorys(categorydata);
  }

  async create_open_categorys(categorydata) {
    // return console.log(categorydata)

    let { categories } = this.state;

    let newcategory = [];

    categorydata.map((category) => {
      let ss = categories.find(
        (category_data) =>
          category_data.session === category.session &&
          category_data.catref === category.catref
      );
      if (ss === undefined) {
        return newcategory.push(category);
      } else {
        return false;
      }
    });

    // return console.log(newcategory)

    if (newcategory.length > 0) {
      let data = {
        new_category_array: newcategory,
      };

      await axios
        .post(`/cms/category/standard/category_bulk`, data)
        .then((response) => {
          if (response.data.success) {
            Swal.fire("Category Created Succesfully!", "", "success");
            this.getcategories();
          } else {
            return Swal.fire("Something Went wrong!", "", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          return Swal.fire("Database Issue !", "", "error");
        });
    } else {
      return Swal.fire("Duplicate Category Found!", "", "error");
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "catref") {
      this.setState({ category_name: e.target.value });
    }
  }

  getcategories = async () => {
    await axios
      .get(`/cms/category/standard/getall/${this.state.event_obj_id}`)
      .then((response) => {
        if (response) {
          this.setState({ categories: response.data });
        }
      })
      .catch((err) => console.log(err));
  };

  componentWillMount() {
    const geteventid = sessionStorage.getItem("eventid");

    let get_days = {
      event_obj_id: geteventid,
    };

    axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        sessiondetails: event.data.events.sessiondetails,
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: event.data.events._id,
        days: event.data.events.sessiondetails,
        venuecode: event.data.events.venuecode,
        country: event.data.events.country,
        eventtype: event.data.events.eventtype,
        dtcm2: event.data.events.dtcm2,
        category_creation: event.data.events.category_creation,
      });

      this.getcategories();
      this.sync_days(get_days);
    });
  }

  sync_days = (get_days) => {
    axios
      .post(`/cms/days/standard/alldays`, get_days)
      .then((response) => {
        const days_data = response.data;

        // console.log(days_data)

        this.setState({ session_details: days_data.data });
        this.get_open_categories();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { eventname, event_id_bms, categories_sync } = this.state;
    let categorydata;
    var uniqueItems = Array.from(new Set(categories_sync));
    categorydata = uniqueItems.map(function (category, i) {
      return (
        <option className="text-center" key={i} value={category}>
          {category}
        </option>
      );
    });

    let category_cration = this.state.category_creation;
    let showcategory;

    if (category_cration === "dtcm") {
      showcategory = (
        <div className="col-md-6">
          <div className="form-group ">
            <div className="form-group ">
              <select
                onChange={this.onChange}
                name="catref"
                className="custom-select my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                required
              >
                <option className="text-center" value="">
                  Choose Category...
                </option>
                {categorydata}
              </select>
            </div>
          </div>
        </div>
      );
    } else {
      showcategory = null;
    }

    return (
      <Fragment>
        <center>
          <div className="container  create-event">
            <h2 className="display-2 text-muted">Create Category</h2>
            <br />

            <div className="row ">
              <div className="col-md-1" />
              <div className="col-md-10">
                <form onSubmit={this.create_open_category}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                {" "}
                                Category Name :
                              </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            value={this.state.category_name}
                            name="category_name"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Category Name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <hr />

                    {showcategory}

                    <hr />
                  </div>

                  <button
                    className="btn btn-icon btn-3 btn-primary"
                    type="submit"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">Create Category</span>
                  </button>
                </form>
                <button
                  onClick={() => this.autocreate()}
                  className="btn btn-icon btn-3 mt-2 btn-primary"
                  // type="submit"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-bullet-list-67" />
                  </span>

                  <span className="btn-inner--text">
                    Auto Create Categories
                  </span>
                </button>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
          <Categories
            delete={this.delete}
            updatecategory={this.updatecategory}
            categorystandard={this.categorystandard}
            categories={this.state.categories}
            eventtype={this.state.eventtype}
          />
        </center>
      </Fragment>
    );
  }
}

export default CategoryStandard;
