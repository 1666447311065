import React, { Component } from "react";
import Blacklist from "./Blacklist";
import PickupBlacklist from "./Pickup";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtype: "",
    };
  }

  componentWillMount() {
    //let eventid = this.props.match.params.id;
    //this.props.setevent(eventid);

    let eventtype = sessionStorage.getItem("eventtype");
    this.setState({ eventtype });
  }
  render() {
    const { eventtype } = this.state;

    let Mainpage;


    if (eventtype === "pickup") {
      Mainpage = <PickupBlacklist eventdata={this.props.eventdata} />;
    } else {
      Mainpage = <Blacklist eventdata={this.props.eventdata} />;
    }
    return (Mainpage);
  }
}

export default Index;
