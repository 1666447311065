import { SET_EVENT, SET_EVENT_ERROR, DELETE_EVENT, GATE_EDIT, CHANGE_SESSION } from "./types";
import axios from "axios";

export const setevent = (event_object_id) => (dispatch) => {


  axios
    .get(`/cms/events/event/${event_object_id}`)
    .then((event) => {
      let eventdetails = event.data;
      // console.log(event)
      dispatch(setCurrentevent(eventdetails));
    })
    .catch((err) => {
      dispatch(setCurrenteventerror(err));
    });
};

export const setCurrentevent = (eventdetails) => {
  return {
    type: SET_EVENT,
    payload: eventdetails,
  };
};

export const setCurrenteventerror = (err) => {
  return {
    type: SET_EVENT_ERROR,
    payload: err,
  };
};

export const deleteCurrentevent = (payload) => {
  return {
    type: DELETE_EVENT,
    payload: payload,
  };
};

export const gateedit_update = (payload) => {
  return {
    type: GATE_EDIT,
    payload: payload,
  };
};

export const changesession = (payload) => {
  return {
    type: CHANGE_SESSION,
    payload: payload,
  };
};
