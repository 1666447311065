import React, { useState, useEffect } from "react";
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, Row, Nav } from "reactstrap";

import axios from "axios";

import { chartOptions, parseOptions } from "variables/charts.js";

const chartExample7 = {
  options: {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  },
};

const chart = {
  labels: ["", "", ""],
  datasets: [
    {
      label: "Entry",
      backgroundColor: "#2ECE89",
      data: [],
      maxBarThickness: 30,
    },
    {
      label: "Exit",
      backgroundColor: "#5E73E4",
      data: [],
      maxBarThickness: 30,
    },
  ],
};

export default function Gategraph2(props) {
  // const [screen, setscreen] = useState(props.screen);
  // const [event_obj_id, setevent_obj_id] = useState(props.event_obj_id);
  const event_obj_id = props.event_obj_id;
  const groupevents = props.groupevents;
  const [graph, setGraph] = useState(chart);

  const fetch_gategraph = () => {


    let requestData ={
      event_obj_id:groupevents?.length>0?[event_obj_id,...groupevents.map((event)=> event.event_obj_id)]:[event_obj_id],
      screen:props.screen
    }

    axios
      .post(`/cms/v3/dashboard/gateGraph`,requestData)
      .then((response) => {
        if (response.data.success) {
          let gate_data_graph = response.data.gate_data;

          let labels = [];
          let entry = [];
          let exit = [];

          gate_data_graph.map((ggraph) => {
            labels.push(ggraph.label);
            entry.push(ggraph.entry);
            return exit.push(ggraph.exit);
          });

          setGraph((prevState) => {
            return {
              ...prevState,
              labels: labels,
              datasets: [
                {
                  label: "Entry",
                  backgroundColor: "#2ECE89",
                  data: entry,
                  maxBarThickness: 20,
                },
                {
                  label: "Exit",
                  backgroundColor: "#0679E4",
                  data: exit,
                  maxBarThickness: 20,
                },
              ],
            };
          });
        } else {
          setGraph((prevState) => {
            return {
              ...prevState,
              labels: [],
              datasets: [
                {
                  label: "Entry",
                  backgroundColor: "#2ECE89",
                  data: [],
                  maxBarThickness: 20,
                },
                {
                  label: "Exit",
                  backgroundColor: "#5E73E4",
                  data: [],
                  maxBarThickness: 20,
                },
              ],
            };
          });
        }
      });
  };

  useEffect(() => {
    if (props.screen) {
      fetch_gategraph();
      return setGraph(chart);
    } else {
      return setGraph(chart);
    }
  }, [props.screen,groupevents]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted ls-1 mb-1">
                Gate / Zone
              </h6>
              <h2 className="h3 mb-0">Overview</h2>
            </div>
          </Row>
          <Nav className="justify-content-end mt-1" pills>
            <span>Entry </span>
            <span
              style={{
                width: "20px",
                height: "10px",
                marginTop: "8px",
                marginLeft: "5px",
                backgroundColor: "#2FCE89",
              }}
            ></span>
            <span className="ml-2 ">Exit </span>
            <span
              style={{
                width: "20px",
                height: "10px",
                marginTop: "8px",
                marginLeft: "5px",
              }}
              className="bg-primary"
            ></span>
          </Nav>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Bar
              data={graph}
              options={chartExample7.options}
              className="chart-canvas"
              id="chart-bar-stacked"
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
