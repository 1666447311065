import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import "./style.css";
import IconButton from "@material-ui/core/IconButton";
import Button from "reactstrap/lib/Button";

class DaysList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gates: [],
      session_details: [],
      event_obj_id: "",
      username: props.auth.user.name,
      pagination: {},
    };

    this.delete = this.delete.bind(this);
  }

  delete(id) {
    this.props.delete(id);
  }

  async edit(index, session_data) {
    const { value: formValues } = await Swal.fire({
      title: "Edit Session",
      html: `
      <div class="form-row">
      <div class="form-group col-md-12">
        <label for="dayrefeventid">Event ID</label>
        <input type="text" class="form-control" id="dayrefeventid"
        value="${
          session_data.event_id_bms
        }" placeholder="Please Enter Event ID">
      </div>
    </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="name">Session Name</label>
      <input type="text" class="form-control" id="name"
      value="${session_data.name}" placeholder="Please Enter Day Name">
    </div>
    <div class="form-group col-md-6">
      <label for="session">Session ID</label>
      <input type="text" class="form-control" id="session" 
      value="${session_data.session}" placeholder="Please Enter Session ID">
    </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-6">
    <label for="dayrefstartdate">Start Date & Time</label>
    <input type="text" class="form-control" id="dayrefstartdate" 
    value="${
      session_data.dayrefstartdate
    }" placeholder="Please Enter Start Day">
  </div>
  <div class="form-group col-md-6">
    <label for="dayrefenddate">End Date & Time</label>
    <input type="text" class="form-control" id="dayrefenddate"
    value="${session_data.dayrefenddate}" placeholder="Please Enter End Day">
  </div>
  </div>
  <div class=${
    this.state.username === "Darshan" ? "form-row" : "form-row display-none"
  } >
  <div class="form-group col-md-6">
    <label for="name">Iv</label>
    <input type="text" class="form-control" id="iv"
    value="${session_data.iv}" placeholder="Please Enter Iv">
  </div>
  <div class="form-group col-md-6">
    <label for="key">Key</label>
    <input type="text" class="form-control" id="key" 
    value="${session_data.key}" placeholder="Please Enter Key">
  </div>
</div>
  
</div>   
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          dayrefeventid: document.getElementById("dayrefeventid").value,
          name: document.getElementById("name").value,
          session: document.getElementById("session").value,
          dayrefstartdate: document.getElementById("dayrefstartdate").value,
          dayrefenddate: document.getElementById("dayrefenddate").value,
          iv: document.getElementById("iv").value,
          key: document.getElementById("key").value,
          id: session_data._id,
          event_obj_id: session_data.event_obj_id,
          unique: `${document.getElementById("dayrefeventid").value}-${
            document.getElementById("name").value
          }-${session_data.venue_Code}`,
        };
      },
    });

    if (formValues) {
      this.update(formValues);
    }
  }

  update = (formValues) => {
    let startday = formValues.dayrefstartdate;
    let endday = formValues.dayrefenddate;

    startday = startday.substring(0, 10);
    endday = endday.substring(0, 10);

    let update_data = {
      session: formValues.session,
      dayrefstartdate: formValues.dayrefstartdate,
      dayrefenddate: formValues.dayrefenddate,
      startday,
      endday,
      iv: formValues.iv,
      key: formValues.key,
      event_id_bms: formValues.dayrefeventid,
      name: formValues.name,
      unique: formValues.unique,
      id: formValues.id,
      event_obj_id: formValues.event_obj_id,
    };

    axios
      .post(`/cms/days/standard/update_day_configs2`, update_data)
      .then((response_data) => {
        let data = {
          event_obj_id: formValues.event_obj_id,
        };

        this.props.sync_days(data);
        return Swal.fire("Success", "Configuration Updated", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  is_disable_toggle = (session) => {
    // return alert(session._id)

    let obj = {
      _id: session._id,
      is_disable: !session.is_disable,
      event_obj_id: session.event_obj_id,
    };

    axios.post(`/cms/days/standard/is_disable_toggle`, obj).then((respos) => {
      if (respos.data.success) {
        let newsession_details = [];

        this.state.session_details.map((session_details) => {
          if (session_details._id === session._id) {
            session_details.is_disable = !session_details.is_disable;
          }

          newsession_details.push(session_details);
        });

        this.setState({ session_details: newsession_details });
      } else {
        alert("something went wrong");
      }
    });
  };

  componentWillReceiveProps(nextprops) {
    return this.setState({
      session_details: nextprops.session_details,
      event_obj_id: nextprops.event_obj_id,
      pagination: nextprops.pagination,
    });
  }

  render() {
    if (this.state.finish) {
      return <Redirect to={"/dashboard"} />;
    }

    let daylist;
    daylist = this.state.session_details.map((session, index) => {
      return (
        <tr className="eventsearch text-center" key={index}>
          {/* <td>{index + 1}</td> */}
          <td>{session.event_id_bms}</td>

          <td>{session.name}</td>
          <td>{session.session}</td>
          <td>{session.iv ? <span>Active</span> : <span>Inactive</span>}</td>
          <td>{session.venue_Code}</td>
          <td>{session.dayrefstartdate}</td>
          <td>{session.dayrefenddate}</td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <label className="custom-toggle">
              <input
                type="checkbox"
                checked={session.is_disable ? false : true}
                onChange={() => {
                  this.is_disable_toggle(session);
                }}
              />
              <span className="custom-toggle-slider rounded-circle " />
            </label>
          </td>
          <td>
            <button
              onClick={() => this.edit(index, session)}
              className="btn btn-sm btn-icon btn-2 btn-info"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-settings" />
              </span>
            </button>
          </td>
          <td>
            <button
              onClick={() => this.delete(session)}
              className="btn btn-sm btn-icon btn-2 btn-danger"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div
        className="search-data"
        style={{ padding: 40, backgroundColor: "#fff" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            placeholder="Search by EventID , Name , Session & Venuecode"
            onChange={(e) => this.props.setsearch(e.target.value)}
            style={{
              width: 400,
              height: 30,
              border: "1px solid #ccc",
              borderRadius: 2,
              alignSelf: "center",
              marginBottom: 15,
              borderTopColor: "#fff",
              borderLeftColor: "#fff",
              borderRightColor: "#fff",
              fontSize: 14,
            }}
          />
          <div style={{ marginLeft: 10, marginBottom: 40 }}>
            <Button
              onClick={() => this.props.sync_days()}
              style={{ backgroundColor: "#192A4D", color: "#fff" }}
            >
              Search
            </Button>
          </div>
        </div>
        {this.state.session_details.length > 0 ? (
          <center>
            <h4 className="text-muted">ALL Sessions</h4>
            <table className="table table-bordered">
              <thead className="theme-dark text-white text-center">
                <tr>
                  {/* <th scope="col">Sr.</th> */}
                  <th scope="col">EventID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Session</th>
                  <th scope="col">Encryption</th>
                  <th scope="col">Venue Code</th>
                  <th scope="col">Start Session</th>
                  <th scope="col">End Session</th>
                  <th scope="col">Sync to mobile</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>

              <tbody>{daylist}</tbody>
            </table>
          </center>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 10, marginRight: 30 }}>
            <h5>
              {this.state.pagination?.pagedetails} of{" "}
              {this.state.pagination?.total}
            </h5>
            {/* <h5>
              {this.state.pagination?.page} - {this.state.pagination?.limit} of{" "}
              {this.state.pagination?.pages}
            </h5> */}
          </div>

          <IconButton
            onClick={() =>
              this.props.sync_days(this.state.pagination?.page - 1)
            }
            disabled={this.state.pagination?.hasPrevPage ? false : true}
          >
            <NavigateBeforeIcon fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() =>
              this.props.sync_days(this.state.pagination?.page + 1)
            }
            disabled={this.state.pagination?.hasNextPage ? false : true}
          >
            <NavigateNextIcon fontSize="medium" />
          </IconButton>
        </div>
      </div>
    );
  }
}

DaysList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(DaysList);
