import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";

import { QRCode } from "react-qrcode-logo";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open, barcodeValue } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 30,
        }}
      >
        <QRCode enableCORS={true} size="150" value={barcodeValue} />
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({ barcode, closeModal }) {
  const [open, setOpen] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [barcodeValue, setbarcodeValue] = React.useState();

  useEffect(() => {
    setbarcodeValue(barcode);
  }, [barcode]);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    closeModal();
  };

  return (
    <div>
      <SimpleDialog
        barcodeValue={barcodeValue}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
