import React, { useState, useEffect } from "react";
import axios from "axios";
// import Swal from "sweetalert2";
import logo from "../reports/bms.jpeg";
import ReactDatetime from "react-datetime";

import {
  FormGroup,
  InputGroupText,
  InputGroup,
  // Col,
  // Row,
} from "reactstrap";

import commaNumber from "comma-number";

import jspdf from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";

let host = window.location.hostname;
let url;

if (host === "eventmanager.bms.bz") {
  url = "https://acs.bms.bz/";
} else if (host === "eventmanager-sg.bms.bz") {
  url = "https://acs-sg.bms.bz/";
} else if (host === "eventmanager-ae.bms.bz") {
  url = "https://acs-ae.bms.bz/";
} else if (host === "eventmanager-sit.bms.bz") {
  url = "https://eventmanager-acs-sit.bms.bz/";
} else if (host === "eventmanager-ae-sit.bms.bz") {
  url = "https://eventmanager-ae-acs-sit.bms.bz/";
} else {
  url = `http://${host}:8090/`;
}

function Reports() {
  // const [loading, setLoading] = useState(false);
  const [submit_button, Setsubmit_button] = useState(false);
  const [manual, setManual] = useState(false);
  const [type, setType] = useState("ticket");
  const [format, setFormat] = useState("pdf");
  const [sessionType, setsessionType] = useState("Single");

  const [groupSessions, setgroupSessions] = useState([]);

  const [groupevents,setgroupevents]= useState([]);

  const [day, setDay] = useState("");
  const [report, setReport] = useState("report1");
  const [dayref, setDayref] = useState("");
  const [starttime, setStarttime] = useState("N-A");
  const [endtime, setEndtime] = useState("N-A");
  const [admission, setAdmission] = useState("entry");

  const [eventname, Seteventname] = useState("");
  const [event_id_bms, Setevent_id_bms] = useState("");
  const [event_obj_id, Setevent_obj_id] = useState("");
  const [eventtype, Seteventtype] = useState("");
  const [days, Setdays] = useState("");

  const [showtype, Setshowtype] = useState(false);
  const [showduration, Setshowduration] = useState(false);
  const [is_admission, setIs_admission] = useState(false);
  const [test_mode, settest_mode] = useState(false);

  // const [mockData, setmockData] = useState(null)

  const submit = (e) => {
    Setsubmit_button(true);
    e.preventDefault();
    // console.log(manual, type, day, report, dayref, starttime, endtime);
    // console.log(report);

    switch (report) {
      case "report1":
        catgate("$cat_ref", "Category-wise");
        break;
      case "report14":
        report14("$cat_ref", "Category & Ttype summary");
        break;
      case "report2":
        catgate("$gate_details", "Gate-wise");
        break;
      case "report4":
        report4("Anti Pass Back Override");
        break;
      case "report5":
        report5("APB Override Transwise");
        break;
      case "report6":
        report6("Daily Attendance");
        break;
      case "report7":
        report7("Access Errors");
        break;
      case "report8":
        report8("Zone & Gate Details Hourly");
        break;
      case "report15":
        report15("Category & Ttype usage");
        break;
      case "report9":
        report9("Ticket/Pass Usage/Outstanding");
        break;
      case "report10":
        report10("Re Entry");
        break;
      case "report11":
        report11("HOURLY PATRON-STAFF COUNT");
        break;
      case "report12":
        report12("Override Details");
        break;
      case "report13":
        report13("Hourly Flow at Gates");
        break;
      case "report16":
        report16("Device or User Wise");
        break;
      default:
        break;
    }
  };

  const csvexport = (data, report_name) => {
    const csvRows = [];
    let headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${report_name}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const report10 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      test_mode,
    };

    axios
      .post(`/cms/pdf/report/report10`, report)
      .then((response) => {
        Setsubmit_button(false);

        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.category_data, report_name);
          } else {
            var head = [
              [
                "Sr",
                "Ref",
                "Name",
                "Type",
                "Re-Entry (1x)",
                "Re-Entry (2x)",
                "Re-Entry (3x)",
                "Re-Entry (>3x)",
              ],
            ];

            let body = response.data.category_data;
            let count = response.data.count;

            pdf_generate(count, head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report6 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      test_mode,
    };

    axios
      .post(`/cms/pdf/report/report6`, report)
      .then((response) => {
        Setsubmit_button(false);

        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report6, report_name);
          } else {
            var head = [
              ["Attendee Type", "ENTRY + RE-ENTRY", "EXIT", "TOTAL IN"],
            ];
            let body = response.data.report6;
            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  //  reports
  const catgate = (ref, report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      ref,
      admission,
      format,
      report_name,
      test_mode,
    };

    axios
      .post(`/cms/pdf/report/catgate`, report)
      .then((response) => {
        Setsubmit_button(false);

        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.category_data, report_name);
          } else {
            var head;

            if (report_name === "Gate-wise") {
              head = [["Sr", "Gate", "Type", "Count"]];
            } else {
              head = [["Sr", "Category", "Type", "Count"]];
            }

            let body = response.data.category_data;
            let count = response.data.count;
            pdf_generate(count, head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report16 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      admission,
      format,
      report_name,
      test_mode,
    };

    axios
      .post(`/cms/pdf/report/report16`, report)
      .then((response) => {
        Setsubmit_button(false);
        // console.log(response.data)

        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report16, report_name);
          } else {
            var head = [
              ["Sr", "Device or User", "Total Count", "Unique Count"],
            ];

            let body = response.data.report16;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report14 = (ref, report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      ref,
      admission,
      format,
      report_name,
      test_mode,
    };

    axios
      .post(`/cms/pdf/report/report14`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            csvexport(response.data.category_data, report_name);
          } else {
            var head = [["Sr", "Category", "Ticket type", "Type", "Count"]];
            let body = response.data.category_data;
            let count = response.data.count;
            pdf_generate(count, head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report11 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      dayref,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report11`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report11, report_name);
          } else {
            var head = [["Session", "Time", "Patron", "Staff"]];

            let data = response.data.report11;
            let array = [];

            data.map((rep) => {
              let array2 = [
                dayref,
                rep.time,
                commaNumber(rep.ticket),
                commaNumber(rep.accred),
              ];
              array.push(array2);
            });

            let body = array;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report7 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      admission,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report7`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report7, report_name);
          } else {
            var head = [
              ["Gate", "Not Exit", "Not Entered", "Invalid", "Blacklisted"],
            ];

            let body = response.data.report7;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report8 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      admission,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report8`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report8, report_name);
          } else {
            var head = [
              [
                "Zone",
                "Gate",
                "00:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
                "Row Total",
              ],
            ];

            let body = response.data.report8;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report13 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      admission,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report13`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report13, report_name);
          } else {
            var head = [
              [
                "Gate",
                "00:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
                "Row Total",
              ],
            ];

            let body = response.data.report13;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report9 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name: "outstanding",
      test_mode,
    };

    axios
      .post(`/cms/pdf/report/report9`, report)
      .then((response) => {
        Setsubmit_button(false);
        // console.log(response);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report9, report_name);
          } else {
            var head = [
              [
                "Zone",
                "Category",
                "Attendee Type",
                "Tickets sold/Passes issued",
                "Consumed",
                "Outstanding Balance",
              ],
            ];

            let body = response.data.report9;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report15 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report15`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report15, report_name);
          } else {
            var head = [
              [
                "Zone",
                "Category",
                "Ticket Type",
                "Attendee Type",
                "Tickets sold/Passes issued",
                "Currently In the venue",
                "Outstanding Balance",
              ],
            ];

            let body = response.data.report15;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report4 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report4`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report4, report_name);
          } else {
            var head = [
              [
                "GATE",
                "Type",
                "OVERRIDE - ENTRY",
                "OVERRIDE RE-ENTRY",
                "OVERRIDE - EXIT",
              ],
            ];

            let body = response.data.report4;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report5 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report5`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report5, report_name);
          } else {
            var head = [
              [
                "GATE",
                "Category",
                "Type",
                "BARCODE",
                "Device",
                "OVERRIDE",
                "DATE/TIME STAMP",
              ],
            ];

            let body = response.data.report5;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const report12 = (report_name) => {
    let report = {
      event_obj_id,
      session: day,
      barcode_type: type,
      manual,
      starttime,
      endtime,
      format,
      report_name,
      test_mode,
    };
    axios
      .post(`/cms/pdf/report/report12`, report)
      .then((response) => {
        Setsubmit_button(false);
        if (response.data.success) {
          if (format === "excel") {
            return csvexport(response.data.report12, report_name);
          } else {
            var head = [["GATE", "TICKET/PASS TYPE", "OVERRIDE TYPE", "COUNT"]];

            let body = response.data.report12;

            pdf_generate("", head, body, report_name);
          }
        } else {
          nodata();
        }
      })
      .catch((err) => {
        Setsubmit_button(false);
        console.log(err);
      });
  };

  const pdf_generate = (count, head, body, report_name) => {
    // console.log(dayref)

    var doc = new jspdf({ putOnlyUsedFonts: true, orientation: "landscape" });
    //var doc = new jspdf();

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    // FOOTER
    let event_name = `${eventname.toUpperCase()}`;
    let report = `${report_name.toUpperCase()} REPORT - ${dayref.toUpperCase()}`;
    let startdate = `START TIME : ${starttime ? starttime : "N/A"}`;
    let enddate = `END TIME : ${endtime ? endtime : "N/A"}`;
    let access_type = `ACCESS TYPE : ${type ? type.toUpperCase() : "N/A"}`;
    let admission_type = `DIRECTION : ${
      admission ? admission.toUpperCase() : "N/A"
    }`;
    let session = `SESSION NAME : ${dayref ? dayref.toUpperCase() : "N/A"}`;

    doc.setTextColor(100);
    doc.setFontSize(10);

    var img = new Image();
    // img.src =
    //   "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQOwxc8JFTlmx65YdM083oP0qFNydg1t2VFPQ&usqp=CAU";

    img.src = logo;
    // img.height('100');
    // img.width('100');

    doc.addImage(img, 125, 1, 0, 20, 20);
    doc.setFont("bold");
    doc.setFontSize("17");
    doc.text(event_name, pageWidth / 2, pageHeight - 179, "center");
    doc.setFont("bold");
    doc.setFontSize("12");
    doc.text(report, pageWidth / 2, pageHeight - 172, "center");
    doc.setFont("normal");
    doc.setFontSize("10");
    {
      startdate !== "N/A" &&
        doc.text(startdate, pageWidth / 2, pageHeight - 160, "center");
    }

    {
      enddate !== "N/A" &&
        doc.text(enddate, pageWidth / 2, pageHeight - 154, "center");
    }

    // { access_type !== 'N/A' && doc.text(access_type, pageWidth / 2, pageHeight - 151, "center") }

    {
      access_type !== "N/A" &&
        doc.text(admission_type, pageWidth / 2, pageHeight - 147, "center");
    }
    // { doc.text(session, pageWidth / 2, pageHeight - 137, "center") }

    if (count) {
      doc.setFont("bold");
      doc.setFontSize("12");
      doc.text(
        `${type.toUpperCase()} : ${count}`,
        pageWidth / 2,
        pageHeight - 139,
        "center"
      );
    }

    let drawCell = function (data) {
      if (
        report_name === "Hourly Flow" ||
        report_name === "Hourly Flow at Gates"
      ) {
        var doc = data.doc;
        var rows = data.table.body;

        if (rows.length === 1) {
        } else if (data.row.index === rows.length - 1) {
          doc.setFont("bold");
          doc.setFontSize("12");
          doc.setFillColor(59, 70, 75);
          doc.setTextColor(255, 255, 255);
        }
      }
    };

    // console.log(body)

    doc.autoTable({
      margin: { top: 75 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
      },
      willDrawCell: drawCell,
      //styles: { fillColor: "#3B464B" },
      theme: "grid",
      bodyStyles: { lineColor: [0, 0, 0] },
      headStyles: { fillColor: [59, 70, 75], lineColor: [0, 0, 0] },
      head: head,
      body: body,
    });

    doc.save(`${report_name} Report.pdf`);
  };

  const nodata = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "No Data Found!",
    });
  };

  useEffect(() => {
    const geteventid = sessionStorage.getItem("eventid");
    axios.get(`/cms/events/${geteventid}`).then((event) => {
      Seteventname(event.data.events.eventname);
      Setevent_id_bms(event.data.events.event_id_bms);
      Setevent_obj_id(event.data.events._id);
      Seteventtype(event.data.events.eventtype);
      fetch_days(event.data.events._id,event.data.events.groupevents);
      setgroupSessions(event.data.events.groupSession);
      setgroupevents(event.data.events.groupevents)
      //Setdays(event.data.events.sessiondetails);
      // console.log(event.data.events.sessiondetails)
    });
  }, []);

  const fetch_days = (event_obj_id,groupevents) => {
    let fech_day = {
      event_obj_id: groupevents.length>0?[event_obj_id,...groupevents.map((event)=> event.event_obj_id)]:[event_obj_id],
    };

    axios
      .post("/cms/days/standard/alldays", fech_day)
      .then((days) => {
        // console.log(days)
        Setdays(days.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <center>
      <div className="container create-event">
        <h2 className="display-2 text-muted">Create Report </h2>
        <hr />
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-md-4 offset-md-2">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Mode
                  </label>
                  <select
                    name="environment"
                    onChange={(e) =>
                      e.target.value === "testmode"
                        ? settest_mode(true)
                        : settest_mode(false)
                    }
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    required
                  >
                    <option value="prodmode">Production</option>
                    <option value="testmode">Test</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    File Format
                  </label>
                  <select
                    name="format"
                    onChange={(e) =>
                      e.target.value === "pdf"
                        ? setFormat("pdf")
                        : setFormat("excel")
                    }
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    required
                  >
                    <option value="pdf">PDF</option>
                    <option value="excel">CSV</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4 offset-md-2">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Session Type
                  </label>
                  <select
                    name="sessiontype"
                    onChange={(e) => setsessionType(e.target.value)}
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    required
                  >
                    <option value="Single">Single</option>
                    <option value="Group">Group</option>
                  </select>
                </div>
              </div>
            </div>
            {sessionType === "Single" ? (
              <div className="col-md-4 ">
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Choose Sessions
                    </label>
                    <select
                      name="day"
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      onChange={(e) => {
                        let array = e.target.value.split(",");
                        // setDay(array[0]);
                        setDay([array[0]]);
                        setDayref(array[1]);
                        Setevent_obj_id(array[2])
                      }}
                      required
                    >
                      <option value=""> Select...</option>

                      {days.length > 0 &&
                        days.map((day, index) => {
                          let dayname = day.name.replace(",", "");
                          return (
                            <option
                              key={index}
                              value={[day.session, dayname,day.event_obj_id]}
                              //value={dayname}
                            >
                              {dayname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-4">
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Choose Group Sessions
                    </label>
                    <select
                      name="day"
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      onChange={(e) => {
                        let array = e.target.value.split(",");
                        let Dayref = array.pop();

                        setDay(array);
                        setDayref(Dayref);
                        //console.log(e.target.value)
                      }}
                      required
                    >
                      <option value=""> Select...</option>

                      {groupSessions.length > 0 &&
                        groupSessions.map((day, index) => {
                          let dayname = day.name.replace(",", "");

                          return (
                            <option
                              key={index}
                              value={[day.sessions, dayname]}
                              //value={dayname}
                            >
                              {dayname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-4 offset-md-4">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Report Type
                  </label>
                  <select
                    name="type"
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => {
                      let report = e.target.value;
                      setReport(e.target.value);

                      if (report === "report6" || report === "report11") {
                        Setshowtype(false);
                        Setshowduration(false);
                      } else if (
                        report === "report9" ||
                        report === "report15" ||
                        report === "report10"
                      ) {
                        Setshowtype(true);
                        setIs_admission(false);
                        Setshowduration(false);
                      } else if (
                        report === "report7" ||
                        report === "report8" ||
                        report === "report13"
                      ) {
                        setIs_admission(true);
                        Setshowtype(true);
                        Setshowduration(true);
                      } else if (
                        report === "report5" ||
                        report === "report12"
                      ) {
                        setIs_admission(false);
                        Setshowtype(true);
                        Setshowduration(true);
                      } else {
                        setIs_admission(true);
                        Setshowtype(true);
                        Setshowduration(true);
                      }
                    }}
                    required
                  >
                    <option value=""> Select...</option>
                    <option value="report1"> Category-wise</option>
                    <option value="report2"> Gate-wise</option>
                    <option value="report14">Category & Ttype summary</option>
                    <option value="report5"> APB Override Transwise</option>
                    <option value="report8">Zone & Gate Details Hourly</option>
                    {/* <option value="report3"> Gates + Categories</option> */}
                    <option value="report4">
                      {/* Anti Pass Back Override (Report 1) */}
                      Anti Pass Back Override
                    </option>
                    <option value="report12">
                      {/* Override Details (Report 2) */}
                      Override Summary
                    </option>
                    <option value="report6">
                      {/* Entry / Exit Details (Report 3) */}
                      {/* Entry / Exit Details */}
                      Daily Attendance
                    </option>
                    <option value="report7">
                      {/* Access Errors (Report 4) */}
                      Access Errors
                    </option>
                    {eventtype === "acs-sgp" ? (
                      <option value="report13">
                        {/* Hourly Flow (Report 5) */}
                        Hourly Flow
                      </option>
                    ) : null}
                    <option value="report9">
                      {/* Ticket/Pass Usage/Outstanding (Report 6) */}
                      Ticket/Pass Usage/Outstanding
                    </option>
                    <option value="report15">Category & Ttype usage</option>
                    <option value="report10">
                      {/* Re-Entry (Report 7) */}
                      Re-Entry
                    </option>
                    <option value="report11">
                      {/* Hourly Patron / Staff Count (Report 8) */}
                      Hourly Patron / Staff Count
                    </option>
                    <option value="report16">Device or User Wise</option>
                  </select>
                </div>
              </div>
            </div>
            {is_admission ? (
              <div className="col-md-4 offset-md-4 ">
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Direction
                    </label>
                    <select
                      name="admission"
                      onChange={(e) => setAdmission(e.target.value)}
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      required
                    >
                      <option value="entry">Entry</option>
                      <option value="exit">Exit</option>
                      {/* {report === 'report1' ? null : <option value="all">All</option>} */}
                    </select>
                  </div>
                </div>
              </div>
            ) : null}

            {showtype ? (
              <div
                className={
                  !showduration
                    ? "col-md-4 offset-md-4"
                    : "col-md-4 offset-md-2"
                }
              >
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Attendee Type
                    </label>
                    <select
                      name="mode"
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      onChange={(e) => setType(e.target.value)}
                      required
                    >
                      {/* <option value="all">All</option> */}
                      <option value="ticket">Patron</option>
                      <option value="accred">Staff</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>
              </div>
            ) : null}

            {showduration ? (
              <div className="col-md-4 ">
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Duration
                    </label>
                    <select
                      name="mode"
                      onChange={(e) =>
                        e.target.value === "manual"
                          ? setManual(true)
                          : setManual(false)
                      }
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      required
                    >
                      <option value="auto">Auto</option>
                      <option value="manual">Manual</option>
                    </select>
                  </div>
                </div>
              </div>
            ) : null}

            {manual ? (
              <>
                {" "}
                <div className="col-md-4 offset-md-2 ">
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      <ReactDatetime
                        inputProps={{
                          placeholder: "Start Time",
                          name: "starttime",
                          required: true,
                          autoComplete: "off",
                        }}
                        timeFormat="HH:mm:ss"
                        dateFormat={false}
                        onChange={(e) => {
                          let string_data = e._d;
                          string_data = String(string_data);
                          let time = string_data.split(" ");
                          time = time[4];
                          time = time.replace(":", "");
                          time = time.replace(":", "");
                          setStarttime(time);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className="col-md-4 ">
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                    < InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      <ReactDatetime
                        inputProps={{
                          placeholder: "End Time",
                          name: "endtime",
                          required: true,
                          autoComplete: "off",
                        }}
                        timeFormat="HH:mm:ss"
                        dateFormat={false}
                        onChange={(e) => {
                          let string_data = e._d;
                          string_data = String(string_data);

                          let time = string_data.split(" ");

                          time = time[4];

                          time = time.replace(":", "");
                          time = time.replace(":", "");

                          setEndtime(time);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
              </>
            ) : null}
          </div>

          <button
            className="btn btn-icon btn-3 btn-primary"
            disabled={submit_button}
            type="submit"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-cloud-download-95" />
            </span>

            <span className="btn-inner--text">
              {submit_button ? "Downloading.." : "Generate"}
            </span>
          </button>
        </form>
      </div>
    </center>
  );
}

export default Reports;
