import React, { Component } from "react";

import axios from "axios";
import Statistics from "./Statistics";
import Categoryscans from "./Categoryscans";
//    import CategoryProgress from "./CategoryProgress";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: "",
      tick_pickup: "",
      tick_unpickup: "",
      tick_progress: "",
      trans: "",
      trans_pickup: "",
      trans_unpickup: "",
      trans_progress: "",
      device: "",
      inv: "",
      accuracy: "0",
      sync: "",
      eventname: "",
      event_id_bms: "",
      days: "",
      venuecode: "",
      event_obj_id: "",
    };
  }

  getdevicecount = async (event_obj_id) => {
    await axios
      .get(`/cms/events/eventmonitor/${event_obj_id}`)
      .then((response) => {

        //console.log(response.data)

        // let devices = response.data;
        // let length = Object.keys(devices).length;
        this.setState({ device: response.data.data.length });
      });
  };

  getalldata(event_obj_id) {
    this.gettrans(event_obj_id);
    this.getticket(event_obj_id);
    this.getdevicecount(event_obj_id);
  }

  syncevent = async () => {
    let days = this.state.days;
    var session_data = [];
    days.forEach((day, index) => {
      session_data.push(day.dayrefsession);
    });
    session_data = session_data.join();
    let synccat = {
      venuecode: this.state.venuecode,
      session: session_data,
      event_id_bms: this.state.event_id_bms,
      event_obj_id: this.state.event_obj_id,
    };

    axios
      .post(`/cms/transaction/sync`, synccat)
      .then((response) => { })
      .catch((err) => {
        console.log(err);
      });

    this.interval2 = setInterval(() => {
      let days = this.state.days;
      var session_data = [];
      days.forEach((day, index) => {
        session_data.push(day.dayrefsession);
      });
      session_data = session_data.join();
      let synccat = {
        venuecode: this.state.venuecode,
        session: session_data,
        event_id_bms: this.state.event_id_bms,
        event_obj_id: this.state.event_obj_id,
      };
      // console.log(synccat);
      axios
        .post(`/cms/transaction/sync`, synccat)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 30000);
  };

  async componentDidMount() {
    let event_obj_id = sessionStorage.getItem("eventid");

    await axios.get(`/cms/events/${event_obj_id}`).then((event) => {
      this.setState({
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: event_obj_id,
        days: event.data.events.sessiondetails,
        venuecode: event.data.events.venuecode,
      });
    });

    //this.syncevent();
    this.getalldata(event_obj_id);
    this.interval = setInterval(() => {

      this.getalldata(event_obj_id);
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  }

  async gettrans(event_obj_id) {
    axios
      .get(`/cms/dashboard/pickup/transactions/${event_obj_id}`)
      .then((transaction) => {
        let transactions = transaction.data;
        if (transaction.data.success) {
          this.setState({
            trans: transactions.count,
            trans_pickup: transactions.pickup_status,
            trans_unpickup: transactions.unpickup_status,
            trans_progress: transactions.progress_status,
            sync: transactions.bo_status,
            accuracy: transactions.accuracy,
          });
        } else {
          this.setState({
            trans: 0,
            trans_pickup: 0,
            trans_unpickup: 0,
            trans_progress: 0,
            sync: 0,
          });
        }
      });
  }

  async getticket(event_obj_id) {
    axios
      .get(`/cms/dashboard/pickup/tickets/${event_obj_id}`)
      .then((tickets) => {
        let tickets_data = tickets.data;
        if (tickets.data.success) {
          this.setState({
            tickets: tickets_data.count,
            tick_pickup: tickets_data.pickup_status,
            tick_unpickup: tickets_data.unpickup_status,
            tick_progress: tickets_data.progress_status,
          });
        } else {
          this.setState({
            tickets: 0,
            tick_pickup: 0,
            tick_unpickup: 0,
            tick_progress: 0,
          });
        }
      });
  }

  render() {
    return (
      <div>
        <div className="header pb-8 pt-2 pt-md-4 theme-dark">
          <div className="container-fluid">
            <h2 className="text-white text-left pb-4">
              {`${this.state.eventname.toUpperCase()} - ${this.state.event_id_bms
                }`}
            </h2>
            <div className="header-body">
              <Statistics statsdata={this.state} />
            </div>
          </div>
        </div>

        <div className="container-fluid mt--7">
          <div className="row" />
          <div className="row mt-5">
            <Categoryscans
              type="Categorys"
              event_obj_id={this.state.event_obj_id}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
