import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";

import Dayslist from "./DaysList";
import Swal from "sweetalert2";
import moment from "moment";

class Days extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      session_details: [],
      dayrefeventid: "",
      dayrefname: "",
      dayrefsession: "",
      iv: "",
      key: "",
      event_ref_ids: [],
      session_ref_ids: [],
      dayrefstartdate: moment().format("DD-MM-YYYY HH:mm"),
      dayrefenddate: moment().format("DD-MM-YYYY HH:mm"),
      venue_Code: "",
      pagination: {
        total: 0,
        page: 1,
        pages: 0,
        hasNextPage: false,
        hasPrevPage: false,
      },
      groupevents:[],
      search: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
    });
  };

  delete = (e) => {
    this.delete_alert(e);
  };

   pagination_res_details = (days_data) => {
    return {
      total: days_data.total,
      page: days_data.page,
      pages: days_data.pages,
      hasNextPage: days_data.hasNextPage,
      hasPrevPage: days_data.hasPrevPage,
      limit: days_data.limit,
      pagedetails:
        days_data.page === 1
          ? `${days_data.page} - ${days_data.docs.length} `
          : `${days_data.limit * days_data.page - days_data.limit + 1} - ${
              days_data.limit * (days_data.page - 1) + days_data.docs.length
            }`,
    };
  };

  async componentDidMount() {
    let geteventid = sessionStorage.getItem("eventid");
    this.setState({ event_obj_id: geteventid });

    // this.geteventmonitors(geteventid);
    await axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        event: event.data,
        sync: event.data.events.sync,
        mobile: event.data.events.mobile,
        emails: event.data.events.emails,
        eventname: event.data.events.eventname,
        emailaccess: event.data.events.emailaccess,
        smsaccess: event.data.events.smsaccess,
        event_obj_id: geteventid,
        event_id_bms: event.data.events.event_id_bms,
        groupevents: event.data.events.groupevents,
      });
    });
    this.sync_days();
  }

  sync_days = async (page = 1) => {
    const { event_obj_id, search, groupevents } = this.state;

    let post_groupevents_data;

    if (groupevents.length > 0) {
      post_groupevents_data = {
        id: [event_obj_id,...groupevents.map((event)=> event.event_obj_id)],
        limit: 10,
        page,
        search,
      };
    }


    try {
      let days_data;

      if (post_groupevents_data) {
        days_data = await axios.post("/cms/v3/sessions", post_groupevents_data);
      } else {
        days_data = await axios.get(
          `/cms/v3/sessions?id=${event_obj_id}&limit=10&page=${page}&search=${search}`
        );
      }

      const { data } = days_data;

      if (data) {
        this.setState({
          session_details: data.docs,
          pagination: this.pagination_res_details(data),
        });
      }
    } catch (error) {
      console.log(error);
    }

   
  };

  session_split = () => {
    const { session_details } = this.state;
    let event_ref_ids = [];
    let session_ref_ids = [];

    session_details.forEach((session) => {
      event_ref_ids.push(session.dayrefeventid);
      session_ref_ids.push(session.dayrefsession);
    });

    return this.setState({ event_ref_ids, session_ref_ids });
  };

  validation = () => {
    const dayrefeventid = this.state.event_ref_ids;
    const dayrefsession = this.state.session_ref_ids;

    let day_ref_event_check = dayrefeventid.includes(this.state.dayrefeventid);
    let day_ref_session_check = dayrefsession.includes(
      this.state.dayrefsession
    );

    if (day_ref_event_check && day_ref_session_check) {
      return false;
    } else {
      return true;
    }
  };

  createday = async (e) => {
    e.preventDefault();

    this.save_session();

  };

  save_session = () => {
    const {
      dayrefeventid,
      dayrefname,
      dayrefsession,
      session_details,
      dayrefstartdate,
      dayrefenddate,
      event_obj_id,
      venue_Code,
    } = this.state;

    let startday = dayrefstartdate.split(" ");
    let endday = dayrefenddate.split(" ");

    startday = startday[0];
    endday = endday[0];

    let newmticket_data = {
      iv: "",
      key: "",
      dayrefeventid,
      dayrefname,
      dayrefsession,
      unique: `${dayrefeventid}-${dayrefsession}-${venue_Code}`,
      dayrefstartdate,
      dayrefenddate,
      startday,
      endday,
      event_obj_id,
      venue_Code,
      session: dayrefsession,
      name: dayrefname,
      event_id_bms: dayrefeventid,
      matchid: [],
    };

    // return this.autoload(session_details);

    return this.updatesession(newmticket_data);
  };

  updatesession = async (newmticket_data) => {
    axios
      .post(`/cms/days/standard`, newmticket_data)
      .then((response) => {
        //console.log(response);
        if (response.data.success) {
          this.sync_days();
          return this.alert_modal(true, response.data.msg);
        } else {
          return this.alert_modal(false, response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  alert_modal(status, msg) {
    if (status) {
      return Swal.fire({
        type: "success",
        title: "Success",
        text: msg,
        footer: "",
      });
    } else {
      return Swal.fire({
        type: "error",
        title: "Oops...",
        text: msg,
        footer: "",
      });
    }
  }

  autoload = (mticket_data) => {
    let timerInterval;
    Swal.fire({
      title: "Fetching Mticket Details!",
      html: "Sync In Progress <strong></strong> .",
      timer: 3000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector("strong").textContent =
            Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
        // return this.setState({ session_details });
        if (mticket_data) {
          return this.save_session(mticket_data);
        } else {
          return this.save_session();
        }

        // console.log("I was closed by the timer");
      }
    });
  };

  delete_alert = (e) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.delete_day(e);
          // swalWithBootstrapButtons.fire(
          //   "Deleted!",
          //   `${day_sesion.dayrefname} has been deleted.`,
          //   "success"
          // );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Action Cancelled :)",
            "error"
          );
        }
      });
  };

  setsearch = (search) => {
    this.setState({ search }, () => {
      if (search === "") {
        this.sync_days();
      }
    });
  };

  delete_day = async (e) => {
    let data = {
      id: e._id,
    };

    axios
      .post("/cms/days/standard/delete", data)
      .then((response) => {
        if (response.data.success) {
          this.sync_days();
          return this.alert_modal(true, `${e.name} Deleted Succesfully !`);
        } else {
          return this.alert_modal(false, "Something went wrong");
        }
        // console.log(response);
      })
      .catch((err) => {
        console.log(err);
        return this.alert_modal(false, err);
      });

    // this.setState({ session_details });
  };

  render() {
    const {
      eventname,
      event_id_bms,
      dayrefsession,
      dayrefeventid,
      dayrefname,
      groupevents
    } = this.state;

    let showbutton = false;
    if (dayrefsession && dayrefeventid && dayrefname) showbutton = true;

    return (
      <Fragment>
        <center>
          {groupevents.length===0?<div className="container  create-event">
            <h2 className="display-2 text-muted">Create Session</h2>
            <br />

            <div className="row ">
              <div className="col-md-2" />
              <div className="col-md-8">
                <form onSubmit={this.createday}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefeventid"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Event ID"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Session Name :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefname"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Day Name"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Session ID :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefsession"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Session ID"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Venue Code :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="venue_Code"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Venue Code"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                Start Date & Time :
                              </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefstartdate"
                            value={this.state.dayrefstartdate}
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Start Day"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                End Date & Time :
                              </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefenddate"
                            value={this.state.dayrefenddate}
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Start Day"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <button
                    className="btn btn-icon btn-3 bg-default"
                    type="submit"
                    disabled={showbutton ? false : true}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">Create Session</span>
                  </button>
                </form>
              </div>
              <div className="col-md-2" />
            </div>
          </div>:null}
          <Dayslist
            delete={this.delete}
            sync_days={this.sync_days}
            session_details={this.state.session_details}
            event_obj_id={this.state.event_obj_id}
            pagination={this.state.pagination}
            setsearch={this.setsearch}
          />
        </center>
      </Fragment>
    );
  }
}

export default Days;
