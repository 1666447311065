import React, { Component } from "react";
import Turnstile_IMG from "../../images/turnstile.png";
import Mobile_IMG from "../../images/mobile.svg";
import Laptop_IMG from "../../images/laptop.png";
import Swal from "sweetalert2";

import axios from "axios";

export default class List extends Component {
  update = async (devices) => {
    let mobile = false;
    let turnstile = false;
    let server = false;
    let type = devices.type;
    if (type === "mobile") {
      mobile = true;
    } else if (type === "turnstile") {
      turnstile = true;
    } else {
      server = true;
    }

    const { value: formValues } = await Swal.fire({
      title: "Edit Device",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-12">
      <label for="devicetype">Device Type</label>
      <select class="custom-select custom-select-md" id="devicetype">
      <option value="mobile" ${mobile ? "selected" : null}>Mobile</option>
      <option value="turnstile" ${
        turnstile ? "selected" : null
      } >Turnstile</option>
      <option value="server" ${server ? "selected" : null} >Server</option>
    </select>
      </div>
  </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="deviceid">Device ID</label>
      <input type="text" class="form-control" id="deviceid"
      value="${devices.device_id}"
      placeholder="Please Enter Unique ID">
    </div>
    <div class="form-group col-md-6">
      <label for="deviceserial">Serial Number</label>
      <input type="text" class="form-control" id="deviceserial" 
      value="${devices.famoco_id}"
       placeholder="Please Enter Unique ID">
    </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-12">
    <label for="macid">Mac Address</label>
    <input type="text" class="form-control" id="macid" 
    value="${devices.mac_id}"
     placeholder="Please Enter Unique ID">
  </div>
  
</div>

    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          type: document.getElementById("devicetype").value,
          device_id: document.getElementById("deviceid").value,
          famoco_id: document.getElementById("deviceserial").value,
          mac_id: document.getElementById("macid").value,
        };
      },
    });

    if (formValues) {
      this.updatedevice(formValues, devices._id);
    }
  };

  delete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios.delete(`/cms/devices/${id}`).then((response) => {
          if (response.data.success) {
            Swal.fire("Device Deleted Succesfully", "Success", "success");
            this.props.sync();
          } else {
            Swal.fire("Something went wrong", "Error", "error");
          }
        });
      }
    });
  };

  updatedevice = (updatedevice, id) => {
    axios.patch(`/cms/devices/${id}`, updatedevice).then((response) => {
      if (response.data.success) {
        Swal.fire("Device Update Succesfully", "Success", "success");
        this.props.sync();
      } else {
        Swal.fire("Something went wrong", "Error", "error");
      }
    });
  };

  render() {
    let image;

    let type = this.props.device.type;

    if (type === "mobile") {
      image = Mobile_IMG;
    } else if (type === "turnstile") {
      image = Turnstile_IMG;
    } else {
      image = Laptop_IMG;
    }

    return (
      <tr className="text-center">
        <td className="pl-2">{this.props.index + 1}</td>
        <th scope="row">
          <div>
            <img height="30" alt={this.props.device.type} src={image} />
          </div>
        </th>
        <td>{this.props.device.type}</td>
        <td>{this.props.device.device_id}</td>
        <td>{this.props.device.mac_id}</td>
        <td>
          {this.props.device.famoco_id ? this.props.device.famoco_id : "Null"}
        </td>
        <td>
          <div className="col">
            <div
              href="#!"
              onClick={() => this.update(this.props.device)}
              className="btn btn-sm bg-yellow text-white"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-settings" />
              </span>
            </div>
          </div>
        </td>
        <td>
          <div className="col">
            <div
              href="#!"
              onClick={() => this.delete(this.props.device._id)}
              className="btn btn-sm bg-red text-white"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}
