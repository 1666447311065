import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Select from "react-select";
import List from "./List";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { connect } from "react-redux";


class Gates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: null,
      selectedDay: null,
      event: "",
      Days: [],
      sessiondetails: [],
      category: "",
      day: "",
      barcode: "",
      barcode_array: [],
      Days_session: [],
      categorys_data: [],
      countlist: [],
      eventtype: "",
      event_obj_id: "",
      groupevents: [],
    };
    //this.onChange = this.onChange.bind(this);
  }

  whitelist_download = () => {
    window.open(
      `${axios.defaults.baseURL}/cms/report/download/gateentry/whitelist/${this.state.event_obj_id}`,
      "_blank"
    );
  };

  whitelist_download_sessionwise = (session) => {
    window.open(
      `${axios.defaults.baseURL}/cms/report/download/gateentry/whitelist/${this.state.event_obj_id}/${session}`,
      "_blank"
    );
  };

  delete = (name, day) => {
    Swal.fire({
      title: `Please Enter Password`,
      input: "password",

      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Please Enter Admin Passoword",
        type: "t",
      },
      showCancelButton: true,
      confirmButtonText: "Proceed",
      showLoaderOnConfirm: true,
      preConfirm: (passoword) => {
        if (passoword !== "admin1234") {
          return Swal.fire("Error", "Passowrd Not Match", "error");
        } else {
          this.deleteconfirm(name, day);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  delete2 = (name, day) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.deleteconfirm(name, day);
      }
    });
  };

  deleteconfirm = (name, day) => {
    const { event_obj_id } = this.state;

    let new_delete = {
      event_obj_id,
      category_name: name,
      session_id: day,
    };

    axios
      .post("/cms/whitelist/category_delete", new_delete)
      .then((response) => {
        let deletedcount = response.data.deleted;

        Swal.fire({
          type: "success",
          title: "Success!",
          text: `${deletedcount} Barcodes Deletd`,
        });
        return this.get_count();
      });
  };

  change = (e) => {
    let barcode = e.target.value;
    barcode = barcode.split("\n").filter((i) => i);

    this.setState({ barcode_array: barcode });

    //this.setState({ [e.target.name]: e.target.value });
  };

  handleChange_category = (selectedCategory) => {
    this.setState({
      selectedCategory,
      category: selectedCategory.value,
    });
  };

  handleChange_day = (selectedDay) => {
    this.setState({
      selectedDay,
      day: selectedDay.value,
    });
  };

  async componentDidMount() {
    const geteventid = sessionStorage.getItem("eventid");

    let get_days = {
      event_obj_id: geteventid,
    };

    await axios.get(`/cms/events/${geteventid}`).then((response) => {
      let events_data = response.data.events;
      this.setState({
        event: events_data,
        event_id_bms: events_data.event_id_bms,
        event_obj_id: events_data._id,
        eventtype: events_data.eventtype,
        groupevents: events_data.groupevents,
      });
    });

    await this.get_category_details();
    await this.sync_days(get_days);
  }

  sync_days = (get_days) => {
    const { groupevents, event_obj_id } = this.state;

    const requestData = {
      id:
        groupevents.length > 0
          ? [event_obj_id,...groupevents.map((event)=> event.event_obj_id)]
          : [event_obj_id],
      limit: 10000,
      page: 1,
      search: "",
    };

    axios
      .post("/cms/v3/sessions", requestData)
      .then((response) => {
        const days_data = response.data;
        this.setState({ sessiondetails: days_data.docs });
        this.get_count();
        this.get_session_details();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_count = () => {
    // alert('hello')
    const { sessiondetails, event_obj_id, Days } = this.state;
    let countlist = [];

    let x = 0;

    sessiondetails.forEach(async (session) => {
      let countCat = {
        event_obj_id: session.event_obj_id,
        session_id: session.session,
      };

      await axios
        .post("/cms/whitelist/whitelist_sessionwise", countCat)
        .then(async (response) => {
          let count_cat = response.data.count;
          let count_prev = {
            dayref: session.name,
            count_cat,
            session_id: session.session,
            event_obj_id:session.event_obj_id,
            event_id_bms: session.event_id_bms,
          };

          countlist.push(count_prev);
          x = x + 1;

          if (sessiondetails.length === x) {
            this.setState({ countlist: countlist });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });

    // countlist = countlist.sort({ dayref: 1 })
  };

  get_category_details = async () => {
    const { _id } = this.state.event;

    await axios.get(`/cms/category/standard/getall/${_id}`).then((response) => {
      let categorys = response.data;
      this.setState({ categorys_data: categorys });
      let Category = [];
      let Category2 = [];
      categorys.map((category) => {
        return Category.push(category.category_name);
      });

      let Category_new = [...new Set(Category)];

      Category_new.map((cat) => {
        let precategory = {
          value: cat,
          label: cat,
        };
        return Category2.push(precategory);
      });

      this.setState({ Category: Category2 });
    });
  };

  get_session_details = async (geteventid) => {
    const { sessiondetails } = this.state;

    let Days = [];

    sessiondetails.map((session) => {
      let preoptions = {
        value: session.session,
        label: session.name,
      };

      return Days.push(preoptions);
    });

    // console.log(sessiondetails)

    this.setState({ Days });
  };

  createwhitelist = (e) => {
    e.preventDefault();

    const {
      category,
      categorys_data,
      event_id_bms,
      event_obj_id,
      barcode_array,
      day,
      eventtype,
    } = this.state;

    let selected_category = {};

    categorys_data.map((category_info) => {
      if (category_info.category_name === category) {
        selected_category = category_info;
      }
    });

    //return console.log(this.state);

    let newwhitelist = {
      category_id: selected_category._id,
      zone: selected_category.zone,
      inserted_by: this.props.auth.user.name,
      barcode_type: selected_category.barcode_type,
      category_ref: selected_category.param3,
      gate_ref: selected_category.gate_ref,
      category_name: selected_category.category_name,
      session_id: day,
      event_id_bms,
      event_obj_id,
      barcode_array,
      eventtype,
    };

    axios
      .post("/cms/whitelist", newwhitelist)
      .then((response) => {
        if (response.data.success) {
          Swal.fire(
            "Success!",
            `${response.data.barcodes} Barcodes Added Successfully`,
            "success"
          );
          return this.get_count();
        } else {
          Swal.fire("Error!", response.data.message, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  render() {
    let {
      selectedCategory,
      selectedDay,
      event,
      barcode_array,
      countlist,
      groupevents,
    } = this.state;
    let showbutton = false;

    if (
      selectedCategory != null &&
      selectedDay != null &&
      barcode_array.length > 0
    ) {
      showbutton = true;
    } else {
      showbutton = false;
    }

    return (
      <Fragment>
        <center>
          {!this.props.auth.user.superadmin ? null : (
            <div className="container  create-event">
              <h2 className="display-2 text-muted">Whitelist Configuration</h2>
              <br />

              <div className="row ">
                <div className="col-md-2" />
                <div className="col-md-8">
                  {groupevents.length === 0 ? (
                    <form onSubmit={this.createwhitelist}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group has-success ">
                            <div className="input-group input-group-alternative mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text ">
                                  <span className="text-muted">
                                    Event Name :
                                  </span>
                                </span>
                              </div>
                              <input
                                style={{ padding: "10px" }}
                                disabled
                                className="form-control form-control-alternative"
                                placeholder={event.eventname}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group has-success ">
                            <div className="input-group input-group-alternative mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text ">
                                  <span className="text-muted">
                                    Event ID :{" "}
                                  </span>
                                </span>
                              </div>
                              <input
                                style={{ padding: "10px" }}
                                disabled
                                className="form-control form-control-alternative"
                                placeholder={event.event_id_bms}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 ">
                        <label
                          className="text-muted"
                          htmlFor="exampleFormControlSelect2"
                        >
                          Select Session
                        </label>
                        <Select
                          value={selectedDay}
                          isSearchable
                          closeMenuOnSelect={true}
                          onChange={this.handleChange_day}
                          options={this.state.Days}
                          isMulti={false}
                          required
                          allowSelectAll={false}
                        />
                      </div>

                      <div className="col-md-6 ">
                        <label
                          className="text-muted"
                          htmlFor="exampleFormControlSelect2"
                        >
                          Select Category
                        </label>
                        <Select
                          value={selectedCategory}
                          isSearchable
                          closeMenuOnSelect={true}
                          onChange={this.handleChange_category}
                          options={this.state.Category}
                          isMulti={false}
                          required
                          allowSelectAll={false}
                        />
                      </div>

                      <br />
                      <div className="col-md-6 ">
                        <label
                          className="text-muted"
                          htmlFor="exampleFormControlSelect2"
                        >
                          Insert Barcodes
                        </label>
                        <br />

                        <textarea
                          className="form-control form-control-alternative"
                          rows="10"
                          placeholder="Insert Barcodes.."
                          name="barcodes"
                          onChange={this.change}
                        ></textarea>
                      </div>
                      <br />
                      <span>
                        {barcode_array.length > 0
                          ? `Total Barcodes Entered :- ${barcode_array.length} `
                          : ""}
                      </span>
                      <hr />

                      <button
                        className="btn btn-icon btn-3 bg-default"
                        type="submit"
                        disabled={showbutton ? false : true}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-bullet-list-67" />
                        </span>

                        <span className="btn-inner--text">Insert Barcodes</span>
                      </button>
                    </form>
                  ) : null}
                  <br />

                  {groupevents.length === 0?<button
                    onClick={() => {
                      this.whitelist_download();
                    }}
                    className="btn btn-icon btn-3 btn-info"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">Download Barcodes</span>
                  </button>:null}
                </div>
                <div className="col-md-2" />
              </div>
            </div>
          )}

          <List delete={this.delete} countlist={countlist} />
        </center>
      </Fragment>
    );
  }
}

// export default Gates;

Gates.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Gates);
