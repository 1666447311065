import React, { Component } from "react";
import axios from "axios";

import { Redirect } from "react-router-dom";
// import "./monitoringstyle.css";
import { IconButton } from "@material-ui/core";


// import Download from '@material-ui/icons/Sync';
// import Delete_logo from '@material-ui/icons/Delete';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';

//import Delete_logo from '@mui/icons-material/Delete';

import Swal from "sweetalert2";

export default class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: "",
      sync: false,
      eventname: "",
      mobile: "",
      emails: "",
      days: "",
      venuecode: "",
      event_id_bms: "",
      event_obj_id: "",
      finish: false,
      emailaccess: false,
      smsaccess: false,
      days_data: []
    };
    this.handleChange = this.handleChange.bind(this);
    // this.getNameById = this.getNameById.bind(this);
  }

  // componentDidMount() {
  //   let event_obj_id = sessionStorage.getItem("eventid");

  // }

  toggle = togg => {
    if (togg === "sms") {
      this.setState({ smsaccess: !this.state.smsaccess });
    }
    if (togg === "email") {
      this.setState({ emailaccess: !this.state.emailaccess });
    }
    console.log(this.state);
  };

  delete_event = () => {
    Swal.fire({
      title: "Delete This Event ?",
      input: "password",

      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Please Enter Admin Passoword",
        type: "t"
      },
      showCancelButton: true,
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      preConfirm: passoword => {
        if (passoword !== "admin1234") {
          return Swal.fire("Error", "Passowrd Not Match", "error");
        } else {
          return this.delete_event_proceed();
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  };

  delete_event_proceed = () => {
    const id = this.state.event.events._id;
    const eid = this.state.event.events.event_id_bms;
    axios
      .delete(`/cms/events/${id}/${eid}`)
      .then(response => {
        let event_status = response.data;

        if (!event_status.success) {
          return Swal.fire("Error!", event_status.msg, "error");
        } else {
          this.setState({ finish: true });
          return Swal.fire("Deleted!", event_status.msg, "success");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async componentWillMount() {
    let geteventid = sessionStorage.getItem("eventid");
    let get_days = {
      event_obj_id: geteventid,
    };
    await axios.get(`/cms/events/${geteventid}`).then(event => {
      this.setState({
        event: event.data,
        sync: event.data.events.sync,
        venuecode: event.data.events.venuecode,
        mobile: event.data.events.mobile,
        emails: event.data.events.emails,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: geteventid,
        // days: event.data.events.sessiondetails,
        eventname: event.data.events.eventname,
        emailaccess: event.data.events.emailaccess,
        smsaccess: event.data.events.smsaccess
      });
    });
    this.sync_days(get_days);
  }


  sync_days = (get_days) => {
    axios
      .post(`/cms/days/standard/alldays`, get_days)
      .then((response) => {
        const days_data = response.data;
        // console.log(days_data);

        this.setState({ days_data: days_data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  update_event = e => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want To Update This!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!"
    }).then(result => {
      if (result.value) {
        this.update_event_config();
      }
    });
  };

  update_event_config = () => {
    let update_event = {
      event_obj_id: this.state.event.events._id,
      eventname: this.state.eventname,
      emails: this.state.emails,
      mobile: this.state.mobile,
      emailaccess: this.state.emailaccess,
      smsaccess: this.state.smsaccess,
      venuecode: this.state.venuecode
    };

    axios
      .post(`/cms/events/config`, update_event)
      .then(event => {
        Swal.fire(
          "Updated!",
          "Your Event Has Been Updated Succesfully!",
          "success"
        );
      })
      .catch(err => {
        console.log(err);
        alert("Something went wrong");
      });
  };

  session_sync = (session, venuecode, id) => {
    let days_data = this.state.days_data;
    var session_data = [];
    days_data.forEach((day, index) => {
      session_data.push(day.session);
    });



    session_data = session_data.join();
    let synccat = {
      venuecode: venuecode,
      session: session.session,
      event_id_bms: this.state.event_id_bms,
      event_obj_id: this.state.event_obj_id
    };

    axios
      .post(`/cms/transaction/sync`, synccat)
      .then(response => {
        let data = response.data;
        if (data.success === true) {
          this.sync_complete(true, "Sync Completed");
        } else {
          let msg = data.msg;
          this.sync_complete(false, msg);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  blacklist_sync = (session_sync, venuecode, id) => {
    let session_sync_data = {
      event_id_bms: session_sync.dayrefeventid,
      session: session_sync.dayrefsession,
      venuecode: venuecode,
      event_obj_id: id
    };

    axios
      .post(`/cms/mtrans/blacklist`, session_sync_data)
      .then(res => {
        let sync_data = res.data.mtrans;
        let success = res.data.success;

        if (sync_data) {
          this.sync_blacklist_complete(sync_data, success);
        } else {
          Swal.fire({
            type: "error",
            title: "Error !",
            text: "No Data Found For Downloading!"
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }
      })
      .catch(err => {
        console.log(err);
        alert(err.message)
      });

    //console.log(session_sync, venuecode, id);
  };

  sync_complete = (success, msg) => {
    let timerInterval;
    Swal.fire({
      title: "Downloading Session Data!",
      html: "Sync In Progress.. <strong></strong>",
      timer: 4000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector(
            "strong"
          ).textContent = Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    }).then(result => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        if (success) {
          Swal.fire({
            type: "success",
            title: "Sync Completed...",
            text: msg
            // footer: '<a href>Why do I have this issue?</a>'
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Something Went Wrong..",
            text: msg
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }

        // console.log("I was closed by the timer");
      }
    });
  };

  autosync = async (session) => {



    let autosync = !session.auto_sync;
    let session_day = session.session;

    let days_data = this.state.days_data;
    let new_days = [];

    days_data.map((day) => {
      if (day.session === session_day && day.venue_Code === session.venue_Code) {
        day.auto_sync = autosync;
      }
      return new_days.push(day);
    });

    this.setState({ days_data: new_days }, () => {
      let autosync_mode = {
        autosync,
        id: session._id,
        eventdata: this.state.event.events
      };
      axios
        .post(`/cms/days/standard/autoupdate`, autosync_mode)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });

  };

  delete_password_trans = () => {
    Swal.fire({
      title: "Delete Transaction ?",
      input: "password",

      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Please Enter Admin Passoword",
        type: "t",
      },
      showCancelButton: true,
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      preConfirm: (passoword) => {

        if (passoword !== "Bongo1234") {
          return Swal.fire("Error", "Passowrd Not Match", "error");
        } else {
          return this.delete_confirm();
        }


      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  delete_trans = session => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        this.delete_password_trans();
      }
    });
  };

  sync = async () => {
    await this.setState({ sync: !this.state.sync });

    let sync = {
      event_obj_id: this.state.event.events._id,
      sync: this.state.sync
    };

    await axios.post(`/cms/events/sync`, sync).then(event => { });
  };

  delete_confirm = () => {
    let event_obj_id = this.state.event.events._id;

    axios
      .get(`/cms/transaction/delete_trans/${event_obj_id}`)
      .then(response => {
        let success = response.data.success;
        console.log(response.data);

        if (success) {
          Swal.fire(
            "Deleted!",
            `${response.data.response.deletedCount} Transactions has been deleted.`,
            "success"
          );
        } else {
          Swal.fire("Failed!", `${response.data.msg} `, "error");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  unpick_trans = session => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        this.unpick_confirm();
      }
    });
  };

  unpick_confirm = () => {
    let event_obj_id = this.state.event.events._id;

    axios
      .get(`/cms/transaction/unblock_trans/${event_obj_id}`)
      .then(response => {
        let data = response.data;
        if (data.success) {
          Swal.fire(
            "Deleted!",
            `${response.data.response.nModified} Transactions has been Unblocked.`,
            "success"
          );
        } else {
          Swal.fire("Failed!", `${response.data} `, "error");
        }
      })
      .catch(err => {
        Swal.fire("Failed!", `${err} `, "error");
      });
  };

  sync_blacklist_complete = (sync_data, success) => {
    let timerInterval;
    Swal.fire({
      title: "Downloading Session Data!",
      html: "Sync In Progress.. <strong></strong>",
      timer: 4000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector(
            "strong"
          ).textContent = Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    }).then(result => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        if (success) {
          Swal.fire({
            type: "success",
            title: "Sync Completed...",
            text: `${sync_data} Blacklist Tickets Downloaded Succesfully!`
            // footer: '<a href>Why do I have this issue?</a>'
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Already Sync...",
            text: `${sync_data} Blacklist Tickets Already Downloaded!`
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }

        // console.log("I was closed by the timer");
      }
    });
  };

  render() {
    if (this.state.finish) {
      return <Redirect to={"/home"} />;
    }
    const { event } = this.state;

    let eventmonitorsdata = (
      <tr key="1" className="text-center">
        <td className="pl-2" />
      </tr>
    );

    if (event) {
      const { venuecode, _id } = event.events;
      eventmonitorsdata = this.state.days_data.map((session, index) => {
        // console.log(session)
        return (
          <tr key={index + 1} className="text-center">
            <td className="pl-2">{index + 1}</td>
            <td className="pl-2">{session.event_id_bms}</td>
            <td className="pl-2">{session.name}</td>
            <td className="pl-2">{session.session}</td>
            <td className="pl-2">
              {/* <button
                onClick={() => this.delete_trans(session)}
                style={{
                  backgroundColor: "transparent",
                  border: "transparent"
                }}
              >
                <img height="30" alt="checked" src={DeleteIcon}></img>
              </button> */}
              <IconButton onClick={() => this.delete_trans(session)}>    <DeleteIcon color="action" />
              </IconButton>
            </td>
            <td className="pl-2">
              <IconButton onClick={() => this.unpick_trans(session, venuecode, _id)}>    <DeleteIcon color="action" />
              </IconButton>
            </td>
            <td className="pl-2">
              <IconButton onClick={() => {
                //console.log(session)

                this.session_sync(session, session.venue_Code, _id)
              }}>
                <SyncIcon color="action" />
              </IconButton>
            </td>
            <td className="pl-2">
              <IconButton onClick={() => this.blacklist_sync(session, session.venue_Code, _id)}>
                <SyncIcon color="action" />
              </IconButton>
            </td>

            <td>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  checked={session.auto_sync}
                  onChange={() => {

                    this.autosync(session);
                  }}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <h3 className="mt-5 p-2 text-center">EVENT CONFIGURATION </h3>
          <div className="card-header border-0">
            <div className="row align-items-center"></div>
          </div>

          <div className="container">
            <form onSubmit={this.update_event}>
              <div className="col-md-6 offset-md-3 text-center">
                <div className="form-group ">
                  <div className="input-group input-group-alternative mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text ">
                        <span className="text-muted">Event Name :</span>
                      </span>
                    </div>
                    <input
                      name="eventname"
                      value={this.state.eventname}
                      onChange={this.handleChange}
                      className="form-control form-control-alternative"
                      placeholder="Type Event Name"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 offset-md-3 text-center">
                <div className="form-group ">
                  <div className="input-group input-group-alternative mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text ">
                        <span className="text-muted"> Mobile:</span>
                      </span>
                    </div>
                    <input
                      name="mobile"
                      onChange={this.handleChange}
                      value={this.state.mobile}
                      className="form-control form-control-alternative"
                      placeholder="Type Mobile Numbers"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-3 text-center">
                <div className="form-group ">
                  <div className="input-group input-group-alternative mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text ">
                        <span className="text-muted"> Email:</span>
                      </span>
                    </div>
                    <input
                      name="emails"
                      onChange={this.handleChange}
                      value={this.state.emails}
                      className="form-control form-control-alternative"
                      placeholder="Type Emails"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 offset-md-3 text-center">
                <div className="form-group ">
                  <div className="input-group input-group-alternative mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text ">
                        <span className="text-muted"> Venue Code:</span>
                      </span>
                    </div>
                    <input
                      name="venuecode"
                      onChange={this.handleChange}
                      value={this.state.venuecode}
                      className="form-control form-control-alternative"
                      placeholder="Type Venue Code"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 offset-md-3 text-center">
                <span className="text-muted"> Email Reporting:</span>
                <span className="clearfix"></span>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={this.state.emailaccess}
                    onChange={() => this.toggle("email")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>
              <div className="col-md-6 offset-md-3 text-center">
                <span className="text-muted"> Mobile Reporting :</span>
                <span className="clearfix"></span>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={this.state.smsaccess}
                    onChange={() => this.toggle("sms")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>
              <center>
                <button
                  className="btn btn-md bg-success text-white"
                  type="submit"
                >
                  Update
                </button>
              </center>
            </form>
          </div>

          <hr />

          <h3 className="mt-5 p-2 text-center">SESSION CONFIGURATION </h3>
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col text-right">
                {/* <a href="#!" className="btn btn-sm btn-primary">
                  Download
                </a> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr.</th>
                  <th scope="col">Event ID's</th>
                  <th scope="col">Day Details</th>
                  <th scope="col">Session ID</th>
                  <th scope="col">Transactions</th>
                  <th scope="col">Blacklist</th>
                  <th scope="col">Transactions</th>
                  <th scope="col">Blacklist</th>
                  <th scope="col">Auto Sync</th>
                </tr>
              </thead>
              <tbody>{eventmonitorsdata}</tbody>
            </table>
          </div>
          <hr />
          <center>
            <button
              className="btn btn-icon btn-3 btn-danger"
              type="button"
              onClick={this.delete_event}
            >
              <span className="btn-inner--icon">
                <i
                  className="fa fa-trash fa-3 text-white"
                  aria-hidden="true"
                ></i>
              </span>

              <span className="btn-inner--text">Delete Event</span>
            </button>
          </center>

          <hr />
        </div>
      </div>
    );
  }
}
