import React, { Component } from "react";

class GatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countlist: [],
    };

    this.delete = this.delete.bind(this);
  }

  delete(name, day) {
    this.props.delete(name, day);
  }

  componentWillReceiveProps(nextprops) {
    return this.setState({ countlist: nextprops.countlist });
  }

  render() {
    let countlist;
    countlist = this.state.countlist.map((list, index) => {
      return (
        <tr className="eventsearch text-center" key={index}>
          <td>{index + 1}</td>
          <td>{list.dayref}</td>
          <td>{list.session_id}</td>
          <td>{list.count_cat}</td>

          {/* <td>
            <button
              onClick={() => this.delete(list.category_name, list.session_id)}
              className="btn btn-sm btn-icon btn-2 btn-danger"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </button>
          </td> */}
        </tr>
      );
    });

    return (
      <div className="search-data">
        {this.state.countlist.length > 0 ? (
          <center>
            <h4 className="text-muted">Blacklist Data</h4>
            <table className="table table-bordered">
              <thead className="theme-dark  text-white text-center">
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Session Name</th>
                  <th scope="col">Session ID</th>
                  <th scope="col">Count</th>

                  {/* <th scope="col">Delete</th> */}
                </tr>
              </thead>

              <tbody>{countlist}</tbody>
            </table>
          </center>
        ) : null}
      </div>
    );
  }
}

export default GatesList;
