import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";

import GroupeventList from "./GroupeventList";
import Swal from "sweetalert2";

class GroupEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      groupevents: [],
      eventtype: "",
    };
  }

  delete = async (groupevent) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          let requestData = {
            groupevent: groupevent.event_id_bms,
            event_obj_id: this.state.event_obj_id,
          };

          // return console.log(requestData)

          try {
            let { data } = await axios.post(
              "/cms/v3/groupevents/deletegroupevent",
              requestData
            );

            this.alert_modal(true, "Event Deleted Succesfully");
            this.setState({ groupevents: data.data });
          } catch (error) {
            this.alert_modal(false, error.response.data.message);
          }
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Event is safe :)",
            "error"
          );
        }
      });
  };

  async componentDidMount() {
    let geteventid = sessionStorage.getItem("eventid");
    this.setState({ event_obj_id: geteventid });

    // this.geteventmonitors(geteventid);
    await axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        event: event.data,
        eventname: event.data.events.eventname,
        event_obj_id: geteventid,
        event_id_bms: event.data.events.event_id_bms,
        groupevents: event.data.events.groupevents,
        eventtype: event.data.events.eventtype,
      });
    });
  }

  alert_modal(status, msg) {
    if (status) {
      return Swal.fire({
        type: "success",
        title: "Success",
        text: msg,
        footer: "",
      });
    } else {
      return Swal.fire({
        type: "error",
        title: "Oops...",
        text: msg,
        footer: "",
      });
    }
  }

  setsearch = async (event_id_bms,zone) => {
    let requestData = {
      event_id_bms,
      eventtype: this.state.eventtype,
      event_obj_id: this.state.event_obj_id,
      zone,
    };

    try {
      let { data } = await axios.post("/cms/v3/groupevents", requestData);
      this.alert_modal(true, "Event Added Succesfully");
      this.setState({ groupevents: data.data });
    } catch (error) {
      this.alert_modal(false, error.response.data.message);
    }
  };

  render() {
    const {
      eventname,
      event_id_bms,
      dayrefsession,
      dayrefeventid,
      dayrefname,
    } = this.state;

    let showbutton = false;
    if (dayrefsession && dayrefeventid && dayrefname) showbutton = true;

    return (
      <Fragment>
        <center>
          <div className="container  create-event">
            <h2 className="display-2 text-muted">Create Event Group</h2>
            <br />

            <div className="row ">
              <div className="col-md-2" />
              <div className="col-md-8">
                <form onSubmit={this.createday}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-2" />
            </div>
          </div>
          <GroupeventList
            delete={this.delete}
            groupevents={this.state.groupevents}
            event_obj_id={this.state.event_obj_id}
            pagination={this.state.pagination}
            setsearch={this.setsearch}
          />
        </center>
      </Fragment>
    );
  }
}

export default GroupEvent;
