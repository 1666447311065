import React, { Component } from "react";
// import moment from "moment";
// import Turnstile from "../../../../assets/img/turnstile.png";
// import Mobile from "../../../../assets/img/mobile.svg";

export default class List extends Component {
  render() {
    const { status, dose, data } = this.props.device;

    let data2 = JSON.parse(data);

    return (
      <tr className="text-center">
        <td className="pl-2">{this.props.index + 1}</td>
        <td>{data2?.benid}</td>
        <td>{data2?.uhid}</td>
        <td>{dose}</td>
        <td
          style={{
            color: status === "allow" ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {status === "allow" ? "Allowed" : "Not Allowed"}
        </td>
        <td>{data2?.time}</td>
        <td>{data2?.user}</td>
      </tr>
    );
  }
}
