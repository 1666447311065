import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// const TIER= process.env.TIER

let host = window.location.hostname;


const httpClient = axios.create();
httpClient.defaults.timeout = 50000;

function Sync(props) {

  const [loading, setloading] = useState(false)

  let syncButtonshow=false;

  if(props.auth.superadmin || props.auth.admin){
    if(host === "eventmanager.bms.bz" || host === "eventmanager-sg.bms.bz" || host === "eventmanager-ae.bms.bz"){
      syncButtonshow=false;
    }else{
      syncButtonshow=true
    }
  }


  let inputvalue;

  const sync = () => {


    Swal.fire({
      title: "Enter Event ID",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      onOpen: () => {
        const input = Swal.getInput()
        input.oninput = (value) => {
          inputvalue = value.target.value
        }
      },
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Acs',
      denyButtonText: `Issuance`,
      allowOutsideClick: () => null,
    }).then(async(result) => {
      // console.log(value)
    

      if (result.isConfirmed || result.isDenied) {

        let payload = {
          event_id_bms: inputvalue,
          mode: result.isDenied ? 'pickup' : 'acs'

        }

        await new Promise((resolve) => setTimeout(resolve, 1000));

        Swal.fire({
          title: 'Event Sync In Progress!',
          html: 'Fetching Details From Server...',
          allowEscapeKey: true,
          allowOutsideClick: false,
          didOpen: () => {

            const output=async(status,message)=>{
               await new Promise((resolve) => setTimeout(resolve, 5000));

              Swal.close();
              if(status){
                Swal.fire("Event Sync Succesfully!", "", "success");
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              }else{
                return Swal.fire(message, "", "error");
              }
             
            }


             Swal.showLoading()

             if(payload.mode==='acs'){
              httpClient
              .get(`/cms/v3/sync/fetchCloudevent/${inputvalue}`)
              .then((response) => {
                let success = response.data.success;
                if (success) {
                  return output(true)
                } else {
                  return output(false,response.data.message)
                }
              })
              .catch((err) => {
                console.log(err);
                return output(false,err.message)
                
              });
    
            }else{
              httpClient
              .post('/cms/events/localsync2/', payload)
              .then((response) => {
                let success = response.data.success;
    
                if (success) {
                  return output(true)
                } else {
                  return output(false,response.data.msg)
                }

              })
              .catch((err) => {
                return output(false,err.message)
              });
            }
          }
        });        
      }
    })
  };


  return syncButtonshow ? (
    <div className="sync-event">
      <div onClick={sync} className="sync-event-round">
        <i className="fas fa-cloud-upload-alt"></i>
      </div>
    </div>
  ) : null;
}

Sync.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps, {})(Sync);
