import React, { Component } from "react";

export default class Statistics extends Component {
  render() {
    return (
      <div>
        <h2 className="text-white">Transactions</h2>
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Transactions
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.trans || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                      <i className="fas fa-database" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fa fa-arrow-up" /> 3.48%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Pickedup
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.trans_pickup || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                      <i className="fas fa-database" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-warning mr-2">
                    <i className="fas fa-arrow-down" /> 1.10%
                  </span>
                  <span className="text-nowrap">10 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      UnPickedup
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.trans_unpickup || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                      <i className="fas fa-database" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Trans Progress
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.trans_progress || 0}%
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                      <i className="fas fa-sync-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h2 className="text-white">Tickets</h2>
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Tickets
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.tickets || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                      <i className="fas fa-ticket-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fa fa-arrow-up" /> 3.48%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Pickedup
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.tick_pickup || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                      <i className="fas fa-ticket-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-warning mr-2">
                    <i className="fas fa-arrow-down" /> 1.10%
                  </span>
                  <span className="text-nowrap">10 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      UnPickedup
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.tick_unpickup || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                      <i className="fas fa-ticket-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Ticket Progress
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.tick_progress || 0}%
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                      <i className="fas fa-sync-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h2 className="text-white">Others</h2>
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Devices
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.device || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                      <i className="fas fa-mobile-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fa fa-arrow-up" /> 3.48%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Inventory
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.inventory || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                      <i className="fas fa-box" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-warning mr-2">
                    <i className="fas fa-arrow-down" /> 1.10%
                  </span>
                  <span className="text-nowrap">10 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Pending
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.accuracy || 0}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-certificate" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Sync Progress
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {this.props.statsdata.sync || 0}%
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                      {/* <img src={sync} height="30" alt="" /> */}
                      <i className="fas fa-sync-alt" />
                    </div>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-muted text-sm">
                  {/* <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                  </span>
                  <span className="text-nowrap">2 mins Ago</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
