import React, { Component } from "react";

import "./style.css";

export class Footer extends Component {
  render(props) {
    return (
      <footer
        // className="footer-main bg-default"
        className={
          this.props.color === "clear"
            ? "footer-main"
            : "footer-main bg-default"
        }
      >
        <span className="love-heart">
          Made with <i className="fa fa-heart pulse"></i>
        </span>
        <span className="footer-content">
          Copyrights &copy; 2019 - {new Date().getFullYear()} bookmyshow{" "}
          <span>
            <img
              width="15"
              src="https://cdn.countryflags.com/thumbs/india/flag-button-square-250.png"
              alt=""
            />
          </span>{" "}
          All Rights Reserved.
        </span>
      </footer>
    );
  }
}
