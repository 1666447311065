import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import BarcodeModal from "../BarcodeModal";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      getcategories: false,
      barcode: "",
    };

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  update(formValues) {
    this.props.updatecategory(formValues);
  }

  closeModal = () => {
    this.setState({ barcode: "" });
  };

  componentWillReceiveProps(nextprops) {
    this.setState({ categories: nextprops.categories });
  }

  apb_toggle = (category) => {
    const { categories } = this.state;

    let new_cat = [];

    let obj = {
      _id: category._id,
      apb: !category.apb,
    };

    axios.post(`/cms/category/standard/apb_toggle`, obj).then((respos) => {
      if (respos.data.success) {
        categories.map((cat) => {
          if (cat._id === category._id) {
            cat.apb = !category.apb;
          }

          return new_cat.push(cat);
        });

        this.setState({ categories: new_cat });
      } else {
        alert("something went wrong");
      }
    });
  };

  async edit(category) {
    const { value: formValues } = await Swal.fire({
      title: "Edit Category",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-6">
        <label for="category">Category</label>
        <input type="text" class="form-control" id="category"
        value="${category.category_name}" placeholder="Please Enter Category Name">
      </div>
      <div class="form-group col-md-6">
        <label for="issuer">ISSUER ID</label>
        <input type="number" class="form-control" id="issuer" 
        value=${category.category_issuer} placeholder="Please Enter Issuer">
      </div>
    </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="location">LOCATION ID</label>
      <input type="number" class="form-control" id="location"
      value=${category.category_location} placeholder="Please Enter Location ID">
    </div>
    <div class="form-group col-md-6">
      <label for="attempt">ATTEMPT</label>
      <input type="number" class="form-control" id="attempt" 
      value=${category.attempt} placeholder="Please Enter No Of Attempt">
    </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-6">
    <label for="sector">SECTOR ID</label>
    <input type="number" class="form-control" id="sector" 
    value=${category.category_sector} placeholder="Please Enter Sector ID">
  </div>
  <div class="form-group col-md-6">
    <label for="discount">DISCOUNT ID</label>
    <input type="number" class="form-control" id="discount"
    value=${category.category_discount} placeholder="Please Enter Discount ID">
  </div>
</div>
<div class="form-row">
<div class="form-group col-md-6">
  <label for="tickettype">TICKET TYPE</label>
  <input type="number" class="form-control" id="tickettype" 
  value=${category.category_type} placeholder="Please Enter TICKET TYPE ID">
 
</div>
<div class="form-group col-md-6">
  <label for="ticketaccred">TICKET/ACCRED</label>
  <input type="number" class="form-control" id="ticketaccred" 
  value=${category.category_tick_accred} placeholder="Please Enter Ticket/Accred">
</div>
</div>
    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          category_name: document.getElementById("category").value,
          category_issuer: document.getElementById("issuer").value,
          category_location: document.getElementById("location").value,
          attempt: document.getElementById("attempt").value,
          category_sector: document.getElementById("sector").value,
          category_discount: document.getElementById("discount").value,
          category_type: document.getElementById("tickettype").value,
          category_tick_accred: document.getElementById("ticketaccred").value,
          category_id: category._id,
        };
      },
    });

    if (formValues) {
      this.update(formValues);
    }
  }
  delete(catid) {
    this.props.delete(catid);
  }

  delete_all_categories = () => {
    this.props.deleteall();
  };

  render() {
    const { categories } = this.state;
    let allcategories;
    if (categories) {
      let new_sort = categories.sort();
      allcategories = new_sort.map((category, i) => {
        let sample_barcode = `${category.category_issuer}${category.category_location}${category.category_matchid}${category.category_sector}${category.category_discount}${category.category_type}${category.category_tick_accred}1234567`;

        let startdate = category.startdate;
        let enddate = category.enddate;

        if (startdate === enddate) {
          startdate = "Default";
          enddate = "Default";
        }

        return (
          <tr className="eventsearch text-center" key={i}>
            <td>{category.category_name}</td>
            <td>{category.dayref}</td>
            <td>{category.category_issuer}</td>
            <td>{category.category_location}</td>
            <td>{category.category_matchid}</td>
            <td>{category.category_sector}</td>
            <td>{category.category_discount}</td>
            <td>{category.category_type}</td>
            <td>{category.category_tick_accred}</td>
            <td>{category.barcode_type}</td>
            <td>{category.attempt}</td>
            <td>
              <button
                onClick={() => {
                  this.setState({ barcode: sample_barcode });
                }}
                className="btn btn-sm btn-icon btn-2 btn-success"
                type="button"
              >
                <ConfirmationNumberIcon />
              </button>
            </td>
            {/* <td>{sample_barcode}</td> */}
            {/* <td>{startdate}</td>
            <td>{enddate}</td> */}
            <td style={{ width: 10 }}>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  checked={category.apb}
                  onChange={() => {
                    this.apb_toggle(category);
                  }}
                />
                <span className="custom-toggle-slider rounded-circle " />
              </label>
            </td>
            <td style={{ width: 10 }}>
              <button
                onClick={() => this.edit(category)}
                className="btn btn-sm btn-icon btn-2 btn-info"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-settings" />
                </span>
              </button>
            </td>
            <td style={{ width: 10 }}>
              <button
                onClick={() => this.delete(category)}
                className="btn btn-sm btn-icon btn-2 btn-danger"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="search-data">
        {this.state.barcode ? (
          <BarcodeModal
            closeModal={this.closeModal}
            barcode={this.state.barcode}
          />
        ) : null}
        {this.state.categories.length > 0 ? (
          <center>
            <h4 className="text-muted">ALL CATEGORIES</h4>
            <table className="table table-bordered">
              <thead className="bg-success text-white text-center">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Session</th>
                  <th scope="col">Issuer</th>
                  <th scope="col">Location</th>
                  <th scope="col">Match</th>
                  <th scope="col">Sector</th>
                  <th scope="col">Discount</th>
                  <th scope="col">TType</th>
                  <th scope="col">T/A</th>
                  <th scope="col">Type</th>
                  <th scope="col">Attempt</th>
                  <th scope="col">Sample</th>
                  {/* <th scope="col">Start</th>
                  <th scope="col">End</th> */}
                  <th scope="col">APB</th>
                  <th scope="col">Update</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>

              <tbody>{allcategories}</tbody>
            </table>
            <div className="col-md-12">
              <button
                className="btn btn-icon btn-3 btn-danger"
                onClick={() => this.delete_all_categories()}
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
                <span className="btn-inner--text">Delete All Categories</span>
              </button>
            </div>
          </center>
        ) : null}
      </div>
    );
  }
}

export default Categories;
