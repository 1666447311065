import React from "react";

function List({ enquiry, barcodeData, index }) {



  const {
    access_status,
    barcode,
    out,
    gate_details,
    cat_ref,
    day_ref,
    mticket_barcode,
    device_id,
    scan_timestamp,
    barcode_type,
    remark,
    day_id,
    bookingid,
    is_scan,
    category_name,
    session_id,
    originPushresponse,
    isSyncOrigin,
    canSynctoOrigin,
    is_blacklist,
    local_server_sync,
    test_mode,
    originSyncStatus,
    callback_url
  } = barcodeData;

  let synctoorigin;
  let msg="Pending";


  if(window.location.protocol==='http:'){
    if(local_server_sync){
      synctoorigin=true
      msg="Synced"
    }else{
      synctoorigin=false
    }
  }else{
    
    if (test_mode) {
      synctoorigin = "Not Required - Test Mode";
      synctoorigin=true
    } else {
      if(access_status ==='invalid' || callback_url === ''){
        msg="Not Required"
        synctoorigin=true
      }else{
        if(originSyncStatus && originSyncStatus===0){
          synctoorigin=false
        }else{
          if(originPushresponse?.success){
            msg="Synced"
            synctoorigin=true
          }else{
            synctoorigin=false
            if(originPushresponse?.error){
              msg=originPushresponse?.error
            }else if(originPushresponse?.respose){
              if(originPushresponse?.respose?.message){
                msg = originPushresponse?.respose?.message
              }else if(originPushresponse?.respose?.error){
                msg = originPushresponse?.respose?.error
              }else{
                msg = "Server Error"
              }
             
            }
          }
          
        }
      }
      
    }
  }


  return enquiry ? (
    <tr className="text-center">
      <td className="pl-2">{index + 1}</td>
      <td>{bookingid}</td>
      <td>
        {barcode.substring(0, 6)} ************************************
        {barcode.substr(barcode.length - 5)}
      </td>
      <td>{category_name}</td>
      <td>{session_id}</td>
      <td
        style={{
          color: is_blacklist ? "red" : is_scan ? "red" : "green",
          fontWeight: "bold",
        }}
      >
        {is_blacklist ? "Blacklisted" : is_scan ? "Consumed" : "Availaible"}
      </td>
    </tr>
  ) : (
    <tr className="text-center">
      <td className="pl-2">{index + 1}</td>
      <td>{barcode.substring(0, 70)}</td>

      <td className={access_status === "allow" ? "text-green" : "text-red"}>
        {access_status === "allow" ? "Allowed" : "Denied"}
      </td>
      <td>{remark}</td>
      <td>{out ? "Exit" : "Entry"}</td>
      <td>{cat_ref}</td>
      <td>{gate_details}</td>
      <td>{day_ref}</td>
      <td>{day_id}</td>
      <td>{device_id}</td>
      <td>{scan_timestamp}</td>
      <td
        style={{
          color: synctoorigin ? "green" : "red",
        }}
      >
        {msg.length>24?'Client Error':msg}
      </td>
    </tr>
  );
}

export default List;
