import React, { useEffect, useState } from "react";
import PropTypes, { func } from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IconButton from "@material-ui/core/IconButton";
import Button from "reactstrap/lib/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: 20,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  categories,
  apb_toggle,
  edit,
  deletecat,
  // search,
  is_disable_toggle,
  pagination,
  getcategories,
  setsearch,
}) {
  const classes = useStyles();

  const [categoriesData, setcategories] = React.useState([]);

  useEffect(() => {
    setcategories(categories);
  }, [categories]);

  const daylist = categories.map((category, index) => {
    return (
      <tr className=" text-center" key={index}>
        <td>{category.event_id_bms}</td>

        <td style={{ whiteSpace: "pre-wrap" }}>{category.category_name}</td>
        <td>{category.ticket_type_name}</td>

        <td style={{ whiteSpace: "pre-wrap" }}>{category.dayref}</td>
        <td>{category.barcode_type}</td>
        <td>{category.session}</td>
        <td>{category.param2}</td>
        <td>{category.param3}</td>
        <td>{category.param4}</td>
        <td>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={category.apb}
              onChange={() => {
                apb_toggle(category);
              }}
            />
            <span className="custom-toggle-slider rounded-circle " />
          </label>
        </td>
        {/* <td>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={category.is_disable ? false : true}
              onChange={() => {
                is_disable_toggle(category);
              }}
            />
            <span className="custom-toggle-slider rounded-circle " />
          </label>
        </td> */}
        <td>
          <button
            onClick={() => {
              // alert('s')
              edit(category);
              // this.edit(row)
            }}
            className="btn btn-sm btn-icon btn-2 btn-info"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-settings" />
            </span>
          </button>
        </td>
        <td>
          <button
            onClick={() => deletecat(category)}
            className="btn btn-sm btn-icon btn-2 btn-danger"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div
      className=""
      style={{ backgroundColor: "#fff", padding: 30, width: "90vw" }}
    >
      <center>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            placeholder="Search by Event ID ,
            Name, Session ID, Session name or Category ID"
            onChange={(e) => setsearch(e.target.value)}
            style={{
              width: 500,
              height: 30,
              border: "1px solid #ccc",
              borderRadius: 2,
              alignSelf: "center",
              marginBottom: 15,
              borderTopColor: "#fff",
              borderLeftColor: "#fff",
              borderRightColor: "#fff",
              fontSize: 14,
            }}
          />
          <div style={{ marginLeft: 10, marginBottom: 40 }}>
            <Button
              onClick={() => getcategories()}
              style={{ backgroundColor: "#192A4D", color: "#fff" }}
            >
              Search
            </Button>
          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: 18 }}>
          <thead className="text-black text-center">
            <tr>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Event ID
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Name
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Ticket Type Name
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Session Name
              </th>

              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Type
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Session ID
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Category Code
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Area Code
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Ticket Type
              </th>

              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                APB
              </th>
              {/* <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Sync to mobile
              </th> */}
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Edit
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Delete
              </th>
            </tr>
          </thead>

          <tbody>{daylist}</tbody>
        </table>
      </center>

      {categories.length === 0 && (
        <div>
          <div style={{ textAlign: "center" }}>
            <h2>No matching records</h2>
          </div>
        </div>
      )}
      {categories.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 10, marginRight: 30 }}>
            <h5>
              Rows {pagination?.pagedetails} of {pagination?.total} Records
            </h5>
          </div>

          <IconButton
            onClick={() => getcategories(pagination?.page - 1)}
            disabled={pagination?.hasPrevPage ? false : true}
          >
            <NavigateBeforeIcon fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => getcategories(pagination?.page + 1)}
            disabled={pagination?.hasNextPage ? false : true}
          >
            <NavigateNextIcon fontSize="medium" />
          </IconButton>
        </div>
      )}
    </div>
  );
}
