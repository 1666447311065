import React,{useState,useEffect} from 'react'
import { Input } from "reactstrap";
import axios from "axios";
import List from "./List";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// let TIER= process.env.TIER

let protocol = window.location.protocol;

function Logs(props) {

  const [barcodes, setbarcodes] = useState([])
  
  const [search_barcode, setsearch_barcode] = useState('')
  const eventid= sessionStorage.getItem('eventid')


  useEffect(() => {
    sync()
  }, [])
  
  


  const sync = async (props) => {
    synclogs();
  };

  const download_all = () => {

    window.open(`${axios.defaults.baseURL}/cms/report/download/${eventid}`, "_blank");
  };


  const synclogs = () => {

    // const {groupevents,eventid,search_barcode}=this.state
    // console.log(groupevents)
    

    let getevent = {
      event_obj_id: props.groupevents.length>0?[props.eventid,...props.groupevents.map((event)=> event.event_obj_id)]:[props.eventid],
      search_barcode: search_barcode,
    };
    

    axios.post("/cms/dashboard/getbarcodes/", getevent).then((response) => {
      if (response.data.success) {
        let barcodes = response.data.data;
        setbarcodes(barcodes)
        // this.setState({ barcodes: barcodes });
      } else {
        setbarcodes([])
        // this.setState({ barcodes: [] });
      }
    });
  };

  const search = (e) => {
    e.preventDefault();
    synclogs();
  };




  const list = barcodes.map((barcode, index) => {
    let typecheck = false;

    return (
      <List
        sync={sync}
        key={index}
        barcodeData={barcode}
        typecheck={typecheck}
        index={index}
      />
    );
  });

  return (
    <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col text-left">
                {/* <h3 className="mb-0">
                  Barcodes - {this.state.barcodes.length}{" "}
                </h3> */}
                {/* <h3 className="mb-0" style={{ float: "left" }}>
                  Barcodes - {this.state.barcodes.length}{" "}
                </h3> */}
                <h3 className="mb-0" style={{ float: "right" }}>
                  <button
                    onClick={() => {
                     download_all();
                    }}
                    className="btn btn-md bg-primary text-white"
                    disabled={props.auth.user.superadmin ? false : true}
                  >
                    <i className="fa fa-cloud-download-alt"></i>
                  </button>
                </h3>
                {/* <h3 className="mb-0 mr-2" style={{ float: "right" }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-md btn-success"
                    table="table-to-xls"
                    filename="Barcodes"
                    sheet="Barcodes"
                    buttonText="Download"
                  />
                </h3> */}
              </div>
            </div>
          </div>

          <center>
            <form onSubmit={search}>
              <Input
                placeholder="Enter Barcode or Booking ID Details"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setsearch_barcode('')
                    synclogs()    
                  } else {
                    setsearch_barcode(e.target.value)
                  }
                }}
                type="text"
                style={{ position: "relative", width: "50%", bottom: 20 }}
                className="mb-2"
              />
            </form>
          </center>

          <div
            style={{
              height: "800px",
              display: "inline-block",
              width: "100%",
              overflow: "auto",
            }}
            className="table-responsive"
          >
            <table className="table align-items-center" id="table-to-xls">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Access</th>
                  <th scope="col">Status</th>
                  <th scope="col">Direction</th>
                  <th scope="col">Category</th>
                  <th scope="col">Gate</th>
                  <th scope="col">Session Name</th>
                  <th scope="col">SessionId</th>
                  <th scope="col">Device</th>
                  <th scope="col">scan_timestamp</th>
                  {protocol==="https:"?<th scope="col">Sync To Origin</th>:<th scope="col">Sync To Cloud</th>}
                  
                </tr>
              </thead>
              <tbody id="myTable">{list}</tbody>
            </table>
          </div>
        </div>
      </div>
  )
}

Logs.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Logs);


