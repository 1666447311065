import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";
import commaNumber from "comma-number";

function AnalyticsCard({ event_obj_id, session ,groupevents}) {
  // const [event_obj_id, setevent_obj_id] = useState(event_obj_id);


  const [analyticsStats, setanalyticsStats] = useState({
    tickets_scans: 0,
	  tickets_total: 0,
	  tickets_percentage: 0,
	  accred_scans: 0,
	  accred_total: 0,
	  accred_percentage: 0,
	  blacklist_scans: 0,
	  blacklist_total: 0,
	  blacklist_percentage: 0,
  });


  const resetAnalytics = ()=>{
    setanalyticsStats({
      tickets_scans: 0,
	  tickets_total: 0,
	  tickets_percentage: 0,
	  accred_scans: 0,
	  accred_total: 0,
	  accred_percentage: 0,
	  blacklist_scans: 0,
	  blacklist_total: 0,
	  blacklist_percentage: 0,
    })
  }

  


  useEffect(() => {

    if(session){
      fetch_analytics()
    }else{
      resetAnalytics()
    }

    let interval = setInterval(() => {
      if(session){
        fetch_analytics();
      }
    }, 59000);
    return () => {
      clearInterval(interval);
    };
  }, [session]);

  

  const fetch_analytics = async() => {



    let data = {
      event_obj_id: groupevents.length > 0 ? [event_obj_id,...groupevents.map((event)=> event.event_obj_id)] : [event_obj_id],
      session,
    };

    if (session) {
      try {
        const analytics =await axios.post(`cms/v3/analytics/cards`, data)

        const {tickets_scans,tickets_total,tickets_percentage,
          accred_scans,accred_total,accred_percentage,blacklist_scans,
          blacklist_total,blacklist_percentage,success}=analytics.data
        
        if(success){
          setanalyticsStats({
            tickets_scans,tickets_total,tickets_percentage,
          accred_scans,accred_total,accred_percentage,blacklist_scans,
          blacklist_total,blacklist_percentage
          })

        }else{
          resetAnalytics()
        }

      } catch (error) {
        resetAnalytics()
        console.log(error)
      }    
    }
  };

const {tickets_scans,tickets_total,
  tickets_percentage,accred_scans,accred_total,accred_percentage,
blacklist_scans,blacklist_total,blacklist_percentage}=analyticsStats


  return (
    <Container fluid>
      <Row>
        <Col md="6" xl="4">
          <Card className="bg2-darker2 border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h5" className=" text-muted mb-0 text-white">
                    Tickets
                  </CardTitle>
                  <span className="font-weight-bold mb-0 ">
                    {commaNumber(tickets_scans)} / {commaNumber(tickets_total)}
                  </span>
                  <Progress
                    className="progress-md mt-3 mb-0"
                    max="100"
                    value={tickets_percentage}
                    color="success"
                  />
                </div>
                <Col className="col-auto">
                  <UncontrolledDropdown>
                    <DropdownToggle size="sm" color="neutral" className="mr-0">
                      <i className="fas fa-ellipsis-h" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#pablo"
                        onClick={() => fetch_analytics()}
                      >
                        Refresh
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm text-white">
                Progress {tickets_percentage} %
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" xl="4">
          <Card className="bg2-darker2 border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h5" className=" text-muted mb-0 text-white">
                    Accred
                  </CardTitle>
                  <span className=" font-weight-bold mb-0 text-white">
                    {commaNumber(accred_scans)} / {commaNumber(accred_total)}
                  </span>
                  <Progress
                    className="progress-md mt-3 mb-0"
                    max="100"
                    value={accred_percentage}
                    color="success"
                  />
                </div>
                <Col className="col-auto">
                  <UncontrolledDropdown>
                    <DropdownToggle size="sm" color="neutral" className="mr-0">
                      <i className="fas fa-ellipsis-h" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#pablo"
                        onClick={() => fetch_analytics()}
                      >
                        Refresh
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm text-white">
                Progress {accred_percentage} %
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" xl="4">
          <Card className="bg2-darker2 border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h5" className=" text-muted mb-0 text-white">
                    Blacklist
                  </CardTitle>
                  <span className="font-weight-bold mb-0 text-white">
                    {commaNumber(blacklist_scans)} / {commaNumber(blacklist_total)}
                  </span>
                  <Progress
                    className="progress-md mt-3 mb-0"
                    max="100"
                    value={blacklist_percentage}
                    color="success"
                  />
                </div>
                <Col className="col-auto">
                  <UncontrolledDropdown>
                    <DropdownToggle size="sm" color="neutral" className="mr-0">
                      <i className="fas fa-ellipsis-h" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#pablo"
                        onClick={() => fetch_analytics()}
                      >
                        Refresh
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm text-white">
                Progress {blacklist_percentage} %
              </p>
            </CardBody>
          </Card>
        </Col>

        {/* {isMobile ? <>
          <Col md="12" xl='4'>
            {occupancy ? <TotalOccupancy occupancy={occupancy} event_obj_id={event_obj_id} /> : null}
          </Col>
          <Col md="12" xl='8'>
            <EntryGraph2 event_obj_id={event_obj_id} />
          </Col>
        </> : <>

          <Col md="12" xl='8'>
            <EntryGraph2 event_obj_id={event_obj_id} />
          </Col>
          <Col md="12" xl='4'>
            {occupancy ? <TotalOccupancy occupancy={occupancy} event_obj_id={event_obj_id} gateselected={gateselected} /> : null}
          </Col>

        </>} */}

        {/* <Col md="6" xl="3">
          <LocalCloudSync event_obj_id={props.event_obj_id} />
        </Col> */}
      </Row>
    </Container>
  );
}

AnalyticsCard.propTypes = {
  screen: PropTypes.string,
  session_selected: PropTypes.object,
};

const mapStateToProps = (state) => ({
  session: state.event.session,
});
export default connect(mapStateToProps, null)(withRouter(AnalyticsCard));
