import React, { Component } from "react";

import axios from "axios";

class GatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countlist: [],
    };

    this.delete = this.delete.bind(this);
  }

  delete(name, day) {
    this.props.delete(name, day);
  }

  whitelist_download_sessionwise = (list) => {

    // return console.log(list)

    window.open(
      `${axios.defaults.baseURL}/cms/report/download/gateentry/whitelist/${list.event_obj_id}/${list.session_id}`,
      "_blank"
    );
  };

  componentWillReceiveProps(nextprops) {
    return this.setState({ countlist: nextprops.countlist });
  }

  render() {
    let countlist;
    countlist = this.state.countlist.map((list, index) => {
      return (
        <tr className="eventsearch text-center" key={index}>
          <td>{index + 1}</td>
          <td>{list.dayref}</td>
          <td>{list.event_id_bms}</td>
          <td>{list.session_id}</td>
          <td>{list.count_cat}</td>
          <td>
            <button
              onClick={() => {
                this.whitelist_download_sessionwise(list);
              }}
              className="btn btn-sm btn-icon btn-3 btn-info"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-cloud-download-95" />
              </span>

              {/* <span className="btn-inner--text">Download Barcodes</span> */}
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div className="search-data">
        {this.state.countlist.length > 0 ? (
          <center>
            <h4 className="text-muted">Whitelist Data</h4>
            <table className="table table-bordered">
              <thead className="theme-dark  text-white text-center">
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Session Name</th>
                  <th scope="col">Event Id</th>
                  <th scope="col">Session ID</th>
                  <th scope="col">Count</th>
                  <th style={{ width: 20 }} scope="col">
                    Download Whitelist
                  </th>

                  {/* <th scope="col">Delete</th> */}
                </tr>
              </thead>

              <tbody>{countlist}</tbody>
            </table>
          </center>
        ) : null}
      </div>
    );
  }
}

export default GatesList;
