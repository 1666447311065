import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./helper/store";
import jwt_decode from "jwt-decode";
import setAuthtoken from "./helper/utils/setAuthToken";
import PrivateRoute from "./helper/common/PrivateRoute";
import axios from "axios";



import { setCurrentUser, logoutUser } from "./helper/actions/authActions";

import "assets/css/google-font.css";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
// import "react-notification-alert/dist/animate.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import AdminLayout from "layouts/Admin.js";
// import RTLLayout from "layouts/RTL.js";
// import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import Login from "views/pages/application/login";
import Home from "views/pages/application/home";
import Create from "views/pages/application/create";
import Events from "views/pages/application/events";
import Profile from "views/pages/application/profile";
import Users from "views/pages/application/users";
import Devices from "views/pages/application/devices";
import Reports from "views/pages/application/view/reports";
import Reports2 from "views/pages/application/view/reports2";
import Logs from "views/pages/application/view/Logs";



if (localStorage.jwtToken) {
  //set auth token to header
  setAuthtoken(localStorage.jwtToken);
  //decode token and get user info and exp
  const decode = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decode));

  const currentTime = Date.now() / 1000;
  if (decode.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}

setInterval(() => {
  if (localStorage.jwtToken) {
    //set auth token to header
    setAuthtoken(localStorage.jwtToken);
    //decode token and get user info and exp
    const decode = jwt_decode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(decode));

    const currentTime = Date.now() / 1000;
    if (decode.exp < currentTime) {
      store.dispatch(logoutUser());
      window.location.href = "/";
    }
  }
}, 250000);




const URL= window.location.protocol==='http:'?'http://localhost:8091/':window.location.origin

const BASE_URL = URL;
axios.defaults.baseURL = BASE_URL

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/demo" component={IndexView} />
            <PrivateRoute
              path="/admin"
              component={AdminLayout}
            // render={(props) => <AdminLayout {...props} />}
            />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute path="/create" component={Create} />
            <PrivateRoute path="/events" component={Events} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/devices" component={Devices} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/logs" component={Logs} />
            <PrivateRoute path="/reports" component={Reports} />
            <PrivateRoute path="/reports2" component={Reports2} />
            <PrivateRoute path="*" component={Home} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
