import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import axios from "axios";
import commaNumber from "comma-number";
import Modal from "./Modal";

export default function Servers(props) {
  // const [event_obj_id, setevent_obj_id] = useState(

  // );
  const event_obj_id = window.sessionStorage.eventid;

  const [servers, Setservers] = useState([]);
  const [Time, SetTime] = useState([]);
  const [modal, Setmodal] = useState(false);
  const [detail, Setdetail] = useState(false);
  const [config_ver, setconfig_ver] = useState(0);

  useEffect(() => {
    server_monitor();
    const interval3 = setInterval(() => {
      console.log("Analytics Interval");
      // update_data();
      server_monitor();
      // fetch_event();
    }, 90000);

    return () => clearInterval(interval3);
  }, []);

  const fetch_event = (event_obj_id) => {
    axios.get(`/cms/events/${event_obj_id}`).then((response) => {
      setconfig_ver(response.data.events.config_ver);
    });
  };

  const server_monitor = () => {
    axios
      .get(`/cms/dashboard/new/server_monitor/${event_obj_id}`)
      .then((response) => {
        if (response.data.success) {
          Setservers(response.data.server_data);
          SetTime(response.data.time_now2);
           fetch_event(event_obj_id);
          // sets_p(response.data.percentage);
        }
      });
  };

  const server_details = (server) => {
    Setdetail(server);
    Setmodal(true);
  };

  const modal_props = () => {
    Setmodal(false);
  };

  return (
    <div>
      {modal ? <Modal detail={detail} modal_props={modal_props} /> : null}
      <Card>
        <CardHeader>
          <div className="col">
            {/* <h6 className="text-uppercase text-muted ls-1 mb-1">Servers</h6> */}
            <h2 className="h3 mb-0">Server Monitoring</h2>
          </div>
        </CardHeader>
        <CardBody style={{ height: "398px", overflow: "scroll" }}>
          <table className="table table-striped ">
            <thead>
              <tr className="text-center">
                <th scope="col">#</th>
                <th scope="col">Server</th>
                <th scope="col">Devices</th>
                <th scope="col">Scan Data</th>
                <th scope="col">LAST CONFIG FETCH	</th>
                <th scope="col">LAST DATA PUSH</th>
                <th scope="col">Config Status</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {servers.map((server, index) => {

                let uptodate= server.config_ver >= config_ver ?true:false


                let lastupdated = server.lastupdated_time;

                let calculate = Time - lastupdated;

                let status = Math.sign(calculate);

                let connected = false;
                let configstatus;

                if (status === -1) {
                } else {
                  if (calculate < 160) {
                    connected = true;
                  }
                }

                if (uptodate) {
                  configstatus = (
                    <span className="blink badge p-1 mr-4 bg-success text-white">
                      <i className="bg-success">Upto date</i>
                    </span>
                  );
                } else {
                  configstatus = (
                    <span className="blink badge p-1 mr-4 bg-danger text-white">
                      <i className="bg-warning">Outdated</i>
                    </span>
                  );
                }

                return (
                  <tr className="text-center" key={index}>
                    <th scope="row">{index + 1}</th>

                    <td>{server.device_id}</td>

                    <td>
                      {server?.server_details?.connected_device
                        ? server.server_details.connected_device
                        : 0}
                       / 
                      {server?.server_details?.total_device
                        ? server.server_details.total_device
                        : 0}
                    </td>
                    <td>
                      {commaNumber(
                        server?.server_details?.sync_scan
                          ? server.server_details.sync_scan
                          : 0
                      )}{" "}
                      /
                      {commaNumber(
                        server?.server_details?.total_scan
                          ? server.server_details.total_scan
                          : 0
                      )}
                    </td>
                    <td>{server.lastsynced}</td>
                    <td>{server.lastupdated}</td>
                    <td>{configstatus}</td>
                    <td>
                      <span
                        onClick={() => server_details(server)}
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        className={
                          connected
                            ? "fas fa-laptop text-success blink"
                            : "fas fa-laptop text-danger blink"
                        }
                      ></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  );
}
