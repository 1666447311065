import React, { Component } from "react";

import Navbar from "../navbar";

import Content from "./Content";
import { Footer } from "../footer";

export default class index extends Component {
  render() {
    return (
      <div className="main-content">
        <Navbar />
        <Content />
        <Footer />
      </div>
    );
  }
}
