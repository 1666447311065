import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

// let protocol = window.location.protocol

// alert(window.location)

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    compose
    // protocol === 'https:' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() || compose
  )
);

export default store;

// const store = createStore(
//   rootReducer,
//   initialState,
//   compose(
//     applyMiddleware(...middleware),
//     (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//       window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
//       compose
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );
