import React, { Component } from "react";

import Gatelogs from "./Gatelogs/index";
import Pickuplogs from "./Pickuplogs/index";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtype: "",
    };
  }

  componentWillMount() {
    //let eventid = this.props.match.params.id;
    //this.props.setevent(eventid);

    let eventtype = sessionStorage.getItem("eventtype");
    this.setState({ eventtype });
  }

  render() {
    const { eventtype } = this.state;

    let Mainpage;
    if (eventtype === "pickup") {
      Mainpage = <Pickuplogs />;
    } else {
      Mainpage = <Gatelogs />;
    }
    return Mainpage;
  }
}

export default index;
