import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import commaNumber from "comma-number";

// reactstrap components
import {
  // Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";
let protocol = window.location.protocol

function HomeCard({ ...props }) {
  const [screen, setScreen] = useState(props.screen_data);
  const event_obj_id = props.event_obj_id;
  const groupevents = props.groupevents;

  const [pscanssync, setpscanssync] = useState(0)
  const [dashboardStats, setdashboardStats] = useState({
    patron_entry: 0,
    patron_exit: 0,
    staff_entry: 0,
    staff_exit: 0,
    blacklisted: 0,
    already: 0,
    unique: 0,
    apb_override: 0,
  });


  useEffect(() => {

    if (props.screen_data && groupevents) {
      fetch_dashboardstats()
      if (protocol === 'http:') {
        fetch_syncscanpending()
      }
    } else {
      resetDashboardstats()
    }

    let interval = setInterval(() => {
      if (props.screen_data && groupevents) {
        fetch_dashboardstats();
        if (protocol === 'http:') {
          fetch_syncscanpending()
        }
      }
    }, 59000);
    return () => {
      clearInterval(interval);
    };
  }, [props.screen_data, groupevents]);



  const fetch_syncscanpending = async () => {
    let request_data = {
      event_obj_id: groupevents.length > 0 ? [event_obj_id, ...groupevents.map((event) => event.event_obj_id)] : [event_obj_id],
    };

    try {
      const dashboarddata = await axios.post(
        `/cms/v3/dashboard/pendingscansync`,
        request_data
      );

      const { pendingscanssync, success } = dashboarddata.data

      if (success) {
        setpscanssync(pendingscanssync)


      } else {
        setpscanssync(0)
      }


    } catch (error) {
      console.log(error);

    }


  }


  const resetDashboardstats = () => {
    setdashboardStats({
      patron_entry: 0,
      patron_exit: 0,
      staff_entry: 0,
      staff_exit: 0,
      blacklisted: 0,
      already: 0,
      unique: 0,
      apb_override: 0,
    })
  }


  const fetch_dashboardstats = async () => {
    let request_data = {
      event_obj_id: groupevents.length > 0 ? [event_obj_id, ...groupevents.map((event) => event.event_obj_id)] : [event_obj_id],
      screen: props.screen_data,
    };


    try {

      const dashboarddata = await axios.post(
        `/cms/v3/dashboard/cards`,
        request_data
      );

      const { stats, success } = dashboarddata.data

      if (success) {
        return setdashboardStats(stats);
      } else {
        return resetDashboardstats()
      }

    } catch (error) {
      console.log(error);
      resetDashboardstats()
    }
  };

  const { patron_entry,
    patron_exit,
    staff_entry,
    staff_exit,
    blacklisted,
    already,
    unique,
    apb_override } = dashboardStats

  return (
    <>
      <div
        className={
          props.theme.theme === "light"
            ? "header theme-light pb-6"
            : "header theme-dark pb-6"
        }
      >
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Patron Entry
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(patron_entry)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="fas fa-arrow-down" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">10 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Patron Exit
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(patron_exit)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i className="fas fa-arrow-up" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Staff Entry
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(staff_entry)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="fas fa-arrow-down" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Staff Exit
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(staff_exit)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i className="fas fa-arrow-up" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <h3 className="text-white title">Accred</h3> */}
            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Blacklisted Barcodes
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(blacklisted)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="fas fa-ban" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">10 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Already Scan
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(already)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="fas fa-exclamation-triangle" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          Unique Entries
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {commaNumber(unique || 0)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="fas fa-arrow-down" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-muted mb-0">
                          APB Override
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{commaNumber(apb_override)}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="fas fa-shield-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              {protocol === 'http:' ?
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-muted mb-0">
                            Pending Scan Sync
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{commaNumber(pscanssync)}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="fas fa-arrow-up" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0 %
                      </span>{" "}
                      <span className="text-nowrap">1 min Ago</span> */}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                : null}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

HomeCard.propTypes = {
  screen: PropTypes.string,
  // parentName: PropTypes.string,
};

export default HomeCard;
