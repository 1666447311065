import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./style.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import axios from "axios";

import { updateUser } from "../../../../helper/actions/authActions";
import moment from "moment";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      superadmin: props.auth.user.superadmin,
      name: props.auth.user.name,
      avatar: props.auth.user.avatar,
      email: props.auth.user.email,
      admin: props.auth.user.admin,
      lastsuccesfullogin: props.auth.user.lastsuccesfullogin,
      lastattempt: props.auth.user.lastattempt,
      number: props.auth.user.number,
      username: "",
      password1: "",
      password2: "",
      error: false,
      mac: "",
    };
    this.update = this.update.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  onchange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  update(e) {
    e.preventDefault();
    const updateprofile = {
      name: this.state.name,
      avatar: this.state.avatar,
      number: this.state.number,
    };

    if (this.state.password1 === "" && this.state.password2 === "") {
      this.setState({ error: false });
      alert("Profile Update Succesfully");
      return this.props.updateUser(updateprofile);
    } else if (this.state.password1 !== "" && this.state.password2 !== "") {
      if (this.state.password1 === this.state.password2) {
        updateprofile.password = this.state.password1;
        this.setState({ error: false, password1: "", password2: "" });
        alert("Profile Update Succesfully");
        return this.props.updateUser(updateprofile);
      } else {
        return this.setState({ error: true, password1: "", password2: "" });
      }
    }

    this.props.updateUser(this.state);
    alert("updated succesfully");
  }

  componentWillMount() {
      axios.get(`/cms/events/server/getmac`).then((response) => {
      //console.log(response.data)
      if (response.data.success) {
        this.setState({ mac: response.data.mac_id });
      }
    });
  }

  render() {
    // const { admin } = this.state;

    let error;
    if (this.state.error) {
      error = "Passowrd Did Not Match";
    }

    // if (!admin) {
    //   return <Redirect to="/events" />;
    // }

    return (
      <div className="main-content">
        <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center custom">
          <span className="mask bg-gradient-default opacity-8" />

          <div className="container-fluid d-flex align-items-center">
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <h3 className="display-2 text-white">
                  {"Hey " + this.state.name}
                </h3>
                <Button color="primary" type="button" href="/home">
                  <i className="fas fa-home 2x"></i> Home
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt--7">
          <div className="row">
            <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
              <div className="card card-profile shadow">
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-profile-image">
                      <a href="/test">
                        <img
                          alt="ss"
                          src={this.state.avatar}
                          className="rounded-circle"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                <div className="card-body pt-0 pt-md-4">
                  <div className="row">
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="h2">
                            {this.props.auth.user.role}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3>Email : {this.state.email}</h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      <h3>Mobile : {this.state.number}</h3>
                    </div>

                    <div>
                      <i className="ni education_hat mr-2" />
                      <h5 className="text-green">
                        Last Successful Login : {moment(this.state.lastsuccesfullogin).format('LLLL')}
                      </h5>
                    </div>
                    <div>
                      <i className="ni education_hat mr-2 " />

                      <h5 className="text-red">
                        Last Wrong Attempt : { this.state.lastattempt}
                      </h5>

                      <h6 className="text-blue">Mac_ID : {this.state.mac}</h6>

                    </div>
                    <hr className="my-4" />

                    {/* <a href="/test">Show more</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 order-xl-1">
              <div className="card bg-secondary shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0">My account</h3>
                    </div>
                    <div className="col-4 text-right">
                      <a href="/test!" className="btn btn-sm btn-primary">
                        Settings
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id="input-email"
                              className="form-control form-control-alternative"
                              disabled={true}
                              value={this.state.email}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Username
                            </label>
                            <input
                              type="text"
                              name="name"
                              onChange={this.onchange}
                              id="input-first-name"
                              className="form-control form-control-alternative"
                              placeholder="Username"
                              value={this.state.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Mobile
                            </label>
                            <input
                              type="text"
                              name="number"
                              onChange={this.onchange}
                              id="input-last-name"
                              className="form-control form-control-alternative"
                              placeholder="Mobile"
                              value={this.state.number}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Profile information
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Avatar Address Url
                            </label>
                            <input
                              id="input-address"
                              name="avatar"
                              onChange={this.onchange}
                              className="form-control form-control-alternative"
                              placeholder="Avatar Address"
                              type="text"
                              value={this.state.avatar}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Change Passowrd
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              name="password1"
                              onChange={this.onchange}
                              id="password1"
                              className="form-control form-control-alternative"
                              placeholder="Confirm Password"
                              value={this.state.password1}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              name="password2"
                              onChange={this.onchange}
                              id="password2"
                              className="form-control form-control-alternative"
                              placeholder="Confirm Password"
                              value={this.state.password2}
                            />
                          </div>
                        </div>
                      </div>
                      <center>
                        <h6 className="text-center text-red">{error || ""}</h6>
                        <br />

                        <button
                          onClick={this.update}
                          className="btn btn-md btn-primary"
                        >
                          Update
                        </button>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Content.propTypes = {
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { updateUser })(Content);
