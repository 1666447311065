import React, { Component, Fragment } from "react";

import GateMonitor from "./Gatemonitor/Monitor";
import Pickupmonitor from "./Pickupmonitor/Monitor";
import Servermonitor from "../../components/Servers";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtype: "",
      default: "device",
    };
  }

  componentWillMount() {
    //let eventid = this.props.match.params.id;
    //this.props.setevent(eventid);

    let eventtype = sessionStorage.getItem("eventtype");
    this.setState({ eventtype });
  }

  render() {
    const { eventtype } = this.state;

    let Mainpage;
    if (eventtype === "pickup") {
      Mainpage = <Pickupmonitor />;
    } else {
      if (this.state.default === "device") {
        Mainpage = <GateMonitor />;
      } else {
        Mainpage = <Servermonitor />;
      }
    }
    return (
      <div className="container-fluid mt-4">
        <center>
          <div className="col-md-4">
            <div className="form-group ">
              <div className="form-group ">
                <center>
                  <label
                    className="text-center text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Select Monitor Type
                  </label>
                </center>

                <select
                  onChange={(e) => this.setState({ default: e.target.value })}
                  name="barcode_type"
                  className="custom-select my-1 mr-sm-2"
                  id="inlineFormCustomSelectPref"
                  required
                >
                  <option value="device">Devices</option>
                  <option value="server">Servers</option>
                </select>
              </div>
            </div>
          </div>
        </center>
        {Mainpage}
      </div>
    );
  }
}

export default index;
