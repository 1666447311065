import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Images from "../../../images/images";

import Select from "react-select";
import List from "./List";
import Swal from "sweetalert2";

class Gates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: null,
      selectedDay: null,
      event: "",
      Gatelist: [],
      Days: [],
      sessiondetails: [],
      category: "",
      day: "",
      barcode: "",
      barcode_array: [],
      Days_session: [],
      categorys_data: [],
      countlist: [],
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
      iswhitelist_true: true,
      selectedoption: { value: "yes", label: "Yes" },
      void_codes: [
        { value: "101", label: "TICKET ISSUE - 1" },
        { value: "102", label: "TICKET ISSUE - 2" },
        { value: "103", label: "TICKET ISSUE - 3" },
        { value: "104", label: "TICKET ISSUE - 4" },
        { value: "105", label: "TICKET ISSUE - 5" },
        { value: "106", label: "TICKET ISSUE - 6" },
        { value: "107", label: "TICKET ISSUE - 7" },
        { value: "108", label: "TICKET ISSUE - 8" },
        { value: "109", label: "TICKET ISSUE - 9" },
        { value: "125", label: "TICKET ISSUE - 10" },
        { value: "900", label: "Blacklisted Ticket" },
        { value: "901", label: "Blocked Pass" },
        { value: "902", label: "PASS ISSUE - 1" },
        { value: "903", label: "PASS ISSUE - 2" },
        { value: "904", label: "PASS ISSUE - 3" },
        { value: "905", label: "PASS ISSUE - 4" },
      ],
      selectedvoid: { value: "101", label: "TICKET ISSUE - 1" },
      groupevents:[]
    };
    //this.onChange = this.onChange.bind(this);
  }

  blacklist_download = () => {
    window.open(
      `${axios.defaults.baseURL}/cms/report/download/gateentry/blacklist/${this.state.event_obj_id}`,
      "_blank"
    );
  };

  delete = (name, day) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.deleteconfirm(name, day);
      }
    });
  };

  deleteconfirm = (name, day) => {
    const { event_obj_id } = this.state;

    let new_delete = {
      event_obj_id,
      session_id: day,
    };

    // console.log(new_delete);

    axios.post("/cms/blacklist/delete_session", new_delete).then((response) => {
      let deletedcount = response.data.deletecount;

      Swal.fire({
        type: "success",
        title: "Success!",
        text: `${deletedcount} Barcodes Deletd`,
      });
      return this.get_count();
    });
  };

  change = (e) => {
    let barcode = e.target.value;
    barcode = barcode.split("\n").filter((i) => i);

    this.setState({ barcode_array: barcode });

    //this.setState({ [e.target.name]: e.target.value });
  };

  handleChange_category = (selectedCategory) => {
    this.setState({
      selectedCategory,
      category: selectedCategory.value,
    });
  };

  handleChange_day = (selectedDay) => {
    this.setState({
      selectedDay,
      day: selectedDay.value,
    });
  };
  handleChange_void = (selectedvoid) => {
    this.setState({
      selectedvoid: selectedvoid,
    });
  };

  handleChange_option = (selectedoption) => {
    this.setState({
      selectedoption,
      iswhitelist_true: selectedoption.value === "yes" ? true : false,
    });
  };

  async componentDidMount() {
    const geteventid = sessionStorage.getItem("eventid");

    let get_days = {
      event_obj_id: geteventid,
    };

    await axios.get(`/cms/events/${geteventid}`).then((response) => {
      let events_data = response.data.events;
      this.setState({
        event: events_data,
        event_id_bms: events_data.event_id_bms,
        event_obj_id: events_data._id,
        groupevents: events_data.groupevents,
      });
    });

    await this.sync_days(get_days);
  }

  sync_days = (get_days) => {

    const { groupevents, event_obj_id } = this.state;

    const requestData = {
      id:
        groupevents.length > 0
          ? [event_obj_id,...groupevents.map((event)=> event.event_obj_id)]
          : [event_obj_id],
      limit: 10000,
      page: 1,
      search: "",
    };

    axios
    .post("/cms/v3/sessions", requestData)
      .then(async (response) => {
        const days_data = response.data;
        // console.log(days_data)
        this.setState({ sessiondetails: days_data.docs });
        await this.get_session_details();
        await this.get_count();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_count = () => {
    const { sessiondetails, event_obj_id } = this.state;
    let countlist = [];

    // console.log(Days);

    sessiondetails.forEach(async (session) => {
      let countCat = {
        event_obj_id: session.event_obj_id,
        session_id: session.session,
      };

      await axios
        .post("/cms/blacklist/count", countCat)
        .then(async (response) => {
          let count_cat = response.data.count;
          let count_prev = {
            dayref: session.name,
            count_cat,
            session_id: session.session,
            event_id_bms:session.event_id_bms
          };

          countlist.push(count_prev);
          await this.setState({ countlist });
          // console.log(countlist);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  get_session_details = async () => {
    const { sessiondetails } = this.state;

    let Days = [];

    if (sessiondetails.length > 0) {
      sessiondetails.map((session) => {
        let preoptions = {
          value: session.session,
          label: session.name,
        };

        return Days.push(preoptions);
      });

      this.setState({ Days, selectedDay: Days[0], day: Days[0].value });
    }
  };

  createblacklist = (e) => {
    e.preventDefault();

    const {
      barcode_array,
      day,
      event_id_bms,
      event_obj_id,
      iswhitelist_true,
      selectedvoid,
    } = this.state;

    let newblacklist = {
      barcode_array,
      day,
      event_id_bms,
      event_obj_id,
      void_code: selectedvoid.value,
    };

    if (iswhitelist_true) {
      axios
        .post("/cms/blacklist/insertbarcode2", newblacklist)
        .then((response) => {
          if (response.data.success) {
            Swal.fire(
              "Success!",
              `${barcode_array.length} Blacklisted Successfully`,
              "success"
            );
            return this.get_count();
          }
        })
        .catch((err) => {});
    } else {
      axios
        .post("/cms/blacklist/insertbarcode", newblacklist)
        .then((response) => {
          if (response.data.success) {
            Swal.fire(
              "Success!",
              `${barcode_array.length} Blacklisted Successfully`,
              "success"
            );
            return this.get_count();
          }
        })
        .catch((err) => {});
    }
  };

  render() {
    let {
      selectedDay,
      event,
      barcode_array,
      countlist,
      selectedoption,
      selectedvoid,
      groupevents
    } = this.state;
    let showbutton = false;

    if (
      selectedoption != null &&
      barcode_array.length > 0 &&
      selectedvoid != null
    ) {
      showbutton = true;
    } else {
      showbutton = false;
    }

    return (
      <Fragment>
        <center>
          <div className="container  create-event">
            <h2 className="display-2 text-muted">Blacklist Configuration</h2>
            <br />

            <div className="row ">
              <div className="col-md-2" />
              <div className="col-md-8">
              {groupevents.length===0? <form onSubmit={this.createblacklist}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event.eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event.event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Availaible In Whitelist
                    </label>
                    <Select
                      value={selectedoption}
                      isSearchable={false}
                      closeMenuOnSelect={true}
                      onChange={this.handleChange_option}
                      options={this.state.options}
                      isMulti={false}
                      required
                      allowSelectAll={false}
                    />
                  </div>

                  <br />

                  {selectedoption.value === "yes" ? null : (
                    <div className="col-md-6">
                      <label
                        className="text-muted"
                        htmlFor="exampleFormControlSelect2"
                      >
                        Select Session
                      </label>
                      <Select
                        value={selectedDay}
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        onChange={this.handleChange_day}
                        options={this.state.Days}
                        isMulti={false}
                        required
                        allowSelectAll={false}
                      />
                    </div>
                  )}

                  <br />

                  <div className="col-md-6">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Void Code
                    </label>
                    <Select
                      value={selectedvoid}
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      onChange={this.handleChange_void}
                      options={this.state.void_codes}
                      isMulti={false}
                      required
                      allowSelectAll={false}
                    />
                  </div>
                  <br />
                  <div className="col-md-6">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Insert Barcodes
                    </label>
                    <br />

                    <textarea
                      style={{ width: 350 }}
                      className="form-control form-control-alternative"
                      rows="10"
                      placeholder="Insert Barcodes.."
                      name="barcodes"
                      onChange={this.change}
                    ></textarea>
                  </div>
                  <br />
                  <span>
                    {barcode_array.length > 0
                      ? `Total Barcodes Entered :- ${barcode_array.length} `
                      : ""}
                  </span>
                  <hr />

                  <button
                    className="btn btn-icon btn-3 bg-default"
                    type="submit"
                    disabled={showbutton ? false : true}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">Insert Barcodes</span>
                  </button>
                </form>:null}
                <br />

                {groupevents.length === 0?<button
                  onClick={() => {
                    this.blacklist_download();
                  }}
                  className="btn btn-icon btn-3 btn-info"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-bullet-list-67" />
                  </span>

                  <span className="btn-inner--text">Download Barcodes</span>
                </button>:null}
              </div>
              <div className="col-md-2" />
            </div>
          </div>

          <List delete={this.delete} countlist={countlist} />
        </center> 
      </Fragment>
    );
  }
}

export default Gates;
