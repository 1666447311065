import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: 500,
    height: 200
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function DialogSelect({ openModal, closeModal }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [create, setCreate] = React.useState(false);
  const [submit, setsubmit] = React.useState(false);
  const [option, setOption] = React.useState('create');
  const [type, setType] = React.useState('acs');
  const [eventid, seteventid] = React.useState('');



  useEffect(() => {
    setOpen(openModal)

  }, [openModal])



  const handleChange = (event) => {
    setOption(event.target.value)
  };

  const handleChange2 = (event) => {
    setType(event.target.value)
  };



  const handleClose = () => {
    seteventid('')
    setOption('create')
    setOpen(false);
    closeModal()
    setsubmit(false)
  };

  const handleSubmit = () => {

    setsubmit(true)

    if (option === 'create') {
      setsubmit(false)
      setOpen(false);
      closeModal()
      return setCreate(true)
    } else {
      if (eventid === '') {
        setOpen(false);
        setsubmit(false)
        closeModal()
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Event ID Not Not Provided !',
        })
      } else {
        axios
          .get(`/cms/events/syncfrombmsindia/${eventid}`)
          .then((response) => {
            // console.log(response)
            if (response.data.success) {
              handleClose()
              success(response.data.msg)
            } else {
              handleClose()
              return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.msg,
              })
            }
          })
          .catch((err) => {
            console.log(err);
            handleClose()
            return Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Database error',
            })
          })
      }
    }
  }


  const success = (msg) => {
    let timerInterval
    Swal.fire({
      title: 'Event Sync In Progress!',
      html: 'Event Sync Completed in <b></b> milliseconds.',
      timer: 2000,
      timerProgressBar: true,
      willOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        return Swal.fire(
          'Good job!',
          msg,
          'success'
        )
      }
    })
  }


  if (create) {
    return <Redirect to="/create" />
  }

  return (
    <div>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Create Event</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <Select
                style={{ width: 300, marginBottom: 20 }}
                native
                value={option}
                onChange={handleChange}
                input={<Input id="demo-dialog-native" />}
              >

                <option value='create'>Create New</option>
                <option value='bmsindia'>Sync From Bookmyshow India</option>
                <option disabled value='bigtix'>Sync From Bookmyshow South East Asia</option>
              </Select>

              {option === 'bmsindia' || option === 'bigtix' ? <>
                <Select
                  style={{ width: 300, marginBottom: 20 }}
                  native
                  value={type}
                  onChange={handleChange2}
                  input={<Input id="demo-dialog-native" />}
                >

                  <option value='acs'>Access Control</option>
                  <option disabled value='pickup'>Pickup</option>
                </Select></> : null}


              {option === 'bmsindia' || option === 'bigtix' ? <TextField type="text" id="standard-basic" placeholder="Please Enter Event ID" value={eventid} onChange={(e) => seteventid(e.target.value)} label="Event ID" /> : null}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={submit} onClick={handleSubmit} color="primary">
            {submit ? 'Wait..' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
