// import createevent from "./create_event.svg";
import nfc from "./nfc.svg";
import pickup from "./pickup.svg";
import open from "./open.svg";
import standard from "./standard.svg";
import scanner from "./scanner.svg";
import ticketscanner from "./ticketscanner.svg";
import multievent from "./multievent.png";
import warning from "./warning.gif";
import service from './service.jpg'

export default { nfc, pickup, open, standard, scanner, ticketscanner,multievent,warning,service };
