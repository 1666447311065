import React, { Component } from "react";
import axios from "axios";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      getcategories: false,
      eventtype: "",
    };

    this.delete = this.delete.bind(this);
    this.categorystandard = this.categorystandard.bind(this);
  }

  categorystandard() {
    this.props.categorystandard();
  }

  apb_toggle = (category) => {
    const { categories } = this.state;

    let new_cat = [];

    let obj = {
      _id: category._id,
      apb: !category.apb,
    };

    axios.post(`/cms/category/standard/apb_toggle`, obj).then((respos) => {
      if (respos.data.success) {
        categories.map((cat) => {
          if (cat._id === category._id) {
            cat.apb = !category.apb;
          }

          return new_cat.push(cat);
        });

        this.setState({ categories: new_cat });
      } else {
        alert("something went wrong");
      }
    });
  };

  componentWillReceiveProps(nextprops) {
    this.setState({
      categories: nextprops.categories,
      eventtype: nextprops.eventtype,
    });
  }

  delete(catid) {
    this.props.delete(catid);
  }

  render() {
    const { categories } = this.state;

    let allcategories;
    if (categories.length > 0) {
      allcategories = categories.map((category, i) => {
        return (
          <tr className="eventsearch text-center" key={i}>
            <td>{i + 1}</td>
            <td>{category.category_name}</td>
            <td>{category.catref}</td>
            <td>{category.dayref}</td>

            <td>
              <button
                onClick={() => this.delete(category._id)}
                className="btn btn-sm btn-icon btn-2 btn-danger"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="search-data">
        {this.state.categories.length > 0 ? (
          <center>
            <h4 className="text-muted">ALL CATEGORIES</h4>
            <table className="table table-bordered">
              <thead className="bg-success text-white text-center">
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Name</th>
                  <th scope="col">Category Reference</th>
                  <th scope="col">Session </th>
                  {/* <th scope="col">APB</th> */}

                  <th scope="col">Delete</th>
                </tr>
              </thead>

              <tbody>{allcategories}</tbody>
            </table>
          </center>
        ) : null}
      </div>
    );
  }
}

export default Categories;
