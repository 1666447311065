import {
  TEST_DISPATCH,
  LOGIN_DISPATCH,
  GET_AUTH_ERROR,
  CHANGE_COLOR,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import Swal from "sweetalert2";

export const registerUser = (userData) => {
  return {
    type: TEST_DISPATCH,
    payload: userData,
  };
};

export const loginUser = (userData, history) => async (dispatch) => {
  axios.defaults.timeout = 3000;

  let url;

  
  if (window.location.protocol === "http:" && window.location.hostname === "127.0.0.1") {
    url = "https://eventmanager.bigtix.io/cms/users/login";
  } else {
    url = "/cms/users/login";
  }

  try {
    let { data } = await axios.post(url, userData);

    if (window.location.protocol === "http:") {
      const decoded = jwt_decode(data.token);

      let localuser = await axios.post("/cms/users/locallogin", decoded);

      localStorage.setItem("jwtToken", localuser.data.token);
      //set authtoken
      setAuthToken(localuser.data.token);

      // decode authtoken
      const decoded_2 = jwt_decode(localuser.data.token);
      dispatch(setCurrentUser(decoded_2));

      let access_type = decoded_2.access_type;

      if (access_type === "2") {
        history.push("/events");
      } else {
        history.push("/home");
      }
    } else {
      const { token } = data;
      localStorage.setItem("jwtToken", token);
      //set authtoken
      setAuthToken(token);

      // decode authtoken
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));

      let access_type = decoded.access_type;

      if (access_type === "2") {
        history.push("/events");
      } else {
        history.push("/home");
      }
    }

    // dispatch(setCurrentUser(decoded));
  } catch (err) {
    console.log(err);
    if (err.response?.data.msg) {
      Swal.fire("Error!", err.response.data.msg, "error");
    } else {
      Swal.fire("Error!", "Server Timeout", "error");
    }
  }
};

export const cloud_login = (userData, history) => (dispatch) => {
  axios
    .post("http://14.141.26.82/cms/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      //set authtoken
      setAuthToken(token);

      // decode authtoken
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      history.push("/home");
    })
    .catch((err) => {
      dispatch({
        type: GET_AUTH_ERROR,
        payload: err.response.data,
      });
    });
};

export const loginUser2 = (userData, history) => (dispatch) => {
  axios
    .post("/cms/users/authenticate_cloud", userData)
    .then((res) => {
      if (res.data.success) {
        const user_data = res.data.user;

        axios
          .post("/cms/users/login2", user_data)
          .then((response) => {
            let status = response.data.success;

            if (status) {
              axios
                .post("/cms/users/login", userData)
                .then((res) => {
                  const { token } = res.data;
                  localStorage.setItem("jwtToken", token);
                  //set authtoken
                  setAuthToken(token);

                  // decode authtoken
                  const decoded = jwt_decode(token);
                  dispatch(setCurrentUser(decoded));
                  history.push("/home");
                })
                .catch((err) => {
                  dispatch({
                    type: GET_AUTH_ERROR,
                    payload: err.response.data,
                  });
                });
            } else {
              dispatch({
                type: GET_AUTH_ERROR,
                payload: response.data.err,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: GET_AUTH_ERROR,
              payload: err,
            });
          });
      } else {
        dispatch({
          type: GET_AUTH_ERROR,
          payload: res.data.msg,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_AUTH_ERROR,
        payload: err.response.data,
      });
    });
};

export const updateUser = (userData) => (dispatch) => {

  // return alert('hello')

  axios
    .patch("/cms/users/", userData)
    .then((res) => {
      const token = res.data.token;
      localStorage.setItem("jwtToken", token);
      //set authtoken
      setAuthToken(token);

      // decode authtoken
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      dispatch({
        type: GET_AUTH_ERROR,
        payload: err.response.data,
      });
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: LOGIN_DISPATCH,
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  console.log("hello from logout");
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setlogoutUser({}));
};

export const setlogoutUser = (decoded) => {
  return {
    type: LOGIN_DISPATCH,
    payload: decoded,
  };
};

export const changeColor = () => {
  return {
    type: CHANGE_COLOR,
    // payload: decoded,
  };
};
