export const TEST_DISPATCH = "TEST_DISPATCH";
export const LOGIN_DISPATCH = "LOGIN_DISPATCH";
export const GET_AUTH_ERROR = "GET_AUTH_ERROR";
export const LOGOUT_DISPATCH = "GET_AUTH_ERROR";

export const SET_EVENT = "SET_EVENT";
export const GATE_EDIT = "GATE_EDIT";
export const CHANGE_SESSION = "CHANGE_SESSION";
export const SET_EVENT_ERROR = "SET_EVENT_ERROR";
export const DELETE_EVENT = "DELETE_EVENT";
export const CHANGE_COLOR = "CHANGE_COLOR";

