import React, { useState, useEffect } from "react";
import axios from "axios";

// import Swal from "sweetalert2";
import logo from "../reports/bms.jpeg";
import ReactDatetime from "react-datetime";

import {
  FormGroup,
  InputGroupText,
  InputGroup,
  // Col,
  // Row,
} from "reactstrap";

import jspdf from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import moment from "moment";

const httpClient = axios.create();
httpClient.defaults.timeout = 50000;

let host = window.location.hostname;
let url;

if (host === "eventmanager.bms.bz") {
  url = "https://acs.bms.bz/";
} else if (host === "eventmanager-sg.bms.bz") {
  url = "https://acs-sg.bms.bz/";
} else if (host === "eventmanager-ae.bms.bz") {
  url = "https://acs-ae.bms.bz/";
} else if (host === "eventmanager-sit.bms.bz") {
  url = "https://eventmanager-acs-sit.bms.bz/";
} else if (host === "eventmanager-ae-sit.bms.bz") {
  url = "https://eventmanager-ae-acs-sit.bms.bz/";
} else {
  url = `http://${host}:8090/`;
}

function Reports() {
  // const [loading, setLoading] = useState(false);
  const [submit_button, Setsubmit_button] = useState(false);

  const [barcode_type, setbarcode_type] = useState("ticket");
  const [format, setFormat] = useState("pdf");
  const [sessionType, setsessionType] = useState("Single");

  const [reportname, setreportname] = useState("");
  const [starttime, setStarttime] = useState("N/A");
  const [endtime, setEndtime] = useState("N/A");
  const [startTimestamp, setstartTimestamp] = useState("N/A");
  const [endTimestamp, setendTimestamp] = useState("N/A");
  const [eventname, Seteventname] = useState("");
  const [event_id_bms, Setevent_id_bms] = useState("");
  const [event_obj_id, setevent_obj_id] = useState("");
  const [event_obj_ids, setevent_obj_ids] = useState("");
  const [durationvalue, setdurationvalue] = useState("auto");

  const [out, setOut] = useState(false);
  const [test_mode, settest_mode] = useState(false);

  const [dayref, setdayref] = useState("");

  // new
  const [singleSessions, setsingleSessions] = useState([]);
  const [groupSessions, setgroupSessions] = useState([]);
  const [day_obj, setday_obj] = useState([]);
  const [showDirection, setshowDirection] = useState(false);
  const [showbarcodeType, setshowbarcodeType] = useState(false);
  const [showDuration, setshowDuration] = useState(false);
  const [showmanualDuration, setshowmanualDuration] = useState(false);
  const [reportref, setreportref] = useState("");
  // const [showDirection, setreportref] = useState('')

  // const [mockData, setmockData] = useState(null)

  const submit = async (e) => {
    Setsubmit_button(true);
    e.preventDefault();

    let payload = {
      reportref,
      reportname,
      day_obj,
      dayref,
      test_mode,
      out,
      starttime: startTimestamp,
      endtime: endTimestamp,
      event_obj_id,
      event_obj_ids,
      eventname,
      barcode_type,
      format,
      event_id_bms,
    };
    const timecheck =
    new Date(endTimestamp).getTime() - new Date(startTimestamp).getTime();
   
    if (timecheck < 0) {
      return erroralert("End date time must be after start date time");
    }

    if (timecheck < 0) {
      return erroralert("End date time must be after start date time");
    }

    try {
      let { data } = await httpClient.post(
        `cms/v3/reports/${reportref}`,
        payload
      );

      return generateReport(data);
    } catch (error) {
      Setsubmit_button(false);
      console.error(error);
      if (error.response.data) {
        return erroralert(error.response.data.message);
      } else {
        return erroralert(error.message);
      }
    }
  };

  const generateReport = async (data) => {
    const { count, head, reportData } = data;

    if (format === "excel") {
      await csvexport(reportData, reportname);
      return Setsubmit_button(false);
    } else {
      await pdf_generate(count, head, reportData, reportname);
      return Setsubmit_button(false);
    }
  };

  const csvexport = (data, report_name) => {
    const csvRows = [];
    let headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });

      if (reportref === "report5") {
        if (values[0] === '"undefined"') {
          values[0] = `\"${row.name}\"`;
        }
        if (values[1] === '"undefined"') {
          values[1] = `\"${row.type}\"`;
        }
      }
      csvRows.push(values.join(","));
    }

    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${report_name}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const pdf_generate = (count, head, body, report_name) => {
    // // console.log(dayref)

    let type = barcode_type === "ticket" ? "Patron" : "Staff";

    var doc = new jspdf({ putOnlyUsedFonts: true, orientation: "landscape" });
    //var doc = new jspdf();

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const Timeformat = "DD/MM/YYYY H:mm:ss a";



    const startTime = moment.utc(startTimestamp);
    const endTime = moment.utc(endTimestamp);
    const formattedstartTime = startTime.format(Timeformat);
    const formattedendTime = endTime.format(Timeformat);


    // FOOTER
    let event_name = `${eventname.toUpperCase()}`;
    let report = `${report_name.toUpperCase()} REPORT - ${dayref.toUpperCase()}`;
    let startdate = `START TIME : ${
      startTimestamp !=="N/A" ? formattedstartTime : "N/A"
    }`;
    let enddate = `END TIME : ${
      endTimestamp !=="N/A" ? formattedendTime: "N/A"
    }`;
    // let access_type = `ACCESS TYPE : ${type ? type.toUpperCase() : "N/A"}`;
    let admission_type = `DIRECTION : ${out ? "Exit" : "Entry"}`;
    // let session = `SESSION NAME : ${dayref ? dayref.toUpperCase() : "N/A"}`;

    doc.setTextColor(100);
    doc.setFontSize(10);

    var img = new Image();

    img.src = logo;

    doc.addImage(img, 125, 1, 0, 20, 20);
    doc.setFont("bold");
    doc.setFontSize("17");
    doc.text(event_name, pageWidth / 2, pageHeight - 179, "center");
    doc.setFont("bold");
    doc.setFontSize("12");
    doc.text(report, pageWidth / 2, pageHeight - 172, "center");
    doc.setFont("normal");
    doc.setFontSize("10");

    {
      startdate !== "N/A" &&
        doc.text(startdate, pageWidth / 2, pageHeight - 160, "center");
    }

    {
      enddate !== "N/A" &&
        doc.text(enddate, pageWidth / 2, pageHeight - 154, "center");
    }

    {
      showDirection &&
        doc.text(admission_type, pageWidth / 2, pageHeight - 147, "center");
    }

    if (count) {
      doc.setFont("bold");
      doc.setFontSize("12");
      doc.text(
        `${type.toUpperCase()} : ${count}`,
        pageWidth / 2,
        pageHeight - 139,
        "center"
      );
    }

    let drawCell = function (data) {
      if (report_name === "Zone & Gate Details Hourly") {
        if (data.row.raw[0] === "Total") {
          doc.setFont("bold");
          doc.setFontSize("12");
          doc.setFillColor(59, 70, 75);
          doc.setTextColor(255, 255, 255);
        } else if (data.row.raw[0].includes("Total")) {
          doc.setFont("bold");
          doc.setFontSize("12");
          doc.setFillColor(108, 117, 125);
          doc.setTextColor(255, 255, 255);
        }
      }
    };

    doc.autoTable({
      margin: { top: 75 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
      },
      willDrawCell: drawCell,
      //styles: { fillColor: "#3B464B" },
      theme: "grid",
      bodyStyles: { lineColor: [0, 0, 0] },
      headStyles: { fillColor: [59, 70, 75], lineColor: [0, 0, 0] },
      head: head,
      body: body,
    });

    doc.save(`${report_name} Report.pdf`);
  };

  const erroralert = (msg) => {
    Setsubmit_button(false);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: msg || "No Data Found!",
    });
  };

  useEffect(() => {
    const geteventid = sessionStorage.getItem("eventid");
    axios.get(`/cms/events/${geteventid}`).then((event) => {
      const { events } = event.data;

      Seteventname(event.data.events.eventname);
      Setevent_id_bms(event.data.events.event_id_bms);
      setevent_obj_id(events._id);
      // Seteventtype(event.data.events.eventtype);
      fetch_sessions(events._id, event.data.events.groupevents);
      setgroupSessions(event.data.events.groupSession);
      // setgroupevents(event.data.events.groupevents)
      // Setdays(event.data.events.sessiondetails);
      // console.log(event.data.events.sessiondetails)
    });
  }, []);

  const fetch_sessions = (event_obj_id, groupevents) => {
    let fech_day = {
      event_obj_id:
        groupevents.length > 0
          ? [event_obj_id, ...groupevents.map((event) => event.event_obj_id)]
          : [event_obj_id],
    };

    setevent_obj_ids(fech_day.event_obj_id);

    axios
      .post("/cms/days/standard/alldays", fech_day)
      .then((days) => {
        // console.log(days)
        setsingleSessions(days.data.data);
        // Setdays(days.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatDateTime = (dateStr) => {
    const dateObj = new Date(dateStr);

    // Extract date components
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString();

    // Extract time components
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const seconds = dateObj.getSeconds().toString().padStart(2, "0");

    // Create the dateTime string
    const dateTime = `${day}${month}${year}${hours}${minutes}${seconds}`;

    return dateTime;
  };

  const resetFilter = () => {
    setshowDirection(false);
    setshowbarcodeType(false);
    setshowDuration(false);
    setStarttime("N/A");
    setEndtime("N/A");
    setshowmanualDuration(false);
    setdurationvalue("auto");
  };

  const setReport = (report) => {
    resetFilter();
    setreportref(report);

    switch (report) {
      case "report1":
        setreportname("Category-wise");
        setshowDirection(true);
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report2":
        setreportname("Gate-wise");
        setshowDirection(true);
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report3":
        setreportname("Category & Ttype summary");
        setshowDirection(true);
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report4":
        setreportname("APB Override Transwise");
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report5":
        setreportname("Zone & Gate Details Hourly");
        setshowDirection(true);
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report6":
        setreportname("Anti Pass Back Override");
        // setshowDirection(true)
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report7":
        setreportname("Override Details");
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report8":
        setreportname("Daily Attendance");

        break;
      case "report9":
        setreportname("Access Errors");
        // setshowDirection(true)
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report10":
        setreportname("Product Outstanding usage");
        // setshowbarcodeType(true)

        break;
      case "report11":
        setreportname("Category & Ttype usage");
        setshowbarcodeType(true);

        break;
      case "report12":
        setreportname("Re-Entry");
        // setshowbarcodeType(true)

        break;
      case "report13":
        setreportname("Hourly Patron / Staff Count");

        break;
      case "report14":
        setreportname("Device or User Wise");
        setshowDirection(true);
        setshowbarcodeType(true);
        setshowDuration(true);

        break;
      case "report15":
        setreportname("");
        setshowbarcodeType(true);

        break;

      default:
        break;
    }
  };

  return (
    <center>
      <div className="container create-event">
        <h2 className="display-2 text-muted">Reports V2 </h2>
        <hr />
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-md-4 offset-md-2">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Mode
                  </label>
                  <select
                    name="environment"
                    onChange={(e) =>
                      e.target.value === "testmode"
                        ? settest_mode(true)
                        : settest_mode(false)
                    }
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    required
                  >
                    <option value="prodmode">Production</option>
                    <option value="testmode">Test</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    File Format
                  </label>
                  <select
                    name="format"
                    onChange={(e) =>
                      e.target.value === "pdf"
                        ? setFormat("pdf")
                        : setFormat("excel")
                    }
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    required
                  >
                    <option value="pdf">PDF</option>
                    <option value="excel">CSV</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 offset-md-2">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Session Type
                  </label>
                  <select
                    name="sessiontype"
                    onChange={(e) => setsessionType(e.target.value)}
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    required
                  >
                    <option value="Single">Single</option>
                    <option value="Group">Group</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Choose Sessions
                  </label>
                  <select
                    name="day"
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => {
                      let obj = JSON.parse(e.target.value);
                      setdayref(obj.dayname);
                      // setDay([array[0]]);
                      // setDayref(array[1]);
                      // Setevent_obj_id(array[2])
                      // console.log(array)
                      setday_obj(obj.day_ids);
                    }}
                    required
                  >
                    <option value=""> Select...</option>

                    {sessionType === "Single"
                      ? singleSessions.length > 0 &&
                        singleSessions.map((day, index) => {
                          let dayname = day.name.replace(",", "");

                          let value = {
                            dayname,
                            day_ids: [day._id],
                          };
                          return (
                            <option
                              key={index}
                              value={JSON.stringify(value)}
                              // value={[days.session, dayname,days.event_obj_id,days._id]}
                              //value={dayname}
                            >
                              {dayname}
                            </option>
                          );
                        })
                      : groupSessions.length > 0 &&
                        groupSessions.map((day, index) => {
                          let dayname = day.name.replace(",", "");
                          // console.log()
                          let value = {
                            dayname,
                            day_ids: day.session_obj_ids,
                          };

                          return (
                            <option
                              key={index}
                              value={JSON.stringify(value)}
                              // value={[day.sessions, dayname,day.session_obj_ids.toString(',')]}
                              //value={dayname}
                            >
                              {dayname}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="form-group ">
                <div className="form-group ">
                  <label
                    className="text-muted"
                    htmlFor="exampleFormControlSelect2"
                  >
                    Report Type
                  </label>
                  <select
                    name="type"
                    className="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => setReport(e.target.value)}
                    required
                  >
                    <option value=""> Select...</option>
                    <option value="report1"> Category-wise</option>
                    <option value="report2"> Gate-wise</option>
                    <option value="report3">Category & Ttype summary</option>
                    <option value="report4"> APB Override Transwise</option>
                    <option value="report5">Zone & Gate Details Hourly</option>
                    <option value="report6">Anti Pass Back Override</option>
                    <option value="report7">Override Summary</option>
                    <option value="report8">Daily Attendance</option>
                    <option value="report9">Access Errors</option>
                    <option value="report10">Product Outstanding usage</option>
                    <option value="report11">Category & Ttype usage</option>
                    <option value="report12">Re-Entry</option>
                    <option value="report13">
                      Hourly Patron / Staff Count
                    </option>
                    <option value="report14">Device or User Wise</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 600,
            }}
          >
            {showDirection && (
              <div style={{ marginRight: 10 }}>
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Direction
                    </label>
                    <select
                      name="admission"
                      onChange={(e) => {
                        if (e.target.value === "entry") {
                          setOut(false);
                        } else {
                          setOut(true);
                        }
                        // setAdmission(e.target.value)
                      }}
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      required
                    >
                      <option value="entry">Entry</option>
                      <option value="exit">Exit</option>
                      {/* {report === 'report1' ? null : <option value="all">All</option>} */}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {showbarcodeType && (
              <div style={{ marginRight: 10 }}>
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Attendee Type
                    </label>
                    <select
                      name="mode"
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      onChange={(e) => setbarcode_type(e.target.value)}
                      required
                    >
                      {/* <option value="all">All</option> */}
                      <option value="ticket">Patron</option>
                      <option value="accred">Staff</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {showDuration && (
              <div>
                <div className="form-group ">
                  <div className="form-group ">
                    <label
                      className="text-muted"
                      htmlFor="exampleFormControlSelect2"
                    >
                      Duration
                    </label>
                    <select
                      value={durationvalue}
                      name="mode"
                      onChange={(e) => {
                        e.target.value === "manual"
                          ? setshowmanualDuration(true)
                          : setshowmanualDuration(false);
                        setdurationvalue(e.target.value);
                      }}
                      className="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      required
                    >
                      <option value="auto">Auto</option>
                      <option value="manual">Manual</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>

          {showmanualDuration && (
            <>
              <div className="col-md-4 ">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Start Date Time",
                        name: "starttime",
                        required: true,
                        autoComplete: "off",
                      }}
                      timeFormat="HH:mm:ss"
                      dateFormat={true}
                      onChange={(e) => {
                        const date1 = new Date(e._d);

                        const utcDate = new Date(
                          date1.getTime() -
                            date1.getTimezoneOffset() * 60 * 1000
                        );

                        const isoDate = utcDate.toISOString();

                        setstartTimestamp(isoDate);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </div>
              <div className="col-md-4 ">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "End Date Time",
                        name: "endtime",
                        required: true,
                        autoComplete: "off",
                      }}
                      timeFormat="HH:mm:ss"
                      dateFormat={true}
                      onChange={(e) => {
                        const date1 = new Date(e._d);

                        const utcDate = new Date(
                          date1.getTime() -
                            date1.getTimezoneOffset() * 60 * 1000
                        );

                        const isoDate = utcDate.toISOString();

                        setendTimestamp(isoDate);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </>
          )}
          <div className="row col-md-4 offset-md-2">
            <button
              className="btn btn-icon btn-3 btn-primary"
              disabled={submit_button}
              type="submit"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-cloud-download-95" />
              </span>

              <span className="btn-inner--text">
                {submit_button ? "Downloading.." : "Generate"}
              </span>
            </button>
          </div>
        </form>
      </div>
    </center>
  );
}

export default Reports;
