import React, { Component } from "react";

export default class List extends Component {
  render() {
    return (
      <tr className="text-center">
        <td className="pl-2">{this.props.index + 1}</td>
        <td>{this.props.device.booking_id}</td>
        <td>{this.props.device.category_name}</td>
        <td>{this.props.device.ticket_qty}</td>
        <td>{this.props.device.email}</td>
        <td>{this.props.device.mobile}</td>
        <td>{this.props.device.pickup_status ? "Yes" : "No"}</td>
        <td>{this.props.device.pickupusr}</td>
        <td>{this.props.device.pickup_timestamp}</td>
        {/* <td>{this.props.device.update_bo_time}</td>
        <td>{this.props.device.bo_sync ? "Yes" : "No"}</td> */}
        <td>
          {this.props.device.search &&
          this.props.device.pickup_status === false ? (
            <span className="blink badge badge-dot p-1 mr-4 bg-danger text-white">
              {this.props.device.search_by}
            </span>
          ) : (
            <span className="blink badge badge-dot p-1 mr-4 bg-success text-white">
              ok
            </span>
          )}
        </td>
      </tr>
    );
  }
}
