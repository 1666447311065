import React, { useState, useEffect } from "react";
import Logs from "./Logs";
import Enquiry from "./Enquiry";
import Cowinlogs from "./Cowinlogs";
import axios from "axios";

export default function GateLogs() {
  const [status, setstatus] = useState("Logs");
  const [eventid, seteventid] = useState(null);
  const [groupevents, setgroupevents] = useState([]);

  useEffect(() => {
    let eventid = sessionStorage.getItem("eventid");

    getEventDetails(eventid);
  }, []);

  const getEventDetails = async (eventid) => {

    try {
      let { data } = await axios.get(`/cms/events/${eventid}`);
      await setgroupevents(data.events.groupevents);
     await seteventid(data.events._id);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    eventid && <div>
      <div className="main-content">
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <button
            onClick={() => setstatus("Logs")}
            style={{
              width: 150,
              outlineStyle: "none",
              backgroundColor: status === "Logs" ? "#1A2A4D" : "#fff",
              color: status === "Logs" ? "#fff" : "#000",
              height: 40,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              border: "1px solid #ccc",
            }}
          >
            Logs
          </button>
          <button
            onClick={() => setstatus("Enquiry")}
            style={{
              width: 150,
              outlineStyle: "none",
              backgroundColor: status === "Enquiry" ? "#1A2A4D" : "#fff",
              color: status === "Enquiry" ? "#fff" : "#000",
              height: 40,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              border: "1px solid #ccc",
            }}
          >
            Enquiry
          </button>
          {/* <button
            onClick={() => setstatus("CowinLogs")}
            style={{
              width: 150,
              outlineStyle: "none",
              backgroundColor: status === "CowinLogs" ? "#1A2A4D" : "#fff",
              color: status === "CowinLogs" ? "#fff" : "#000",
              height: 40,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              border: "1px solid #ccc",
            }}
          >
            CowinLogs
          </button> */}
        </div>

        {status === "Logs" ? (
         eventid&& <Logs eventid={eventid} groupevents={groupevents} />
        ) : status === "CowinLogs" ? (
          <Cowinlogs />
        ) : (
          <Enquiry eventid={eventid} groupevents={groupevents} />
        )}
      </div>
    </div>
  );
}
