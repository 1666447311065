import React, { Component } from "react";
import Certificate from "./Certificate";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import { Input } from "reactstrap";
import axios from "axios";

// import Swal from "sweetalert2";

let host = window.location.hostname;
let url;

// let u2;

if (host === "eventmanager.bms.bz") {
  url = "https://acs.bms.bz/";
} else if (host === "eventmanager-sg.bms.bz") {
  url = "https://acs-sg.bms.bz/";
} else if (host === "eventmanager-ae.bms.bz") {
  url = "https://acs-ae.bms.bz/";
} else if (host === "eventmanager.testbms.com") {
  url = "https://eventmanager.testbms.com/";
} else {
  url = `http://${host}:8090/`;
}

class Barcodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodes: [],
      eventid: "",
      search_barcode: "",
    };
  }

  sync = () => {
    this.synclogs();
  };

  download_all = () => {
    window.open(
      `${url}report/download/cowin/logs/${this.state.eventid}`,
      "_blank"
    );
  };

  componentDidMount() {
    let eventid = sessionStorage.getItem("eventid");
    this.setState({ eventid }, () => {
      this.synclogs();
    });
  }

  synclogs = async () => {
    try {
      let { data } = await axios.get(`/cms/cowin/${this.state.eventid}`);

      this.setState({ barcodes: data });
    } catch (error) {
      console.log(error);
      this.setState({ barcodes: [] });
    }
  };

  search = (e) => {
    e.preventDefault();
    this.synclogs();
  };

  render() {
    let list;

    let barcodeslist = this.state.barcodes;

    list = barcodeslist.map((barcode, index) => {
      let typecheck = false;

      return (
        <Certificate
          sync={this.sync}
          key={index}
          device={barcode}
          typecheck={typecheck}
          index={index}
        />
      );
    });

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col text-left">
                {/* <h3 className="mb-0">
                  Barcodes - {this.state.barcodes.length}{" "}
                </h3> */}
                {/* <h3 className="mb-0" style={{ float: "left" }}>
                  Barcodes - {this.state.barcodes.length}{" "}
                </h3> */}
                <h3 className="mb-0" style={{ float: "right" }}>
                  <button
                    onClick={() => {
                      this.download_all();
                    }}
                    className="btn btn-md bg-primary text-white"
                    disabled={this.props.auth.user.superadmin ? false : true}
                  >
                    <i className="fa fa-cloud-download-alt"></i>
                  </button>
                </h3>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "800px",
              display: "inline-block",
              width: "100%",
              overflow: "auto",
            }}
            className="table-responsive"
          >
            <table className="table align-items-center" id="table-to-xls">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr.</th>
                  <th scope="col">Beneficiary ID</th>
                  <th scope="col">UHID</th>
                  <th scope="col">Dose Number</th>
                  <th scope="col">Status</th>
                  <th scope="col">Qr scan time</th>
                  <th scope="col">User Id</th>
                </tr>
              </thead>
              <tbody id="myTable">{list}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

Barcodes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Barcodes);
