import React, { Component } from "react";

// import Navbar from "../navbar";
import Content from "./Content";
// import { Footer } from "../footer";

export default class index extends Component {
  render() {
    return (
      <div>
        {/* <Navbar /> */}

        <Content />

        {/* <Footer /> */}
      </div>
    );
  }
}
