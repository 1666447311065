// import Alternative from "views/pages/dashboards/Alternative.js";
// import Charts from "views/pages/Charts.js";
// import Dashboard from "views/pages/dashboards/Dashboard.js";

import Dashboard from "views/pages/application/view/dashboards/Dashboard";
// import Alternative from "views/pages/application/view/dashboards/Alternative";
import GatesAnalytics from "views/pages/application/view/dashboards/Gatedashboard/GatesAnalytics";
import Report from "views/pages/application/view/reports";
import Report2 from "views/pages/application/view/reports2";
import Logs from "views/pages/application/view/Logs";


const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-info",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/analytics",
    name: "Analytics",
    icon: "ni ni-chart-pie-35 text-info",
    component: GatesAnalytics,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Report",
    icon: "ni ni-chart-bar-32 text-blue",
    component: Report,
    layout: "/admin",
  },
  {
    path: "/reports2",
    name: "Report V2",
    icon: "ni ni-chart-bar-32 text-blue",
    component: Report2,
    layout: "/admin",
  },
  {
    path: "/logs",
    name: "Audit",
    icon: "ni ni-bullet-list-67 text-red",
    component: Logs,
    layout: "/admin",
  },
];

export default routes;
