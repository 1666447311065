/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";

const defaultgate = {
  zone_ref: "All Zone",
  gatename: "All Gates",
};

export default function Grouped({ gateselected, event_obj_id, groupevents }) {
  const [gatesdata, setgatesdata] = useState([defaultgate]);

  useEffect(() => {
    const fech = fetchGates();

    return fech;
  }, [groupevents]);

  const fetchGates = () => {
    let data = {
      event_obj_id
    };

    if (data.event_obj_id === null) {
      return;
    }

    axios.post("/cms/gates/standard/fetchgroupgates", data).then((response) => {
      let { success, gates } = response.data;

      if (success) {
        let newgates = [
          {
            zone_ref: "All Zone",
            gatename: "All Gates",
          },
        ];

        gates.map((gate) => {
          newgates.push(gate);
        });

        //console.log(newgates)
        if (newgates.length > 0) {
          setgatesdata(newgates);
          //gateselected(newgates[0])
        }
      }
    });
  };

  return (
    <Autocomplete
      id="grouped-demo"
      multiple={false}
      options={gatesdata.sort((a, b) => b.zone_ref.localeCompare(a.zone_ref))}
      groupBy={(option) => option.zone_ref}
      getOptionLabel={(option) => option.gatename}
      style={{
        width: 350,
        padding: "10px",
        border: "1px solid #eee",
        borderRadius: 5,
        backgroundColor: "#fff",
        outlineColor: "transparent",
        zIndex: 0,
      }}
      // onChange={(e, v) => gateselected(v)}
      onChange={(e, v) => gateselected(v)}
      defaultValue={gatesdata.length > 0 ? gatesdata[0] : {}}
      renderInput={(params) => (
        <TextField {...params} label="Gate" variant="outlined" />
      )}
    />
  );
}
