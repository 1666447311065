import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";

import Dayslist from "./DaysList";
import GroupSession from "./GroupSession";
import Swal from "sweetalert2";
import moment from "moment";

class Days extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      session_details: [],
      venue_Code: "",
      groupSession: [],
      newgroupSessions: [],
      newgroupSessionname: "",
      groupevents:null,
      search:undefined,
      dayrefstartdate: moment().format("DD-MM-YYYY HH:mm"),
      dayrefenddate: moment().format("DD-MM-YYYY HH:mm"),

    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
    });
  };

  async componentDidMount() {
    let geteventid = sessionStorage.getItem("eventid");
    this.setState({ event_obj_id: geteventid });

    // this.geteventmonitors(geteventid);
    await axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        event: event.data,
        eventname: event.data.events.eventname,
        event_obj_id: geteventid,
        event_id_bms: event.data.events.event_id_bms,
        groupSession: event.data.events.groupSession,
        groupevents: event.data.events.groupevents
      });
    });
    this.sync_days();
  }


  sync_days = async (page = 1) => {
    const { event_obj_id, search, groupevents } = this.state;

    let post_groupevents_data;

    if (groupevents.length > 0) {
      post_groupevents_data = {
        id: [event_obj_id,...groupevents.map((event)=> event.event_obj_id)],
        limit: 1000,
        page,
        search,
        isgroup:true
      };
    }


    try {
      let days_data;

      if (post_groupevents_data) {
        days_data = await axios.post("/cms/v3/sessions", post_groupevents_data);
      } else {
        days_data = await axios.get(
          `/cms/v3/sessions?id=${event_obj_id}&limit=10000&page=${page}&search=${search}`
        );
      }

      const { data } = days_data;

      // console.log(data)

      if (data) {
        this.setState({
          session_details: data.docs,
          // pagination: this.pagination_res_details(data),
        });
      }
    } catch (error) {
      console.log(error);
    }

   
  };


  selected_session = (sessions) => {
    this.setState({ newgroupSessions: sessions });
  };

  creategroupsession = (e) => {
    e.preventDefault();

    let newgroup;
    let sessions = [];
    let group_encryptions = [];
    let session_obj_ids=[];

    this.state.newgroupSessions.map((session) => {
      session_obj_ids.push(session._id)
      sessions.push(session.session);
      let encryption_group_keys = {
        iv: session.iv,
        key: session.key,
      };
      return group_encryptions.push(encryption_group_keys);
    });



    newgroup = {
      session_obj_ids:[...new Set(session_obj_ids)],
      sessions: sessions.join(),
      name: this.state.newgroupSessionname,
      encryption_keys: {
        iv: this.state.newgroupSessions[0].iv,
        key: this.state.newgroupSessions[0].key,
        venue_Code: this.state.newgroupSessions[0].venue_Code,
      },
      group_encryptions,
      id: `${moment().format("DDMMHHYYYYHHmmss")}-${sessions.join()}`,
      active: true,
      dayrefstartdate:this.state.dayrefstartdate,
      dayrefenddate:this.state.dayrefenddate
    };

    let newgroupsess = [...this.state.groupSession, newgroup];

    let payload = {
      event_obj_id: this.state.event_obj_id,
      groupSession: newgroupsess,
      group_event_obj_id:[this.state.event_obj_id,...this.state.groupevents.map((event)=> event.event_obj_id)],
      request:"add"
    };

    axios
      .post("/cms/events/updategroupsession", payload)
      .then((response) => {
        if (response.data.success) {
          Swal.fire(
            "Good job!",
            "Good Session Created Succesfully!",
            "success"
          );
          this.setState({
            groupSession: newgroupsess,
            newgroupSessionname: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deletegroupsession = (sesion) => {
    Swal.fire({
      title: "Do you want to Delete the Group Session?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Delete`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire('Saved!', '', 'success')
        deletesession(sesion);
      } else {
        Swal.fire("Your group Session is safe", "", "info");
      }
    });

    const deletesession = (sesion) => {
      let { groupSession } = this.state;

      let b = groupSession.filter(function (e) {
        return e.id !== sesion.id;
      });

      let payload = {
        event_obj_id: this.state.event_obj_id,
        groupSession: b,
        request:'delete',
        id:sesion.id
      };

      axios
        .post("/cms/events/updategroupsession", payload)
        .then((response) => {
          // console.log(response.data)
          if (response.data.success) {
            this.setState({ groupSession: b });
            return Swal.fire(
              "Group Session Deleted Succesfully!",
              "",
              "success"
            );
          } else {
            return Swal.fire("Something Went Wrong", "", "info");
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Something Went Wrong", "", "info");
        });
    };
  };

  activetoggle = (session, status,session_obj_ids) => {
    let { groupSession } = this.state;

    let newgroup = [];

    groupSession.map((ses) => {
      if (ses.id === session.id) {
        ses.active = status;
      }
      return newgroup.push(ses);
    });

    let payload = {
      event_obj_id: this.state.event_obj_id,
      groupSession: newgroup,
      request:"update",
      status,
      session_obj_ids
    };

    axios
      .post("/cms/events/updategroupsession", payload)
      .then((response) => {
        // console.log(response.data)
        if (response.data.success) {
          this.setState({ groupSession: newgroup });
          return Swal.fire(
            `Group Session ${status ? "Enabled" : "Disabled"} Succesfully!`,
            "",
            "success"
          );
        } else {
          return Swal.fire("Something Went Wrong", "", "info");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Something Went Wrong", "", "info");
      });
  };

  render() {
    const {
      eventname,
      event_id_bms,
      dayrefsession,
      dayrefeventid,
      dayrefname,
    } = this.state;

    let showbutton = false;
    if (dayrefsession && dayrefeventid && dayrefname) showbutton = true;

    return (
      <Fragment>
        <center>
          <div className="container  create-event">
            <h2 className="display-2 text-muted">Create Session Groups</h2>
            <br />

            <div className="row ">
              <div className="col-md-2" />
              <div className="col-md-8">
                <form onSubmit={this.creategroupsession}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                Session Group Name :
                              </span>
                            </span>
                          </div>
                          <input
                            value={this.state.newgroupSessionname}
                            onChange={this.onChange}
                            name="newgroupSessionname"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Session Groups Name"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                Start Date & Time :
                              </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefstartdate"
                            value={this.state.dayrefstartdate}
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Start Day"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                End Date & Time :
                              </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="dayrefenddate"
                            value={this.state.dayrefenddate}
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Start Day"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group ">
                        <GroupSession
                          session_details={this.state.session_details}
                          selected_session={this.selected_session}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <button
                    className="btn btn-icon btn-3 bg-default"
                    type="submit"
                    disabled={
                      this.state.newgroupSessions.length > 0 &&
                      this.state.newgroupSessionname
                        ? false
                        : true
                    }
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">
                      Create Session Groups
                    </span>
                  </button>
                </form>
              </div>
              <div className="col-md-2" />
            </div>
          </div>
          <Dayslist
            // deletegroupsession={this.delete}
            groupSession={this.state.groupSession}
            deletegroupsession={this.deletegroupsession}
            activetoggle={this.activetoggle}
          />
        </center>
      </Fragment>
    );
  }
}

export default Days;
