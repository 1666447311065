import React, { Component } from "react";
import axios from "axios";
import Categorysync from "./categorysync";
import Select from "react-select";

import { Card, CardHeader, CardBody, Collapse } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
// import "./monitoringstyle.css";
// import Download from "../../../images/download.png";
// import Email from "../../../images/email.png";

// import Delete_logo from "../../../images/delete.png";
// import checked from "../../../images/tick.png";
// import unchecked from "../../../images/untick.png";

import { Redirect, Switch } from "react-router-dom";

import Swal from "sweetalert2";
import { IconButton } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import EmailIcon from "@material-ui/icons/Email";
import NoEncryptionIcon from "@material-ui/icons/NoEncryption";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";

let host = window.location.hostname;

export default class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: "",
      sync: false,
      event_obj_id: "",
      eventname: "",
      venuecode: "",
      mobile: "",
      emails: "",
      emailaccess: false,
      smsaccess: false,
      finish: false,
      days_data: [],
      openedCollapses: ["collapseOne"],
      tohome: false,
      is_whitelistvalidation_enable: false,
      is_apb_enable: false,
      backup_server: "",
      cloud_server_url: "",
      is_backupserver_sync: false,
      country: "",
      event_id_bms: "",
      eventtype: "",
      resync_button: false,
      autoexit: false,
      is_logicvalidation_enable: true,
      validation: "",
      settingspassword: "",
      adminpassword: "",
      directionpassword: "",
      cowinvalidation: false,
      cowindose: 1,
      cowindate: moment().format("DD-MM-YYYY"),
      callback_url:'',
      accred_callback_url:'',
      device_stats:false,
    };
    this.handleChange = this.handleChange.bind(this);
    // this.getNameById = this.getNameById.bind(this);
  }

  collapsesToggle = (collapse) => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: [],
      });
    } else {
      this.setState({
        openedCollapses: [collapse],
      });
    }
  };
  toggle = (togg) => {
    let { is_logicvalidation_enable, is_whitelistvalidation_enable } =
      this.state;

    if(togg==="device_stats"){
      this.setState({ device_stats: !this.state.device_stats });
    }

    if (togg === "sms") {
      this.setState({ smsaccess: !this.state.smsaccess });
    }
    if (togg === "email") {
      this.setState({ emailaccess: !this.state.emailaccess });
    }
    if (togg === "whitelist") {
      // this.setState({
      //   is_whitelistvalidation_enable: !this.state
      //     .is_whitelistvalidation_enable,
      // });

      if (is_whitelistvalidation_enable && !is_logicvalidation_enable) {
        return Swal.fire("Error", "Primary Validation Required", "error");
      } else if (!is_whitelistvalidation_enable && is_logicvalidation_enable) {
        return this.setState({ is_whitelistvalidation_enable: true });
      } else if (is_whitelistvalidation_enable && is_logicvalidation_enable) {
        return this.setState({ is_whitelistvalidation_enable: false });
      }
    }
    if (togg === "backupSync") {
      this.setState({
        is_backupserver_sync: !this.state.is_backupserver_sync,
      });
    }
    if (togg === "apb") {
      this.setState({ is_apb_enable: !this.state.is_apb_enable });
    }
    if (togg === "autoexit") {
      this.setState({ autoexit: !this.state.autoexit });
    }
    if (togg === "logic") {
      if (!is_whitelistvalidation_enable && is_logicvalidation_enable) {
        return Swal.fire("Error", "Primary Validation Required", "error");
      } else if (is_whitelistvalidation_enable && !is_logicvalidation_enable) {
        return this.setState({ is_logicvalidation_enable: true });
      } else if (is_whitelistvalidation_enable && is_logicvalidation_enable) {
        return this.setState({ is_logicvalidation_enable: false });
      }
    }

    // console.log(this.state);
  };

  resync_event = () => {
    Swal.fire({
      title: "Resync This Event ?",
      input: "password",

      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Please Enter Admin Passoword",
        type: "t",
      },
      showCancelButton: true,
      confirmButtonText: "Resync",
      showLoaderOnConfirm: true,
      preConfirm: (passoword) => {
        if (passoword !== "admin1234") {
          Swal.fire("Error", "Passowrd Not Match", "error");
          return this.setState({ resync_button: false });
        } else {
          return this.sync_event_proceed();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  delete_event = () => {
    Swal.fire({
      title: "Delete This Event ?",
      input: "password",

      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Please Enter Admin Passoword",
        type: "t",
      },
      showCancelButton: true,
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      preConfirm: (passoword) => {
        if (host === "eventmanager-ae.bms.bz") {
          if (passoword !== "uae1234") {
            return Swal.fire("Error", "Passowrd Not Match", "error");
          } else {
            return this.delete_event_proceed();
          }
        } else {
          if (passoword !== "Bongo1234") {
            return Swal.fire("Error", "Passowrd Not Match", "error");
          } else {
            return this.delete_event_proceed();
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  unscan = (session, type) => {
    let data = {
      session: session,
      type: type,
    };

    axios
      .post(`/cms/whitelist/unscan`, data)
      .then((response) => {
        let success = response.data.success;

        if (success) {
          Swal.fire("Success!", `All Data Cleared !`, "success");
        } else {
          Swal.fire("Failed!", `${response.data.msg} `, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  password_validate = (session, task) => {
    Swal.fire({
      title: "Select Barcod Type",
      icon: "info",
      // html:
      //   'Please Select <b>Type</b>,',
      showDenyButton: true,
      // showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: `Ticket`,
      confirmButtonColor: "#0779E4",
      cancelButtonColor: "#0779E4",
      cancelButtonText: `Accred`,
    }).then((result) => {
      console.log(result);
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire('Saved!', '', 'success')
        return this.proceed(task, session, "Ticket");
      } else if (result.isDismissed) {
        return this.proceed(task, session, "Accred");

        //Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };

  proceed(task, session, type) {
    let remark;
    switch (task) {
      case "unscan":
        remark = `Reset ${type} Scan Records`;
        break;
      case "whitelist":
        remark = `Remove ${type} Whitelist Data`;
        break;
      case "blacklist":
        remark = `Remove ${type} Blacklist Data`;
        break;

      default:
        break;
    }

    Swal.fire({
      title: `Password for ${remark}`,
      input: "password",

      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Please Enter Admin Passoword",
        type: "t",
      },
      showCancelButton: true,
      confirmButtonText: "Proceed",
      showLoaderOnConfirm: true,
      preConfirm: (passoword) => {
        if (host === "eventmanager-ae.bms.bz") {
          if (passoword !== "uae1234") {
            return Swal.fire("Error", "Passowrd Not Match", "error");
          } else {
            if (task === "unscan") {
              this.delete_barcodes(session, type);
            } else if (task === "whitelist") {
              return this.whitelist_delete(session, type);
            } else {
              return this.blacklist_delete(session, type);
            }
          }
        } else {
          if (passoword !== "Bongo1234") {
            return Swal.fire("Error", "Passowrd Not Match", "error");
          } else {
            if (task === "unscan") {
              this.delete_barcodes(session, type);
            } else if (task === "whitelist") {
              return this.whitelist_delete(session, type);
            } else {
              return this.blacklist_delete(session, type);
            }
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  sendreport = (session) => {
    let protocol = window.location.protocol;
    let url = window.location.hostname;

    session.protocols = protocol;
    session.url = url;

    axios
      .post(`/cms/report/send_email_sms`, session)
      .then((res) => {
        if (res.data.success) {
          return Swal.fire(
            "Success!",
            "Email / Sms Sent Succesfully!",
            "success"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  sync_event_proceed = () => {
    const eid = this.state.event.events.event_id_bms;

     this.setState({ resync_button: true });

  
    axios
      .get(`/cms/v3/sync/fetchCloudevent/${eid}`)
      .then((response) => {
        let event_status = response.data;

        if (!event_status.success) {
          Swal.fire("Error!", event_status.message, "error");
          return this.setState({ resync_button: false });
        } else {
           this.setState({ resync_button: false });
          Swal.fire("Success!", event_status.message, "success");
         
        }
      })
      .catch((err) => {
         this.setState({ resync_button: false });
        Swal.fire("Error!", err.response.data.message, "error");
      });
  };

  autocloser = () => {
    let timerInterval;
    this.setState({ resync_button: false });

    Swal.fire({
      title: "Event Sync In Progress!",
      html: "Fetching Details From Server <strong></strong>",
      timer: 10000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector("strong").textContent =
            Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
        Swal.fire("Event Sync Succesfully!", "", "success");
        this.setState({ finish: true, tohome: true });
      }
    });
  };

  delete_event_proceed = () => {
    const id = this.state.event.events._id;
    const eid = this.state.event.events.event_id_bms;
    axios
      .delete(`/cms/events/${id}/${eid}`)
      .then((response) => {
        let event_status = response.data;

        if (!event_status.success) {
          return Swal.fire("Error!", event_status.msg, "error");
        } else {
          //
          Swal.fire("Deleted!", event_status.msg, "success");
          this.setState({ finish: true, tohome: true });
          // setTimeout(function(){ window.location.href("/home");
          // }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async componentWillMount() {
    let geteventid = sessionStorage.getItem("eventid");
    let get_days = {
      event_obj_id: geteventid,
    };
    // this.geteventmonitors(geteventid);
    await axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        event: event.data,
        sync: event.data.events.sync,
        mobile: event.data.events.mobile,
        emails: event.data.events.emails,
        eventname: event.data.events.eventname,
        venuecode: event.data.events.venuecode,
        emailaccess: event.data.events.emailaccess,
        smsaccess: event.data.events.smsaccess,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: geteventid,
        is_whitelistvalidation_enable:
          event.data.events.is_whitelistvalidation_enable,
        is_apb_enable: event.data.events.is_apb_enable,
        cloud_server_url: event.data.events.cloud_server_url,
        backup_server: event.data.events.backup_server,
        is_backupserver_sync: event.data.events.is_backupserver_sync,
        country: event.data.events.country,
        eventtype: event.data.events.eventtype,
        autoexit: event.data.events.autoexit,
        is_logicvalidation_enable: event.data.events.is_logicvalidation_enable,
        validation: event.data.events.validation,
        settingspassword: event.data.events.settingspassword,
        adminpassword: event.data.events.adminpassword,
        directionpassword: event.data.events.directionpassword,
        cowinvalidation: event.data.events.cowinscanningconfig?.validation,
        cowindose: event.data.events.cowinscanningconfig?.dose,
        cowindate: event.data.events.cowinscanningconfig?.date,
        groupevents: event.data.events.groupevents,
        callback_url:event.data.events.callback_url,
        accred_callback_url:event.data.events.accred_callback_url,
        device_stats:event.data.events.device_stats
      });

      if (event.data.events.validation === "default") {
        this.setState({ validation: { value: "default", label: "Default" } });
      } else if (event.data.events.validation === "logiconly") {
        this.setState({
          validation: { value: "logiconly", label: "Logic Only" },
        });
      } else {
        this.setState({
          validation: { value: "whitelistonly", label: "Whitelist Only" },
        });
      }
    });
    this.sync_days(get_days);
  }

  sync_days = async (get_days) => {
    const { event_obj_id, groupevents } = this.state;

    const requestdata = {
      id:
        groupevents.length > 0
          ? [event_obj_id,...groupevents.map((event)=> event.event_obj_id)]
          : [event_obj_id],
      limit: 10000,
      page: 1,
      search: "",
    };

    try {
      const days_data = await axios.post("/cms/v3/sessions", requestdata);

      this.setState({ days_data: days_data.data.docs });
    } catch (error) {
      console.log(error);
    }
  };

  update_event = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want To Update This!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then((result) => {
      if (result.value) {
        this.update_event_config();
       
      }
    });
  };

  update_event_config = () => {
    let update_event = {
      event_obj_id: this.state.event.events._id,
      eventname: this.state.eventname,
      emails: this.state.emails,
      mobile: this.state.mobile,
      emailaccess: this.state.emailaccess,
      smsaccess: this.state.smsaccess,
      venuecode: this.state.venuecode,
      is_apb_enable: this.state.is_apb_enable,
      is_whitelistvalidation_enable: this.state.is_whitelistvalidation_enable,
      cloud_server_url: this.state.cloud_server_url,
      backup_server: this.state.backup_server,
      is_backupserver_sync: this.state.is_backupserver_sync,
      autoexit: this.state.autoexit,
      is_logicvalidation_enable: this.state.is_logicvalidation_enable,
      validation: this.state.validation.value,
      settingspassword: this.state.settingspassword,
      adminpassword: this.state.adminpassword,
      directionpassword: this.state.directionpassword,
      cowinscanningconfig: {
        validation: this.state.cowinvalidation,
        dose: this.state.cowindose,
        date: this.state.cowindate,
      },
      callback_url:this.state.callback_url,
      accred_callback_url:this.state.accred_callback_url,
      device_stats:this.state.device_stats
    };

    // return console.log(update_event)

    axios
      .post(`/cms/events/config`, update_event)
      .then((event) => {
        Swal.fire(
          "Updated!",
          "Your Event Has Been Updated Succesfully!",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong");
      });
  };

  session_sync = (session_sync, venuecode, id) => {
    Swal.fire({
      title: "What Do You Want To Sync?",
      showDenyButton: false,
      showCancelButton: true,
      //  showCloseButton: true,
      confirmButtonText: `Sync Whitelist / Blacklist Barcodes`,
      cancelButtonText: "Sync Ticket / Accred Barcodes",
      // denyButtonText: `Sync Ticket / Accred Barcodes`,
      cancelButtonColor: "#00baf2",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.dismiss === "backdrop") {
        // Swal.fire('Request Canceled!', '', 'success')
      } else if (result.dismiss === "cancel") {
        //alert('push data')
        let session_sync_data = {
          sesionid: session_sync.session,
          event_obj_id: id,
        };

        axios
          .post(`/cms/scans/standard/syncbarcodes`, session_sync_data)
          .then((response) => {
            // console.log(response)

            if (response.data.success) {
              Swal.fire({
                type: "success",
                title: "Sync Completed...",
                text: response.data.msg,
                // footer: '<a href>Why do I have this issue?</a>'
              });
            }
          })
          .catch((err) => {
            console.error(err);
            Swal.fire({
              type: "error",
              title: "Error !",
              text: "Something Went Wrong!",
              // footer: '<a href>Why do I have this issue?</a>'
            });
          });
      } else if (result.isConfirmed) {
        let session_sync_data = {
          event_id_bms: session_sync.event_id_bms,
          sesionid: session_sync.session,
          venuecode: session_sync.venue_Code || venuecode,
          event_obj_id: id,
        };

        axios
          .post(`/cms/events/syncindmticket_whitelist`, session_sync_data)
          .then((res) => {
            let success = res.data.success;
            // this.blacklist_sync(session_sync.session, session_sync.venue_Code, id)

            if (success) {
              // this.sync_complete(res.data.barcodes, success);
              this.blacklist_sync(
                session_sync.session,
                session_sync.venue_Code,
                id
              );
            } else {
              Swal.fire({
                type: "error",
                title: "Error !",
                text: "No Data Found For Downloading!",
                // footer: '<a href>Why do I have this issue?</a>'
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  session_sync_open = (session_sync, venuecode, id) => {
    let session_sync_data = {
      event_id_bms: session_sync.event_id_bms,
      sesionid: session_sync.session,
      venuecode: session_sync.venue_Code || venuecode,
      event_obj_id: id,
      eventtype: this.state.eventtype,
      mainevent_id_bms: this.state.event_id_bms,
    };

    axios
      .post(`/cms/events/syncopen_whitelist`, session_sync_data)
      .then((res) => {
        // alert(res.data.success)
        let success = res.data.success;

        // this.blacklist_sync_open(session_sync.session, session_sync.venue_Code, id)

        if (success) {
          // this.sync_complete(res.data.barcodes, success);
          this.blacklist_sync_open(
            session_sync.session,
            session_sync.venue_Code,
            id
          );
        } else {
          Swal.fire({
            type: "error",
            title: "Error !",
            text: "No Data Found For Downloading!",
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  blacklist_sync = (session_sync, venuecode, id) => {
    // alert('hello')

    let session_sync_data = {
      event_id_bms: session_sync.event_id_bms,
      sesionid: session_sync.session,
      venuecode: session_sync.venue_Code || venuecode,
      event_obj_id: id,
      event_id_bms_fetch: this.state.event_id_bms,
    };

    // return alert(this.state.event_id_bms)

    axios
      .post(`/cms/events/syncindmticket_blacklist`, session_sync_data)
      .then((res) => {
        let success = res.data.success;

        if (res.data.success) {
          this.sync_complete(res.data.barcodes, success);
        } else {
          this.sync_complete(res.data.barcodes, success);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  blacklist_sync_open = (session_sync, venuecode, id) => {
    let session_sync_data = {
      event_id_bms: session_sync.event_id_bms,
      sesionid: session_sync.session,
      venuecode: session_sync.venue_Code || venuecode,
      event_obj_id: id,
      event_id_bms_fetch: this.state.event_id_bms,
      eventtype: this.state.eventtype,
    };

    // return alert(this.state.event_id_bms)

    axios
      .post(`/cms/events/syncopen_blacklist`, session_sync_data)
      .then((res) => {
        let success = res.data.success;

        if (success) {
          this.sync_complete(res.data.barcodes, success);
        } else {
          //  Swal.fire({
          //   type: "error",
          //   title: "Error !",
          //   text: "No Data Found For Downloading!",
          //   // footer: '<a href>Why do I have this issue?</a>'
          // });
          this.sync_complete(res.data.barcodes, success);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  sync_complete = (sync_data, success) => {
    let timerInterval;
    Swal.fire({
      title: "Downloading Session Data!",
      html: "Sync In Progress.. <strong></strong>",
      timer: 4000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector("strong").textContent =
            Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        Swal.fire({
          type: "success",
          title: "Sync Completed...",
          text: `Tickets Whitelist/Blacklist Sync Succesfully!`,
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    });
  };

  delete_barcodes = (session, type) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.delete_confirm(session, type);
      }
    });
  };

  sync = async (session) => {
    let autosync = !session.auto_sync;
    let session_day = session.session;

    let days = this.state.days_data;
    let new_days = [];

    days.map((day) => {
      if (
        day.session === session_day &&
        day.venue_Code === session.venue_Code
      ) {
        day.auto_sync = autosync;
      }
      return new_days.push(day);
    });

    this.setState({ days_data: new_days }, () => {
      let autosync_mode = {
        autosync,
        id: session._id,
        eventdata: this.state.event.events,
      };
      axios
        .post(`/cms/days/standard/autoupdate`, autosync_mode)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });

    // await axios.post(`/cms/events/sync`, sync).then(event => {});
  };

  synctolocalupdate = async (session) => {

    // return alert(session)

    let synctolocal = !session.synctolocal;
    let session_day = session.session;

    let days = this.state.days_data;
    let new_days = [];

    days.map((day) => {
      if (day._id === session._id) {
        day.synctolocal = synctolocal;
      }
      return new_days.push(day);
    });


    this.setState({ days_data: new_days }, () => {
      let synctolocal_mode = {
        synctolocal,
        id: session._id,
        eventdata: this.state.event.events
      };
      axios
        .post(`/cms/days/standard/synctolocal`, synctolocal_mode)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });

    // await axios.post(`/cms/events/sync`, sync).then(event => {});
  };

  delete_confirm = (session, type) => {
    // let event_id_bms = session.dayrefeventid;
    let day_id = session.session;
    // let eventtype = this.state.event.events.eventtype;
    let event_obj_id = session.event_obj_id;


    axios
      .delete(`/cms/scans/standard/${event_obj_id}/${day_id}/${type}`)
      .then((response) => {
        let success = response.data.success;

        if (success) {
          this.unscan(session, type);
        } else {
          // this.unscan(session, type);
          console.log(response.data);
          return alert("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  unpick_trans = (session) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.delete_confirm(session, "");
      }
    });
  };

  sync_blacklist_complete = (sync_data, success) => {
    let timerInterval;
    Swal.fire({
      title: "Downloading Session Data!",
      html: "Sync In Progress.. <strong></strong>",
      timer: 4000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector("strong").textContent =
            Swal.getTimerLeft();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        if (success) {
          Swal.fire({
            type: "success",
            title: "Sync Completed...",
            text: `${sync_data} Blacklist Tickets Downloaded Succesfully!`,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Already Sync...",
            text: `${sync_data} Blacklist Tickets Already Downloaded!`,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }

        // console.log("I was closed by the timer");
      }
    });
  };

  sync_encryption = (session) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You Want To Sync Encryption Keys!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        let session_data = {
          event_id_bms: session.event_id_bms,
          id: session._id,
          sesionid: session.session,
          venuecode: session.venue_Code,
          country: this.state.country,
        };

        let get_days = {
          event_obj_id: session.event_obj_id,
        };

        let country = this.state.country;
        let url = "";

        // return alert(country)

        switch (country) {
          case "india":
            url = "/cms/events/syncindencryption";
            break;

          default:
            url = "/cms/events/syncsgencryption";
            break;
        }

        axios
          .post(url, session_data)
          .then((response) => {
            // console.log(response);
            if (response.data.success) {
              this.sync_days(get_days);
              Swal.fire("Success!", response.data.msg, "success");
            } else {
              console.log(response.data.err);
              Swal.fire("Error!", response.data.msg, "error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // this.syncencryption(session, "");
      }
    });
  };

  whitelist_delete = (session_data, type) => {
    axios
      .delete(
        `/cms/whitelist/${session_data.event_obj_id}/${session_data.session}/${type}`
      )
      .then((response) => {
        let success = response.data.success;

        if (success) {
          Swal.fire(
            "Deleted!",
            `${response.data.deleted} - Barcodes deleted `,
            "success"
          );
        } else {
          Swal.fire("Failed!", `${response.data.msg} `, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  blacklist_delete = (session_data, type) => {
    // const { event_obj_id } = this.state;

    let new_delete = {
      event_obj_id: session_data.event_obj_id,
      session_id: session_data.session,
      type,
    };

    // console.log(new_delete);

    axios.post("/cms/blacklist/delete_session", new_delete).then((response) => {
      let deletedcount = response.data.deletecount;
      Swal.fire({
        type: "success",
        title: "Success!",
        text: `${deletedcount} Barcodes Deletd`,
      });
      // return this.get_count();
    });
  };

  resyncbmsevent = () => {
    // return alert('ss')
    this.setState({ resync_button: true });

    axios
      .get(
        `/cms/events/resyncfrombmsindia/${this.state.event_id_bms}/${this.state.venuecode}`
      )
      .then((response) => {
        // console.log(response)
        this.setState({ resync_button: false });

        if (response.data.success) {
          this.success(response.data.msg);
        } else {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Database error",
        });
      });
  };

  handlevalidation = (value) => {
    this.setState({ validation: value });
  };

  success = (msg) => {
    let timerInterval;
    Swal.fire({
      title: "Event Sync In Progress!",
      html: "Please Wait While We Fetch Details From Bookmyshow!",
      timer: 12000,
      timerProgressBar: true,
      willOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        return Swal.fire("Good job!", msg, "success");
      }
    });
  };

  sync_scan_barcodes = (session) => {
    let timerInterval;
    Swal.fire({
      title: "Scan Barcodes Sync In Progress!",
      html: "Please Wait While We Fetch Details From CLoud Server!",
      timer: 12000,
      timerProgressBar: true,
      willOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
        return Swal.fire(
          "Good job!",
          "WHitelist Barcodes Sync Succesfully !",
          "success"
        );
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        return Swal.fire(
          "Good job!",
          "Scan Barcodes Sync Succesfully !",
          "success"
        );
      }
    });
  };

  sync_whitelist_barcodes = (session) => {
    let timerInterval;
    Swal.fire({
      title: "Whitelist Barcodes Sync In Progress!",
      html: "Please Wait While We Fetch Details From CLoud Server!",
      timer: 12000,
      timerProgressBar: true,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
        return Swal.fire(
          "Good job!",
          "WHitelist Barcodes Sync Succesfully !",
          "success"
        );
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        return Swal.fire(
          "Good job!",
          "WHitelist Barcodes Sync Succesfully !",
          "success"
        );
      }
    });
  };

  render() {
    const { event, days_data, tohome } = this.state;

    // console.log(window.location);

    if (tohome) {
      return <Redirect to="/home" />;
    }

    let eventmonitorsdata = (
      <tr key="1" className="text-center">
        <td className="pl-2" />
      </tr>
    );
    let eventmonitorsdata2 = (
      <tr key="1" className="text-center">
        <td className="pl-2" />
      </tr>
    );

    if (event) {
      const { venuecode, _id } = event.events;
      eventmonitorsdata = days_data.map((session, index) => {
        let encryption = false;
        let iv = session.iv;
        let key = session.key;

        if (iv && key) {
          encryption = true;
        }

        return (
          <tr key={index + 1} className="text-center">
            <td className="pl-2">{index + 1}</td>
            <td className="pl-2">{session.event_id_bms}</td>
            <td className="pl-2">{session.name}</td>
            <td className="pl-2">{session.session}</td>

            <td className="pl-3">
              <IconButton onClick={() => this.sendreport(session)}>
                <EmailIcon color="action" />
              </IconButton>
            </td>
          </tr>
        );
      });

      eventmonitorsdata2 = days_data.map((session, index) => {
        let encryption = false;
        let iv = session.iv;
        let key = session.key;

        if (iv && key) {
          encryption = true;
        }

        return (
          <tr key={index + 1} className="text-center">
            <td className="pl-2">{index + 1}</td>
            <td className="pl-2">{session.event_id_bms}</td>
            <td className="pl-2">{session.name}</td>
            <td className="pl-2">{session.session}</td>
            <td className="pl-2">{session.scan_barcodes}</td>
            <td className="pl-2">{session.whitelist_barcodes}</td>
            <td className="pl-2">
              <IconButton onClick={(session) => this.sync_scan_barcodes()}>
                <SyncIcon color="action" />
              </IconButton>
            </td>
            <td className="pl-2">
              <IconButton onClick={(session) => this.sync_whitelist_barcodes()}>
                <SyncIcon color="action" />
              </IconButton>
            </td>
            
          </tr>
        );
      });
    }

    return (
      <div className="accordion pt-3 pl-3 pr-3">
        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => this.collapsesToggle("collapseOne")}
            aria-expanded={this.state.openedCollapses.includes("collapseOne")}
          >
            <h5 className="mb-0">#Event Configuration</h5>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={this.state.openedCollapses.includes("collapseOne")}
          >
            <CardBody>
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <div className="container">
                    <form onSubmit={this.update_event}>
                      <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">Event Name :</span>
                              </span>
                            </div>
                            <input
                              disabled={
                                window.location.protocol === "http:"
                                  ? true
                                  : false
                              }
                              name="eventname"
                              value={this.state.eventname}
                              onChange={this.handleChange}
                              className="form-control form-control-alternative"
                              placeholder="Type Event Name"
                              type="text"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted"> Mobile:</span>
                              </span>
                            </div>
                            <input
                              disabled={
                                window.location.protocol === "http:"
                                  ? true
                                  : false
                              }
                              name="mobile"
                              onChange={this.handleChange}
                              value={this.state.mobile}
                              className="form-control form-control-alternative"
                              placeholder="Type Mobile Numbers"
                              type="text"
                              
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted"> Email:</span>
                              </span>
                            </div>
                            <input
                              disabled={
                                window.location.protocol === "http:"
                                  ? true
                                  : false
                              }
                              name="emails"
                              onChange={this.handleChange}
                              value={this.state.emails}
                              className="form-control form-control-alternative"
                              placeholder="Type Email ID's"
                              type="text"
                              
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Filter Code:
                                </span>
                              </span>
                            </div>
                            <input
                              name="venuecode"
                              disabled={window.location.protocol === "https:"?false:true}
                              onChange={this.handleChange}
                              value={this.state.venuecode}
                              className="form-control form-control-alternative"
                              placeholder="Type Filter Code"
                              type="text"
                              
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Ticketing Server:
                                </span>
                              </span>
                            </div>
                            <input
                             disabled={window.location.protocol === "https:"?false:true}
                              name="callback_url"
                              onChange={this.handleChange}
                              value={this.state.callback_url}
                              className="form-control form-control-alternative"
                              placeholder="Type Callback URL"
                              type="text"
                              
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Accred Server:
                                </span>
                              </span>
                            </div>
                            <input
                              disabled={window.location.protocol === "https:"?false:true}
                              name="accred_callback_url"
                              onChange={this.handleChange}
                              value={this.state.accred_callback_url}
                              className="form-control form-control-alternative"
                              placeholder="Type Accred Callback URL"
                              type="text"
                              
                            />
                          </div>
                        </div>
                      </div>

                      {window.location.protocol === "https:" ? null : (
                        <div className="col-md-6 offset-md-3 text-center">
                          <div className="form-group ">
                            <div className="input-group input-group-alternative mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text ">
                                  <span className="text-muted">
                                    {" "}
                                    Cloud Server
                                  </span>
                                </span>
                              </div>
                              <input
                                disabled
                                name="cloud_server_url"
                                onChange={this.handleChange}
                                value={this.state.cloud_server_url}
                                className="form-control form-control-alternative"
                                placeholder="Type Cloud Server Address"
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* {window.location.protocol === "https:" ? null : (
                        <div className="col-md-6 offset-md-3 text-center">
                          <div className="form-group ">
                            <div className="input-group input-group-alternative mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text ">
                                  <span className="text-muted">
                                    {" "}
                                    Backup Server
                                  </span>
                                </span>
                              </div>
                              <input
                                name="backup_server"
                                onChange={this.handleChange}
                                value={this.state.backup_server}
                                className="form-control form-control-alternative"
                                placeholder="Type Backup Server Address"
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )} */}

                      <div
                        className={
                          window.location.protocol === "http:"
                            ? "col-md-6 offset-md-3 text-center d-none"
                            : "col-md-6 offset-md-3 text-center"
                        }
                      >
                        <span className="text-muted"> Email Reporting:</span>
                        <span className="clearfix"></span>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={this.state.emailaccess}
                            onChange={() => this.toggle("email")}
                          />
                          <span className="custom-toggle-slider rounded-circle"></span>
                        </label>
                      </div>
                      <div
                        className={
                          window.location.protocol === "http:"
                            ? "col-md-6 offset-md-3 text-center d-none"
                            : "col-md-6 offset-md-3 text-center"
                        }
                      >
                        <span className="text-muted"> Mobile Reporting :</span>
                        <span className="clearfix"></span>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={this.state.smsaccess}
                            onChange={() => this.toggle("sms")}
                          />
                          <span className="custom-toggle-slider rounded-circle"></span>
                        </label>
                      </div>
                      <div className="col-md-6 offset-md-3 mb-3">
                        <Select
                          
                          value={this.state.validation}
                          isSearchable={true}
                          closeMenuOnSelect={true}
                          onChange={this.handlevalidation}
                          options={[
                            { value: "default", label: "Default" },
                            { value: "logiconly", label: "Logic Only" },
                            { value: "whitelistonly", label: "Whitelist Only" },
                          ]}
                          isMulti={false}
                          required
                          allowSelectAll={false}
                          placeholder="Select Validation"
                        />
                      </div>
                      <div className="col-md-6 offset-md-3 text-center">
                        <span className="text-muted"> In App Stats :</span>
                        <span className="clearfix"></span>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={this.state.device_stats}
                            onChange={() => this.toggle("device_stats")}
                          />
                          <span className="custom-toggle-slider rounded-circle"></span>
                        </label>
                      </div>
                      <div className="col-md-6 offset-md-3 text-center">
                        <span className="text-muted"> Auto Exit :</span>
                        <span className="clearfix"></span>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={this.state.autoexit}
                            onChange={() => this.toggle("autoexit")}
                          />
                          <span className="custom-toggle-slider rounded-circle"></span>
                        </label>
                      </div>
                      {window.location.protocol === "http:" ? (
                        <div className="col-md-6 offset-md-3 text-center">
                          <span className="text-muted">
                            {" "}
                            Backup Server Sync :
                          </span>
                          <span className="clearfix"></span>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              checked={this.state.is_backupserver_sync}
                              onChange={() => this.toggle("backupSync")}
                            />
                            <span className="custom-toggle-slider rounded-circle"></span>
                          </label>
                        </div>
                      ) : null}

                      <center>
                        <button
                          className="btn btn-md bg-success text-white"
                          type="submit"
                        >
                          Update
                        </button>
                      </center>
                    </form>
                  </div>
                </div>
              </div>
            </CardBody>
          </Collapse>
        </Card>
        
       
        

        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => this.collapsesToggle("collapseFour")}
            aria-expanded={this.state.openedCollapses.includes("collapseFour")}
          >
            <h5 className="mb-0">#Application Authorization</h5>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={this.state.openedCollapses.includes("collapseFour")}
          >
            <CardBody>
              <div className="card-body">
                <div className="container">
                  <div className="col-md-6 offset-md-4 text-center">
                    <div className="form-group ">
                      <div className="input-group input-group-alternative mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            <span className="text-muted">
                              {" "}
                              Settings Password
                            </span>
                          </span>
                        </div>
                        <input
                          name="settingspassword"
                          onChange={this.handleChange}
                          value={this.state.settingspassword}
                          className="form-control form-control-alternative"
                          placeholder="Type Settings Password"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-4 text-center">
                    <div className="form-group ">
                      <div className="input-group input-group-alternative mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            <span className="text-muted"> Admin Password</span>
                          </span>
                        </div>
                        <input
                          name="adminpassword"
                          onChange={this.handleChange}
                          value={this.state.adminpassword}
                          className="form-control form-control-alternative"
                          placeholder="Type Admin Password"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-4 text-center">
                    <div className="form-group ">
                      <div className="input-group input-group-alternative mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            <span className="text-muted">
                              {" "}
                              Direction Password
                            </span>
                          </span>
                        </div>
                        <input
                          name="directionpassword"
                          onChange={this.handleChange}
                          value={this.state.directionpassword}
                          className="form-control form-control-alternative"
                          placeholder="Type Direction Password"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-3 text-center">
                    <div className="form-group ">
                      <button
                        className="btn btn-md bg-success text-white"
                        // type="submit"
                        onClick={() => this.update_event_config()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Collapse>
        </Card>
        {/* <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => this.collapsesToggle("collapseSix")}
            aria-expanded={this.state.openedCollapses.includes("collapseSix")}
          >
            <h5 className="mb-0">#Cowin Authorization</h5>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={this.state.openedCollapses.includes("collapseSix")}
          >
            <CardBody>
              <center>
                <div className="row container">
                  <div className="col-md-4 offset-md-4">
                    <span className="text-muted">Cowin Validation :</span>
                    <span className="clearfix"></span>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={this.state.cowinvalidation}
                        onChange={() =>
                          this.setState({
                            cowinvalidation: !this.state.cowinvalidation,
                          })
                        }
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                  </div>
                  <div className="col-md-4 offset-md-4 ">
                    <div className="form-group ">
                      <div className="input-group input-group-alternative mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            <span className="text-muted"> Dose Required</span>
                          </span>
                        </div>
                        <input
                          name="cowindose"
                          onChange={this.handleChange}
                          value={this.state.cowindose}
                          className="form-control form-control-alternative"
                          placeholder="Dose Required"
                          type="text"
                          required
                          disabled={this.state.cowinvalidation ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 offset-md-4 ">
                    <div className="form-group ">
                      <div className="input-group input-group-alternative mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            <span className="text-muted"> Date Required</span>
                          </span>
                        </div>
                        <input
                          name="cowindate"
                          onChange={this.handleChange}
                          value={this.state.cowindate}
                          className="form-control form-control-alternative"
                          placeholder={moment().format("DD-MM-YYYY")}
                          type="text"
                          required
                          disabled={this.state.cowinvalidation ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-3 text-center">
                    <div className="form-group ">
                      <button
                        className="btn btn-md bg-success text-white"
                        // type="submit"
                        onClick={() => this.update_event_config()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </center>
            </CardBody>
          </Collapse>
        </Card> */}

        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => this.collapsesToggle("collapseFive")}
            aria-expanded={this.state.openedCollapses.includes("collapseFive")}
          >
            <h5 className="mb-0">#Event Authorization</h5>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={this.state.openedCollapses.includes("collapseFive")}
          >
            <CardBody>
              <center>
                <div className="row container">
                  <div className="col-md-6 offset-md-3">
                    <button
                      className="btn btn-icon btn-3 btn-info"
                      type="button"
                      disabled={this.state.resync_button}
                      // onClick={window.location.protocol === "http:" ? this.resync_event : this.resyncbmsevent}
                      onClick={this.resync_event}
                      // onClick={window.location.protocol === "http:" ? this.resyncbmsevent : this.resyncbmsevent}
                    >
                      <span className="btn-inner--icon">
                        <i
                          className="fa fa-sync fa-3 text-white"
                          aria-hidden="true"
                        ></i>
                      </span>

                      {window.location.protocol === "http:" ? (
                        <span className="btn-inner--text">
                          Re-sync From Eventmanager
                        </span>
                      ) : (
                        null
                        // <span className="btn-inner--text">
                        //   Re-sync From Bms
                        // </span>
                      )}
                    </button>
                    <button
                      className="btn btn-icon btn-3 btn-danger"
                      type="button"
                      onClick={this.delete_event}
                    >
                      <span className="btn-inner--icon">
                        <i
                          className="fa fa-trash fa-3 text-white"
                          aria-hidden="true"
                        ></i>
                      </span>

                      <span className="btn-inner--text">Delete Event</span>
                    </button>
                  </div>
                </div>
              </center>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
