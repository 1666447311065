import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class GatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gates: []
    };

    this.delete = this.delete.bind(this);
  }

  delete() {
    this.props.delete();
  }

  componentWillReceiveProps(nextprops) {
    return this.setState({ gates: nextprops.gatedata });
  }

  render() {
    if (this.state.finish) {
      return <Redirect to={"/dashboard"} />;
    }

    let gatelist;
    let category_details = [];

    this.state.gates.forEach(category => {
      category_details.push(category.label);
    });

    category_details = category_details.join();

    gatelist = (
      <tr className="eventsearch text-center">
        <td>{1}</td>

        <td style={{ maxWidth: "100px", overflow: "scroll" }}>
          {category_details}
        </td>
        <td>
          <button
            onClick={() => this.delete()}
            className="btn btn-sm btn-icon btn-2 btn-danger"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </button>
        </td>
      </tr>
    );

    return (
      <div className="search-data">
        {this.state.gates.length > 0 ? (
          <center>
            <h4 className="text-muted">ALL CATEGORIES</h4>
            <table className="table table-bordered">
              <thead className="bg-success text-white text-center">
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Name</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>{gatelist}</tbody>
            </table>
          </center>
        ) : null}
      </div>
    );
  }
}

export default GatesList;
