import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Select from "react-select";


import Result from "./Result";
// import Swal from "sweetalert2";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

class Gates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      catrefrence: "",
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      categories: [],
      options: [],
      gatename: "",
      prevcategories: [],
      gates: [],
      category_sync_details: [],
      groupevents:[],
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ gatename: e.target.value });
  }

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
    });
  };

  delete = () => {
    const { event_obj_id } = this.state;
    let categoryupdate = {
      event_obj_id,
      update: false,
    };

    axios
      .post(`/cms/events/sync_category`, categoryupdate)
      .then((response) => {
        this.setState({ category_sync_details: [] });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getcategory() {
    const categories = this.state.prevcategories;
    let copycat = categories;
    let selectall = [];

    copycat.map(({ _id }) => {
      return selectall.push(_id);
    });

    //var allcate = selectall.join();
    let options = [];

    categories.map((cat, index) => {
      let preoptions = {
        value: cat._id,
        label: `${cat.category_name} | ${cat.barcode_type.toLocaleUpperCase()} | ${cat.dayref} | ${cat.session} `,
      };
      return options.push(preoptions);
    });

    this.setState({ options });
  }

  create_category_sync = (e) => {
    e.preventDefault();

    const { selectedOption, event_obj_id } = this.state;

    let categoryupdate = {
      category_sync_details: selectedOption,
      event_obj_id,
      update: true,
    };

    axios
      .post(`/cms/events/sync_category`, categoryupdate)
      .then((response) => {
        this.event_sync(event_obj_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async componentDidMount() {
    const geteventid = sessionStorage.getItem("eventid");
    this.event_sync(geteventid);
  }

  event_sync = async (geteventid) => {


    

    await axios
      .get(`/cms/events/${geteventid}`)
      .then(async (event) => {

        let groupevents= event.data.events.groupevents

        let request_data = {
          page:1,
          id:
            groupevents.length > 0
              ? [geteventid,...groupevents.map((event)=> event.event_obj_id)]
              : [geteventid],
          limit: 10000,
          search:'',
        };


        await axios
        .post(`/cms/v3/categories/getcategories`, request_data)
          .then((categories) => {
            this.setState({
              eventname: event.data.events.eventname,
              event_id_bms: event.data.events.event_id_bms,
              event_obj_id: geteventid,
              prevcategories: categories.data.docs,
              category_sync_details: event.data.events.category_sync_details,
              groupevents,

            });
            this.getcategory();
            //this.gategatedata(geteventid);
          });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { selectedOption, category_sync_details } = this.state;

    return (
      <Fragment>
        <center>
          <div className="container create-event">
            <h3 className="p-2 text-center">SYNC FROM CLOUD </h3>
            <br />

            {category_sync_details.length <= 0 ? (
              <div className="row ">
                <div className="col-md-2" />
                <div className="col-md-8">
                  <form onSubmit={this.create_category_sync}>
                    <div className="col-md-8" >
                      
                      <Select

                        placeholder="Select Categories...."
                        // isClearable={true}
                        isSearchable
                        isMulti={true}
                        selectall={true}
                        defaultValue={selectedOption}
                        onChange={this.handleChange}
                        options={this.state.options}
                      />
                    </div>

                    <div className="col-md-3" />

                    <hr />

                    <button
                      className="btn btn-icon btn-3 btn-primary"
                      type="submit"
                      disabled={selectedOption ? false : true}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-bullet-list-67" />
                      </span>

                      <span className="btn-inner--text mb-10">
                        Create Sync Categorys
                      </span>
                    </button>
                  </form>
                </div>
                <div className="col-md-2 mb-20" />
              </div>
            ) : (
                ""
              )}
          </div>
          <Result delete={this.delete} gatedata={category_sync_details} />
        </center>
      </Fragment>
    );
  }
}

export default Gates;
