import React, { Component } from "react";
import axios from "axios";

export default class Categoryscans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryscans: [],
      event_obj_id: ""
    };
  }

  getcategorydata = async event_obj_id => {
    try {
      await axios
        .get(`/cms/dashboard/pickup/getcategory/${event_obj_id}`)
        .then(response => {
          let categorys = response.data.category;
          let category_arrayy = [];

          if (categorys) {
            categorys.forEach((category, index) => {
              let category_data = category.catref;
              axios
                .get(
                  `/dashboard/pickup/categorystats/${event_obj_id}/${category_data}`
                )
                .then(catresponse => {
                  let new_category = catresponse.data;
                  category_arrayy.push(new_category);
                });
            });
            return this.setState({ categoryscans: category_arrayy });
          }
        });
    } catch (error) {
      return console.log(error);
    }
  };

  transaction_category_summary = event_obj_id => {
    axios
      .get(`/cms/transaction/transaction_category_summary/${event_obj_id}`)
      .then(response => {
        console.log(response);
        let category_data = response.data;
        if (category_data.success === true) {
          let new_category = category_data.new_category;

          this.setState({ categoryscans: new_category });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  async componentDidMount() {
    let event_obj_id = sessionStorage.getItem("eventid");
    //this.getcategorydata(event_obj_id);
    this.transaction_category_summary(event_obj_id);
  }

  render() {
    let categoryscan = this.state.categoryscans;

    let categorydata = categoryscan.map((category, index) => {
      let progress = (category.picktrans / category.count) * 100;

      progress = Math.floor(progress);

      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <th scope="row">{category.category_name}</th>
          <td>{category.count}</td>
          <td>{category.picktrans}</td>
          <td>{category.ticketcount}</td>
          <td>{category.pickticket}</td>
          <td>
            <div className="progress-percentage text-left">
              <span>{progress || 0}%</span>
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                aria-valuenow="60"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${progress || 0}%` }}
              // style="width: 60%;"
              ></div>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div className="col-xl-12 mb-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="mb-0">{this.props.type}</h3>
              </div>
              {/* <div className="col text-right">
                <a href="#!" className="btn btn-sm btn-primary">
                  Download
                </a>
              </div> */}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Sr.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Transactions</th>
                  <th scope="col">Trans_Pickup</th>
                  <th scope="col">Tickets</th>
                  <th scope="col">Tickets_Pickup</th>
                  <th scope="col">Progress</th>
                </tr>
              </thead>
              <tbody>{categorydata}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
