import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      getcategories: false,
    };

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  update(formValues) {
    this.props.updatecategory(formValues);
  }

  componentWillReceiveProps(nextprops) {
    this.setState({ categories: nextprops.categories });
  }

  apb_toggle = (category) => {
    const { categories } = this.state;

    let new_cat = [];

    let obj = {
      _id: category._id,
      apb: !category.apb,
    };

    axios.post(`/cms/category/standard/apb_toggle`, obj).then((respos) => {
      if (respos.data.success) {
        categories.map((cat) => {
          if (cat._id === category._id) {
            cat.apb = !category.apb;
          }

          return new_cat.push(cat);
        });

        this.setState({ categories: new_cat });
      } else {
        alert("something went wrong");
      }
    });
  };

  async edit(category) {
    const { value: formValues } = await Swal.fire({
      title: "Edit Category",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-6">
        <label for="category_name">Category</label>
        <input type="text" class="form-control" id="category_name"
        value="${category.category_name}" placeholder="Please Enter Category Name">
      </div>
      <div class="form-group col-md-6">
        <label for="param2">ISSUER ID</label>
        <input type="text" class="form-control" id="param2" 
        value=${category.param2} placeholder="Please Enter ISSUER ID">
      </div>
    </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="param3">PRODUCT ID</label>
      <input type="text" class="form-control" id="param3"
      value=${category.param3} placeholder="Please Enter PRODUCT ID">
    </div>
    <div class="form-group col-md-6">
      <label for="param4">DAY ID	</label>
      <input type="text" class="form-control" id="param4" 
      value=${category.param4} placeholder="Please Enter DAY ID">
    </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-6">
    <label for="startdate">Start Date</label>
    <input type="text" class="form-control" id="startdate" 
    value="${category.startdate}" placeholder="Please Enter Start Date">
  </div>
  <div class="form-group col-md-6">
    <label for="enddate">End Date</label>
    <input type="text" class="form-control" id="enddate"
    value="${category.enddate}" placeholder="Please Enter End Date">
  </div>
</div>
<div class="form-row">
  <div class="form-group col-md-6">
    <label for="startdate">Zone</label>
    <input type="text" class="form-control" id="zone" 
    value="${category.zone}" placeholder="Please Enter Zone">
  </div>
  <div class="form-group col-md-6">
  <label for="gate_ref">Gate</label>
  <input type="text" class="form-control" id="gate_ref" 
  value="${category.gate_ref}" placeholder="Please Enter Zone">
</div>
<div class="form-group col-md-6 offset-md-3">
<label for="attempt">Attempt</label>
<input type="number" class="form-control" id="attempt" 
value="${category.attempt}" placeholder="Please Enter Attempt">
</div>
</div>

</div>

    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          param2: document.getElementById("param2").value,
          param3: document.getElementById("param3").value,
          param4: document.getElementById("param4").value,
          attempt: document.getElementById("attempt").value,
          category_name: document.getElementById("category_name").value,
          startdate: document.getElementById("startdate").value,
          enddate: document.getElementById("enddate").value,
          zone: document.getElementById("zone").value,
          gate_ref: document.getElementById("gate_ref").value,
          category_id: category._id,

          param1: `${document.getElementById("param2").value}${
            document.getElementById("param3").value
          }${document.getElementById("param4").value}`,
          event_id_ref: category.event_id_bms,
        };
      },
    });

    if (formValues) {
      this.update(formValues);
    }
  }
  delete(catid) {
    this.props.delete(catid);
  }

  delete_all_categories = () => {
    this.props.deleteall();
  };

  render() {
    const { categories } = this.state;
    let allcategories;
    if (categories) {
      // console.log(categories);
      let new_sort = categories.sort();
      allcategories = new_sort.map((category, i) => {
        

        return (
          <tr className="eventsearch text-center" key={i}>
            <td>{i + 1}</td>
            <td>{category.category_name}</td>

            <td>{category.barcode_type}</td>
            <td>{category.zone}</td>
            <td>{category.gate_ref}</td>

            <td>{category.param2}</td>
            <td>{category.param3}</td>
            <td>{category.param4}</td>
            <td>{category.attempt}</td>
            {/* <td>{startdate}</td>
            <td>{enddate}</td> */}
            <td>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  checked={category.apb}
                  onChange={() => {
                    this.apb_toggle(category);
                  }}
                />
                <span className="custom-toggle-slider rounded-circle " />
              </label>
            </td>
            <td>
              <button
                onClick={() => this.edit(category)}
                className="btn btn-sm btn-icon btn-2 btn-info"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-settings" />
                </span>
              </button>
            </td>
            <td>
              <button
                onClick={() => this.delete(category)}
                className="btn btn-sm btn-icon btn-2 btn-danger"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="search-data">
        {this.state.categories.length > 0 ? (
          <center>
            <h4 className="text-muted">ALL CATEGORIES</h4>
            <table className="table table-bordered">
              <thead className="bg-primary text-white text-center">
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Name</th>

                  <th scope="col">Type</th>
                  <th scope="col">Zone</th>
                  <th scope="col">Gate</th>

                  <th scope="col">Issuer ID</th>
                  <th scope="col">Product ID</th>
                  <th scope="col">Day ID</th>
                  <th scope="col">Attempt</th>
                  <th scope="col">APB</th>

                  {/* <th scope="col">Start</th>
                  <th scope="col">End</th> */}
                  <th scope="col">Update</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>

              <tbody>{allcategories}</tbody>
            </table>
            <div className="col-md-12">
              <button
                className="btn btn-icon btn-3 btn-danger"
                onClick={() => this.delete_all_categories()}
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
                <span className="btn-inner--text">Delete All Categories</span>
              </button>
            </div>
          </center>
        ) : null}
      </div>
    );
  }
}

export default Categories;
