import React, { Component } from "react";

import Barcodes from "./Barcodes";

export default class index extends Component {
  render() {
    return (
      <div>
        <div className="main-content">
          <Barcodes />
        </div>
      </div>
    );
  }
}
