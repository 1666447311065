import React, { useState, useEffect } from "react";

import axios from "axios";
import Swal from "sweetalert2";

export default function GateEdit(props) {
  // const [gate_details, setGate_details] = useState(props.gate_details);
  // const [category_details, setcategory_details] = useState(props.gate_details);
  const [selected_category_details, setSelected_category_details] = useState(
    {}
  );
  const { groupevents } = props;

  const gate_details = props.gate_details;

  // const [selected_category, setSelected_category] = useState(
  //   props.gate_details.categories
  // );

  const selected_category = props.gate_details.categories;

  const delete_confirm = (cat) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        delete_category(cat);
      }
    });
  };

  const delete_category = (cat) => {
    props.delete_cat(cat);
    let gate_details2 = gate_details;

    let new_array = [];

    let categorycheck = gate_details.categories;

    categorycheck.map((category) => {
      if (category === cat) {
        return false;
      } else {
        return new_array.push(category);
      }
    });

    gate_details2.categories = new_array;

    //props.delete_category(cat);
    update_gate(gate_details2, cat);
  };

  const update_local = (cat) => {
    let new_array = [];

    selected_category_details.map((category) => {
      if (category._id === cat) {
        return false;
      } else {
        return new_array.push(category);
      }
    });

    setSelected_category_details(new_array);
  };

  const update_gate = (gate_details2, cat) => {
    axios
      .patch(`/cms/gates/standard/${gate_details2._id}`, gate_details2)
      .then((response) => {
        if (response.data.success) {
          Swal.fire(`Success`, `Category Deleted`, "success");
          update_local(cat);
        } else {
          Swal.fire(`Error`, `Something Went Wrong`, "errror");
        }
      });
  };

  useEffect(() => {
    let request_data = {
      page: 1,
      id:
        groupevents.length > 0
          ? [gate_details.event_obj_id,...groupevents.map((event)=> event.event_obj_id)]
          : [gate_details.event_obj_id],
      limit: 10000,
      search: "",
    };

    axios
      .post(`/cms/v3/categories/getcategories`, request_data)
      .then((response) => {
        if (response.data) {
          // setcategory_details(response.data);

          let array = [];

          response.data.docs.map((category) => {
            let is_selected = selected_category.findIndex(
              (k) => k === category._id
            );

            if (is_selected !== Math.sign(-3)) {
              return array.push(category);
            } else {
              return false;
            }
          });
          setSelected_category_details(array);
        }
      });
  }, []);

  // console.log(category_details.length);

  return (
    <div>
      <div className="text-right">
        <button
          onClick={() => props.disable_edit()}
          className="btn btn-sm theme-dark  text-white"
        >
          <i className="fa fa-arrow-left mr-2"></i> Back
        </button>
      </div>
      {selected_category_details.length > 0 ? (
        <center style={{ width: "100%" }}>
          <table className="table table-bordered">
            <thead className="theme-dark  text-white text-center">
              <tr>
                <th scope="col">Sr</th>
                <th scope="col">{gate_details.gatename}</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>

            <tbody>
              {selected_category_details.map((cat, index) => {
                return (
                  <tr className="eventsearch text-center" key={index}>
                    <td>{index + 1}</td>
                    {props.eventtype === "acs-sg" ? (
                      <td>
                        {cat.category_name}| {cat.dayref} | {cat.session} |
                        {cat.param2} | {cat.param3} | {cat.param4} |{" "}
                        {cat.event_id_bms}
                      </td>
                    ) : (
                      <td>
                        {cat.category_name}| {cat.dayref} | {cat.session}
                      </td>
                    )}

                    <td>
                      <button
                        onClick={() => delete_confirm(cat._id)}
                        className="btn btn-sm btn-icon btn-2 btn-danger"
                        type="button"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-fat-remove" />
                        </span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </center>
      ) : null}
    </div>
  );
}
