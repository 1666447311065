import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
// import moment from "moment";

// import CountUp from "react-countup";
import axios from "axios";

export default function CurrentDevice({ ...props }) {
  const [total, setTotal] = useState(0);
  const [time_now, setTime_now] = useState("");
  // const [last, setLast] = useState(0);
  const [connected, setConnected] = useState(0);
  // const [event_obj_id, setEvent_obj_id] = useState();
  const event_obj_id = props.event_obj_id;
  const groupevents = props.groupevents;

  useEffect(() => {

    if(groupevents){
      currentdevice()
    }

    let interval = setInterval(() => {
      if( groupevents){
        currentdevice();
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [groupevents]);




  const currentdevice = async() => {
    let requestData = {
      event_obj_id:
        groupevents?.length > 0
          ? [event_obj_id,...props.groupevents.map((event)=> event.event_obj_id)]
          : [event_obj_id],
    };


    try {
      let deviceData = await axios
      .post("/cms/v3/dashboard/currentDevice", requestData)

      const {success,total,active,time_now}= deviceData.data

      if(success){
        setConnected(active);
        setTotal(total);
        setTime_now(time_now);
      }

    } catch (error) {
      console.error(error)
    }


  };


  return (
    <>
      <Card
        // className="bg-gradient-danger"
        className={
          total === connected ? "bg-gradient-success" : "bg-gradient-danger"
        }
      >
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h6 className=" ls-1 mb-1 text-white">Device Monitor</h6>

              <h5 className="h3 mb-0 text-white"> Overview</h5>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div style={{ height: "350px" }} className="chart">
            <div className="active-devices">
              <h1>Right now</h1>
              <span className="active-devices-count">
                {/* <CountUp start={last} end={connected} duration={10} /> */}
                {connected}
                <span className="active-outoff"> / {total}</span>
              </span>
              <center>
                <h5 style={{ color: "#fff", marginTop: "30px" }}>
                  Last Updated <br />
                  {time_now}
                </h5>
              </center>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
