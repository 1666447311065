import React, { Component } from "react";
import Whitelist from "./Whitelist";

class Index extends Component {
  render() {
    return (
      <div>
        <Whitelist eventdata={this.props.eventdata} />
      </div>
    );
  }
}

export default Index;
