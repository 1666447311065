import React, { Component } from "react";
import Day from "./Day";
class Index extends Component {
  render() {
    return (
      <div>
        <Day eventdata={this.props.eventdata} />
      </div>
    );
  }
}

export default Index;
