import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Categories from "./Categories";
import Swal from "sweetalert2";
// import DateTimePicker from "react-datetime-picker";

const moment = require("moment");

class CategoryStandard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      days: [],
      encryption: "yes",
      barcode_type: "ticket",
      category_name: "",
      category_type: "",
      category_tick_accred: "",
      match: "",
      create: "",
      categories: [],
      startdate: moment().format("DD-MM-YYYY HH:mm"),
      enddate: moment().format("DD-MM-YYYY HH:mm"),
      attempt: "1",
      new_category_array: [],
      categoryid: "",
      venuecode: "",
      category_code: "",
      area_code: "",
      ticket_type: "",
      zone: "",
      ticket_type_name: "",
      search: "",
      pagination: {},
      groupevents: [],
    };

    this.createcategory = this.createcategory.bind(this);
    this.onChange = this.onChange.bind(this);
    this.categorystandard = this.categorystandard.bind(this);
    this.updatecategory = this.updatecategory.bind(this);
    this.delete = this.delete.bind(this);
    this.getcategories = this.getcategories.bind(this);
  }

  setsearch = (search) => {
    this.setState({ search }, (s) => {
      search.length === 0 && this.getcategories();
    });
  };

  deleteall = () => {
    let event_obj_id = this.state.event_obj_id;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete All Categories!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .post(`/cms/category/standard/deleteall/${event_obj_id}`)
            .then((result) => {
              this.delete_match_ids(event_obj_id);

              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Category's Has Been Deleted.",
                "success"
              );
              return this.getcategories();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Something went wrong!", "", "error");
            });
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Categorys is safe :)",
            "error"
          );
        }
      });
  };

  delete_match_ids = (event_obj_id) => {
    axios
      .post(`/cms/days/standard/update_match_id/${event_obj_id}`)
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delete = (catid) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`/cms/category/standard/${catid._id}`)
            .then((result) => {
              this.deletegate(catid);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Category has been deleted.",
                "success"
              );
              return this.getcategories();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Something went wrong!", "", "error");
            });
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Category is safe :)",
            "error"
          );
        }
      });
  };

  deletegate = async (gatedata) => {
    await axios
      .get(`/cms/days/standard/${gatedata.event_obj_id}/${gatedata.session}`)
      .then((response) => {
        // eslint-disable-next-line
        Array.prototype.remove = function (elem) {
          var indexElement = this.findIndex((el) => el === elem);
          if (indexElement !== -1) this.splice(indexElement, 1);
          return this;
        };
        let getmatchdata = response.data.data.matchid;
        let newmatch = getmatchdata.remove(gatedata.category_matchid);

        let matchdata = {
          matchid: newmatch,
        };

        let patchurl = `/cms/days/standard/${gatedata.event_obj_id}/${gatedata.session}`;

        axios.patch(patchurl, matchdata).then();
      });
  };

  categorystandard() {
    this.props.categorystandard();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  startdate = (startdate) => this.setState({ startdate });
  enddate = (enddate) => this.setState({ enddate });

  updatecategory = (formValues) => {


    // return console.log(formValues)

    axios
      .patch("/cms/category/standard", formValues)
      .then((category) => {
        if (category) {
          Swal.fire("Category Updated Succesfully!", "", "success");
          this.getcategories();
        }
      })
      .catch((err) => {
        Swal.fire("Something went wrong", "", "error");
      });
  };

  alert_modal = (msg, status) => {
    return Swal.fire(msg, "", status);
  };

  async getcategories(page = 1) {
    const { event_obj_id, search, groupevents } = this.state;

    if (event_obj_id) {
      let request_data = {
        page,
        id:
          groupevents.length > 0
            ? [event_obj_id,...groupevents.map((event)=> event.event_obj_id)]
            : [event_obj_id],
        limit: 10,
        search,
      };

      await axios
        .post(`/cms/v3/categories/getcategories`, request_data)
        .then((response) => {
          if (response.data.docs) {
            let pagination_res = {
              total: response.data.total,
              page: response.data.page,
              pages: response.data.pages,
              hasNextPage: response.data.hasNextPage,
              hasPrevPage: response.data.hasPrevPage,
              limit: response.data.limit,
              pagedetails:
                response.data.page === 1
                  ? `${response.data.page} - ${response.data.docs.length} `
                  : `${
                      response.data.limit * response.data.page -
                      response.data.limit +
                      1
                    } - ${
                      response.data.limit * (response.data.page - 1) +
                      response.data.docs.length
                    }`,
            };

            this.setState({
              categories: response.data.docs,
              pagination: pagination_res,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  async createcategory(e) {
    e.preventDefault();
    let days = this.state.days;

    let category_name = this.state.category_name;
    let category_code = this.state.category_code;
    let ticket_type = this.state.ticket_type;
    let area_code = this.state.area_code;

    category_name = category_name.split(",");
    category_code = category_code.split(",");
    ticket_type = ticket_type.split(",");
    area_code = area_code.split(",");

    if (category_name.length !== category_code.length) {
      return this.alert_modal(
        "Category Name And Category ID Length Not Match!",
        "error"
      );
    }

    if (category_name.length !== ticket_type.length) {
      return this.alert_modal(
        "Category Name And Ticket Type Length Not Match!",
        "error"
      );
    }

    if (category_name.length !== area_code.length) {
      return this.alert_modal(
        "Category Name And Area Code Length Not Match!",
        "error"
      );
    }

    let new_category_array = [];

    category_name.forEach((category, index2) => {
      days.map(async (day, index) => {

        console.log(day)

        let category_code_value = category_code[index2];
        let ticket_type_value = ticket_type[index2];
        let area_code_value = area_code[index2];

        let param1_value = `${day.event_id_bms}${day.session}${category_code_value}${area_code_value}${ticket_type_value}`;

        if (category_code_value !== "") {
          let newcategory = {
            eventname: this.state.eventname,
            event_id_bms: day.event_id_bms,
            event_obj_id: this.state.event_obj_id,
            dayref: day.name,
            session: day.session,
            category_name: category,
            startdate: this.state.startdate,
            enddate: this.state.enddate,
            param1: param1_value,
            param2: category_code_value,
            param3: area_code_value,
            param4: ticket_type_value,
            barcode_type: this.state.barcode_type,
            encryption: this.state.encryption,
            attempt: this.state.attempt,
            zone: this.state.zone,
            day_id: day._id,
            ticket_type_name: this.state.ticket_type_name,
          };

          new_category_array.push(newcategory);
        }
      });

      //await this.createcat(newcategory, loopvalue);
    });

    this.setState({ new_category_array });
    this.createcat_bulk(new_category_array);
  }

  createcat_bulk = async (new_category_array) => {
    const { categories } = this.state;

    let newcategory = [];

    // console.log(new_category_array);
    // return console.log(categories);

    new_category_array.map((category) => {
      let ss = categories.find(
        (category_data) =>
          category_data.param2 === category.param2 &&
          category_data.session === category.session &&
          category_data.param3 === category.param3 &&
          category_data.param4 === category.param4
      );
      if (ss === undefined) {
        return newcategory.push(category);
      } else {
        return false;
      }
    });

    if (newcategory.length > 0) {
      let bulkcategory = {
        new_category_array: newcategory,
      };

      await axios
        .post("/cms/category/standard/category_bulk", bulkcategory)
        .then((response) => {
          if (response.data.success) {
            this.getcategories();
            // this.update_bulk_days();
            Swal.fire("Success!", "Category Created Succesfully!", "success");
          }
          //console.log(response);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Error!", err, "error");
        });
    } else {
      return Swal.fire("Success!", "Category Already Created!", "success");
    }
  };

  async componentDidMount() {
    const geteventid = sessionStorage.getItem("eventid");
    await axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: event.data.events._id,
        venuecode: event.data.events.venuecode,
        groupevents: event.data.events.groupevents,
        //days: event.data.events.sessiondetails
      });
    });
    await this.getdays();

    await this.getcategories();
  }

  getdays = async () => {
    let getday = {
      event_obj_id: this.state.event_obj_id,
    };

    await axios.post("/cms/days/standard/alldays", getday).then((daysdata) => {
      let days = daysdata.data.data;

      this.setState({ days: days });
    });
  };

  render() {
    const { eventname, event_id_bms, groupevents } = this.state;

    return (
      <Fragment>
        <center>
          {groupevents.length === 0 ? (
            <div className="container create-event">
              <h2 className="display-2 text-muted">Create Category </h2>
              <br />

              <div className="row ">
                <div className="col-md-1" />
                <div className="col-md-10">
                  <form onSubmit={this.createcategory}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group has-success ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">Event Name :</span>
                              </span>
                            </div>
                            <input
                              style={{ padding: "10px" }}
                              disabled
                              className="form-control form-control-alternative"
                              placeholder={eventname}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group has-success ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">Event ID : </span>
                              </span>
                            </div>
                            <input
                              style={{ padding: "10px" }}
                              disabled
                              className="form-control form-control-alternative"
                              placeholder={event_id_bms}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Category Name :
                                </span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="category_name"
                              style={{ padding: "10px" }}
                              className="form-control form-control-alternative"
                              placeholder="Please Enter Category Name"
                              type="text"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Category Code :
                                </span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="category_code"
                              style={{ padding: "10px" }}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Enter 2 Digit Category Code"
                              type="text"
                              value={this.state.category_code}
                              required
                              // pattern="\d*"
                              // maxLength="10"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted"> Area Code :</span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="area_code"
                              style={{ padding: "10px" }}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Enter 2 Digit Area ID"
                              type="text"
                              value={this.state.area_code}
                              required
                              // pattern="\d*"
                              // maxLength="10"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Ticket Type :
                                </span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="ticket_type"
                              style={{ padding: "10px" }}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Enter 2 Digit Ticket Type"
                              type="text"
                              value={this.state.ticket_type}
                              required
                              // pattern="\d*"
                              // maxLength="10"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 offset-md-3 ">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Ticket Type Name :
                                </span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="ticket_type_name"
                              style={{ padding: "10px" }}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Enter Ticket Type Name"
                              type="text"
                              value={this.state.ticket_type_name}
                              // required
                              // pattern="\d*"
                              // maxLength="10"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <div className="form-group ">
                            <label
                              className="text-muted"
                              htmlFor="exampleFormControlSelect2"
                            >
                              Select Barcode Type
                            </label>
                            <select
                              onChange={this.onChange}
                              name="barcode_type"
                              className="custom-select my-1 mr-sm-2"
                              id="inlineFormCustomSelectPref"
                              required
                            >
                              <option value="ticket">Ticket</option>
                              {/* <option selected value="mticket">
                              M-Ticket
                            </option> */}
                              <option value="accred">Accred</option>
                              <option value="special">Special</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <div className="form-group ">
                            <label
                              className="text-muted"
                              htmlFor="exampleFormControlSelect2"
                            >
                              Attempt
                            </label>
                            <div className="input-group input-group-alternative mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text ">
                                  <span className="text-muted"> Attempt :</span>
                                </span>
                              </div>
                              <input
                                name="attempt"
                                style={{ padding: "10px" }}
                                onChange={this.onChange}
                                className="noscroll form-control form-control-alternative"
                                placeholder="No of Attempt's - 0 for unlimited"
                                type="number"
                                defaultValue={this.state.attempt}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted">
                                  {" "}
                                  Start Date :
                                </span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="startdate"
                              style={{ padding: "10px" }}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Position 9-10"
                              type="text"
                              value={this.state.startdate}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted"> End Date :</span>
                              </span>
                            </div>
                            <input
                              onChange={this.onChange}
                              name="enddate"
                              style={{ padding: "10px" }}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Position 9-10"
                              type="text"
                              value={this.state.enddate}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 offset-md-4">
                        <div className="form-group ">
                          <div className="form-group ">
                            <label
                              className="text-muted"
                              htmlFor="exampleFormControlSelect2"
                            >
                              Zone
                            </label>
                            <div className="input-group input-group-alternative mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text ">
                                  <span className="text-muted"> Zone :</span>
                                </span>
                              </div>
                              <input
                                name="zone"
                                style={{ padding: "10px" }}
                                onChange={this.onChange}
                                className="noscroll form-control form-control-alternative"
                                placeholder="Enter Zone Information"
                                type="text"
                                defaultValue={this.state.zone}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                      <br />
                      <br />
                      <hr />

                      <hr />
                    </div>

                    <button
                      className="btn btn-icon btn-3 bg-default"
                      type="submit"
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-bullet-list-67" />
                      </span>

                      <span className="btn-inner--text">Create Categories</span>
                    </button>
                  </form>
                </div>
                <div className="col-md-1" />
              </div>
            </div>
          ) : null}
          <Categories
            delete={this.delete}
            updatecategory={this.updatecategory}
            categorystandard={this.categorystandard}
            categories={this.state.categories}
            deleteall={this.deleteall}
            event={this.state}
            getcategories={this.getcategories}
            pagination={this.state.pagination}
            setsearch={this.setsearch}
          />
        </center>
      </Fragment>
    );
  }
}

export default CategoryStandard;
