import React, { Component } from "react";

import GateDashboard from "./Gatedashboard/GateDashboard";
import PickupDashboard from "./Pickupdashboard/Dashboard";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setevent } from '../../../../../helper/actions/eventActions'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtype: "",
    };
  }

  componentWillMount() {
    //let event_id = this.props.match.params.id;
    //this.props.setevent(eventid);


    let eventtype = sessionStorage.getItem("eventtype");
    let eventid = sessionStorage.getItem("eventid");

    this.props.setevent(eventid)

    this.setState({ eventtype });
  }

  render() {
    const { eventtype } = this.state;

    let Mainpage;
    if (eventtype === "pickup") {
      Mainpage = <PickupDashboard />;
    } else {
      Mainpage = <GateDashboard />;
    }
    return Mainpage;
  }
}


Dashboard.protoTypes = {
  setevent: PropTypes.func.isRequired,

};


export default connect(null, { setevent })(
  withRouter(Dashboard)
);
