import React, { useEffect, useState } from "react";
import Gatereports from "./Gatereports";
import Pickupreports from "./Pickupreports";

function Report() {
  const [eventtype, setEventtype] = useState("");

  useEffect(() => {
    let eventtype = sessionStorage.getItem("eventtype");
    setEventtype(eventtype);
  }, []);

  return <>{eventtype === "pickup" ? <Pickupreports /> : <Gatereports />}</>;
}

export default Report;
