import React, { Component } from "react";
import CategoryStandard from "./CategoryStandard";

class Categorys extends Component {
  render() {
    return (
      <center>
        <CategoryStandard eventdata={this.props.eventdata} />
      </center>
    );
  }
}

export default Categorys;
