import React, { Component } from "react";

import Navbar from "../navbar";
import Event from "./Event";
import Sync from './sync'

export default class index extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="main-content">
          <Event />
        </div>
        <Sync />
      </div>
    );
  }
}

