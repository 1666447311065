import React, { useState } from "react";
import Report9 from "./Report9";
import Report13 from "./Report13";
import Productstats from "./productstats";

export default function Report({ eventtype,groupevents }) {
  const [report, setReport] = useState("");

  let Mainpage;
  if (report === "report9") {
    Mainpage = <Report9 eventtype={eventtype} groupevents={groupevents} />;
  } else if (report === "report13") {
    Mainpage = <Report13 eventtype={eventtype} groupevents={groupevents} />;
  }else if (report === "products_stats") {
    Mainpage = <Productstats eventtype={eventtype} groupevents={groupevents} />;
  }

  return (
    // <div>
    //   <Report9 />

    // </div>
    <div className="container-fluid mt-4">
      <center>
        <div className="col-md-4">
          <div className="form-group ">
            <div className="form-group ">
              <select
                onChange={(e) => setReport(e.target.value)}
                name="barcode_type"
                className="custom-select my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                required
              >
                <option value="">Select Report Type... </option>
                <option value="report9">Ticket/Pass Usage/Outstanding</option>
                <option value="products_stats">Product Usage/Outstanding</option>
                <option value="report13">Hourly Flow At Gates</option>
              </select>
            </div>
          </div>
        </div>
      </center>
      {Mainpage}
    </div>
  );
}

