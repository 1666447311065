import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import GateZoneSelection from "./GateZoneSelection";

import axios from "axios";

export default function TotalOccupancy({ ...props }) {
  // const [time_now, setTime_now] = useState("");
  const event_obj_id = props.event_obj_id;
  const totalin=props.occupancy.total_in;
  const totaexit=props.occupancy.total_exit;
  const totaloc=totalin-totaexit

  const total_occupancy=totaloc<0?0:totaloc


  // const currentdevice = () => {
   
  //   axios
  //     .get(`/cms/dashboard/new/currentdevice/${event_obj_id}`)
  //     .then((response) => {
  //       const devicedata = response.data;

  //       setTime_now(devicedata.time_now);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   currentdevice();
  //   // eslint-disable-next-line

  //   const interval = setInterval(() => {
  //     currentdevice();
  //   }, 20000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <Card className="bg-gradient-success">
        <CardHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className="bg-transparent"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "70%",
            }}
          >
            <GateZoneSelection
              groupevents={props.groupevents}
              event_obj_id={event_obj_id}
              gateselected={props.gateselected}
            />
          </div>
        </CardHeader>
        <CardBody>
          <div style={{ height: "320px" }} className="chart">
            <div className="active-devices">
              <span
                style={{ color: "white", fontSize: "30px", fontWeight: "600" }}
              >
                Current Occupancy
              </span>
              <span className="active-devices-count">
                {total_occupancy}
              </span>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h4 className="text-white">Scanned In</h4>
                  <h4 className="text-white">{totalin}</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h4 className="text-white">Scanned Out</h4>
                  <h4 className="text-white">{totaexit}</h4>
                </div>
              </div>
              {/* <center>
                <h5 style={{ color: "#fff", marginTop: "30px" }}>
                  Last Updated <br />
                  {time_now}
                </h5>
              </center> */}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
