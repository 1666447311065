import React, { Component } from "react";

import Create from "./Create";

import { Redirect } from "react-router-dom";
class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventcreated: false,
      finish: false,
      event_obj_id: "",
    };

    this.eventcreated = this.eventcreated.bind(this);
    this.categorystandard = this.categorystandard.bind(this);
  }

  eventcreated(event_obj_id) {
    this.setState({ eventcreated: true, finish: true, event_obj_id });
  }

  categorystandard() {
    this.setState({ categorystandard: true });
    sessionStorage.setItem("categorycreated", true);
  }
  componentDidMount() {
    if (sessionStorage.getItem("eventid")) {
      this.setState({ eventcreated: true });
    }

    if (sessionStorage.getItem("categorycreated")) {
      this.setState({ categorystandard: true });
    }
  }

  render() {
    let outputdata;
    const { finish } = this.state;

    if (finish) {
      return <Redirect to={"/home"} />;
    }

    outputdata = <Create eventcreated={this.eventcreated} />;

    return <div>{outputdata}</div>;
  }
}

export default Content;
