import React, { Component } from "react";
import Blacklist from "./Blacklist";

class Index extends Component {
  render() {
    return (
      <div>
        <Blacklist eventdata={this.props.eventdata} />
      </div>
    );
  }
}

export default Index;
