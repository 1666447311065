import React, { Component } from "react";
// import Turnstile from "../../assets/img/turnstile.png";
// import Mobile from "../../assets/img/mobile.svg";
import Swal from "sweetalert2";

import axios from "axios";


const SERVER_URL= {
  INDIA_PROD:"https://acs.bms.bz",
  INDIA_SIT:"https://eventmanager-acs-sit.bms.bz",
  SG_DEV:"https://eventmanager.dev.bigtix.dev",
  SG_SIT:"https://eventmanager.sit.bigtix.dev",
  SG_UAT:"https://eventmanager.uat.bigtix.dev",
  SG_TRAINING:"https://eventmanager.training.bigtix.io",
  SG_STAGING:"https://eventmanager.stg.bigtix.io",
  SG_PROD:"https://eventmanager.bigtix.io",
}

export default class List extends Component {
  update = async (user) => {
    let User = false;
    let Admin = false;
    let SuperAdmin = false;
    if (user.access_type === "0") {
      SuperAdmin = true;
    } else if (user.access_type === "1") {
      Admin = true;
    } else {
      User = true;
    }

    let disabled = false;
    if (user.disabled === true) {
      disabled = true;
    }

    let access_url = user.access_url;

    let venue_access = user.venue_access;
    venue_access = venue_access.join();

    let India = false;
    let Singapore_DEV = false;
    let Singapore_SIT = false;
    let Singapore_UAT = false;
    let Singapore_TRAINING = false;
    let Singapore_STAGING = false;
    let Singapore = false;
    let India_SIT = false;

    switch (access_url) {
      case SERVER_URL.INDIA_SIT:
        India_SIT = true;
        break;
      case SERVER_URL.INDIA_PROD:
        India = true;
        break;
      case SERVER_URL.SG_DEV:
        Singapore_DEV = true;
        break;
      case SERVER_URL.SG_SIT:
        Singapore_SIT = true;
        break;
      case SERVER_URL.SG_UAT:
        Singapore_UAT = true;
        break;
      case SERVER_URL.SG_TRAINING:
        Singapore_TRAINING = true;
        break;
      case SERVER_URL.SG_STAGING:
        Singapore_STAGING = true;
        break;
      case SERVER_URL.SG_PROD:
        Singapore = true;
        break;

      default:
      // code block
    }

    const { value: formValues } = await Swal.fire({
      title: "Edit Device",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-12">
      <label for="devicetype">Disabled</label>
      <select class="custom-select custom-select-md" id="disabled">
      <option value="false" ${
        disabled === false ? "selected" : null
      }>No</option>
      <option value="true" ${disabled === true ? "selected" : null}>Yes</option>
     
    </select>
      </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-6">
  <label for="devicetype">Access</label>
  <select class="custom-select custom-select-md" id="access_type">
  <option value="2" ${User ? "selected" : null}>User</option>
  <option value="1" ${Admin ? "selected" : null}>Admin</option>
  <option value="0" ${SuperAdmin ? "selected" : null}>SuperAdmin</option>
</select>
  </div>
  <div class="form-group col-md-6">
  <label for="role">Role</label>
  <select class="custom-select custom-select-md" id="role">
  <option value="Validator" ${
    user.role === "Validator" ? "selected" : null
  }>Validator</option>
  <option value="Admin" ${
    user.role === "Admin" ? "selected" : null
  }>Admin</option>
  <option value="Superadmin" ${
    user.role === "Superadmin" ? "selected" : null
  }>Superadmin</option>
  <option value="Console" ${
    user.role === "Console" ? "selected" : null
  }>Console</option>
  <option value="Api" ${user.role === "Api" ? "selected" : null}>Api</option>
  
</select>
  </div>
</div>
  <div class="form-row">
  <div class="form-group col-md-6">
    <label for="deviceid">Name</label>
    <input value="${user.name}" type="text" class="form-control" id="name"
    placeholder="Please Enter Name">
  </div>
  <div class="form-group col-md-6">
    <label for="deviceid">Password</label>
    <input value="" type="password" class="form-control" id="password"
    placeholder="Please Enter Password">
  </div>
  <div class="form-group col-md-12">
    <label for="deviceid">Venue</label>
    <input value="${venue_access}" type="text" class="form-control" id="venue_access"
    placeholder="Keep Blank For All Access">
  </div>
</div>
<div class="form-row">
<div class="form-group col-md-12">
    <label for="deviceserial">Event Sync Server</label>
    <select class="custom-select custom-select-md" id="access_url">
    <option value=${SERVER_URL.INDIA_PROD} ${
      India ? "selected" : null
    }>India</option>
    <option value=${SERVER_URL.SG_PROD} ${
      Singapore ? "selected" : null
    }>Singapore</option>
    <option value=${SERVER_URL.INDIA_SIT} ${
      India_SIT ? "selected" : null
    }>India_SIT</option>
    <option value=${SERVER_URL.SG_DEV} ${
      Singapore_DEV ? "selected" : null
    }>Singapore_DEV</option>
    <option value=${SERVER_URL.SG_SIT} ${
      Singapore_SIT ? "selected" : null
    }>Singapore_SIT</option>
    <option value=${SERVER_URL.SG_UAT} ${
      Singapore_UAT ? "selected" : null
    }>Singapore_UAT</option>
    <option value=${SERVER_URL.SG_TRAINING} ${
      Singapore_TRAINING ? "selected" : null
    }>Singapore_TRAINING</option>
    <option value=${SERVER_URL.SG_STAGING} ${
      Singapore_STAGING ? "selected" : null
    }>Singapore_STAGING</option>
  
  </div>
  
</div>

    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          disabled: document.getElementById("disabled").value,
          access_type: document.getElementById("access_type").value,
          name: document.getElementById("name").value,
          role: document.getElementById("role").value,
          password: document.getElementById("password").value,
          venue_access: document.getElementById("venue_access").value,
          access_url: document.getElementById("access_url").value,
          id: user._id,
        };
      },
    });

    if (formValues) {
      console.log(formValues);
      this.updateuser(formValues);
    }
  };

  delete = (id, email) => {
    if (email === "darshan.asolkar@bookmyshow.com") {
      return Swal.fire(
        "You Can't Delete Darshanz",
        "Delete Request Sent to Darshan.asolkar@hotmail.com",
        "error"
      );
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios.delete(`/cms/users/${id}`).then((response) => {
          if (response.data.success) {
            Swal.fire("User Deleted Succesfully", "Success", "success");
            this.props.sync();
          } else {
            Swal.fire("Something went wrong", "Error", "error");
          }
        });
      }
    });
  };

  updateuser = (updateuser) => {
    if (updateuser.access_type === "0") {
      updateuser.admin = true;
      updateuser.superadmin = true;
    } else if (updateuser.access_type === "1") {
      updateuser.admin = true;
      updateuser.superadmin = false;
    } else {
      updateuser.admin = false;
      updateuser.superadmin = false;
    }

    if (updateuser.disabled === "false") {
      updateuser.disabled = false;
    } else {
      updateuser.disabled = true;
    }

    axios.post(`/cms/users/update_user_admin/`, updateuser).then((response) => {
      if (response.data.success) {
        Swal.fire("User Update Succesfully", "Success", "success");
        this.props.sync();
      } else {
        Swal.fire("Something went wrong", "Error", "error");
      }
    });
  };

  render() {
    let accesstype = this.props.user.access_type;

    if (accesstype === "0") {
      accesstype = "SuperAdmin";
    } else if (accesstype === "1") {
      accesstype = "Admin";
    } else {
      accesstype = "User";
    }
    return (
      <tr className="text-center">
        <td className="pl-2">{this.props.index + 1}</td>

        <td>{this.props.user.name}</td>
        <td>{this.props.user.email}</td>
        <td>{this.props.user.role}</td>
        <td>{this.props.user.disabled ? "True" : "False"}</td>
        <td>{this.props.user.attempt}</td>
        <td>{this.props.user.lastattempt}</td>
        <td>{this.props.user.access_url}</td>
        <td>{accesstype}</td>
        <td>
          <div className="col">
            <div
              href="#!"
              onClick={() => this.update(this.props.user)}
              className="btn btn-sm bg-yellow text-white"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-settings" />
              </span>
            </div>
          </div>
        </td>
        <td>
          <div className="col">
            <div
              href="#!"
              onClick={() => {
                if (
                  this.props.user.email === "darshan.asolkar@bookmyshow.com"
                ) {
                } else {
                  this.delete(this.props.user._id, this.props.user.email);
                }
              }}
              className="btn btn-sm bg-red text-white"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}
