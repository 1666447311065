// import Alternative from "views/pages/dashboards/Alternative.js";
// import Dashboard from "views/pages/dashboards/Dashboard.js";

// import Charts from "views/pages/Charts.js";
import Dashboard from "views/pages/application/view/dashboards/Dashboard";
// import Alternative from "views/pages/application/view/dashboards/Alternative";
// import GatesAnalytics from "views/pages/application/view/dashboards/Gatedashboard/GatesAnalytics";

import Days from "views/pages/application/view/days";
// import GroupDays from "views/pages/application/view/groupdays";
import Blacklist from "views/pages/application/view/blacklist";
// import Whitelist from "views/pages/application/view/whitelist";
import Gates from "views/pages/application/view/gates";
import Categories from "views/pages/application/view/categories";
// import Report from "views/pages/application/view/reports";
import Logs from "views/pages/application/view/Logs";
import Monitoring from "views/pages/application/view/monitoring";
import Configuration from "views/pages/application/view/configuration";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-info",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Configurations",
    icon: "ni ni-settings text-red",
    state: "examplesCollapse",
    views: [
      {
        path: "/days",
        name: "Sessions",
        miniName: "D",
        component: Days,
        layout: "/admin",
      },
      {
        path: "/categories",
        miniName: "C",
        name: "Categories",
        component: Categories,
        layout: "/admin",
      },
      {
        path: "/gates",
        name: "Gates",
        miniName: "G",
        component: Gates,
        layout: "/admin",
      },
      // {
      //   path: "/whitelist",
      //   miniName: "W",
      //   name: "Whitelist",
      //   component: Whitelist,
      //   layout: "/admin",
      // },
      {
        path: "/blacklist",
        miniName: "B",
        name: "Blacklist",
        component: Blacklist,
        layout: "/admin",
      },
      {
        path: "/configuration",
        miniName: "S",
        name: "Settings",
        component: Configuration,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/monitor",
    name: "Monitor",
    icon: "ni ni-controller text-yellow",
    component: Monitoring,
    layout: "/admin",
  },
  {
    path: "/logs",
    name: "Audit",
    icon: "ni ni-bullet-list-67 text-red",
    component: Logs,
    layout: "/admin",
  },
];

export default routes;
