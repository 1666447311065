import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment-timezone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changesession } from "../../../../../helper/actions/eventActions";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function SessionSelect({ changesession }) {
  const [selectedOption, setSelectedOption] = useState([]);
  const [sessions, setsessions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [defaultSession, setdefaultSession] = useState([]);
  const [selectedSessions, setselectedSessions] = useState([])
  const [alreadySetsessions, setalreadySetsessions] = useState([])


  useEffect(() => {
    // alert(selectedSessions.length)
    if (alreadySetsessions.length !== selectedSessions.length) {
      setstateSessions()
    }
  }, [selectedSessions])



  useEffect(() => {
    fetchdays();
    return () => {
      changesession(null);
      // setdefaultSession(null);
    };
  }, []);

  const setstateSessions = () => {

    console.log(selectedSessions)

    if (selectedSessions.length === 0 || selectedSessions[0]?.value === "all") {
      changesession(null);
      setalreadySetsessions([])
      // setselectedSessions(setselectedSessions);
    } else {
      let newsession_data = [];
      let newdaysession_data = [];



      selectedSessions.map((session) => {

        if (session.value === typeof ('array')) {
          let tempArray = session.value.map((day_id) => day_id)
          newdaysession_data.push(tempArray);
        } else {
          newdaysession_data.push(session.value);
        }
      });


      setalreadySetsessions([].concat(...newdaysession_data))
      changesession([].concat(...newdaysession_data));

    }
  }

  // const changeSession = (value) => {
  //   if (value.length === 0 || value[0]?.value === "all") {
  //     changesession(null);
  //     // setSelectedOption(value);
  //   } else {
  //     let newsession_data = [];

  //     value.map((session) => {
  //       if (session.value.includes(",")) {
  //         var array = session.value.split(",");
  //         return array.map((arr) => {
  //           return newsession_data.push(arr);
  //         });
  //       } else {
  //         return newsession_data.push(session.value);
  //       }
  //     });
  //     changesession(newsession_data);
  //     // setSelectedOption(value);
  //   }
  // };

  const fetchdays = () => {
    let eventid = sessionStorage.getItem("eventid");

    const server_country = process.env.COUNTRY;
    let timestamp_time = moment();
    let today;

    if (server_country === "sg") {
      today = timestamp_time.tz("Asia/Singapore").format("DD-MM-YYYY");
    } else {
      today = timestamp_time.tz("Asia/Kolkata").format("DD-MM-YYYY");
    }

    axios
      .get(`/cms/events/${eventid}`)
      .then((response) => {
        // console.log(response.data.events)
        const { groupSession, groupevents } = response.data.events;

        let post_groupevents_data;

        if (groupSession.length > 0) {
          let newsession = [];
          let todaysession = [];
          let todaysession_data = [];

          let x = 0;
          let allsession = true;

          groupSession.map((session_data) => {
            let session = {
              // value: session_data.sessions,
              value: session_data.session_obj_ids,
              label: `${session_data.name}`,
            };
            allsession = false;
            newsession.push(session);

            // console.log(session_data)

            if (session_data.dayrefenddate.includes(today)) {
              todaysession.push(session_data.session);
              todaysession_data.push(session);
            } 

            x = x + 1;

            

            if (x === groupSession.length) {
              if (todaysession.length > 0) {
                changesession(todaysession);
                setselectedSessions(todaysession_data)

                // setSelectedOption(todaysession_data);
                // setdefaultSession(todaysession);
              } else {
                // setSelectedOption([]);
                changesession(null);
                // setdefaultSession(null);
              }
            }
          });
          // console.log(newsession)
          setsessions(newsession);
          // setSelectedOption(newsession);
        } else {
          let get_days = {
            id:
              groupevents.length > 0
                ? [eventid, ...groupevents.map((event) => event.event_obj_id)]
                : [eventid],
            limit: 10000,
            page: 1,
            search: "",
          };
          axios
            .post("/cms/v3/sessions", get_days)
            .then((response) => {
              const days_data = response.data;

              if (days_data.docs.length > 0) {
                let newsession = [];
                let todaysession = [];
                let todaysession_data = [];

                let x = 0;
                let allsession = true;

                days_data.docs.map((session_data) => {
                  let session = {
                    value: session_data._id,
                    label: `${session_data.name} - ${session_data.session}`,
                  };
                  if (session_data.startday === today) {
                    todaysession.push(session_data.session);
                    todaysession_data.push(session);
                  } else {
                    allsession = false;
                    // alert('hello')
                  }
                  newsession.push(session);

                  x = x + 1;
                  if (x === days_data.docs.length) {
                    if (todaysession.length > 0) {
                      changesession(todaysession);
                      setselectedSessions(todaysession_data)
                    }
                  }
                });
                setsessions(newsession);

              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ zIndex: 10000, color: "gray" }}>
      <Autocomplete
        onClose={() => setstateSessions()}
        onChange={(event, value) => {
          // changeSession(value);
          setselectedSessions(value)

        }}
        freeSolo
        multiple
        id="checkboxes-tags-demo"
        // inputValue={"2"}
        options={sessions}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        searchText={"s"}
        value={selectedSessions}
        renderOption={(option, { selected }) => (
          //
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />

            {option.label}
          </React.Fragment>
        )}
        style={{ width: "100%", backgroundColor: "#fff" }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="false"
            variant="filled"
            label="Session List.."
            placeholder="Choose Multiple Session"
            fullWidth
          />
        )}
      />
    </div>
  );
}

SessionSelect.protoTypes = {
  auth: PropTypes.object.isRequired,
  changesession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eventdata: state.event.eventdata,
});
export default connect(mapStateToProps, { changesession })(
  withRouter(SessionSelect)
);
