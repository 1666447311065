import {
  TEST_DISPATCH,
  LOGIN_DISPATCH,
  GET_AUTH_ERROR,
  CHANGE_COLOR
} from "../actions/types";
const initialState = {
  isAuthenticated: false,
  user: {},
  auth_error: "",
  theme: 'dark',
  // 2A3244


};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEST_DISPATCH:
      return {
        ...state,
        user: action.payload
      };
    case GET_AUTH_ERROR:
      return {
        ...state,
        auth_error: action.payload.msg
      };
    case LOGIN_DISPATCH:
      let check = false;
      if (Object.keys(action.payload).length > 0) {
        check = true;
      }
      return {
        ...state,
        user: action.payload,
        isAuthenticated: check
      };

    case CHANGE_COLOR:
      if (state.theme === 'dark') {
        return {
          ...state,
          theme: 'light'
        };
      } else {
        return {
          ...state,
          theme: 'dark'
        };
      }

    default:
      return state;
  }
}
