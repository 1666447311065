import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Swal from "sweetalert2";
import Completed from "./Completed";

const moment = require("moment");

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventname: "",
      event_id_bms: "",
      emailaccess: false,
      emails: "",
      mobile: "",
      device_type: "",
      local_ip: "192.168.1.2",
      whitelist: false,
      country: "",
      venuecode: "",
      smsaccess: true,
      eventtype: "",
      sessiondetails: [],
      statesession: [],
      prevsession: [],
      proceed: false,
      configs: [],
      keys: [],
      ivs: [],
      category_creation: "null",
      startdate: moment().format("DD-MM-YYYY HH:mm"),
      enddate: moment().format("DD-MM-YYYY HH:mm"),
      start_date: "",
      end_date: "",
      dtcm2: false,
      mode: "",
      ticket_type: "",
      cloud_server_url: window.location.origin,
      validation: "default"
    };

    this.onChange = this.onChange.bind(this);
    this.addsession = this.addsession.bind(this);
    this.remove = this.remove.bind(this);
  }

  onChange(e) {
    let value = e.target.value;
    this.setState({ [e.target.name]: value });

    if (value === "open") {
      this.setState({
        whitelist: true,
        validation: 'whitelistonly',
        mode: "acs",
        ticket_type: "Access Control Dubai",
      });
    }
    if (value === "pickup") {
      this.setState({
        whitelist: true,
        mode: "pickup",
        ticket_type: "Pickup INDIA",
      });
    }
    if (value === "Standard") {
      this.setState({
        whitelist: false,
        mode: "acs",
        ticket_type: "Access Control",
      });
    }
    if (value === "acs-india") {
      this.setState({
        whitelist: false,
        mode: "acs",
        ticket_type: "Access Control INDIA",
      });
    }
    if (value === "acs-sg") {
      this.setState({
        whitelist: false,
        mode: "acs",
        ticket_type: "Access Control Singapore",
      });
    }
    if (value === "acs-sgp") {
      this.setState({
        whitelist: false,
        mode: "acs",
        ticket_type: "Access Control SGP",
      });
    }
    if (value === "dtcm2") {
      this.setState({
        category_creation: "dtcm",
        validation: 'whitelistonly',
        dtcm2: true,
        mode: "acs",
        ticket_type: "Access Control Dubai",
      });
    }
  }

  addsession(e) {
    e.preventDefault();

    this.setState({
      statesession: [...this.state.statesession, ""],
      proceed: true,
    });
  }

  async remove(index) {
    if (index === 1) {
      index = 0;
    }

    let statesession = this.state.statesession;
    statesession.splice(index, 1);
    await this.setState({ statesession: statesession });
    if (statesession.length === 0) {
      this.setState({ proceed: false, eventtype: "" });
    }
  }

  confirm_create_event = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "Are You Sure All Information Are Inserted Correctly!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Create It!",
    }).then((result) => {
      if (result.value) {
        this.create_event();
      }
    });
  };

  create_event = (e) => {
    let session_length = this.state.statesession;

    let prevsessiondetails = [];
    session_length.map(async (session, index) => {
      let dayrefname = document.getElementById(`dayrefname${index}`).value;
      let sesionid = document.getElementById(`dayrefsession${index}`).value;
      let event_id_bms = document.getElementById(`dayrefeventid${index}`).value;
      let dayrefstartdate = document.getElementById(`dayrefstartdate${index}`)
        .value;
      let dayrefenddate = document.getElementById(`dayrefenddate${index}`)
        .value;

      let dayrefvenue = document.getElementById(`dayrefvenue${index}`)
        .value;
      let newsessiondetails;

      let startday = dayrefstartdate.split(" ");
      let endday = dayrefenddate.split(" ");

      startday = startday[0];
      endday = endday[0];

      newsessiondetails = {
        event_id_bms: event_id_bms,
        name: dayrefname,
        dayrefname,
        dayrefsession: sesionid,
        dayrefeventid: event_id_bms,
        matchid: [],
        session: sesionid,
        iv: "",
        key: "",
        dtcm2: this.state.dtcm2,
        unique: `${event_id_bms}-${sesionid}-${dayrefvenue}-${this.state.mode}`,
        dayrefstartdate,
        dayrefenddate,
        startday,
        endday,
        venue_Code: dayrefvenue,
        eventtype: this.state.eventtype
      };

      prevsessiondetails.push(newsessiondetails);
    });

    // console.log(prevsessiondetails)

    this.setState({ sessiondetails: prevsessiondetails }, () => {
      this.save_new_event();
    });
  };

  save_new_event = () => {
 
    const { mobile, emails,sessiondetails } = this.state;

    if (mobile.length > 0) {
      this.setState({ smsaccess: true });
    }
    if (emails.length > 0) {
      this.setState({ emailaccess: true });
    }

    axios
      .post("/cms/events", this.state)
      .then((event) => {
        if(sessiondetails.length===0){
          Swal.fire("Event Created Succesfully!", "", "success");
        return this.props.eventcreated();
        }
        let event_obj_id = event.data._id;
        this.setState({ event_obj_id });
        this.save_days(event_obj_id);
      })
      .catch((err) => {
        //console.log(err);
        this.setState({ error: true });
        return Swal.fire("Error", err.response.data.msg, "error");
      });
  };

  save_days = async (event_obj_id) => {
    const { sessiondetails } = this.state;
    
    let dayarray = {
      sessions: sessiondetails,
      event_obj_id,
      event_id_bms: this.state.event_id_bms,
    };

    await axios
      .post("/cms/days/standard/add_alldays", dayarray)
      .then((response) => {
        let success = response.data.success;
        if (success) {
          //this.getmticket_new_configs();
          this.loading_modal();
          // return Swal.fire("Event Created Succesfully!", "", "success");
        } else {
          return Swal.fire("Error!", "Something Went Wrong", "error");
        }
      })
      .catch((err) => console.log(err));
  };



  loading_modal = () => {


    let timerInterval
    Swal.fire({
      title: "Fetching Event Details",
      html: "Time Remaining <b></b> seconds.",
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        sessionStorage.setItem("eventid", this.state.event_obj_id);
        Swal.fire("Event Created Succesfully!", "", "success");
        return this.props.eventcreated();
      }
    })

  };

  render() {
    const { eventtype } = this.state;
    let addday;
    let showaddday;
    if (eventtype !== "") {
      showaddday = true;
      addday = (
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-icon btn-3 btn-primary"
                onClick={this.addsession}
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>

                <span className="btn-inner--text">Add Session</span>
              </button>

              {this.state.statesession.length > 0 ? <button
                className="btn btn-icon btn-3 btn-danger"
                onClick={() => this.remove(1)}
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>

                <span className="btn-inner--text">Remove Session</span>
              </button> : null}

            </div>
          </div>
        </div>
      );
    } else {
      showaddday = false;
      addday = "";
    }

    let category_creation;

    if (eventtype === "open") {
      category_creation = (
        <div className="col-md-12">
          <div className="form-group ">
            <label className="text-muted" htmlFor="exampleFormControlSelect2">
              Select Category Type
            </label>
            <select
              onChange={this.onChange}
              name="category_creation"
              className="custom-select my-1 mr-sm-2"
              id="inlineFormCustomSelectPref"
              required
            >
              <option value="">Choose Event Type ....</option>
              <option value="dtcm">DTCM</option>
              <option value="dtcm2">Dubai Calender</option>

            </select>
          </div>
        </div>
      );
    } else {
      category_creation = "";
    }

    return (
      <Fragment>
        <center>
          <Completed event={true} />
          <div className="container create-event">
            <h2 className="display-2 text-muted">Create Event</h2>
            <br />

            <div className="row ">

              <div className="col-md-12">
                <form onSubmit={this.confirm_create_event}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Name :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="eventname"
                            className="form-control form-control-alternative"
                            placeholder="Type Event Name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="event_id_bms"
                            className="form-control form-control-alternative "
                            placeholder="Type Event ID"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Select Device Type
                          </label>
                          <select
                            onChange={this.onChange}
                            name="device_type"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                          >
                            <option value="">Choose Device Type...</option>
                            <option value="mobile">Mobiles</option>
                            <option value="turnstiles">Turnstiles</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Select Country
                          </label>
                          <select
                            onChange={this.onChange}
                            name="country"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                          >
                            <option value="">Choose Country...</option>
                            <option value="india">India</option>
                            <option value="singapore">Singapore</option>
                            <option value="malaysia">Malaysia</option>
                            <option value="indonesia">Indonesia</option>
                            <option value="westindies">Westindies</option>
                            <option value="uae">Dubai</option>
                            <option value="srilanka">Srilanka</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Emails :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="emails"
                            className="form-control form-control-alternative "
                            placeholder="Type Email's with commma separated if multiple"
                            type="text"
                            required
                          />
                          {/* <textarea
                            onChange={this.onChange}
                            name="emails"
                            className="form-control form-control-alternative"
                            rows="1"
                            placeholder="Type Email's with commma seperated if multiple"
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Mobiles :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="mobile"
                            className="form-control form-control-alternative "
                            placeholder="Type Numbers with commma separated if multiple"
                            type="text"
                            required
                          />
                          {/* <textarea
                            onChange={this.onChange}
                            name="mobile"
                            className="form-control form-control-alternative"
                            rows="1"
                            placeholder="Type Numbers with commma seperated if multiple "
                          /> */}
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div className="col-md-12">
                      <div className="form-group ">
                        <label
                          className="text-muted"
                          htmlFor="exampleFormControlSelect2"
                        >
                          Select Event Type
                        </label>
                        <select
                          onChange={this.onChange}
                          name="eventtype"
                          className="custom-select my-1 mr-sm-2"
                          id="inlineFormCustomSelectPref"
                          required
                        >
                          <option value="">Choose Event Type ....</option>
                          <option value="Standard">
                            Access Control - Skidata 22 Digit
                          </option>
                          <option value="acs-india">
                            Access Control - BTS Auto Generated
                          </option>
                          <option value="acs-sg">
                            Access Control - BigTix
                          </option>
                          <option value="acs-sgp">Access Control - Sistic</option>
                          <option value="open">
                            Access Control - DTCM UAE
                          </option>
                          {/* <option value="custom">
                            Access Control - Custom
                          </option> */}
                          <option value="pickup">
                            Box-Office - INDIA
                          </option>
                          {/* <option value="f&b">
                            F&B : Topup | Redemption | Sale
                          </option> */}
                        </select>
                      </div>
                    </div>

                    <hr />

                    {category_creation}

                    <div className="col-md-12">
                      <div className="form-group ">
                        <label
                          className="text-muted"
                          htmlFor="exampleFormControlSelect2"
                        >
                          {showaddday ? "Add Session/EventId" : null}
                        </label>
                        <span className="clearfix" />
                        <div className="container">
                          <div>
                            <br />
                            {addday}
                          </div>
                        </div>

                        <div className="col-md-12 ">
                          {this.state.statesession.map((session, index) => {
                            return (
                              <div key={index} className="">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <input
                                        // ref={input => (this.dayrefname = input)}
                                        id={`dayrefname${index}`}
                                        type="text"
                                        className="form-control"
                                        placeholder="Session Name"
                                        defaultValue={`Session ${index + 1}`}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <input
                                        id={`dayrefsession${index}`}
                                        type="text"
                                        className="form-control"
                                        placeholder="Session ID"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <input
                                        id={`dayrefeventid${index}`}
                                        type="text"
                                        className="form-control"
                                        placeholder="Event ID"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <input
                                        id={`dayrefvenue${index}`}
                                        type="text"
                                        className="form-control"
                                        placeholder="Venue Code"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <input
                                        id={`dayrefstartdate${index}`}
                                        defaultValue={this.state.startdate}
                                        type="text"
                                        className="form-control"
                                        placeholder="Start Date"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <input
                                        id={`dayrefenddate${index}`}
                                        defaultValue={this.state.startdate}
                                        type="text"
                                        className="form-control"
                                        placeholder="End Date"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                    // disabled={this.state.proceed ? false : true}
                  >
                    Create Event
                  </button>
                </form>
              </div>

            </div>
          </div>
        </center >
      </Fragment >
    );
  }
}

export default Create;
