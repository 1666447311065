import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Images from "../../images/images";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../helper/actions/authActions";
// import moment from 'moment'
import PaginationLink from "./Pagination";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      active: false,
      prevpage: 0,
      nextpage: 0,
      total: 0,
    };
  }

  searchclick = (event_id, eventtype) => {
    sessionStorage.setItem("eventid", event_id);
    sessionStorage.setItem("eventtype", eventtype);
  };

  componentWillReceiveProps(nexprops) {
    const search = {
      search: nexprops.search,
    };

    this.setState({ active: false, events: [] });
    if (nexprops.search === "") {
      return this.getall_event();
    } else {
      return this.search_event(search);
    }
  }

  componentDidMount() {
    this.getall_event();
    // this.getall_autosync_event();
  }

  search_event = (search) => {
    axios.post("/cms/events/search", search).then((res) => {
      this.setState({ events: res.data });
    });
  };

  fetchpage = (page) => {
    // alert('fetchpage ' + page)
    this.getall_event(page);
  };

  getall_event = (page) => {
    axios
      .get(`/cms/events/pagination?page=${page || 1}&&limit=5`)
      .then((res) => {
        if (res.data.results.length > 0) {
          this.setState({
            events: res.data.results,
            nextpage: res.data.next?.page,
            prevpage: res.data.previous?.page,
            total: res.data.total ? res.data.total + 1 : 0,
          });
        } else {
          this.setState({ events: [] });
        }
      });
  };

  getall_autosync_event = () => {
    axios.post("/cms/events/autosync").then((res) => {
      if (res.data.success) {
        this.setState({ events: res.data.events });
      } else {
        this.setState({ events: [] });
      }
    });
  };

  autosync = (id) => {
    const { events } = this.state;
    let newevent = [];

    events.map((event) => {
      if (event._id === id) {
        event.auto_sync = !event.auto_sync;

        let autosync_mode = {
          autosync: event.auto_sync,
          id: id,
          eventdata: event.eventdata,
        };

        // console.log(autosync_mode)

        return axios
          .post(`/cms/days/standard/autoupdate`, autosync_mode)
          .then((res) => {
            //console.log(res.data);
            return newevent.push(event);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return newevent.push(event);
      }
    });

    return this.setState({ events: newevent });
  };

  render() {
    const { user } = this.props.auth;

    const { events } = this.state;

    let allevent;
    if (this.state.active === false) {
      if (events.length > 0) {
        allevent = events.map((event, i) => {
          let eventtype;

          let eventype = event.eventtype.toUpperCase();
          let groupevents = event.groupevents;

          if (groupevents !== undefined && groupevents?.length > 0) {
            eventtype = <img height="30" src={Images.multievent} alt="" />;
          } else {
            eventype =
              eventype === "ACS-SG"
                ? "BigTix"
                : eventype === "ACS-INDIA"
                ? "BTS"
                : eventype === "STANDARD"
                ? "22 Digit"
                : eventype;

            if (event.eventtype === "pickup") {
              eventtype = <img height="40" src={Images.scanner} alt="" />;
            } else {
              eventtype = <img height="40" src={Images.ticketscanner} alt="" />;
            }
          }

          return (
            <tr className="eventsearch text-center" key={i}>
              <td>{i + 1}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{event.eventname}</td>
              <td>{event.event_id_bms}</td>
              <td>{eventype}</td>
              <td>{eventtype}</td>
              <td>{event.country.toUpperCase()}</td>
              <td>{event.createdtime}</td>
              <td>{event.createdby}</td>
              <td>
                <Link
                  onClick={() => this.searchclick(event._id, event.eventtype)}
                  to={`/admin`}
                >
                  <button
                    className="btn btn-md btn-icon btn-2 btn-default follow-button"
                    type="button"
                  >
                    <i className="ni ni-button-play" />
                  </button>
                </Link>
              </td>
            </tr>
          );
        });
      }
    } else if (this.state.active) {
      const { events } = this.state;

      if (events.length > 0) {
        allevent = events.map((event, i) => {
          let eventype = event.eventdata.eventtype.toUpperCase();

          eventype =
            eventype === "ACS-SG"
              ? "BigTix"
              : eventype === "ACS-INDIA"
              ? "BTS"
              : eventype === "STANDARD"
              ? "22 Digit"
              : eventype;

          return (
            <tr className="eventsearch text-center" key={i}>
              <td>{i + 1}</td>
              <td>{event.eventdata?.eventname}</td>
              <td>{event.event_id_bms}</td>
              <td>{event.name}</td>
              <td>{event.dayrefstartdate}</td>
              <td>{eventype}</td>
              <td>{event.lastsync}</td>
              <td>{event.session}</td>
              <td>{event.venue_Code}</td>

              <td>
                <Button
                  onClick={() => this.autosync(event._id)}
                  className={event.auto_sync ? "theme-dark text-white" : ""}
                >
                  <i className="ni ni-button-power" />
                </Button>
              </td>
            </tr>
          );
        });
      }
    }

    return (
      <div className="search-data">
        {user.admin ? (
          <div
            style={{
              display: "flex",
              marginBottom: 20,
              justifyContent: "center",
            }}
          >
            {/* <Button
              onClick={() => {
                this.setState({ active: true, events: [] });
                this.getall_autosync_event();
              }}
              size="lg"
              className={this.state.active ? "theme-dark  text-white" : ""}
            >
              Active Session
            </Button> */}
            {/* <Button
              onClick={() => {
                this.setState({ active: false, events: [] });
                this.getall_event();
              }}
              size="lg"
              className={
                this.state.active === false ? "theme-dark  text-white" : ""
              }
            >
              Recent Events
            </Button> */}
          </div>
        ) : (
          <h2 className="text-center">Recent Events</h2>
        )}

        <center className="search-data-result">
          <table className="table table-bordered ">
            {this.state.active ? (
              events.length > 0 ? (
                <thead className="theme-dark text-white text-center">
                  <tr>
                    <th scope="col">Sr.</th>
                    <th scope="col">Event Name</th>

                    <th scope="col">Event ID</th>
                    <th scope="col">Session Name</th>
                    <th scope="col">Event Date</th>
                    <th scope="col">Event Type</th>
                    <th scope="col">Last Sync</th>
                    <th scope="col">Session</th>
                    <th scope="col">Venue Code</th>
                    <th scope="col">Auto Sync</th>
                  </tr>
                </thead>
              ) : (
                <h2 className="text-center">No Records Found</h2>
              )
            ) : events.length > 0 ? (
              <thead className="theme-dark text-white text-center">
                <tr>
                  <th scope="col">Sr.</th>
                  <th scope="col">Event Name</th>
                  <th scope="col">Event ID</th>
                  <th scope="col">Event Type</th>
                  <th scope="col">Mode</th>
                  <th scope="col">Country</th>
                  <th scope="col">Event Created</th>
                  <th scope="col">Created By</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
            ) : (
              <h2 className="text-center">No Records Found</h2>
            )}

            <tbody>{allevent}</tbody>
          </table>
        </center>
        {!this.state.active && (
          <div style={{ alignSelf: "flex-end" }}>
            <PaginationLink
              prevpage={this.state.prevpage}
              nextpage={this.state.nextpage}
              fetchpage={this.fetchpage}
              total={this.state.total}
            />
          </div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Search);
