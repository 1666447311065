import React from "react";
// node.js library that concatenates classes (strings)
import PropTypes from "prop-types";

import "./style.css";
import axios from "axios";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

// custom components
import HomeCard from "../../../components/HomeCard.js";
import EntryGraph from "../../../components/EntryGraph";
import GateGraph from "../../../components/GateGraph";
import GateGraph2 from "../../../components/GateGraph2";
import CurrentDevice from "../../../components/CurrentDevice";

// import AnalyticsCard from "../../../components/AnalyticsCard";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      screen: "today",
      eventname: "",
      event_id_bms: "",
      groupevents:null
    };
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      event_obj_id: "",
      eventtype: "",
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };

  componentWillMount() {
    let event_obj_id = sessionStorage.eventid;
    let eventtype = sessionStorage.eventtype;


    if (event_obj_id === "") {
      this.props.history.push("/home");
    } else {
      this.setState({ event_obj_id, eventtype });
      this.getevent_data(event_obj_id);
    }
  }

  getevent_data = async (event_obj_id) => {
    await axios.get(`/cms/events/${event_obj_id}`).then((event) => {

      this.setState({
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        groupevents: event.data.events.groupevents

      });
    });
  };

  render() {
    const { event_obj_id, eventname, event_id_bms, screen,groupevents } = this.state;
    const { theme } = this.props
    return (
      <>
        <Container fluid
          className={theme.theme === 'light' ? 'theme-light' : 'theme-dark'}
        >
          <Row className="align-items-center py-4">
            <Col lg="6" xs="7">
              <h6 className="h2 text-white d-inline-block mb-0">Dashboard</h6>{" "}
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <Link to="/home">
                    <i className="fas fa-home" />
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="#overall" onClick={(e) => e.preventDefault()}>
                    {eventname.length > 40
            ? eventname.substring(0, 40) + '... '
            : eventname}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  {event_id_bms}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="text-right" lg="6" xs="5">
              <h5 className="text-white">Select Duration</h5>
              <Button
                // className="btn-neutral"
                className={screen === "today" ? "" : "btn-neutral"}
                color="primary"
                href="#today"
                onClick={() => this.setState({ screen: "today" })}
                size="sm"
              >
                Today
              </Button>
              <Button
                className={screen === "overall" ? "" : "btn-neutral"}
                color="primary"
                href="#overall"
                onClick={() => this.setState({ screen: "overall" })}
                size="sm"
              >
                All
              </Button>
            </Col>
          </Row>
        </Container>

        <HomeCard screen_data={screen} event_obj_id={event_obj_id} theme={theme} groupevents={groupevents} />

        <Container className="mt--6" fluid>
          <Row>
            <Col xl="8">
              <EntryGraph theme={theme} screen={screen} event_obj_id={event_obj_id} groupevents={groupevents} />
            </Col>

            <Col xl="4">
              {event_obj_id !== "" ? (
                <CurrentDevice theme={theme} event_obj_id={event_obj_id}  groupevents={groupevents} />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <GateGraph2 theme={theme} screen={screen} event_obj_id={event_obj_id}  groupevents={groupevents} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}


Dashboard.propTypes = {

  theme: PropTypes.oneOf(["dark", "light"]),
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  theme: state.auth
});

export default connect(mapStateToProps, {})(
  Dashboard
);

// export default Dashboard;
