import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";


import "./style.css";
import Button from "reactstrap/lib/Button";

class GroupeventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchEventID:"",
      groupevents: [],
      event_obj_id: "",
      zone:"",
      username: props.auth.user.name,
      pagination: {},
    };

    this.delete = this.delete.bind(this);
  }

  delete(groupevent) {
   this.props.delete(groupevent);
  }

 

  componentWillReceiveProps(nextprops) {
    return this.setState({
      groupevents: nextprops.groupevents,
      event_obj_id: nextprops.event_obj_id,
      pagination: nextprops.pagination,
    });
  }

  render() {
    if (this.state.finish) {
      return <Redirect to={"/dashboard"} />;
    }

    let groupevents;
    groupevents = this.state.groupevents.map((event, index) => {
      return (
        <tr className="eventsearch text-center" key={index}>
           <td>{index + 1}</td>
           <td>{event.eventname}</td>
          <td>{event.event_id_bms}</td>         
          <td>{event.zone}</td>         
          <td>
            <button
              onClick={() => this.delete(event)}
              className="btn btn-sm btn-icon btn-2 btn-danger"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div
        className="search-data"
        style={{ padding: 40, backgroundColor: "#fff" }}
      >
        <div
          style={{
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
         >
          <div>
          <input
          required
            type="text"
            placeholder="Input the event ID"
            onChange={(e) => this.setState({searchEventID:e.target.value})}
            style={{
              width: 400,
              height: 30,
              border: "1px solid #ccc",
              borderRadius: 2,
              alignSelf: "center",
              marginBottom: 15,
              borderTopColor: "#fff",
              borderLeftColor: "#fff",
              borderRightColor: "#fff",
              fontSize: 14,
            }}
          />
          </div>
          
           <div>
           <input
           required
            type="text"
            placeholder="Input the Zone Info"
            onChange={(e) => this.setState({zone:e.target.value})}
            style={{
              width: 400,
              height: 30,
              border: "1px solid #ccc",
              borderRadius: 2,
              alignSelf: "center",
              marginBottom: 15,
              borderTopColor: "#fff",
              borderLeftColor: "#fff",
              borderRightColor: "#fff",
              fontSize: 14,
            }}
          />
           </div>
          <div style={{ marginLeft: 10, marginBottom: 40 }}>
            <Button
              type="submit"
              onClick={() => this.props.setsearch(this.state.searchEventID,this.state.zone)}
              style={{ backgroundColor: "#192A4D", color: "#fff" }}
            >
              Submit
            </Button>
          </div>
        </div>
        {this.state.groupevents.length > 0 ? (
          <center>
            {/* <h4 className="text-muted">ALL Sessions</h4> */}
            <table className="table table-bordered">
              <thead className="theme-dark text-white text-center">
                <tr>
                   <th scope="col">Sr.</th> 
                  <th scope="col">Event Name</th>
                  <th scope="col">EventID</th>    
                  <th scope="col">Zone</th>    

                  <th scope="col">Delete</th>
                </tr>
              </thead>

              <tbody>{groupevents}</tbody>
            </table>
          </center>
        ) : null}

      </div>
    );
  }
}

GroupeventList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GroupeventList);
