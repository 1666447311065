import React, { Component } from "react";
import "./completed.css";
import create_image from "./create.svg";

class Completed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: false,
      category: false,
      gate: false,
    };
  }

  componentDidMount() {
    if (this.props) {
      let event = this.props.event;
      let category = this.props.category;
      let gate = this.props.gate;

      if (event === true) {
        this.setState({ event });
      }
      if (category === true) {
        this.setState({ category });
      }
      if (gate === true) {
        this.setState({ gate });
      }
    }
  }

  render() {
    return (
      <div className="container" style={{ margin: "20px" }}>
        <div className="process">
          <div className="process-row">
            <div className="process-step">
              <button
                type="button"
                className={
                  this.state.event
                    ? "btn bg-primary btn-circle btnstyle"
                    : "btn bg-white btn-circle btnstyle"
                }
                disabled="disabled"
              >
                <img height="40" src={create_image} alt="" />
              </button>
              <p>Create Event</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Completed;
