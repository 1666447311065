import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  chartOptions,
  parseOptions,
  // chartExample1,
  // chartExample_gatewise,
  // chartExample_gateentry,
} from "variables/TimeEntry.js";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Row,
} from "reactstrap";

import { Line } from "react-chartjs-2";
import classnames from "classnames";
import Chart from "chart.js";

import axios from "axios";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const labels_data = [
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];



const chartExample7 = {
  options: {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  },
};
const Child = React.memo(function EntryGraph2({ session, event_obj_id, theme, groupevents }) {

  let data_before = {
    labels: labels_data,
    datasets: [
      {
        label: "Entry",
        // backgroundColor: "#2ECE89",
        color: "#2ECE89",
        data: [],
        maxBarThickness: 30,
      },
      {
        label: "Exit",
        // backgroundColor: "#0679E4",
        color: "#0679E4",
        data: [],
        maxBarThickness: 30,
      },
    ],
  };

  const [activeNav, setactiveNav] = useState(1);

  const [barcode_type, setbarcode_type] = useState("ticket");
  const [data, setdata] = useState(data_before);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }



  useEffect(() => {
    let fetchData = fetch_entrygraph(session);

    return fetchData;
  }, [session, barcode_type]);

  

  const fetch_entrygraph = () => {


    let data = {
      event_obj_id:
        groupevents?.length > 0
          ? [event_obj_id, ...groupevents.map((event) => event.event_obj_id)]
          : [event_obj_id],
      barcode_type,
      session: session,
      type: barcode_type,
    };

    // console.log(data)

    if (session && barcode_type) {
      // alert(props.session)

      axios
        // .post(`/cms/dashboard/new/entrygraph2`, data)
        .post(`cms/v3/analytics/entry_graph`,data)
        .then((response) => {
          if (response.data.success) {
            // let array_data = response.data.report;
            // console.log(response.data)

            let entry = response.data.entry;
            let exit = response.data.exit;
            let timestamp_display = response.data.timestamp_display;
            setdata((prevState) => {
              return {
                labels: timestamp_display,
                datasets: [
                  {
                    label: "Entry ",
                    data: entry,
                    borderColor: "#2ECE89",
                    pointBackgroundColor: "#2ECE89",
                  },
                  {
                    label: "Exit ",
                    pointBackgroundColor: "#0679E4",
                    data: exit,
                    borderColor: "#0679E4",
                  },
                ],
              };
            });
          } else {
            setdata(data_before);
          }
        })
        .catch((err) => {
          console.log(err);
          setdata(data_before);
        });
    } else {
      setdata(data_before);
    }
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setactiveNav(index);
    setbarcode_type(index === 1 ? "ticket" : "accred");
  };


  return (
    <>
      <Card
        // className="bg-default"
        className={theme === "light" ? "bg-lighter2" : "bg-darker2"}
      >
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <span style={{ fontSize: 12 }}>Hourly Gate Entry Scanning</span>
              <br />
              <span style={{ fontSize: 10 }}>Overview</span>
            </div>
            <Nav className="justify-content-end mt-1" pills>
              <span>Entry</span>
              <span
                style={{
                  width: "20px",
                  height: "10px",
                  marginTop: "8px",
                  marginLeft: "5px",
                  backgroundColor: "#2FCE89",
                }}
              ></span>
              <span className="ml-2">Exit</span>
              <span
                style={{
                  width: "20px",
                  height: "10px",
                  marginTop: "8px",
                  marginLeft: "5px",
                }}
                className="bg-primary"
              ></span>
            </Nav>
            <div className="col">
              <Nav className="justify-content-end" pills>
                <NavItem className="mr-2 mr-md-0">
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 1,
                    })}
                    href="#pablo"
                    onClick={(e) => toggleNavs(e, 1)}
                  >
                    <span className="d-none d-md-block">Tickets</span>
                    <span className="d-md-none">T</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 2,
                    })}
                    data-toggle="tab"
                    href="#pablo"
                    onClick={(e) => toggleNavs(e, 2)}
                  >
                    <span className="d-none d-md-block">Accreds</span>
                    <span className="d-md-none">A</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Line
              data={data}
              options={chartExample7.options}
              id="chart-sales-dark"
              className="chart-canvas"
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
})

Child.propTypes = {
  screen: PropTypes.string,
  event_obj_id: PropTypes.string,
  session_selected: PropTypes.object,
};

const mapStateToProps = (state) => ({
  session: state.event.session,
  event_obj_id: state.event.eventdata._id,
  theme: state.auth.theme,
});
export default connect(mapStateToProps, null)(withRouter(Child));
