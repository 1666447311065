import React, { Component } from "react";
import List from "./List";
import Swal from "sweetalert2";

import axios from "axios";

export default class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      devices_backup: [],
    };
  }

  add = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Add Device",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-12">
      <label for="devicetype">Device Type</label>
      <select class="custom-select custom-select-md" id="devicetype">
      <option value="mobile" selected>Mobile</option>
      <option value="turnstile">Turnstile</option>
      <option value="server">Server</option>
    </select>
      </div>
  </div>
    <div class="form-row">
    <div class="form-group col-md-6">
      <label for="deviceid">Device ID</label>
      <input type="text" class="form-control" id="deviceid"
      placeholder="Please Enter Unique ID">
    </div>
    <div class="form-group col-md-6">
      <label for="deviceserial">Serial Number</label>
      <input type="text" class="form-control" id="deviceserial" 
       placeholder="Please Enter Unique ID">
    </div>
  </div>
  <div class="form-row">
  <div class="form-group col-md-12">
    <label for="macid">Mac Address</label>
    <input type="text" class="form-control" id="macid" 
     placeholder="Please Enter Unique ID">
  </div>
  
</div>

    
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          type: document.getElementById("devicetype").value,
          deviceid: document.getElementById("deviceid").value,
          famocoid: document.getElementById("deviceserial").value,
          macid: document.getElementById("macid").value,
        };
      },
    });

    if (formValues) {
      this.adddevice(formValues);
    }
  };

  sync = () => {
    this.syncdevice();
  };

  adddevice = (adddevice) => {
    axios
      .post("/cms/devices", adddevice)
      .then((response) => {
        if (response.data.device_id) {
          //Swal.fire("Device Added Succesfully", "Success", "success");
          this.syncdevice();
        } else {
          Swal.fire("Duplicate Device Found", "Error", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchdevice = () => {
    axios
      // .get("/cms/devices/syncdevice")
      .get('cms/v3/device/insert_devices')
      .then((data) => {
        if (data.data.success) {
          Swal.fire('Devices Added', "Success", "success");
          return this.syncdevice();
        } else {
          return Swal.fire(`${data.msg}`, "Error", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.syncdevice();
  }

  syncdevice = () => {
    axios.get("/cms/devices/?nextpage=0&limit=0").then((response) => {
      // console.log(response);

      let devicesdata = response.data.device;
      this.setState({ devices: devicesdata, devices_backup: devicesdata });
    });
  };

  filterdevice = (input) => {
    const { devices, devices_backup } = this.state;

    if (input.length > 0) {
      devices.map((device) => {
        if (
          device.device_id === input ||
          device.mac_id === input ||
          device.famoco_id === input
        ) {
          return this.setState({ devices: [device] });
        } else {
          return false;
        }
      });
    } else {
      this.setState({ devices: devices_backup });
    }
  };

  render() {
    let list;

    let devicelist = this.state.devices;

    list = devicelist.map((device, index) => {
      let typecheck = "";

      if (device.type === "mobile") {
        typecheck = "mobile";
      } else if (device.type === "turnstile") {
        typecheck = "turnstile";
      } else {
        typecheck = "server";
      }

      return (
        <List
          sync={this.sync}
          key={index}
          device={device}
          typecheck={typecheck}
          index={index}
        />
      );
    });

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="text-left mb-0">
                  Devices - {this.state.devices.length}{" "}
                </h3>
              </div>
              <div className="form-group has-success">
                <input
                  onChange={(e) => this.filterdevice(e.target.value)}
                  style={{ width: 350 }}
                  type="text"
                  id="device"
                  placeholder="Search Device By Mac Address or Device ID.."
                  className="form-control is-valid"
                />
              </div>
              <div className="col text-right">
                <button onClick={this.add} className="btn btn-md btn-success">
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                  </span>
                </button>
                <button
                  onClick={this.fetchdevice}
                  className="btn btn-md btn-info"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-money-coins" />
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "600px",
              display: "inline-block",
              width: "100%",
              overflow: "auto",
            }}
            className="table-responsive"
          >
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Sr.</th>
                  <th scope="col">Device</th>
                  <th scope="col">Device Type</th>
                  <th scope="col">Device ID</th>
                  <th scope="col">Mac ID</th>
                  <th scope="col">Serial Number</th>

                  <th scope="col">Update</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody id="myTable">{list}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
