import React, { Component } from "react";

import AcsGateEntry from "./Acs_gate_entry";
import Pickup from "./Pickup";
import Acsopen from "./Acs_open";
// import Fnb from "./fnb/index";
import Acsindia from "./Acs_india";
import Acssingapore from "./Acs_singapore";
import Acssgp from "./Acs_sgp";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtype: "",
    };
  }

  componentWillMount() {
    //let eventid = this.props.match.params.id;
    //this.props.setevent(eventid);

    let eventtype = sessionStorage.getItem("eventtype");
    this.setState({ eventtype });
  }

  render() {
    const { eventtype } = this.state;

    let Mainpage;
    if (eventtype === "pickup") {
      Mainpage = <Pickup />;
    } else if (eventtype === "Standard") {
      Mainpage = <AcsGateEntry />;
    } else if (eventtype === "acs-sg") {
      Mainpage = <Acssingapore />;
    } else if (eventtype === "acs-sgp") {
      Mainpage = <Acssgp />;
    } else if (eventtype === "acs-india") {
      Mainpage = <Acsindia />;
    } else {
      Mainpage = <Acsopen />;
    }

    return Mainpage;
  }
}

export default index;
