import React, { Component } from "react";
import Swal from "sweetalert2";
import BarcodeModal from "../BarcodeModal";
import Table from "./Table";

// import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

import axios from "axios";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      originalcategories: [],
      getcategories: false,
      barcode: "",
    };

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  update(formValues) {
    this.props.updatecategory(formValues);
  }

  componentWillReceiveProps(nextprops) {
    // console.log(nextprops.categories)
    let categories = nextprops.categories;
    this.setState({ categories, originalcategories: categories });
  }

  async edit(category) {
    const { value: formValues } = await Swal.fire({
      title: "Edit Category",
      html: `  
      <div class="form-row">
      <div class="form-group col-md-6">
        <label for="category">Category Name</label>
        <input type="text" class="form-control" id="category"
        value="${category.name}" placeholder="Please Enter Category Name">
      </div>
      <div class="form-group col-md-6">
        <label for="issuer">Category ID</label>
        <input type="text" class="form-control" id="category_sector" 
        value="${category.category_id}" placeholder="Please Enter Category ID">
      </div>
      <div class="form-group col-md-6 ">
      <label for="startdate">Ticket Type Name</label>
      <input type="text" class="form-control" id="ticket_type_name" 
      value="${category.ticket_type_name}" placeholder="Please Enter Ticket Type Name">
      </div>
      <div class="form-group col-md-6 ">
      <label for="zone">Zone</label>
      <input type="text" class="form-control" id="zone" 
      value="${category.zone}" placeholder="Please Enter Zone">
    </div>
    </div>
    <div class="form-row">
  <div class="form-group col-md-6">
    <label for="startdate">Start Date</label>
    <input type="text" class="form-control" id="startdate" 
    value="${category.start}" placeholder="Please Enter Start Date">
  </div>
  <div class="form-group col-md-6">
    <label for="enddate">End Date</label>
    <input type="text" class="form-control" id="enddate"
    value="${category.end}" placeholder="Please Enter End Date">
  </div>

  <div class="form-group col-md-12">
  <label for="barcode_type">Type</label>
  <div class="form-row">
  <select
  style={{backgroundColor:"blue"}}
  class="form-control"
  id="barcode_type"
  name="barcode_type"
  id="inlineFormCustomSelectPref"
  required>
  <option ${
    category.barcode_type === "ticket" ? "selected" : ""
  } value="ticket">Ticket</option>
  <option ${
    category.barcode_type === "accred" ? "selected" : ""
  } value="accred">Accred</option>
   <option ${
     category.barcode_type === "special" ? "selected" : ""
   } value="special">Special</option>
  </select>
  </div>
</div>
      
    `,
      focusConfirm: true,
      preConfirm: () => {
        return {
          category_name: document.getElementById("category").value,
          category_sector: document.getElementById("category_sector").value,
          ticket_type_name: document.getElementById("ticket_type_name").value,
          zone: document.getElementById("zone").value,
          startdate: document.getElementById("startdate").value,
          enddate: document.getElementById("enddate").value,
          barcode_type: document.getElementById("barcode_type").value,
          category_id: category.id,
          param1: `${category.event_id_bms}${category.param4}${
            category.session
          }${document.getElementById("category_sector").value}`,
        };
      },
    });

    if (formValues) {
      this.update(formValues);
    }
  }
  delete(catid) {
    this.props.delete(catid);
  }

  is_disable_toggle = (category) => {
    const { categories } = this.state;

    let new_cat = [];

    let obj = {
      _id: category.id,
      is_disable: !category.is_disable,
    };

    // console.log(category)

    axios
      .post(`/cms/category/standard/is_disable_toggle`, obj)
      .then((respos) => {
        if (respos.data.success) {
          categories.map((cat) => {
            if (cat.id === category.id) {
              cat.is_disable = !category.is_disable;
            }

            return new_cat.push(cat);
          });

          this.setState({ categories: new_cat });
        } else {
          alert("something went wrong");
        }
      });
  };

  apb_toggle = (category) => {
    const { categories } = this.state;

    let new_cat = [];

    let obj = {
      _id: category.id,
      apb: !category.apb,
    };

    // console.log(category)

    axios.post(`/cms/category/standard/apb_toggle`, obj).then((respos) => {
      if (respos.data.success) {
        categories.map((cat) => {
          if (cat.id === category.id) {
            cat.apb = !category.apb;
          }

          return new_cat.push(cat);
        });

        this.setState({ categories: new_cat });
      } else {
        alert("something went wrong");
      }
    });
  };

  delete_all_categories = () => {
    this.props.deleteall();
  };

  closeModal = () => {
    this.setState({ barcode: "" });
  };

  generate = (sample_barcode) => {
    this.setState({ barcode: sample_barcode });
  };

  search = (text) => {
    const { originalcategories } = this.state;
    let searchcategories = [];

    if (text === "") {
      this.setState({ categories: originalcategories });
    } else {
      originalcategories.map((cat) => {
        if (
          cat.session === text ||
          cat.name === text ||
          cat.day === text ||
          cat.category_id === text
        ) {
          return searchcategories.push(cat);
        } else {
          return false;
        }
      });
      if (searchcategories.length === 0) {
        this.setState({ categories: originalcategories });
      } else {
        this.setState({ categories: searchcategories });
      }
    }
  };

  render() {
    const { categories } = this.state;

    return (
      <div className="">
        {this.state.barcode ? (
          <BarcodeModal
            closeModal={this.closeModal}
            barcode={this.state.barcode}
          />
        ) : null}
        <center className="col-md-12">
          <Table
            categories={categories}
            deletecat={this.delete}
            edit={this.edit}
            generate={this.generate}
            apb_toggle={this.apb_toggle}
            search={this.search}
            is_disable_toggle={this.is_disable_toggle}
            getcategories={this.props.getcategories}
            pagination={this.props.pagination}
            setsearch={this.props.setsearch}
          />
        </center>
      </div>
    );
  }
}

export default Categories;
