import React, { Component, Fragment } from "react";
import "./style.css";
import axios from "axios";
import Categories from "./Categories";
import Swal from "sweetalert2";
// import DateTimePicker from "react-datetime-picker";

const moment = require("moment");

class CategoryStandard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventname: "",
      event_id_bms: "",
      event_obj_id: "",
      days: [],
      encryption: "default",
      barcode_type: "",
      category_name: "",
      category_issuer: "",
      category_location: "",
      category_matchid: "",
      category_sector: "",
      category_discount: "",
      category_type: "",
      category_tick_accred: "",
      match: "",
      create: "",
      categories: [],
      startdate: moment().format("DD-MM-YYYY HH:mm"),
      enddate: moment().format("DD-MM-YYYY HH:mm"),
      attempt: "1",
      new_category_array: [],
      zone: "",
    };

    this.createcategory = this.createcategory.bind(this);
    this.onChange = this.onChange.bind(this);
    this.categorystandard = this.categorystandard.bind(this);
    this.updatecategory = this.updatecategory.bind(this);
    this.delete = this.delete.bind(this);
  }

  deleteall = () => {
    let event_obj_id = this.state.event_obj_id;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete All Categories!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .post(`/cms/category/standard/deleteall/${event_obj_id}`)
            .then((result) => {
              this.delete_match_ids(event_obj_id);

              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Category's Has Been Deleted.",
                "success"
              );
              return this.getcategories();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Something went wrong!", "", "error");
            });
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Categorys is safe :)",
            "error"
          );
        }
      });
  };

  delete_match_ids = (event_obj_id) => {
    axios
      .post(`/cms/days/standard/update_match_id/${event_obj_id}`)
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delete = (catid) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You Want To Delete!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`/cms/category/standard/${catid._id}`)
            .then((result) => {
              this.deletegate(catid);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Category has been deleted.",
                "success"
              );
              return this.getcategories();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Something went wrong!", "", "error");
            });
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Category is safe :)",
            "error"
          );
        }
      });
  };

  deletegate = async (gatedata) => {
    await axios
      .get(`/cms/days/standard/${gatedata.event_obj_id}/${gatedata.session}`)
      .then((response) => {
        // eslint-disable-next-line
        Array.prototype.remove = function (elem) {
          var indexElement = this.findIndex((el) => el === elem);
          if (indexElement !== -1) this.splice(indexElement, 1);
          return this;
        };
        let getmatchdata = response.data.data.matchid;
        let newmatch = getmatchdata.remove(gatedata.category_matchid);

        let matchdata = {
          matchid: newmatch,
        };

        let patchurl = `/cms/days/standard/${gatedata.event_obj_id}/${gatedata.session}`;

        axios.patch(patchurl, matchdata).then();
      });
  };

  categorystandard() {
    this.props.categorystandard();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  startdate = (startdate) => this.setState({ startdate });
  enddate = (enddate) => this.setState({ enddate });

  updatecategory = (formValues) => {
    axios
      .patch("/cms/category/standard", formValues)
      .then((category) => {
        if (category) {
          Swal.fire("Category Updated Succesfully!", "", "success");
          this.getcategories();
        }
      })
      .catch((err) => {
        Swal.fire("Something went wrong", "", "error");
      });
  };

  alert_modal = (msg, status) => {
    return Swal.fire(msg, "", status);
  };

  async getcategories() {
    await axios
      .get(`/cms/category/standard/getall/${this.state.event_obj_id}`)
      .then((response) => {
        if (response) {
          this.setState({ categories: response.data });
        }
      })
      .catch((err) => console.log(err));
  }

  async createcategory(e) {
    e.preventDefault();
    let days = this.state.days;

    let category_name = this.state.category_name;
    let category_sector = this.state.category_sector;
    let category_discount = this.state.category_discount;
    let category_type = this.state.category_type;

    category_name = category_name.split(",");
    category_sector = category_sector.split(",");
    category_discount = category_discount.split(",");
    category_type = category_type.split(",");

    if (category_name.length !== category_sector.length) {
      return this.alert_modal(
        "Category Name Or Sector Inserted Incorrectly!",
        "error"
      );
    }

    if (category_name.length !== category_discount.length) {
      return this.alert_modal(
        "Category Name Or Discount Value Inserted Incorrectly!",
        "error"
      );
    }

    if (category_name.length !== category_type.length) {
      return this.alert_modal(
        "Category Name Or Category Type Inserted Incorrectly!",
        "error"
      );
    }

    let new_category_array = [];

    category_name.forEach((category, index2) => {
      days.map(async (day, index) => {
        let category_matchid = document.getElementById(`matchid${index}`).value;

        const {
          category_issuer,
          category_location,
          category_tick_accred,
        } = this.state;

        if (category_matchid !== "") {
          let newcategory = {
            eventname: this.state.eventname,
            category_matchid: document.getElementById(`matchid${index}`).value,
            event_id_bms: this.state.event_id_bms,
            event_obj_id: this.state.event_obj_id,
            dayref: day.name,
            session: day.session,
            category_name: category,
            category_issuer: this.state.category_issuer,
            category_location: this.state.category_location,
            category_sector: category_sector[index2],
            category_discount: category_discount[index2],
            category_type: category_type[index2],
            category_tick_accred: this.state.category_tick_accred,
            startdate: this.state.startdate,
            enddate: this.state.enddate,
            barcode_type: this.state.barcode_type,
            encryption: this.state.encryption,
            attempt: this.state.attempt,
            param1: `${this.state.category_issuer}${this.state.category_location}${category_matchid}${category_sector[index2]}${category_discount[index2]}${category_type[index2]}${this.state.category_tick_accred}`,
            zone: this.state.zone,
          };

          new_category_array.push(newcategory);
        }

        //await this.createcat(newcategory, loopvalue);
      });
    });

    this.setState({ new_category_array });
    this.createcat_bulk(new_category_array);
  }

  createcat_bulk = async (new_category_array) => {
    let bulkcategory = {
      new_category_array,
    };

    await axios
      .post("/cms/category/standard/category_bulk", bulkcategory)
      .then((response) => {
        if (response.data.success) {
          this.getcategories();
          this.update_bulk_days();
          Swal.fire("Success!", "Category Created Succesfully!", "success");
        }
        //console.log(response);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Error!", err, "error");
      });
  };

  update_bulk_days = () => {
    const { days, event_id_bms, event_obj_id } = this.state;

    days.forEach((day, index) => {
      let day_data = {
        event_id_bms,
        category_matchid: document.getElementById(`matchid${index}`).value,
        name: day.name,
        session: day.session,
        event_obj_id,
      };

      this.update_day(day_data);
    });
  };

  update_day = async (day_data) => {
    await axios
      .get(`/cms/days/standard/${day_data.event_obj_id}/${day_data.session}`)
      .then((matchdataresponse) => {
        if (matchdataresponse) {
          let getmatchdata = matchdataresponse.data.data.matchid;

          getmatchdata.push(day_data.category_matchid);

          let matchdata = {
            matchid: getmatchdata,
          };

          let patchurl = `/cms/days/standard/${day_data.event_obj_id}/${day_data.session}`;

          axios.patch(patchurl, matchdata).then();
        }
      });
  };

  async componentDidMount() {
    const geteventid = sessionStorage.getItem("eventid");
    await axios.get(`/cms/events/${geteventid}`).then((event) => {
      this.setState({
        eventname: event.data.events.eventname,
        event_id_bms: event.data.events.event_id_bms,
        event_obj_id: event.data.events._id,
        //days: event.data.events.sessiondetails
      });
    });
    await this.getdays();

    await this.getcategories();
  }

  getdays = async () => {
    let getday = {
      event_obj_id: this.state.event_obj_id,
    };

    await axios.post("/cms/days/standard/alldays", getday).then((daysdata) => {
      let days = daysdata.data.data;

      this.setState({ days: days });
    });
  };

  render() {
    const { eventname, event_id_bms, days } = this.state;
    let sessiondata;

    sessiondata = days.map(function (day, i) {
      let mticket;
      if (day.iv) {
        mticket = true;
      } else {
        mticket = false;
      }
      return (
        <div key={i} className="container row">
          <div className="col-md-6">
            <div className="input-group input-group-alternative mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text ">
                  <span className="text-muted"> {day.name} :</span>
                </span>
              </div>
              <input
                id={`matchid${i}`}
                style={{ padding: "10px" }}
                // onChange={this.onChange}
                className="noscroll form-control form-control-alternative"
                placeholder="Please Enter Match ID's "
                type="text"
                pattern="\d*"
                maxLength="4"
              />
            </div>
          </div>
          <div className="col-md-6">
            {mticket ? (
              <div className="form-group has-success ">
                <div className="input-group input-group-alternative mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text ">
                      <span className="text-muted">M-Ticket :</span>
                    </span>
                  </div>
                  <input
                    style={{ padding: "10px" }}
                    disabled
                    className="form-control form-control-alternative"
                    placeholder="ACTIVE"
                    type="text"
                  />
                </div>
              </div>
            ) : (
                <div className="form-group has-danger ">
                  <div className="input-group input-group-alternative mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text ">
                        <span className="text-muted">M-Ticket :</span>
                      </span>
                    </div>
                    <input
                      style={{ padding: "10px" }}
                      disabled
                      className="form-control form-control-alternative"
                      placeholder="INACTIVE"
                      type="text"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <center>
          <div className="container create-event">
            <h2 className="display-2 text-muted">Create Category </h2>
            <br />

            <div className="row ">
              <div className="col-md-1" />
              <div className="col-md-10">
                <form onSubmit={this.createcategory}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event Name :</span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={eventname}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-success ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Event ID : </span>
                            </span>
                          </div>
                          <input
                            style={{ padding: "10px" }}
                            disabled
                            className="form-control form-control-alternative"
                            placeholder={event_id_bms}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">
                                {" "}
                                Category Name :
                              </span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_name"
                            style={{ padding: "10px" }}
                            className="form-control form-control-alternative"
                            placeholder="Please Enter Category Name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Attempt :</span>
                            </span>
                          </div>
                          <input
                            name="attempt"
                            style={{ padding: "10px" }}
                            onChange={this.onChange}
                            className="noscroll form-control form-control-alternative"
                            placeholder="No of Attempt's - 0 for unlimited"
                            type="number"
                            defaultValue={this.state.attempt}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Select Barcode Type
                          </label>
                          <select
                            onChange={this.onChange}
                            name="barcode_type"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                          >
                            <option value="">Choose Barcode Types...</option>
                            <option value="ticket">Ticket</option>
                            {/* <option value="mticket">M-Ticket</option> */}
                            <option value="accred">Accred</option>
                            <option value="special">Special</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Select Encryption Mode
                          </label>
                          <select
                            onChange={this.onChange}
                            value="default"
                            name="encryption"
                            className="custom-select my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            required
                            disabled
                          >
                            <option value="">Choose Encryption Mode...</option>
                            <option value="default">default</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Start Date :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="startdate"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 9-10"
                            type="text"
                            value={this.state.startdate}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> End Date :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="enddate"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 9-10"
                            type="text"
                            value={this.state.enddate}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <hr />

                    {sessiondata}

                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Issuer ID :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_issuer"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 1-2"
                            type="number"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Location ID :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_location"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 3-4"
                            type="number"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Sector ID :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_sector"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 9-10"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> Discount :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_discount"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 11-12"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted">Ticket Type :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_type"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 13-14"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="input-group input-group-alternative mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">
                              <span className="text-muted"> T-0 / A-1 :</span>
                            </span>
                          </div>
                          <input
                            onChange={this.onChange}
                            name="category_tick_accred"
                            style={{ padding: "10px" }}
                            className="noscroll form-control form-control-alternative"
                            placeholder="Position 15"
                            type="number"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 offset-md-4">
                      <div className="form-group ">
                        <div className="form-group ">
                          <label
                            className="text-muted"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Zone
                          </label>
                          <div className="input-group input-group-alternative mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text ">
                                <span className="text-muted"> Zone :</span>
                              </span>
                            </div>
                            <input
                              name="zone"
                              style={{ padding: "10px" }}
                              onChange={this.onChange}
                              className="noscroll form-control form-control-alternative"
                              placeholder="Enter Zone Information"
                              type="text"
                              defaultValue={this.state.zone}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>

                  <button
                    className="btn btn-icon btn-3 btn-primary"
                    type="submit"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-bullet-list-67" />
                    </span>

                    <span className="btn-inner--text">Create Categories</span>
                  </button>
                </form>
              </div>
              <div className="col-md-1" />
            </div>
          </div>

        </center>
        <div className="">
          <Categories
            delete={this.delete}
            updatecategory={this.updatecategory}
            categorystandard={this.categorystandard}
            categories={this.state.categories}
            deleteall={this.deleteall}
          />
        </div>
      </Fragment>
    );
  }
}

export default CategoryStandard;
