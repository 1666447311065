import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "./monitoringstyle.css";

import Mobile_IMG from "../../../../images/mobile.svg";
import Laptop_IMG from "../../../../images/laptop.png";

export default class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventmonitors: [],
      event_obj_id: "",
      devicename: [],
      timenow: "",
      config_ver: 0,
    };
    // this.getNameById = this.getNameById.bind(this);
  }

  geteventmonitors = async (event_obj_id) => {
    await axios
      .get(`/cms/events/eventmonitor/${event_obj_id}`)
      .then((response) => {
        let eventmonitors = [];

        let device = response.data.data;
        device.forEach((dev, index) => {
          let battery = dev.battery;
          let pending = dev.pending;
          let issued = dev.issued;
          let issued_pend = issued - pending;

          if (battery === "") {
            battery = 100;
          }
          let mac = dev.mac_id.toLowerCase();
          axios.get(`/cms/devices/getdevice/${mac}`).then((response2) => {
            if (response2.data.success) {
              let newdata = {
                battery: battery,
                lastupdated: dev.lastupdated,
                mac_id: response2.data.device.device_id,
                scans: dev.scans,
                version: dev.version,
                pending: dev.pending,
                inout: dev.inout,
                emergency: dev.emergency,
                server: dev.server,
                issued: issued_pend,
              };
              eventmonitors.push(newdata);
              this.setState({ eventmonitors: eventmonitors });
            } else {
              let newdata = {
                battery: battery,
                lastupdated: dev.lastupdated,
                mac_id: dev.mac_id.toLowerCase(),
                scans: dev.scans,
                version: dev.version,
                pending: dev.pending,
                inout: dev.inout,
                emergency: dev.emergency,
                server: dev.server,
                issued: issued_pend,
              };
              eventmonitors.push(newdata);
              this.setState({ eventmonitors: eventmonitors });
            }
          });
        });
      });
  };

  componentDidMount() {
    let event_obj_id = sessionStorage.getItem("eventid");
    this.geteventmonitors(event_obj_id);
    this.getmonitor_data(event_obj_id);
    // this.fetch_event(event_obj_id);
  }

  componentWillUnmount() {
    clearInterval(this.getmonitor);
  }

  getmonitor_data = (event_obj_id) => {
    this.getmonitor = setInterval(() => {
      this.geteventmonitors(event_obj_id);
    }, 9000);
  };

  fetch_event = async (event_obj_id) => {
    await axios.get(`/cms/events/${event_obj_id}`).then((response) => {
      this.setState({ config_ver: response.data.events.config_ver });
    });
  };

  render() {
    let eventmonitors = this.state.eventmonitors;

    const { timenow, config_ver } = this.state;

    let eventmonitorsdata = eventmonitors.map((device, index) => {
      let start = moment(device.lastupdated, "DD-MM-YYYY HH:mm:ss").tz(
        this.state.time_zone
      );
      let end = moment(timenow, "DD-MM-YYYY HH:mm:ss").tz(this.state.time_zone);
      var diff = moment.duration(end?.diff(start));
      var mins = Math.floor(diff.asMinutes());
      // var hours = Math.floor(diff.asHours);
      let configstatus;

      let status;
      // let emergency;
      this.devicename = "";

      if (mins > 2) {
        status = (
          <span className="blink badge badge-dot p-1 mr-4 bg-danger text-white">
            Disconnected
          </span>
        );
      } else {
        status = (
          <span className="blink badge badge-dot p-1 mr-4 bg-success text-white">
            Connected
          </span>
        );
      }

      if (device.config_ver === config_ver) {
        configstatus = (
          <span className="blink badge p-1 mr-4 bg-success text-white">
            <i className="bg-success">Upto date</i>
          </span>
        );
      } else {
        configstatus = (
          <span className="blink badge p-1 mr-4 bg-danger text-white">
            <i className="bg-warning">Outdated</i>
          </span>
        );
      }

      let image;

      let server = device.is_server;

      if (server === true) {
        image = Laptop_IMG;
      } else {
        image = Mobile_IMG;
      }

      return (
        <tr key={index + 1} className="text-center">
          <th scope="row">
            <div>
              <img height="20" alt="mobile" src={image} />
            </div>
          </th>
          <td>{device.mac_id}</td>
          <td>{device.version}</td>
          <td>{device.lastupdated}</td>
          <td>
            <div className="d-flex align-items-center">
              <div>
                <span>{Math.floor(device.battery)}%</span>
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${device.battery}%` }}
                  />
                </div>
              </div>
            </div>
          </td>
          <td>{device.scans}</td>
          <td>{device.pending}</td>
          <td>{configstatus}</td>

          <td>{status}</td>
        </tr>
      );
    });

    return (
      <div className="col-xl-12 mb-5 mt-5 mb-xl-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="mb-0">Device Monitoring</h3>
              </div>
              <div className="col text-right">
                {/* <a href="#!" className="btn btn-sm btn-primary">
                  Download
                </a> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">Device</th>
                  <th scope="col">ID</th>
                  <th scope="col">Version</th>
                  <th scope="col">Updated</th>
                  <th scope="col">Battery</th>
                  <th scope="col">Transactions</th>
                  <th scope="col">Ticket</th>
                  <th scope="col">Config status</th>

                  {/* <th scope="col">Server</th> */}
                  {/* <th scope="col">Emergency</th> */}
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>{eventmonitorsdata}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
