import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IconButton from "@material-ui/core/IconButton";
import Button from "reactstrap/lib/Button";


export default function EnhancedTable({
  categories,
  apb_toggle,
  edit,
  deletecat,
  // search,
  is_disable_toggle,
  pagination,
  getcategories,
  setsearch,
}) {

  const categorylist = categories.map((category, index) => {
    return (
      <tr className=" text-center" key={index}>
        <td style={{ whiteSpace: "pre-wrap" }}>{category.name}</td>

        <td style={{ whiteSpace: "pre-wrap" }}>{category.day}</td>
        <td>{category.category_id}</td>
        <td>{category.session}</td>
        <td>{category.param4}</td>
        <td>{category.type}</td>

        <td>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={category.apb}
              onChange={() => {
                apb_toggle(category);
              }}
            />
            <span className="custom-toggle-slider rounded-circle " />
          </label>
        </td>
        <td>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={category.is_disable ? false : true}
              onChange={() => {
                is_disable_toggle(category);
              }}
            />
            <span className="custom-toggle-slider rounded-circle " />
          </label>
        </td>
        <td>
          <button
            onClick={() => {
              // alert('s')
              edit(category);
              // this.edit(row)
            }}
            className="btn btn-sm btn-icon btn-2 btn-info"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-settings" />
            </span>
          </button>
        </td>
        <td>
          <button
            onClick={() => deletecat(category)}
            className="btn btn-sm btn-icon btn-2 btn-danger"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div
      className=""
      style={{ backgroundColor: "#fff", padding: 30, width: "90vw" }}
    >
      <center>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            placeholder="Search by Name, Session ID, Session name or Category ID"
            onChange={(e) => {
              setsearch(e.target.value);
            }}
            style={{
              width: 400,
              height: 30,
              border: "1px solid #ccc",
              borderRadius: 2,
              alignSelf: "center",
              marginBottom: 15,
              borderTopColor: "#fff",
              borderLeftColor: "#fff",
              borderRightColor: "#fff",
              fontSize: 14,
            }}
          />
          <div style={{ marginLeft: 10, marginBottom: 40 }}>
            <Button
              onClick={() => getcategories()}
              style={{ backgroundColor: "#192A4D", color: "#fff" }}
            >
              Search
            </Button>
          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: 18 }}>
          <thead className="text-black text-center">
            <tr>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Name
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Session
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Category ID
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Session ID
              </th>

              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Venue Code
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Ticket Type
              </th>

              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                APB
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Sync to mobile
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Edit
              </th>
              <th scope="col" style={{ whiteSpace: "pre-wrap" }}>
                Delete
              </th>
            </tr>
          </thead>

          <tbody>{categorylist}</tbody>
        </table>
      </center>

      {categories.length === 0 && (
        <div>
          <div style={{ textAlign: "center" }}>
            <h2>No matching records</h2>
          </div>
        </div>
      )}

      {categories.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 10, marginRight: 30 }}>
            <h5>
              Rows {pagination?.pagedetails} of {pagination?.total} Records
            </h5>
          </div>

          <IconButton
            onClick={() => getcategories(pagination?.page - 1)}
            disabled={pagination?.hasPrevPage ? false : true}
          >
            <NavigateBeforeIcon fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => getcategories(pagination?.page + 1)}
            disabled={pagination?.hasNextPage ? false : true}
          >
            <NavigateNextIcon fontSize="medium" />
          </IconButton>
        </div>
      )}
    </div>
  );
}
