import {
  SET_EVENT,
  SET_EVENT_ERROR,
  DELETE_EVENT,
  GATE_EDIT,
  CHANGE_SESSION
} from "../actions/types";
const initialState = {
  eventdata: {},
  gateedit: false,
  error: {},
  session: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EVENT:
      return {
        ...state,
        eventdata: action.payload,
      };
    case SET_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_EVENT:
      return {
        ...state,
        eventdata: action.payload,
        error: {},
      };
    case GATE_EDIT:
      return {
        ...state,
        gateedit: action.payload,
        error: {},
      };
    case CHANGE_SESSION:
      return {
        ...state,
        session: action.payload,
        error: {},
      };
    default:
      return state;
  }
}
