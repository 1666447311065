import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  // Button,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import "./style.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AnalyticsCard from "../../../components/AnalyticsCard";
// import EntryGraphCard from "../../../components/EntryGraph";
import Reports from "../Reports";
import SessionSelect from "../SessionSelect";

import EntryGraph2 from "../../../components/EntryGraph2";
import TotalOccupancy from "../../../components/TotalOccupancy";
import { isMobile } from "react-device-detect";

function GatesAnalytics({ session, theme, event }) {
  const [occupancy, setoccupancy] = useState({ total_in: 0, total_exit: 0 });
  const [gatename, setgatename] = useState("All Gates");

  const gateselected = (gate) => {
    if (gate) {
      setgatename(gate.gatename);
    }
  };

  useEffect(() => {

    let fetchData = totalOccupancy_fetch();

    return fetchData;
  }, [gatename, session]);

  useEffect(() => {
    let interval = setInterval(() => {
      totalOccupancy_fetch();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [gatename, session]);

  const totalOccupancy_fetch = () => {
    let payload_data = {
      event_obj_id: event?.groupevents?.length > 0 ? [event._id, ...event.groupevents.map((event) => event.event_obj_id)] : [event._id],
      session,
      gate: gatename,
    };

    if (event._id && session && gatename) {



      axios
        .post(`/cms/v3/analytics/occupancy`, payload_data)
        .then((response) => {
          // console.log(response.data)
          if (response.data.success) {
            setoccupancy({
              total_in: response.data.total_in,
              total_exit: response.data.total_out,
            });
          } else {
            setoccupancy({ total_in: 0, total_exit: 0 });
          }
        });
    } else {
      setoccupancy({ total_in: 0, total_exit: 0 });
    }
  };

  return event ? <div>
    <Container
      fluid
      className={theme.theme === "light" ? "theme-light" : "theme-dark"}
    >
      <Row className="align-items-center py-5">
        <Col lg="6" xs="7">
          <h6 className="h2 text-white d-inline-block mb-0">Analytics</h6>{" "}
          <Breadcrumb
            className="d-none d-md-inline-block ml-md-4"
            listClassName="breadcrumb-links breadcrumb-dark"
          >
            <BreadcrumbItem>
              <Link to="/home">
                <i className="fas fa-home" />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>


              {String(event.eventname).length > 40 ?
                String(event?.eventname).substring(0, 40) + '... ' :
                event?.eventname
              }

            </BreadcrumbItem>
            <BreadcrumbItem aria-current="page" className="active">
              {event.event_id_bms}
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: 0,
          marginBottom: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="align-items-center"
      >
        <Col xl="8">
          <SessionSelect />
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: 80,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AnalyticsCard event_obj_id={event._id} groupevents={event.groupevents} />
      </Row>
    </Container>
    <Container style={{ position: "relative", bottom: 80 }} fluid>
      <Row>
        {isMobile ? (
          <>
            <Col md="12" xl="4">
              {occupancy ? (
                <TotalOccupancy
                  occupancy={occupancy}
                  event_obj_id={event._id}
                />
              ) : null}
            </Col>
            <Col md="12" xl="8">
              <EntryGraph2 groupevents={event.groupevents} />
            </Col>
          </>
        ) : (
          <>
            <Col md="12" xl="8">
              <EntryGraph2 groupevents={event.groupevents} />
            </Col>
            <Col md="12" xl="4">
              {
                <TotalOccupancy
                  occupancy={occupancy}
                  event_obj_id={event._id}
                  gateselected={gateselected}
                  groupevents={event.groupevents}
                />
              }
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Reports eventtype={event.eventtype} groupevents={event.groupevents} />
      </Row>
    </Container>
  </div> : null
}


GatesAnalytics.propTypes = {
  theme: PropTypes.oneOf(["dark", "light"]),
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  theme: state.auth,
  session: state.event.session,
  event: state.event.eventdata,
});

export default connect(mapStateToProps, {})(GatesAnalytics);
